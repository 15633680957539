import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Modal from '../../../components/modal';
import Preloader from '../../../components/preloader';
import EmptyState from '../../../components/empty-state-container';
import IconMenu from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import {connect} from 'react-redux';

let envList;

class ManageEnv extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dbFilterCriteria:"",
      emptyState:"You do not have any environments.",
      deleteVal:"",
      modalOpen:false,
      modalTitle:"",
      modalContent:"",
      modalPrimaryButton:"",
      modalSecondaryButton:""
    };
  }


componentDidMount=()=>{
  this.setState({
    dbFilterCriteria:""
  });
}




editEnv = (env) => {
    //this.props.getRuleSetContent("edit-account");
    this.props.onClick({env});
}

modalClose=()=>{
  this.setState({
    modalOpen:false
  });
}

modalConfirm=()=>{

  this.setState({
    modalOpen:false
  });
  this.props.deleteEnv(this.state.deleteVal);
}

deleteItem = (item) => {
    //this.props.getRuleSetContent(value);
    //this.props.onClick(value);
    this.setState({
      modalOpen:true,
      modalTitle:"Delete Environment",
      modalContent:"Are you sure you want to delete \""+item.envName+"\"?",
      modalPrimaryButton:"Yes",
      modalSecondaryButton:"No",
      modalState:"delete",
      deleteVal:item
    });
}

  createNewEnv = () => {
    this.props.onClick('new');
}





   render() {






     const {isLoading} = this.props;
     let manageContent,envListContent;

     if(isLoading.includes('envlist')){
       manageContent=<Preloader loading={true} /> ;
     }
     else if(this.props.envList !== undefined){

      const filterCriteria=this.state.dbFilterCriteria;
      envList = this.props.envList.filter(function (e) {  return e.envName.toLowerCase().includes(filterCriteria.toLowerCase()); } );


      if(envList.length>0){
        envListContent=<table className='manage-ruleset-table'>
        <tbody>
             {envList.map((env,index) => <tr key={index}>
               <td onClick={this.editEnv.bind(this,env)} className='user' >{env.envName+" - "+env.envTypeName}</td>
               <td>
               <IconMenu
                   iconButtonElement={<IconButton size="large"><i className='material-icons'>more_horiz</i></IconButton>}
                   anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                   targetOrigin={{horizontal: 'right', vertical: 'top'}}
                   className='actions-menu'
                 >
                   <MenuItem
                   primaryText="Delete"
                   className='wl-group-card-menu'
                   onClick={this.deleteItem.bind(this,env)}  />
               </IconMenu>
                </td>
               </tr>)}
       </tbody>
        </table>
      }else{
        envListContent=<EmptyState className="margin-top-0" message={this.state.emptyState} />
      }
       manageContent=<Row>

       <Col sm={4} xs={6}>{envListContent}</Col>
       <Col sm={4} xs={6} className="manage-rule-buttons">

          <Button href=""  onClick={this.createNewEnv.bind(this)} label="Add a new environment" className='createruleset-button'></Button>
       </Col>

       </Row>
     }

     return (
       <div>
       <Modal
        open={this.state.modalOpen}
        title={this.state.modalTitle}
        content={this.state.modalContent}
        primaryButton={this.state.modalPrimaryButton}
        secondaryButton={this.state.modalSecondaryButton}
        close={this.modalClose}
        confirm={this.modalConfirm}
        />
       <div className="manage-ruleset margin-top-30">

       {manageContent}

       </div>

         </div>

     );
   }
}


const mapStateToProps = (state) => {
  return{
    isLoading: state.databaseReducer.isLoading.concat(state.commonReducer.isLoading),
    errors: state.databaseReducer.errorMessage
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {

  }
}


export default connect(mapStateToProps,mapDispatchToProps) (ManageEnv);
