import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';



class ManageRsMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open:false,
      anchorEl:null
    };
  }



  handleMenuClick = (event) => {
    this.setState({anchorEl:event.currentTarget,open:true})
    };

   handleMenuClose = () => {
     this.setState({anchorEl:null,open:false})
      };

  deleteRuleset=(ruleset,id)=>{
    this.handleMenuClose();
    this.props.deleteRuleset(ruleset,id);
  }





   render() {

     return (
            <div>
                  <IconButton
                       aria-label="more"
                       id={this.props.ruleset.id}
                       aria-controls={open ? this.props.ruleset.id : undefined}
                       aria-expanded={open ? 'true' : undefined}
                       aria-haspopup="true"
                       onClick={this.handleMenuClick.bind(this)}
                     >
                           <i className='material-icons'>more_horiz</i>
                  </IconButton>

                  <Menu
                    id={this.props.ruleset.id}
                    anchorEl={this.state.anchorEl}
                    open={this.state.open}
                    className="flyout-menu"
                    onClose={this.handleMenuClose.bind(this)}
                  >
                      <MenuItem key={this.props.ruleset.id+'delete'} onClick={this.deleteRuleset.bind(this,this.props.ruleset,this.props.ruleset.id)}>
                        Delete
                      </MenuItem>
                  </Menu>

             </div>
     );
   }
}


export default ManageRsMenu;
