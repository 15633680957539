import React from "react";
import TextField from "@mui/material/TextField";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import ComponentErrorBoundary from '../../../components/component-error-boundary';

export default function Tags(props) {

  let placeholder;
  let dropdownClassName;
  let customVal
  let dropDownOptions=props.fileColumns;
  if(props.value.length===0){
    placeholder='Choose the field(s)';
  }


  const handleKeyDown = event => {

    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      if (event.target.value.length > 0) {
        if(event.target.value.indexOf(' ')>=0){
           customVal=[...props.value,{label:event.target.value.replace(/ /g,"space"),value:event.target.value,origin:'custom'}];
        }else{
           customVal=[...props.value,{label:event.target.value,value:event.target.value,origin:'custom'}];
        }

        props.onSelectValue(customVal);
      }
    }

    // switch (event.key) {
    //   case "Enter":
    //   case " ": {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     if (event.target.value.length > 0) {
    //       var customVal=[...props.value,{label:event.target.value,origin:'custom'}];
    //       props.onSelectValue(customVal);
    //     }
    //     break;
    //   }
    //   default:
    // }


  };




  return (
    <div>
    <ComponentErrorBoundary value="primary id autocomplete">
      <Autocomplete
        multiple
        freeSolo
        autoHighlight
        classes={{ listbox: dropdownClassName }}
        options={dropDownOptions}
        getOptionLabel={option => option.label || option.COLUMN_NAME || option }
        value={props.value}
        isOptionEqualToValue={(value)=>{}}
        onChange={(event, newValue) => {props.onSelectValue(newValue);}}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip label={option.label || option.COLUMN_NAME || option} classes={{ root: option.origin }} {...getTagProps({ index })} />
          ))
        }
        renderInput={params => {
          params.inputProps.onKeyDown = handleKeyDown;
          return (
            <TextField
              {...params}
              variant="standard"
              label={props.label}
              placeholder={placeholder}
              margin="normal"
              error={props.ruleContentErrorStatus}
              helperText={props.ruleContentErrorText}
              fullWidth
            />
          );
        }}
      />
      </ComponentErrorBoundary>
    </div>
  );
}
