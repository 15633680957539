import { settingsActions } from './settings.actions';
import {removeItemFromArray} from "../../util/util";

export const initialState = {
    reconList: [],
    isLoading: [],
    error:[],
    availableDBTblColforSource1:[],
    availableDBTblColforSource2:[]
};

export function settingsReducer (state= initialState, action){


  let status;

  switch(action.type){

      //------- Add Rule Request-------------
      case settingsActions.RECON_ADDRULESET_REQUEST:
      return{
        ...state,
        isLoading:[...state.isLoading,'addrule']
      };

      //------- Add Rule Success-------------
      case settingsActions.RECON_ADDRULESET_SUCCESS:
      return{
        ...state,
        isLoading:removeItemFromArray(state.isLoading,'addrule')
      };

      //------- Add Rule Failure-------------
      case settingsActions.RECON_ADDRULESET_FAILURE:
      status="";
      action.payload.response===undefined?status="404":status=action.payload.response.status;
      return{
        ...state,
        error:[...state.error,status]
      };


      //------- Update Rule Request-------------
      case settingsActions.RECON_UPDATERULESET_REQUEST:
      return{
        ...state,
        isLoading:[...state.isLoading,'updaterule']
      };

      //------- Update Rule Success-------------
      case settingsActions.RECON_UPDATERULESET_SUCCESS:
      return{
        ...state,
        isLoading:removeItemFromArray(state.isLoading,'updaterule')
      };

      //------- Update Rule Failure-------------
      case settingsActions.RECON_UPDATERULESET_FAILURE:
      status="";
      action.payload.response===undefined?status="404":status=action.payload.response.status;
      return{
        ...state,
        error:[...state.error,status]
      };


      //------- Export Rule Request-------------
      case settingsActions.RECON_EXPORTRULESET_REQUEST:
      return{
        ...state,
        isLoading:[...state.isLoading,'exportrule']
      };

      //------- Export Rule Success-------------
      case settingsActions.RECON_EXPORTRULESET_SUCCESS:
      return{
        ...state,
        //exportRulesetContent:action.payload,
        isLoading:removeItemFromArray(state.isLoading,'exportrule')
      };

      //------- Export Rule Failure-------------
      case settingsActions.RECON_EXPORTRULESET_FAILURE:
      status="";
      action.payload.response===undefined?status="404":status=action.payload.response.status;
      return{
        ...state,
        error:[...state.error,status]
      };

      //------- Duplicate Rule Request-------------
      case settingsActions.RECON_DUPLICATERULESET_REQUEST:
      return{
        ...state,
        isLoading:[...state.isLoading,'duplicaterule']
      };

      //------- Duplicate Rule Success-------------
      case settingsActions.RECON_DUPLICATERULESET_SUCCESS:
      return{
        ...state,
        isLoading:removeItemFromArray(state.isLoading,'duplicaterule')
      };

      //------- Duplicate Rule Failure-------------
      case settingsActions.RECON_DUPLICATERULESET_FAILURE:
      status="";
      action.payload.response===undefined?status="404":status=action.payload.response.status;
      return{
        ...state,
        error:[...state.error,status]
      };


      //------- Delete Rule Request-------------
      case settingsActions.RECON_DELETERULESET_REQUEST:
      return{
        ...state,
        isLoading:[...state.isLoading,'deleterule']
      };

      //------- Duplicate Rule Success-------------
      case settingsActions.RECON_DELETERULESET_SUCCESS:
      return{
        ...state,
        isLoading:removeItemFromArray(state.isLoading,'deleterule')
      };

      //------- Duplicate Rule Failure-------------
      case settingsActions.RECON_DELETERULESET_FAILURE:
      status="";
      action.payload.response===undefined?status="404":status=action.payload.response.status;
      return{
        ...state,
        error:[...state.error,status]
      };



      //-------Recon Request-------------
      case settingsActions.GET_RECON_REQUEST:
      return{
        ...state,
        isLoading:[...state.isLoading,'recon']
      };

      //-------Recon Success-------------
      case settingsActions.GET_RECON_SUCCESS:
      return{
        ...state,
        reconData:action.payload,
        reconRunData:action.payload.results,
        isLoading:removeItemFromArray(state.isLoading,'recon')
      };

      //-------Recon Failure-------------
      case settingsActions.GET_RECON_FAILURE:
      status="";
      action.payload.response===undefined?status="404":status=action.payload.response.status;
      return{
        ...state,
        error:[...state.error,status]
      };


      //-------Update Run details-------------
      case settingsActions.UPDATE_RECONRUN_DETAILS:

      let selectedStatus = action.filter;
      let updatedReconRun=[];

      switch(selectedStatus){
        case "pass":
              for (let i = 0; i < state.reconData.results.length; i++) {
                if(state.reconData.results[i].hasFailedItem===false)
                  {
                    updatedReconRun.push(state.reconData.results[i]);
                 }
             }
          break;
          case "fail":
                for (let i = 0; i < state.reconData.results.length; i++) {
                  if(state.reconData.results[i].hasFailedItem===true)
                    {
                      updatedReconRun.push(state.reconData.results[i]);
                   }
               }
            break;
          case "all":
                  updatedReconRun=state.reconData.results;
              break;
        default:
          break;
      }



      return{
        ...state,
        reconRunData:updatedReconRun
      };






      default:
        return state;
  }
}
