import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import { Router} from "react-router-dom";
import appStore from './app.store'
import './styles/main.scss';
import App from './app';
import history from './history';

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import '@aws-amplify/ui-react/styles.css';

import { AmplifyProvider } from '@aws-amplify/ui-react';



Amplify.configure(awsExports);

appStore.subscribe(() => console.log('store',appStore.getState()));

ReactDOM.render(
<Router history={history}>
  <Provider store={appStore}>
    <AmplifyProvider>
      <App />
    </AmplifyProvider>
  </Provider>
</Router>, document.getElementById('root'));
