import React, { Component } from 'react';
import Backdrop from '@mui/material/Backdrop';
import ManageDatabase from './components/db-manage-connection';
import DBConnection from './components/db-add-edit-connection';
import Snackbar from '@mui/material/Snackbar';
import Banner from '../../components/banner';
import Modal from '../../components/modal';
import ErrorBoundary from '../../components/error-boundary';
import Auth from '../../auth/auth';
import {connect} from 'react-redux';
import {
      loadDBList,
      testDBConn,
      addDBConn,
      deleteDBConn,
      loadDBTablesList,
      loadDBDetails
    } from './database.actions';


const auth = new Auth();
let orgId;


class DatabaseConnection extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pageNavContainer:"hide",
      backdrop:false,
      connId:null,
      queryId:null,
      dbData:{name:"",url:"",userid:"",password:"",port:"",databaseName:"",env:null,type:null},
      step1:"",
      step2:"hide",
      snackbarStatus: false,
      bannerType: "danger",
      showBanner: false,
      bannerTitle:"",
      bannerMessage:"",
      actionTriggered:false,
      triggeredItemName:"",
      actionName:"",
      modalOpen:false,
      modalTitle:"",
      modalState:"",
      modalContent:"",
      modalPrimaryButton:"",
      modalSecondaryButton:"",
      dbConnectionTitle:"",
      saveDbId:null,
      pageState:0
    };
    orgId  = auth.getOrgId();
    this.props.getDBList(orgId);
  }





  componentDidUpdate(prevProps) {
    if (prevProps.isLoading !== this.props.isLoading && this.state.actionTriggered === true) {

      if(this.props.isLoading.includes('adddb')){
       this.state.saveDbId === null ? this.setState({snackbarStatus:true,actionName:"add"}) : this.setState({snackbarStatus:true,actionName:"update"});
      }
      else if(this.props.isLoading.includes('deletedb')){
       this.setState({snackbarStatus:true,actionName:"delete"});
      }
      else{
        if(this.state.actionName==="add"){this.saveSuccess();}
        else if(this.state.actionName==="update"){this.updateSuccess();}
        else if(this.state.actionName==="delete"){this.deleteSuccess();}
        this.setState({snackbarStatus:false,actionTriggered:false,actionName:""});
      }
    }

    // ---- Error handing ------

    if (this.props.error !== prevProps.error) {
      this.setState({snackbarStatus:false,showBanner:true,bannerType:"danger",bannerTitle:"Oh snap! Something went wrong!",bannerMessage:"Please contact your admin."});
    }


    if (this.props.dbDetails !== prevProps.dbDetails) {
      this.setState({
        dbData:this.props.dbDetails
      });
    }

    if (this.props.queryDetails !== prevProps.queryDetails) {
      this.setState({
        dbQuery:this.props.queryDetails
      });
    }


  }

saveSuccess=()=>{
  this.props.getDBList(orgId);
  this.navigateToBegin();
  var message = this.state.triggeredItemName+' has been added successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

updateSuccess=()=>{
  this.props.getDBList(orgId);
  this.navigateToBegin();
  var message = this.state.triggeredItemName+' has been updated successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

deleteSuccess=()=>{
  this.props.getDBList(orgId);
  var message = this.state.triggeredItemName+' has been deleted successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

navigateToBegin=()=>{
  this.props.parentNavBar(1);
  this.setState({
    pageState:0,
    pageNavContainer:"hide"
  });
}

cancelFlow=()=>{
  this.navigateToBegin();
}

saveDBFlow=(data,flowName,dbName)=>{
  this.setState({actionTriggered:true,triggeredItemName:dbName,saveDbId:data.id});
  flowName==='new' ? this.props.addDBConnection(data) : this.props.addDBConnection(data)
}

deleteDB=(data)=>{
this.setState({actionTriggered:true,triggeredItemName:data.dbConnectionName});
this.props.deleteDBConnection(data);
}


testDBConnect=(dbObj)=>{
  this.props.testDBConnReq(dbObj);
}


toggleBackdrop=()=>{
  this.state.backdrop ?
  this.setState({backdrop:false,pageNavContainer:'page-nav-container', appContentContainer:"app-content-container in"}) :
  this.setState({backdrop:true,pageNavContainer:'page-nav-container reduce-zindex', appContentContainer:"app-content-container in has-backdrop"})
}

hideBackNavigation=()=>{
  this.setState({
    appContentContainer:"app-content-container",
    pageNavContainer:"hide"
  });
}


getDBTablesList=(db)=>{
  this.props.getDBTablesList(db)
}

handleTabChange = (value) => {
 this.setState({
    slideIndex: value
  });
};


  goToTop=()=>{
    document.getElementById("app").scrollTo(0,0);
  }

  dropdownSelector = (name,id,value) => {
              this.setState({[name]:value});
          }

  dismissBanner = () => {
                this.setState({showBanner:false});
         }

 snackHandler = (state) =>{
   this.setState({snackbarStatus:state});
 }



 modalClose=()=>{
   this.setState({
     modalOpen:false
   });
 }

 modalConfirm=()=>{
   this.setState({
     modalOpen:false
   });
 }


 selectDB= (value) => {

   this.dismissBanner();
   this.props.parentNavBar(2);

   if(value==="new"){
     this.setState({
       pageState:1,
       pageNavContainer:"page-nav-container overlap",
       dbData:[{name:"",env:null,server:"",databaseName:"",user:"",password:"",port:""}],
       connId:null,
       dbConnectionTitle:"Add a new database connection"
     });
   }

   else {
     this.props.getDBDetails(value.db.connId);
     this.setState({
       pageState:1,
       pageNavContainer:"page-nav-container overlap",
       dbConnectionTitle:"View & Edit database connection",
       connId:value.db.connId,
       dbData:this.props.dbDetails
     });
   }
 }






  goBack = (level) => {
                    this.props.parentNavBar(1);
                    if(level===0){
                      this.setState({
                        pageState:0,
                        pageNavContainer:"hide"
                      });
                    }
                }


   render() {

     return (
       <div className="sub-module">

       <Backdrop open={this.state.backdrop}></Backdrop>

       <Snackbar
           open={this.state.snackbarStatus}
           message="Please wait..."
           className="snack-bar"
         />

       <div className={this.state.pageNavContainer}>
         <a onClick={this.goBack.bind(this,0)} className={this.state.level0}>&lt; Manage Database Connections</a>
       </div>


       <Modal
        open={this.state.modalOpen}
        title={this.state.modalTitle}
        content={this.state.modalContent}
        primaryButton={this.state.modalPrimaryButton}
        secondaryButton={this.state.modalSecondaryButton}
        close={this.modalClose}
        confirm={this.modalConfirm}
        />

       <Banner
          type={this.state.bannerType}
          hasAlerts={this.state.showBanner}
          header={this.state.bannerTitle}
          body={this.state.bannerMessage}
          dismissBanner={this.dismissBanner.bind(this)}
          />


{
    this.state.pageState===0 ?
      <ErrorBoundary>
      <h2>Manage Database Connections</h2>
      <ManageDatabase
        onClick={this.selectDB.bind(this)}
        dbList={this.props.dbList}
        orgId={orgId}
        deleteDB={this.deleteDB}
        isLoading={this.props.isLoading}
        />
      </ErrorBoundary> :

      <ErrorBoundary>
      <h2>{this.state.dbConnectionTitle}</h2>
        <DBConnection
          cancelFlow={this.cancelFlow}
          saveFlow={this.saveDBFlow}
          dbList={this.props.dbList}
          orgId={orgId}
          dbData={this.state.dbData}
          connId={this.state.connId}
          testDBConnect={this.testDBConnect.bind(this)}
          dbTestStatus={this.props.dbTestStatus}
          hideBackNavigation={this.hideBackNavigation}
          toggleBackdrop={this.toggleBackdrop.bind(this)}
          />
        </ErrorBoundary>

  }

       </div>
     );
   }
}

const mapStateToProps = (state) => {
  return{
    error: state.databaseReducer.error,
    dbList: state.databaseReducer.dbList,
    queryList: state.databaseReducer.queryList,
    dbTestStatus: state.databaseReducer.dbTestStatus.status,
    dbDetails: state.databaseReducer.dbDetails,
    dbTablesList:state.databaseReducer.dbTablesList,
    isLoading:state.databaseReducer.isLoading
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    getDBList : (orgId) => {
      dispatch(loadDBList(orgId));
    },
    testDBConnReq : (data) => {
      dispatch(testDBConn(data));
    },
    addDBConnection : (data) => {
      dispatch(addDBConn(data));
    },
    deleteDBConnection : (data) => {
      dispatch(deleteDBConn(data));
    },
    getDBDetails : (dbId) => {
      dispatch(loadDBDetails(dbId));
    },
    getDBTablesList : (db) => {
      dispatch(loadDBTablesList(db));
    }

  }
}


export default connect(mapStateToProps,mapDispatchToProps) (DatabaseConnection);
