import React, { Component } from 'react';
import Backdrop from '@mui/material/Backdrop';
import ManageUserGroups from './components/manage-user-groups';
import AddUserGroup from './components/add-user-group';
import EditUserGroup from './components/edit-user-group';
import Snackbar from '@mui/material/Snackbar';
import Banner from '../../components/banner';
import Modal from '../../components/modal';
import ErrorBoundary from '../../components/error-boundary';
import Auth from '../../auth/auth';
import {connect} from 'react-redux';
import {
      loadUserGroup,
      loadUserGroupDetails,
      addUserGroup,
      updateUserGroup,
      deleteUserGroup
    } from './users.actions';






const auth = new Auth();
let orgId;

class AppUserGroups extends Component {

  constructor(props) {
    super(props);
    this.state = {
      appContentContainer:"app-content-container",
      userListClass: "page-content select-container in",
      addUserClass: "page-content results-container",
      editUserClass: "page-content results-container",
      pageNavContainer:"hide",
      backdrop:false,
      userData:{user:{name:"",email:"",id:""}},
      step1:"",
      step2:"hide",
      userDetailsPagetTile:"",
      snackbarStatus: false,
      bannerType: "danger",
      showBanner: false,
      bannerTitle:"",
      bannerMessage:"",
      actionTriggered:false,
      triggeredUserName:"",
      actionName:"",
      modalOpen:false,
      modalTitle:"",
      modalState:"",
      modalContent:"",
      modalPrimaryButton:"",
      modalSecondaryButton:"",
      pageState:"manage"
    };

    this.props.getUserGroupList();
  }





  componentDidUpdate(prevProps) {
    if (prevProps.isLoading !== this.props.isLoading && this.state.actionTriggered === true) {

      if(this.props.isLoading.includes('addusergroup')){
       this.setState({snackbarStatus:true,actionName:"add"});
      }
      else if(this.props.isLoading.includes('updateusergroup')){
       this.setState({snackbarStatus:true,actionName:"update"});
      }
      else if(this.props.isLoading.includes('deleteusergroup')){
       this.setState({snackbarStatus:true,actionName:"delete"});
      }
      else{
        if(this.state.actionName==="add"){this.saveSuccess();}
        else if(this.state.actionName==="update"){this.updateSuccess();}
        else if(this.state.actionName==="delete"){this.deleteSuccess();}
        this.setState({snackbarStatus:false,actionTriggered:false,actionName:""});
      }
    }

    // ---- Error handing ------

    if (this.props.error !== prevProps.error) {
      this.setState({snackbarStatus:false,showBanner:true,bannerType:"danger",bannerTitle:"Oh snap! Something went wrong!",bannerMessage:"Please contact your admin."});
    }

    

  }

saveSuccess=()=>{
  this.props.getUserGroupList(orgId);
  this.navigateToBegin();
  var message = this.state.triggeredUserName+' has been added successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

updateSuccess=()=>{
  this.props.getUserGroupList(orgId);
  this.navigateToBegin();
  var message = this.state.triggeredUserName+' has been updated successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

deleteSuccess=()=>{
  this.props.getUserGroupList(orgId);
  var message = this.state.triggeredUserName+' has been deleted successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

navigateToBegin=()=>{
  this.props.parentNavBar(1);
  this.setState({
    pageState:"manage",
    pageNavContainer:"hide"
  });
}

cancelFlow=()=>{
  this.navigateToBegin();
}

deleteUser=(data)=>{
this.setState({actionTriggered:true,triggeredUserName:data.name});
this.props.deleteUserGroupRequest(data);
}


saveFlow=(data,flowName,userName)=>{

  this.setState({actionTriggered:true,triggeredUserName:userName});
  flowName==='new' ? this.props.addUserGroupRequest(data) : this.props.updateUserGroupRequest(data)
}

toggleBackdrop=()=>{
  this.state.backdrop ?
  this.setState({backdrop:false,pageNavContainer:'page-nav-container', appContentContainer:"app-content-container in"}) :
  this.setState({backdrop:true,pageNavContainer:'page-nav-container reduce-zindex', appContentContainer:"app-content-container in has-backdrop"})
}

hideBackNavigation=()=>{
  this.setState({
    appContentContainer:"app-content-container",
    pageNavContainer:"hide"
  });
}


  goToTop=()=>{
    document.getElementById("app").scrollTo(0,0);
  }

  dropdownSelector = (name,id,value) => {
              this.setState({[name]:value});
          }

  dismissBanner = () => {
                this.setState({showBanner:false});
         }

 snackHandler = (state) =>{
   this.setState({snackbarStatus:state});
 }

 userLicenseLimit=()=>{
   this.setState({
     modalOpen:true,
     modalTitle:"User license limit",
     modalContent:"You've reached the maximum user license. If you require more licenses, please contact Caninsoft team.",
     modalPrimaryButton:"Ok",
     modalState:"limit",
     modalSecondaryButton:"null"
   });
 }

 modalClose=()=>{
   this.setState({
     modalOpen:false
   });
 }

 modalConfirm=()=>{
   this.setState({
     modalOpen:false
   });
 }


 selectUserGroup= (value) => {

   this.dismissBanner();
   this.props.parentNavBar(2);

   if(value==="new"){
     this.setState({
       pageState:"new",
       pageNavContainer:"page-nav-container overlap",
       userData:{user:{userID:"new", name:"", desc:"", list:[]}}
     });
   }

   else {
     this.props.getUserGroupDetails(value.user);
     this.setState({
       pageState:"edit",
       pageNavContainer:"page-nav-container overlap",
       userDetailsPagetTile:"View/Edit user group details",
       userData:this.props.userGroupDetails
     });
   }
 }



  goBack = (level) => {
                    this.props.parentNavBar(1);
                    if(level===0){
                      this.setState({
                        pageState:"manage",
                        pageNavContainer:"hide"
                      });
                    }
                }


   render() {

     return (
       <div className="sub-module">

       <Backdrop open={this.state.backdrop}></Backdrop>

       <Snackbar
           open={this.state.snackbarStatus}
           message="Please wait..."
           className="snack-bar"
         />

       <div className={this.state.pageNavContainer}>
         <a onClick={this.goBack.bind(this,0)} className={this.state.level0}>&lt; Manage User Groups</a>
       </div>


       <Modal
        open={this.state.modalOpen}
        title={this.state.modalTitle}
        content={this.state.modalContent}
        primaryButton={this.state.modalPrimaryButton}
        secondaryButton={this.state.modalSecondaryButton}
        close={this.modalClose}
        confirm={this.modalConfirm}
        />

       <Banner
          type={this.state.bannerType}
          hasAlerts={this.state.showBanner}
          header={this.state.bannerTitle}
          body={this.state.bannerMessage}
          dismissBanner={this.dismissBanner.bind(this)}
          />


      {
        this.state.pageState==="manage" ?
        <ErrorBoundary>
          <h2>Manage User Groups</h2>
            <ManageUserGroups
              onClick={this.selectUserGroup.bind(this)}
              userGroupList={this.props.userGroupList}
              orgId={orgId}
              deleteUser={this.deleteUser}
              isLoading={this.props.isLoading}
              userLicenseLimit={this.userLicenseLimit.bind(this)}
              />
          </ErrorBoundary> : null
      }

      {
        this.state.pageState==="new" ?
        <ErrorBoundary>
        <h2>Add User Group</h2>
          <AddUserGroup
            cancelFlow={this.cancelFlow}
            saveFlow={this.saveFlow}
            orgId={orgId}
            userData={this.state.userData}
            userListOption={userListOption}
            hideBackNavigation={this.hideBackNavigation}
            toggleBackdrop={this.toggleBackdrop.bind(this)}
            />
          </ErrorBoundary> : null
      }


      {
        this.state.pageState==="edit" ?
        <ErrorBoundary>
        <h2>Edit User Group</h2>
          <EditUserGroup
            cancelFlow={this.cancelFlow}
            saveFlow={this.saveFlow}
            orgId={orgId}
            userData={this.state.userData}
            hideBackNavigation={this.hideBackNavigation}
            toggleBackdrop={this.toggleBackdrop.bind(this)}
            userLicenseLimit={this.userLicenseLimit.bind(this)}
            />
          </ErrorBoundary> : null
      }




       </div>
     );
   }
}

const mapStateToProps = (state) => {
  return{
    error: state.usersReducer.error,
    userGroupList: state.usersReducer.userGroupList,
    isLoading:state.usersReducer.isLoading.concat(state.commonReducer.isLoading),
    userGroupDetails:state.usersReducer.userGroupDetails

  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    getUserGroupList : (orgId) => {
      dispatch(loadUserGroup(orgId));
    },
    getUserGroupDetails : (data) => {
      dispatch(loadUserGroupDetails(data));
    },
    addUserGroupRequest: (userGroupObj) => {
      dispatch(addUserGroup(userGroupObj));
    },
    updateUserGroupRequest: (userGroupObj) => {
      dispatch(updateUserGroup(userGroupObj));
    },
    deleteUserGroupRequest: (userGroupObj) => {
      dispatch(deleteUserGroup(userGroupObj));
    }
  }
}


export default connect(mapStateToProps,mapDispatchToProps) (AppUserGroups);
