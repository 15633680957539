import { reconResultsActions } from './recon-results.actions';

export const initialState = {
};

export function reconResultsReducer (state= initialState, action){


  let status,downLoadList,downLoadListOpen;

  switch(action.type){











      default:
        return state;
  }
}
