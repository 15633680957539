import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import Button from '../../../components/button';
import Preloader from '../../../components/preloader';
import ComponentErrorBoundary from '../../../components/component-error-boundary';
import Dropdown from './oneopt-autocomplete';

import {connect} from 'react-redux';

import {
              loadEnvTypeList
            } from '../../../common-redux/common.actions';

class OrgEnv extends Component {

  constructor(props) {
    super(props);
    this.state = {
      envName:"",
      envType:null,
      envDesc:"",
      envNameErrorStatus:false,
      envNameErrorText:"",
      envDescErrorStatus:false,
      envDescErrorText:"",
      saveButtonStatus:true,
      formErrorState:[],
      formErrorState:['envName','envType','envDesc']
    };
    this.props.getEnvTypeList();
  }


  componentDidUpdate(prevProps) {


  if (this.props.envDetails !== prevProps.envDetails) {
    this.setState({
      envName:this.props.envDetails.envName,
      envType:{envTypeId:this.props.envDetails.envTypeId,envTypeName:this.props.envDetails.envTypeName },
      envDesc:this.props.envDetails.envDesc,
      envNameErrorStatus:false,
      envNameErrorText:"",
      envDescErrorStatus:false,
      envDescErrorText:"",
      saveButtonStatus:true
    })

    if(this.props.envDetails.envId===null){
      this.setState({
        formErrorState:['envName','envType','envDesc']
      })
    } else{
      this.setState({
        formErrorState:[]
      })
    }

  }
 }

 cancelFlow=()=>{
    this.props.cancelFlow();
 }

 dropdownSelector = (name,value) => {
             this.formErrorRemoveValue(name);
             this.setState({[name]:value},()=>{this.enableSaveButton();});
           }

 saveFlow=()=>{

   let envObj={
     envId:this.props.envDetails.envId,
     envName: this.state.envName,
     envTypeId: this.state.envType.envTypeId,
     envDesc:this.state.envDesc,
     orgId: Number(this.props.orgId)
   }
   this.props.envDetails.envId === null ? this.props.saveFlow(envObj,'new',this.state.envName) : this.props.saveFlow(envObj,'update',this.state.envName);

 }



  handleTextfield = (event,item) => {
    this.setState({[item]:event.target.value});
  }

  checkNameDuplicates=()=>{
    let hasMatchingName = this.props.envList.filter(function (item) { return item.envName.toLowerCase() === event.target.value.toLowerCase(); });

    if(hasMatchingName.length !=0){

      if(hasMatchingName[0].envId!==this.props.envDetails.envId){
        this.setState({envNameErrorText:"You used this name already. Please try another.",envNameErrorStatus:true},()=>{this.enableSaveButton();});
        this.formErrorInsertValue("envName");
        return false;
      } else{
        this.setState({envNameErrorText:"",envNameErrorStatus:false},()=>{this.enableSaveButton();});
        this.formErrorRemoveValue("envName");
        return true;
      }

    }

    else{
      this.setState({envNameErrorText:"",envNameErrorStatus:false},()=>{this.enableSaveButton();});
      this.formErrorRemoveValue("envName");
      return true;
    }
  }


  formErrorRemoveValue=(attr)=>{
    if (this.state.formErrorState.find(o => o === attr)){
      this.setState({formErrorState:this.state.formErrorState.filter(val=> val !== attr)});
    }
  }

  formErrorInsertValue=(attr)=>{
    if (!this.state.formErrorState.find(o => o === attr)){
      this.setState({formErrorState:[...this.state.formErrorState,attr]});
    }
  }


  validateField=(field)=>{

    let errorStatus=field+"ErrorStatus";
    let errorText=field+"ErrorText";


    if(this.state[field].length>1)
    {
      if(field==="envName"){
        this.checkNameDuplicates();
      } else{
        this.formErrorRemoveValue(field);
        this.setState({[errorStatus]:false,[errorText]:""},()=>{this.enableSaveButton();});
        return true;
      }
    }
    else{

      this.formErrorInsertValue(field);
      this.setState({[errorStatus]:true,[errorText]:"Invalid entry. Enter at least 2 characters."},()=>{this.enableSaveButton();});
      return false;
    }

  }





  enableSaveButton=()=>{
    if(this.state.formErrorState.length===0)
      {
      this.setState({saveButtonStatus:false});
      } else{
      this.setState({saveButtonStatus:true});
      }

}











   render() {

     const {isLoading} = this.props;
     let pageContent;

     if(isLoading.includes('envdetails')){
       pageContent=<Preloader loading={true} /> ;
     }
     else if(this.props.envdetails !== ""){
       pageContent=<div><div className="rounded-card">

       <Row className='label-value-pair'>
         <Col sm={4} xs={12}>
        <ComponentErrorBoundary value="Env Name">
         <FormControl fullWidth>
          <InputLabel htmlFor="Env Name">Name</InputLabel>
          <Input
            type="text"
            value={this.state.envName}
            onChange={(e)=>this.handleTextfield(e,"envName")}
            error={this.state.envNameErrorStatus}
            onBlur={this.validateField.bind(this,"envName")}
          />
          <FormHelperText className="Mui-error">{this.state.envNameErrorText}</FormHelperText>
         </FormControl>
         </ComponentErrorBoundary>
         </Col>
       </Row>

       <Row className='label-value-pair'>
         <Col sm={4} xs={12}>
        <ComponentErrorBoundary value="Env Code">
         <FormControl fullWidth>
         <Dropdown
              label='Select the environment type'
              fileColumns={this.props.envTypeList}
              onSelectValue={this.dropdownSelector.bind(this,'envType')}
              value={this.state.envType} />
         </FormControl>
         </ComponentErrorBoundary>
         </Col>
       </Row>

       <Row className='label-value-pair'>
         <Col sm={6} xs={12}>
        <ComponentErrorBoundary value="Env Desc">
         <FormControl fullWidth>
          <InputLabel htmlFor="Env Desc">Description</InputLabel>
          <Input
            type="text"
            value={this.state.envDesc}
            onChange={(e)=>this.handleTextfield(e,"envDesc")}
            error={this.state.envDescErrorStatus}
            onBlur={this.validateField.bind(this,"envDesc")}
          />
          <FormHelperText className="Mui-error">{this.state.envDescErrorText}</FormHelperText>
         </FormControl>
         </ComponentErrorBoundary>
         </Col>
       </Row>
       </div>
       <div className='margin-top-30'>
       <Row>
       <Col sm={4} xs={12} className=''>

        <Button label="Save" className='btn btn-action' disabled={this.state.saveButtonStatus} onClick={this.saveFlow.bind(this)}  />

         <Button label="Cancel" className='btn btn-default' onClick={this.cancelFlow.bind(this)} />

       </Col>

       </Row>
       </div>
       </div>
    }


     return (
       <div>{pageContent}</div>

     );
   }
}


const mapStateToProps = (state) => {
  return{
    isLoading: state.databaseReducer.isLoading.concat(state.commonReducer.isLoading),
    errors: state.databaseReducer.errorMessage,
    envTypeList: state.commonReducer.envTypeList
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getEnvTypeList : () => {
      dispatch(loadEnvTypeList());
    }
  }
}


export default connect(mapStateToProps,mapDispatchToProps) (OrgEnv);
