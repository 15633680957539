import React, { Component } from 'react';
import ImpactResults from './components/impact-results';
import {Col, Row} from 'react-bootstrap';
import Snackbar from '@mui/material/Snackbar';
import Banner from '../../components/banner';
import TextField from "@mui/material/TextField";
import Button from '../../components/button';
import ComponentErrorBoundary from '../../components/component-error-boundary';
import {connect} from 'react-redux';
import {
      loadFieldImpactAnalysis
    } from './impactor.actions';


class Impactor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      appContentContainer:"app-content-container",
      reconQuestionsClass: "page-content select-container in",
      reconResultsClass: "page-content results-container",
      pageNavContainer:"hide",
      fieldName:"",
      impactResultsContainer:"hide",
      snackbarStatus: false,
      bannerType: "danger",
      showBanner: false
    };

  }






  componentDidUpdate(prevProps) {
  if (this.props.error !== prevProps.error) {
    this.setState({showBanner:true});
  }
}


  goToTop=()=>{
    document.getElementById("app").scrollTo(0,0);
  }

  dropdownSelector = (name,id,value) => {
              this.setState({[name]:value});
          }

  dismissBanner = () => {
                this.setState({showBanner:false});
         }

 snackHandler = (state) =>{
   this.setState({snackbarStatus:state});
 }


 startAnalysis=()=>{
   this.props.getFieldImpactAnalysis(this.state.fieldName)
   this.setState({impactResultsContainer:"show"});

 }

 handleTextfield = (event,item) => {
     this.setState({[item]:event.target.value});
 }


  goBack = (level) => {
                    if(level===0){
                      this.setState({
                        appContentContainer:"app-content-container",
                        reconQuestionsClass: "page-content select-container in",
                        reconResultsClass: "page-content results-container",
                        pageNavContainer:"hide"
                      });
                    }
                }


   render() {




     return (
       <div className={this.state.appContentContainer}>

       <Snackbar
           open={this.state.snackbarStatus}
           message="Please wait..."
           className="snack-bar"
         />





       <div className={this.state.pageNavContainer}>
         <a onClick={this.goBack.bind(this,0)} className={this.state.level0}>&lt; Back</a>
       </div>

       <Banner
          type={this.state.bannerType}
          hasAlerts={this.state.showBanner}
          header="Oh snap! Something went wrong."
          body="Please refresh the page and try again."
          dismissBanner={this.dismissBanner.bind(this)}
          />

        <div id='' className={this.state.reconQuestionsClass}>
          <h2>Impactor</h2>
          <Row>
            <Col sm={12} xs={12}>

                 <div className='rounded-card'>
                 <h3>Enter a field name to analyze the change impact</h3>
                   <Row className='recon-input'>
                    <Col sm={3} xs={12}>
                    <ComponentErrorBoundary value="rule name textfield">
                      <TextField
                        label="Field name"
                        value={this.state.fieldName}
                        fullWidth
                        onChange={(e)=>this.handleTextfield(e,"fieldName")}
                      />
                      </ComponentErrorBoundary>
                   </Col>
                   </Row>
                   <Row>
                   <Col sm={3} xs={12}>
                   <Button label="Start analysis" className='btn btn-action' onClick={this.startAnalysis.bind(this)} />
                  </Col>
                  </Row>


                 </div>


            </Col>
          </Row>
          <Row className={this.state.impactResultsContainer}>
           <Col sm={12} xs={12}>
          <ImpactResults isLoading={this.props.isLoading} fieldImpactResults={this.props.fieldImpactResults}  />
          </Col>
         </Row>

        </div>

       </div>
     );
   }
}

const mapStateToProps = (state) => {
  return{
    error: state.impactorReducer.error,
    isLoading: state.impactorReducer.isLoading,
    fieldImpactResults: state.impactorReducer.fieldImpactResults
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    getFieldImpactAnalysis: (fieldName) => {
      dispatch(loadFieldImpactAnalysis(fieldName));
    }

  }
}


export default connect(mapStateToProps,mapDispatchToProps) (Impactor);
