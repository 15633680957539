import axios from 'axios';
import Auth from '../../auth/auth';

export const databaseActions ={
  GET_DB_LIST_REQUEST : 'GET_DB_LIST_REQUEST',
  GET_DB_LIST_SUCCESS : 'GET_DB_LIST_SUCCESS',
  GET_DB_LIST_FAILURE : 'GET_DB_LIST_FAILURE',
  TEST_DB_CONN_REQUEST : 'TEST_DB_CONN_REQUEST',
  TEST_DB_CONN_SUCCESS : 'TEST_DB_CONN_SUCCESS',
  TEST_DB_CONN_FAILURE : 'TEST_DB_CONN_FAILURE',
  ADD_DB_CONN_REQUEST : 'ADD_DB_CONN_REQUEST',
  ADD_DB_CONN_SUCCESS : 'ADD_DB_CONN_SUCCESS',
  ADD_DB_CONN_FAILURE : 'ADD_DB_CONN_FAILURE',
  DELETE_DB_CONN_REQUEST : 'DELETE_DB_CONN_REQUEST',
  DELETE_DB_CONN_SUCCESS : 'DELETE_DB_CONN_SUCCESS',
  DELETE_DB_CONN_FAILURE : 'DELETE_DB_CONN_FAILURE',
  LOAD_DB_DETAILS_REQUEST : 'LOAD_DB_DETAILS_REQUEST',
  LOAD_DB_DETAILS_SUCCESS : 'LOAD_DB_DETAILS_SUCCESS',
  LOAD_DB_DETAILS_FAILURE : 'LOAD_DB_DETAILS_FAILURE',
  GET_DB_TABLE_LIST_REQUEST : 'GET_DB_TABLE_LIST_REQUEST',
  GET_DB_TABLE_LIST_SUCCESS : 'GET_DB_TABLE_LIST_SUCCESS',
  GET_DB_TABLE_LIST_FAILURE : 'GET_DB_TABLE_LIST_FAILURE',
  ADD_QUERY_REQUEST : 'ADD_QUERY_REQUEST',
  ADD_QUERY_SUCCESS : 'ADD_QUERY_SUCCESS',
  ADD_QUERY_FAILURE : 'ADD_QUERY_FAILURE',
  DELETE_QUERY_REQUEST : 'DELETE_QUERY_REQUEST',
  DELETE_QUERY_SUCCESS : 'DELETE_QUERY_SUCCESS',
  DELETE_QUERY_FAILURE : 'DELETE_QUERY_FAILURE',
  TEST_QUERY_REQUEST : 'TEST_QUERY_REQUEST',
  TEST_QUERY_SUCCESS : 'TEST_QUERY_SUCCESS',
  TEST_QUERY_FAILURE : 'TEST_QUERY_FAILURE',
  LOAD_QUERY_DETAILS_REQUEST : 'LOAD_QUERY_DETAILS_REQUEST',
  LOAD_QUERY_DETAILS_SUCCESS : 'LOAD_QUERY_DETAILS_SUCCESS',
  LOAD_QUERY_DETAILS_FAILURE : 'LOAD_QUERY_DETAILS_FAILURE',
  ADD_ENV_REQUEST : 'ADD_ENV_REQUEST',
  ADD_ENV_SUCCESS : 'ADD_ENV_SUCCESS',
  ADD_ENV_FAILURE : 'ADD_ENV_FAILURE',
  UPDATE_ENV_REQUEST : 'UPDATE_ENV_REQUEST',
  UPDATE_ENV_SUCCESS : 'UPDATE_ENV_SUCCESS',
  UPDATE_ENV_FAILURE : 'UPDATE_ENV_FAILURE',
  DELETE_ENV_REQUEST : 'DELETE_ENV_REQUEST',
  DELETE_ENV_SUCCESS : 'DELETE_ENV_SUCCESS',
  DELETE_ENV_FAILURE : 'DELETE_ENV_FAILURE',
  LOAD_ENV_DETAILS_REQUEST : 'LOAD_ENV_DETAILS_REQUEST',
  LOAD_ENV_DETAILS_SUCCESS : 'LOAD_ENV_DETAILS_SUCCESS',
  LOAD_ENV_DETAILS_FAILURE : 'LOAD_ENV_DETAILS_FAILURE',

  ADD_DATA_SOURCE_REQUEST : 'ADD_DATA_SOURCE_REQUEST',
  ADD_DATA_SOURCE_SUCCESS : 'ADD_DATA_SOURCE_SUCCESS',
  ADD_DATA_SOURCE_FAILURE : 'ADD_DATA_SOURCE_FAILURE',
  UPDATE_DATA_SOURCE_REQUEST : 'UPDATE_DATA_SOURCE_REQUEST',
  UPDATE_DATA_SOURCE_SUCCESS : 'UPDATE_DATA_SOURCE_SUCCESS',
  UPDATE_DATA_SOURCE_FAILURE : 'UPDATE_DATA_SOURCE_FAILURE',
  DELETE_DATA_SOURCE_REQUEST : 'DELETE_DATA_SOURCE_REQUEST',
  DELETE_DATA_SOURCE_SUCCESS : 'DELETE_DATA_SOURCE_SUCCESS',
  DELETE_DATA_SOURCE_FAILURE : 'DELETE_DATA_SOURCE_FAILURE',


}

const auth = new Auth();
const { getAccessToken } = auth;


//--------- Load DB List-----------

export function loadDBList(orgId){
  return function(dispatch){
    dispatch({ type: databaseActions.GET_DB_LIST_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/manageDb/getDbConnection?orgId='+orgId,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: databaseActions.GET_DB_LIST_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: databaseActions.GET_DB_LIST_FAILURE, payload: error, error: true }));
   }
}

//--------- Test DB Connection-----------

export function testDBConn(dbObj){
  // console.log("Action dbobj",dbObj);
  return function(dispatch){
    dispatch({ type: databaseActions.TEST_DB_CONN_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/manageDb/testDbConnection?server='+dbObj.dbUrl+'&user='+dbObj.dbUid+'&password='+dbObj.dbPwd+'&port='+dbObj.dbPort+'&database='+dbObj.database+'&type='+dbObj.type+'&orgId='+dbObj.orgId,{
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: databaseActions.TEST_DB_CONN_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: databaseActions.TEST_DB_CONN_FAILURE, payload: error, error: true }));
   }
}


//--------- Add DB Connection-----------

export function addDBConn(data){
  return function(dispatch){
    dispatch({ type: databaseActions.ADD_DB_CONN_REQUEST });

     axios.post(process.env.REACT_APP_API_URL+'/manageDb/saveDbConnection',data,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: databaseActions.ADD_DB_CONN_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: databaseActions.ADD_DB_CONN_FAILURE, payload: error, error: true }));
   }
}

//--------- Delete DB Connection -----------

export function deleteDBConn(data){
  return function(dispatch){
    dispatch({ type: databaseActions.DELETE_DB_CONN_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/manageDb/deleteDBConnection',data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then ((response) => dispatch({ type: databaseActions.DELETE_DB_CONN_SUCCESS,payload: response }))
      .catch((error) => dispatch({ type: databaseActions.DELETE_DB_CONN_FAILURE, payload: error, error: true }));
   }
}


//--------- Load DB Details -----------

export function loadDBDetails(dbID){
  return function(dispatch){
    dispatch({ type: databaseActions.LOAD_DB_DETAILS_REQUEST });
     axios.get(process.env.REACT_APP_API_URL+'/manageDb/getDbConnectionDetails?dbConnectionId='+dbID,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: databaseActions.LOAD_DB_DETAILS_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: databaseActions.LOAD_DB_DETAILS_FAILURE, payload: error, error: true }));
   }
}


//--------- Load DB Table List Details -----------

export function loadDBTablesList(db){
  return function(dispatch){
    dispatch({ type: databaseActions.GET_DB_TABLE_LIST_REQUEST });
     axios.get(process.env.REACT_APP_API_URL+'/manageDb/getTables?id='+db.connId+'&name='+db.name,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: databaseActions.GET_DB_TABLE_LIST_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: databaseActions.GET_DB_TABLE_LIST_FAILURE, payload: error, error: true }));
   }
}



//--------- Test Query Connection-----------

export function testQuery(data){
  return function(dispatch){
    dispatch({ type: databaseActions.TEST_QUERY_REQUEST });
     axios.post(process.env.REACT_APP_API_URL+'/manageDb/testDbQuery',data,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: databaseActions.TEST_QUERY_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: databaseActions.TEST_QUERY_FAILURE, payload: error, error: true }));
   }
}


//--------- Add Query Connection-----------

export function addQuery(data){
  return function(dispatch){
    dispatch({ type: databaseActions.ADD_QUERY_REQUEST });
     axios.post(process.env.REACT_APP_API_URL+'/manageDb/saveQuery',data,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: databaseActions.ADD_QUERY_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: databaseActions.ADD_QUERY_FAILURE, payload: error, error: true }));
   }
}


//--------- Delete Query Connection -----------

export function deleteQuery(data){
  console.log("deletedata",data);
  return function(dispatch){
    dispatch({ type: databaseActions.DELETE_QUERY_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/manageDb/deleteDBQuery',data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then ((response) => dispatch({ type: databaseActions.DELETE_QUERY_SUCCESS,payload: response }))
      .catch((error) => dispatch({ type: databaseActions.DELETE_QUERY_FAILURE, payload: error, error: true }));
   }
}



//--------- Load Query Details -----------

export function loadQueryDetails(queryID){
  return function(dispatch){
    dispatch({ type: databaseActions.LOAD_QUERY_DETAILS_REQUEST });
     axios.get(process.env.REACT_APP_API_URL+'/manageDb/getDbQueryDetails?queryId='+queryID,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: databaseActions.LOAD_QUERY_DETAILS_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: databaseActions.LOAD_QUERY_DETAILS_FAILURE, payload: error, error: true }));
   }
}





//--------- Add ENV Connection-----------

export function addEnv(data){
  return function(dispatch){
    dispatch({ type: databaseActions.ADD_ENV_REQUEST });
     axios.post(process.env.REACT_APP_API_URL+'/createEnv',data,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: databaseActions.ADD_ENV_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: databaseActions.ADD_ENV_FAILURE, payload: error, error: true }));
   }
}


//--------- Update ENV Connection-----------

export function updateEnv(data){
  return function(dispatch){
    dispatch({ type: databaseActions.UPDATE_ENV_REQUEST });
     axios.post(process.env.REACT_APP_API_URL+'/updateEnv',data,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: databaseActions.UPDATE_ENV_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: databaseActions.UPDATE_ENV_FAILURE, payload: error, error: true }));
   }
}


//--------- Delete ENV Connection -----------

export function deleteEnv(data){
  return function(dispatch){
    dispatch({ type: databaseActions.DELETE_ENV_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/deleteEnv',data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then ((response) => dispatch({ type: databaseActions.DELETE_ENV_SUCCESS,payload: response }))
      .catch((error) => dispatch({ type: databaseActions.DELETE_ENV_FAILURE, payload: error, error: true }));
   }
}



//--------- Load Env Details -----------

export function loadEnvDetails(envID){
  return function(dispatch){
    dispatch({ type: databaseActions.LOAD_ENV_DETAILS_REQUEST });
     axios.get(process.env.REACT_APP_API_URL+'/getEnvDetail?envId='+envID,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: databaseActions.LOAD_ENV_DETAILS_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: databaseActions.LOAD_ENV_DETAILS_FAILURE, payload: error, error: true }));
   }
}







//--------- Add Data Source -----------

export function addDs(data){
  return function(dispatch){
    dispatch({ type: databaseActions.ADD_DATA_SOURCE_REQUEST });
     axios.post(process.env.REACT_APP_API_URL+'/createDatasource',data,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: databaseActions.ADD_DATA_SOURCE_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: databaseActions.ADD_DATA_SOURCE_FAILURE, payload: error, error: true }));
   }
}


//--------- Update Data Source -----------

export function updateDs(data){
  return function(dispatch){
    dispatch({ type: databaseActions.UPDATE_DATA_SOURCE_REQUEST });
     axios.post(process.env.REACT_APP_API_URL+'/updateDatasource',data,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: databaseActions.UPDATE_DATA_SOURCE_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: databaseActions.UPDATE_DATA_SOURCE_FAILURE, payload: error, error: true }));
   }
}


//--------- Delete Data Source -----------

export function deleteDs(data){
  return function(dispatch){
    dispatch({ type: databaseActions.DELETE_DATA_SOURCE_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/deleteEnv',data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then ((response) => dispatch({ type: databaseActions.DELETE_DATA_SOURCE_SUCCESS,payload: response }))
      .catch((error) => dispatch({ type: databaseActions.DELETE_DATA_SOURCE_FAILURE, payload: error, error: true }));
   }
}
