import React, { Component } from 'react';
import TextField from "@mui/material/TextField";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import ComponentErrorBoundary from './component-error-boundary';



class FilterAutocomplete extends Component{


handleKeyDown=()=>{

}

  render() {
    return (
      <div>
      <ComponentErrorBoundary value="filter autocomplete">
        <Autocomplete
          multiple
          disableCloseOnSelect
          filterSelectedOptions={true}
          autoHighlight
          classes={{ listbox: "" }}
          options={this.props.dropDownOptions}
          getOptionLabel={option => option.label || option.name || option}
          value={this.props.value}
          onChange={(event, newValue) => {this.props.onSelectValue(newValue);}}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip label={option.label || option.name || option} {...getTagProps({ index })} />
            ))
          }
          renderInput={params => {
            params.inputProps.onKeyDown = this.handleKeyDown();
            return (
              <TextField
                {...params}
                variant="standard"
                label={this.props.label}
                placeholder={this.props.placeholder}
                margin="normal"
                error={false}
                helperText=""
                fullWidth
              />
            );
          }}
        />
        </ComponentErrorBoundary>
      </div>
    );
  }
}

export default FilterAutocomplete;
