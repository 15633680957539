import React, { Component } from 'react';
import TextField from "@mui/material/TextField";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import ComponentErrorBoundary from './component-error-boundary';



class UserAutocomplete extends Component{




  render() {
    return (
      <div>
      <ComponentErrorBoundary value="user list autocomplete">
        <Autocomplete
          multiple
          disableCloseOnSelect
          filterSelectedOptions={true}
          autoHighlight 
          classes={{ listbox: "" }}
          options={this.props.dropDownOptions}
          isOptionEqualToValue={(option, value) => option.userId === value.userId}
          getOptionLabel={option => option.label || option.name || option.envName || option}
          value={this.props.value}
          onChange={(event, newValue) => {this.props.onSelectValue(newValue);}}
          onBlur={()=>{this.props.validateAutocomplete();}}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip label={option.label || option.name || option} {...getTagProps({ index })} />
            ))
          }
          renderInput={params => {
            return (
              <TextField
                {...params}
                variant="standard"
                label={this.props.label}
                placeholder={this.props.placeholder}
                margin="normal"
                error={this.props.errorStatus}
                helperText={this.props.errorText}
                fullWidth
              />
            );
          }}
        />
        </ComponentErrorBoundary>
      </div>
    );
  }
}

export default UserAutocomplete;
