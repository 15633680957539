import React, { Component } from 'react';
import {RotateLoader} from 'react-spinners';

class Preloader extends Component{

  render() {
    return (
      <div className='preloader'>
        <RotateLoader
          size={15}
          color={'#0071BD'}
          loading={this.props.loading}
        />
      </div>
    );
  }
}

export default Preloader;
