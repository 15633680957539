import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import Button from '../../../components/button';
import Modal from '../../../components/modal';
import EditableTextField from './editable-text-field';
import TextField from '@mui/material/TextField';
import ComponentErrorBoundary from '../../../components/component-error-boundary';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';



class FieldsList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
      modalOpen:false,
      deleteFieldVal:"",
      deleteMatchingRules:[],
      deletePrimaryKeyState:false,
      slideIndex:0,
      source1FilterCriteria:"",
      source2FilterCriteria:"",
      fieldsBeingEdited:[],
      modalPrimaryButton:"",
      modalSecondaryButton:"",
      addFieldError:false,
      addFieldHelperText:"",
      modalIdentifier:"",
      newFieldSource:"",
      newFieldValue:"",
      newField:""
    };
  }


  componentDidUpdate(prevProps) {


 }

 componentDidMount(){
   this.setState({pageLoading: false})
 }




updateValues = (value) =>{
  this.props.updateValues(value);
}

selectPrimaryIdValue = (source,value) =>{
  this.props.selectPrimaryIdValue(source,value);
}

updateSourceFields = (source,sourceFields,combinedFields) =>{
  this.props.updateSourceFields(source,sourceFields,combinedFields);
}

listofFieldsBeingEdited = (action,value) => {

  switch (action){
    case "add":
    this.setState({fieldsBeingEdited:[...this.state.fieldsBeingEdited,value]});
    break;

    case "remove":
    this.setState({fieldsBeingEdited:this.state.fieldsBeingEdited.filter(val=> val !== value)});

    break;
}

}





deleteFieldConfirmed=(field,matchingRules,isPrimary)=> {

  //--- delete source fields ----

  let changedSourceFields, changedCombinedFields,changedPrimaryId, refForPrimaryId;

  changedCombinedFields=this.props.fileColumnsMerged;

    switch (field.origin){
      case "source1":
      changedSourceFields=this.props.file1column;
      changedPrimaryId=this.props.file1primary;
      refForPrimaryId="firstfilePrimaryId";
      break;

      case "source2":
      changedSourceFields=this.props.file2column;
      changedPrimaryId=this.props.file2primary;
      refForPrimaryId="secondfilePrimaryId";
      break;
  }

  changedSourceFields.splice(changedSourceFields.findIndex(el => JSON.stringify(el) === JSON.stringify(field)),1);
  changedCombinedFields.splice(changedCombinedFields.findIndex(el => JSON.stringify(el) === JSON.stringify(field)),1);

  this.props.updateSourceFields(field.origin,changedSourceFields,changedCombinedFields);

    // ---- delete rules ------
          if(matchingRules.length>0){
            this.props.deleteRule(matchingRules);
          }

   // ---- delete from primary key ------
          if(isPrimary){
            changedPrimaryId.splice(changedPrimaryId.findIndex(el => JSON.stringify(el) === JSON.stringify(field)),1);
            this.props.selectPrimaryIdValue(refForPrimaryId,changedPrimaryId);
          }
  }

deleteField = (field,matchingRules,isPrimary) => {
                let deleteFieldModalContent=[];

                deleteFieldModalContent.push(<div key={1}>Are you sure you want to delete the field "{field.value}" from  "{field.origin}"?</div>);

                if(matchingRules.length>0){
                  let deleteFieldRuleNames=[];
                  matchingRules.forEach((ruleItems)=>{
                    deleteFieldRuleNames.push(<div key={ruleItems.ruleName}>{ruleItems.ruleName}</div>);
                  });
                  deleteFieldModalContent.push(<div key={2} className="deleting-rule-names">By proceeding you'll delete the following rule(s): {deleteFieldRuleNames} </div>);
                }

                this.setState({
                  modalOpen:true,
                  modalTitle:"Delete the field",
                  modalContent:deleteFieldModalContent,
                  modalIdentifier:"delete",
                  deleteFieldVal:field,
                  deleteMatchingRules:matchingRules,
                  deletePrimaryKeyState:isPrimary,
                  modalPrimaryButton:"Yes",
                  modalSecondaryButton:"No",
                });
    }


 addNewField = (source) => {
                    let addFieldModalContent=[];
                    let modalTitle="Add new field for "+source;
                    this.setState({
                      modalOpen:true,
                      modalTitle:modalTitle,
                      modalContent:addFieldModalContent,
                      modalPrimaryButton:"Add",
                      modalSecondaryButton:"Cancel",
                      modalIdentifier:"add",
                      newFieldSource:source,
                      newFieldValue:""
                    });
        }



  addNewFieldConfirmed=(source)=>{

    if(this.state.newFieldValue===""){
      this.setState({
        addFieldError:true,
        addFieldHelperText:"Enter a valid name."
      });
    } else{

    let newField={
      label:this.state.newFieldValue,
      value:this.state.newFieldValue,
      origin:source,
      datatype:""
    }

    let isDuplicate=this.checkForDuplicates(source,newField);


    if(isDuplicate==="duplicate"){
      this.setState({
        addFieldError:true,
        addFieldHelperText:"There is another field with same name."
      });

    } else{

        let changedSourceFields,otherSourceFields,changedCombinedFields;

        switch (source){
          case "source1":
          changedSourceFields=this.props.file1column;
          otherSourceFields=this.props.file2column;
          changedSourceFields.push(newField);
          changedCombinedFields=changedSourceFields.concat(otherSourceFields);
          break;

          case "source2":
          changedSourceFields=this.props.file2column;
          otherSourceFields=this.props.file1column;
          changedSourceFields.push(newField);
          changedCombinedFields=otherSourceFields.concat(changedSourceFields);
          break;
      }

      this.setState({
        addFieldError:false,
        addFieldHelperText:"",
        modalOpen:false
      });

        this.props.updateSourceFields(source,changedSourceFields,changedCombinedFields);
    }
  }

  }


  checkForDuplicates=(source,newField) => {

    let sourceFields;

    switch (source){
      case "source1":
      sourceFields=this.props.file1column;
      break;

      case "source2":
      sourceFields=this.props.file2column;
      break;
  }

    let duplicateFieldIndex=sourceFields.findIndex(el => el.value.toLowerCase() === newField.value.toLowerCase())


    if(duplicateFieldIndex === -1){
      return "notduplicate";
    }else{
      return "duplicate";
    }
  }


  modalClose=()=>{   this.setState({ modalOpen:false }); }

  modalConfirm=()=>{


    if(this.state.modalIdentifier==="delete"){
      this.deleteFieldConfirmed(this.state.deleteFieldVal,this.state.deleteMatchingRules,this.state.deletePrimaryKeyState);
      this.setState({
        modalOpen:false
      });
    } else if(this.state.modalIdentifier==="add"){
      this.addNewFieldConfirmed(this.state.newFieldSource);
    }
  }










   render() {

     let source1Fields, source2Fields;
     let renderedModalContent=[];

     if(this.props.fieldsUpdate){

     let matchingRules,findMatching,isPrimary;

     if(this.props.file1column !== undefined){
       let key=0;
       let filterCriteria=this.state.source1FilterCriteria;
       let sourceList = this.props.file1column.filter(function (e) {  return e.value.toLowerCase().includes(filterCriteria.toLowerCase()); } );

        source1Fields=sourceList.map((field) => {
          matchingRules=[];
          isPrimary=false;

          this.props.rulesContent.forEach((ruleItems)=>{
            findMatching=[];
            findMatching=ruleItems.ruleContent.find(ruleContentItem => JSON.stringify(ruleContentItem) === JSON.stringify(field));
            if(findMatching!==undefined){matchingRules.push(ruleItems)};
          })


            findMatching=[];
            findMatching=this.props.file1primary.find(rulePrimaryItem => JSON.stringify(rulePrimaryItem) === JSON.stringify(field));
            if(findMatching!==undefined){isPrimary=true;};


          key++;

          return(

          //---- generating source 1 fields ------
          <div key={key+field.value} className="source-field-edit">
            <EditableTextField
            field={field}
            fieldIndex={key}
            matchingRules={matchingRules}
            isPrimary={isPrimary}
            fileColumnsMerged={this.props.fileColumnsMerged}
            file1column={this.props.file1column}
            file2column={this.props.file2column}
            file1primary={this.props.file1primary}
            file2primary={this.props.file2primary}
            rulesContent={this.props.rulesContent}
            updateValues={this.updateValues.bind(this)}
            deleteField={this.deleteField.bind(this)}
            selectPrimaryIdValue={this.selectPrimaryIdValue.bind(this)}
            updateSourceFields={this.updateSourceFields.bind(this)}
            listofFieldsBeingEdited={this.listofFieldsBeingEdited.bind(this)}
            />
            <div className={matchingRules.length>0?"show matching-rule":"hide"}>Used in {matchingRules.length} rule(s)</div>
            <div className={isPrimary ? "show primary-key":"hide"}> Used as a primary key</div>
          </div>);
        });


     }

     //---- generating source 2 fields ------

     if(this.props.file2column !== undefined){
       let key=0;
       let filterCriteria=this.state.source2FilterCriteria;
       let sourceList = this.props.file2column.filter(function (e) {  return e.value.toLowerCase().includes(filterCriteria.toLowerCase()); } );
        source2Fields=sourceList.map((field) => {
          matchingRules=[];
          isPrimary=false;

          this.props.rulesContent.forEach((ruleItems)=>{
            findMatching=[];
            findMatching=ruleItems.ruleContent.find(ruleContentItem => JSON.stringify(ruleContentItem) === JSON.stringify(field));
            if(findMatching!==undefined){matchingRules.push(ruleItems)};
          })


            findMatching=[];
            findMatching=this.props.file2primary.find(rulePrimaryItem => JSON.stringify(rulePrimaryItem) === JSON.stringify(field));
            if(findMatching!==undefined){isPrimary=true;};


          key++;

          return(
          <div key={key+field.value} className="source-field-edit">
            <EditableTextField
            field={field}
            fieldIndex={key}
            matchingRules={matchingRules}
            isPrimary={isPrimary}
            fileColumnsMerged={this.props.fileColumnsMerged}
            file1column={this.props.file1column}
            file2column={this.props.file2column}
            file1primary={this.props.file1primary}
            file2primary={this.props.file2primary}
            rulesContent={this.props.rulesContent}
            updateValues={this.updateValues.bind(this)}
            deleteField={this.deleteField.bind(this)}
            selectPrimaryIdValue={this.selectPrimaryIdValue.bind(this)}
            updateSourceFields={this.updateSourceFields.bind(this)}
            listofFieldsBeingEdited={this.listofFieldsBeingEdited.bind(this)}
            />
            <div className={matchingRules.length>0?"show matching-rule":"hide"}>Used in {matchingRules.length} rule(s)</div>
            <div className={isPrimary ? "show primary-key":"hide"}> Used as a primary key</div>
          </div>);
        });


     }


     if(this.state.modalOpen){


       if(this.state.modalIdentifier==="add"){
         renderedModalContent.push(<div key={1}>Please note: The new field would be added to the bottom of the list.</div>);
         renderedModalContent.push(<div key={2}><TextField label="Enter field name" error={this.state.addFieldError} helperText={this.state.addFieldHelperText}  fullWidth value={this.state.newFieldValue} onChange={e => this.setState({ newFieldValue:e.target.value })} /></div>);
       } else if(this.state.modalIdentifier==="delete"){
         renderedModalContent=this.state.modalContent;
       }

      }

   } else{
     source1Fields="";
     source2Fields="";
   }

   let pageContent;
   if(this.state.pageLoading) {
     pageContent=<div>Loading...</div>
   } else {

   pageContent=  <div><Row>
     <Col sm={6} xs={12}>

    <Tabs className='recon-tabs'>
      <Tab label="Source1 fields" className="edit-source-fields-button"  value={0}  >
      <div className='tab-content'>
      <Row className="search-btn-container">
       <Col sm={7} xs={12}>
      <ComponentErrorBoundary value="source1 search field">
      <TextField
        label="Search a field"
        fullWidth
        value={ this.state.source1FilterCriteria }
        onChange={ e => this.setState({ source1FilterCriteria:e.target.value }) }
      />
      </ComponentErrorBoundary>
      </Col>
      <Col sm={5} xs={12} className="text-right margin-top-15"><Button label="Add new field" className='btn btn-default' onClick={this.addNewField.bind(this,"source1")} /></Col>
      </Row>

       <div className='fields-list-content' id='source1FieldContainer'>{source1Fields} </div>
       </div>
      </Tab>
    </Tabs>
    </Col>

   <Col sm={6} xs={12}>

  <Tabs className='recon-tabs'>
    <Tab label="Source2 fields" className="edit-source-fields-button"  value={0}  >
    <div className='tab-content'>

    <Row className="search-btn-container">
     <Col sm={7} xs={12}>
    <ComponentErrorBoundary value="source2 search field">
    <TextField
      label="Search a field"
      fullWidth
      value={ this.state.source2FilterCriteria }
      onChange={ e => this.setState({ source2FilterCriteria:e.target.value }) }
    />
    </ComponentErrorBoundary>
    </Col>
    <Col sm={5} xs={12} className="text-right margin-top-15"><Button label="Add new field" className='btn btn-default' onClick={this.addNewField.bind(this,"source2")} /></Col>
    </Row>

     <div className='fields-list-content' id='source2FieldContainer'>{source2Fields} </div>

    </div>
    </Tab>
  </Tabs>
  </Col>
</Row>
<Row>
     <Col sm={12} xs={12} className='text-right'>
          <Button label="Done" className='btn btn-action' onClick={this.props.modifySourceFields} disabled={this.state.fieldsBeingEdited.length>0 ? true : false}   />
     </Col>
   </Row> </div>
 }


     return (

       <div>

       <Modal
        open={this.state.modalOpen}
        title={this.state.modalTitle}
        content={renderedModalContent}
        primaryButton={this.state.modalPrimaryButton}
        secondaryButton={this.state.modalSecondaryButton}
        close={this.modalClose}
        confirm={this.modalConfirm}
        />

        <h3>Modify your source fields</h3>

        {pageContent}
       </div>
     );
   }
}




export default FieldsList;
