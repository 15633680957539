
import axios from 'axios';
import Auth from '../../auth/auth';

export const profileActions ={
  UPDATE_PASSWORD_REQUEST : 'UPDATE_PASSWORD_REQUEST',
  UPDATE_PASSWORD_SUCCESS : 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE : 'UPDATE_PASSWORD_FAILURE'
}



const auth = new Auth();
const { getAccessToken } = auth;

//--------- Update Password -----------

export function updatePassword(data){
  return function(dispatch){
    dispatch({ type: profileActions.UPDATE_PASSWORD_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/auth/resetPassword',data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then ((response) => dispatch({ type: profileActions.UPDATE_PASSWORD_SUCCESS,payload: response }))
      .catch((error) => dispatch({ type: profileActions.UPDATE_PASSWORD_FAILURE, payload: error, error: true }));
   }
}
