import axios from 'axios';
import Auth from '../../auth/auth';

export const impactorActions ={
  GET_FIELD_IMPACT_ANALYSIS_REQUEST : 'GET_FIELD_IMPACT_ANALYSIS_REQUEST',
  GET_FIELD_IMPACT_ANALYSIS_SUCCESS : 'GET_FIELD_IMPACT_ANALYSIS_SUCCESS',
  GET_FIELD_IMPACT_ANALYSIS_FAILURE : 'GET_FIELD_IMPACT_ANALYSIS_FAILURE'
}

const auth = new Auth();
const { getAccessToken } = auth;


//--------- Load Field Impact Analysis List-----------

export function loadFieldImpactAnalysis(fieldName){
  return function(dispatch){
    dispatch({ type: impactorActions.GET_FIELD_IMPACT_ANALYSIS_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/impactor/findColImpact?colName='+fieldName,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: impactorActions.GET_FIELD_IMPACT_ANALYSIS_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: impactorActions.GET_FIELD_IMPACT_ANALYSIS_FAILURE, payload: error, error: true }));
   }
}
