import axios from 'axios';
import Auth from '../../auth/auth';

export const usersActions ={
  ADD_USER_REQUEST : 'ADD_USER_REQUEST',
  ADD_USER_SUCCESS : 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE : 'ADD_USER_FAILURE',
  UPDATE_USER_REQUEST : 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS : 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE : 'UPDATE_USER_FAILURE',
  DELETE_USER_REQUEST : 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS : 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE : 'DELETE_USER_FAILURE',
  GET_USER_GROUP_LIST_REQUEST : 'GET_USER_GROUP_LIST_REQUEST',
  GET_USER_GROUP_LIST_SUCCESS : 'GET_USER_GROUP_LIST_SUCCESS',
  GET_USER_GROUP_LIST_FAILURE : 'GET_USER_GROUP_LIST_FAILURE',
  GET_USER_GROUP_DETAILS_REQUEST : 'GET_USER_GROUP_DETAILS_REQUEST',
  GET_USER_GROUP_DETAILS_SUCCESS : 'GET_USER_GROUP_DETAILS_SUCCESS',
  GET_USER_GROUP_DETAILS_FAILURE : 'GET_USER_GROUP_DETAILS_FAILURE',
  ADD_USER_GROUP_REQUEST : 'ADD_USER_GROUP_REQUEST',
  ADD_USER_GROUP_SUCCESS : 'ADD_USER_GROUP_SUCCESS',
  ADD_USER_GROUP_FAILURE : 'ADD_USER_GROUP_FAILURE',
  UPDATE_USER_GROUP_REQUEST : 'UPDATE_USER_GROUP_REQUEST',
  UPDATE_USER_GROUP_SUCCESS : 'UPDATE_USER_GROUP_SUCCESS',
  UPDATE_USER_GROUP_FAILURE : 'UPDATE_USER_GROUP_FAILURE',
  DELETE_USER_GROUP_REQUEST : 'DELETE_USER_GROUP_REQUEST',
  DELETE_USER_GROUP_SUCCESS : 'DELETE_USER_GROUP_SUCCESS',
  DELETE_USER_GROUP_FAILURE : 'DELETE_USER_GROUP_FAILURE'
}

const auth = new Auth();
const { getAccessToken } = auth;





//--------- Add User -----------

export function addUser(data){
  return function(dispatch){
    dispatch({ type: usersActions.ADD_USER_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/register',data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then ((response) => dispatch({ type: usersActions.ADD_USER_SUCCESS,payload: response }))
      .catch((error) => dispatch({ type: usersActions.ADD_USER_FAILURE, payload: error, error: true }));
   }
}


//--------- Update User -----------

export function updateUser(data){
  return function(dispatch){
    dispatch({ type: usersActions.UPDATE_USER_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/updateuser',data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then ((response) => dispatch({ type: usersActions.UPDATE_USER_SUCCESS,payload: response }))
      .catch((error) => dispatch({ type: usersActions.UPDATE_USER_FAILURE, payload: error, error: true }));
   }
}



//--------- Delete User -----------

export function deleteUser(data){
  return function(dispatch){
    dispatch({ type: usersActions.DELETE_USER_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/deleteuser',data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then ((response) => dispatch({ type: usersActions.DELETE_USER_SUCCESS,payload: response }))
      .catch((error) => dispatch({ type: usersActions.DELETE_USER_FAILURE, payload: error, error: true }));
   }
}


//--------- Load User Group List-----------

export function loadUserGroup(orgId){
  return function(dispatch){
    dispatch({ type: usersActions.GET_USER_GROUP_LIST_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/getUserGroupList',{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: usersActions.GET_USER_GROUP_LIST_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: usersActions.GET_USER_GROUP_LIST_FAILURE, payload: error, error: true }));
   }
}


//--------- Load User Group Details-----------

export function loadUserGroupDetails(data){
  return function(dispatch){
    dispatch({ type: usersActions.GET_USER_GROUP_DETAILS_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/getUserGroupDetails?userGroupId='+data.userGroupId,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: usersActions.GET_USER_GROUP_DETAILS_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: usersActions.GET_USER_GROUP_DETAILS_FAILURE, payload: error, error: true }));
   }
}


//--------- Add User Group -----------

export function addUserGroup(data){
  return function(dispatch){
    dispatch({ type: usersActions.ADD_USER_GROUP_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/registergroup',data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then ((response) => dispatch({ type: usersActions.ADD_USER_GROUP_SUCCESS,payload: response }))
      .catch((error) => dispatch({ type: usersActions.ADD_USER_GROUP_FAILURE, payload: error, error: true }));
   }
}


//--------- Update User Group -----------

export function updateUserGroup(data){
  return function(dispatch){
    dispatch({ type: usersActions.UPDATE_USER_GROUP_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/updateUserGroup',data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then ((response) => dispatch({ type: usersActions.UPDATE_USER_GROUP_SUCCESS,payload: response }))
      .catch((error) => dispatch({ type: usersActions.UPDATE_USER_GROUP_FAILURE, payload: error, error: true }));
   }
}



//--------- Delete User Group -----------

export function deleteUserGroup(data){
  return function(dispatch){
    dispatch({ type: usersActions.DELETE_USER_GROUP_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/deleteusergroup',data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then ((response) => dispatch({ type: usersActions.DELETE_USER_GROUP_SUCCESS,payload: response }))
      .catch((error) => dispatch({ type: usersActions.DELETE_USER_GROUP_FAILURE, payload: error, error: true }));
   }
}
