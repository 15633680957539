import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import OneoptDropdown from './oneopt-autocomplete';
import MultiAutocomplete from './multi-autocomplete';
import Button from '../../../components/button';
import ComponentErrorBoundary from '../../../components/component-error-boundary';
import UserAutocomplete from '../../../components/user-autocomplete';

import Preloader from '../../../components/preloader';

import {connect} from 'react-redux';
import {
          loadEnvList,
          loadUsersList,
          loadEnvTypeList
        } from '../../../common-redux/common.actions';


class AddEditContext extends Component {

  constructor(props) {
    super(props);
    this.state = {
      contextName:"",
      contextDesc:"",
      contextEnvType:null,
      contextEnv:[],
      contextSuperUser:[],
      contextNameErrorStatus:false,
      contextNameErrorText:"",
      contextDescErrorStatus:false,
      contextDescErrorText:"",
      contextSuperUserErrorStatus:false,
      contextSuperUserErrorText:"",
      contextEnvErrorStatus:false,
      contextEnvErrorText:"",
      saveButtonStatus:true,
      envListState:true,
      avlEnvList:[],
      formErrorState:['contextName','contextDesc','contextEnv','contextSuperUser']
    };

    this.props.getEnvList(1);
    this.props.getUsersList();
    this.props.getEnvTypeList();
  }


  componentDidUpdate(prevProps) {

  if (this.props.contextDetails !== prevProps.contextDetails) {

    let envValues

    if(this.props.contextDetails.envType !== "" || this.props.contextDetails.envType !== undefined){envValues=this.props.envList.filter(item => item.envTypeName === this.props.contextDetails.envType.envTypeName);}

    this.setState({
      contextName:this.props.contextDetails.contextName,
      contextDesc:this.props.contextDetails.contextDesc,
      contextEnvType:this.props.contextDetails.envType,
      contextEnv:this.props.contextDetails.env,
      contextSuperUser:this.props.contextDetails.user,
      contextNameErrorStatus:false,
      contextNameErrorText:"",
      contextDescErrorStatus:false,
      contextDescErrorText:"",
      contextSuperUserErrorStatus:false,
      contextSuperUserErrorText:"",
      contextEnvErrorStatus:false,
      contextEnvErrorText:"",
      avlEnvList:envValues,
      envListState:false,
      saveButtonStatus:true,
      formErrorState:[]
    })
  }
 }

 cancelFlow=()=>{
    this.props.cancelFlow();
 }

 saveFlow=()=>{

   let contextObj;


   if(this.props.contextDetails.contextId===null){
     contextObj={
       contextId:this.props.contextDetails.contextId,
       contextName: this.state.contextName,
       contextDesc: this.state.contextDesc,
       envType:this.state.contextEnvType,
       env:this.state.contextEnv,
       user:this.state.contextSuperUser
     }
     this.props.saveFlow(contextObj,'new',this.state.contextName)

   } else{

     let deletedUsers = this.props.contextDetails.user.filter((item1) => !this.state.contextSuperUser.find(item2 => item1.userId === item2.userId )).map(v => ({...v, operation: "remove"}));
     let addedUsers = this.state.contextSuperUser.filter((item1) => !this.props.contextDetails.user.find(item2 => item1.userId === item2.userId )).map(v => ({...v, operation: "add"}));


     let deletedEnv = this.props.contextDetails.env.filter((item1) => !this.state.contextEnv.find(item2 => item1.envId === item2.envId )).map(v => ({...v, operation: "remove"}));
     let addedEnv = this.state.contextEnv.filter((item1) => !this.props.contextDetails.env.find(item2 => item1.envId === item2.envId )).map(v => ({...v, operation: "add"}));



     contextObj={
       contextId:this.props.contextDetails.contextId,
       contextName: this.state.contextName,
       contextDesc: this.state.contextDesc,
       envType:this.state.contextEnvType,
       env:deletedEnv.concat(addedEnv),
       user:deletedUsers.concat(addedUsers)
     }



      if(this.state.contextEnvType.envTypeId !== this.props.contextDetails.envType.envTypeId ){
       contextObj.envType.operation="update";
     } else{
       contextObj.env.operation="";
     }

    this.props.saveFlow(contextObj,'update',this.state.contextName)

   }




   //console.log("contextObj",contextObj);


   //this.props.contextDetails.id === null ? this.props.saveFlow(contextObj,'new',this.state.contextName) : this.props.saveFlow(contextObj,'update',this.state.contextName);

 }



  handleTextfield = (event,item) => {
      this.setState({[item]:event.target.value});
  }


  formErrorRemoveValue=(attr)=>{
    if (this.state.formErrorState.find(o => o === attr)){
      this.setState({formErrorState:this.state.formErrorState.filter(val=> val !== attr)});
    }
  }

  formErrorInsertValue=(attr)=>{
    if (!this.state.formErrorState.find(o => o === attr)){
      this.setState({formErrorState:[...this.state.formErrorState,attr]});
    }
  }


  checkNameDuplicates=()=>{
    let hasMatchingName = this.props.contextList.filter(function (item) { return item.contextName.toLowerCase() === event.target.value.toLowerCase(); });

    if(hasMatchingName.length !=0){

      if(hasMatchingName[0].contextId!==this.props.contextDetails.contextId){
        this.setState({contextNameErrorText:"You used this name already. Please try another.",contextNameErrorStatus:true},()=>{this.enableSaveButton();});
        this.formErrorInsertValue("contextName");
        return false;
      } else{
        this.setState({contextNameErrorText:"",contextNameErrorStatus:false},()=>{this.enableSaveButton();});
        this.formErrorRemoveValue("contextName");
        return true;
      }

    }

    else{
      this.setState({contextNameErrorText:"",contextNameErrorStatus:false},()=>{this.enableSaveButton();});
      this.formErrorRemoveValue("contextName");
      return true;
    }
  }


  validateField=(field)=>{

    let errorStatus=field+"ErrorStatus";
    let errorText=field+"ErrorText";


    if(this.state[field].length>1)
    {

      if(field==="contextName"){
        this.checkNameDuplicates();
      } else{
        this.formErrorRemoveValue(field);
        this.setState({[errorStatus]:false,[errorText]:""},()=>{this.enableSaveButton();});
        return true;
      }

    }
    else{

      this.formErrorInsertValue(field);
      this.setState({[errorStatus]:true,[errorText]:"Inavlid entry."},()=>{this.enableSaveButton();});
      return false;
    }

  }


  dropdownSelector = (name,value) => {
              this.formErrorRemoveValue(name);
              this.setState({[name]:value},()=>{this.enableSaveButton();});

              if(name==="contextEnvType"){
                let envValues=this.props.envList.filter(item => item.envTypeName === value.envTypeName);
                this.setState({avlEnvList:envValues,envListState:false,contextEnv:[]});
              }
            }




  enableSaveButton=()=>{
    if(this.state.formErrorState.length===0)
      {
      this.setState({saveButtonStatus:false});
      } else{
      this.setState({saveButtonStatus:true});
      }

}

selectAutocompleteValue = (item,values) => {
  values.length>0 ? this.formErrorRemoveValue(item) : this.formErrorInsertValue(item);
  this.setState({[item]:values},()=>{this.enableSaveButton();});
}


validateList=(field)=>{

  let errorStatus=field+"ErrorStatus";
  let errorText=field+"ErrorText";


  if(this.state[field].length>0)
  {

    this.formErrorRemoveValue(field);
    this.setState({[errorStatus]:false,[errorText]:""},()=>{this.enableSaveButton();});
    return true;
  }
  else{

    this.formErrorInsertValue(field);
    this.setState({[errorStatus]:true,[errorText]:"Please select an option."},()=>{this.enableSaveButton();});
    return false;
  }
}










   render() {


     const {isLoading} = this.props;
     let pageContent;

     if(isLoading.includes('contextdetails')){
       pageContent=<Preloader loading={true} /> ;
     } else{
       pageContent=<div>

       <div className="rounded-card">

       <Row>
         <Col sm={3} xs={12}>
        <ComponentErrorBoundary value="Context Name">
         <FormControl fullWidth>
          <InputLabel htmlFor="Context Name">Name</InputLabel>
          <Input
            type="text"
            value={this.state.contextName}
            onChange={(e)=>this.handleTextfield(e,"contextName")}
            error={this.state.contextNameErrorStatus}
            onBlur={this.validateField.bind(this,"contextName")}
          />
          <FormHelperText className="Mui-error">{this.state.contextNameErrorText}</FormHelperText>
         </FormControl>
         </ComponentErrorBoundary>
         </Col>
       </Row>



       <Row>
         <Col sm={6} xs={12}>
        <ComponentErrorBoundary value="Context Desc">
         <FormControl fullWidth>
          <InputLabel htmlFor="Context Desc">Description</InputLabel>
          <Input
            type="text"
            value={this.state.contextDesc}
            onChange={(e)=>this.handleTextfield(e,"contextDesc")}
            error={this.state.contextDescErrorStatus}
            onBlur={this.validateField.bind(this,"contextDesc")}
          />
          <FormHelperText className="Mui-error">{this.state.contextDescErrorText}</FormHelperText>
         </FormControl>
         </ComponentErrorBoundary>
         </Col>
       </Row>
       <Row className='label-value-pair'>
         <Col sm={4} xs={12}>
        <ComponentErrorBoundary value="Env Code">
         <FormControl fullWidth>
         <OneoptDropdown
              label='Select the environment type'
              fileColumns={this.props.envTypeList}
              onSelectValue={this.dropdownSelector.bind(this,'contextEnvType')}
              value={this.state.contextEnvType} />
         </FormControl>
         </ComponentErrorBoundary>
         </Col>
       </Row>

       <Row>
         <Col sm={4} xs={12}>
        <ComponentErrorBoundary value="Context Env">
         <FormControl fullWidth>
         <MultiAutocomplete
         label="Select an environment"
         disabled={this.state.envListState}
         placeholder="Select an option"
         value={this.state.contextEnv}
         dropDownOptions={this.state.avlEnvList}
         onSelectValue={this.selectAutocompleteValue.bind(this,'contextEnv')}
         validateAutocomplete={this.validateList.bind(this,'contextEnv')}
         errorStatus={this.state.contextEnvErrorStatus}
         errorText={this.state.contextEnvErrorText} />

         </FormControl>
         </ComponentErrorBoundary>
         </Col>
       </Row>

       <Row>
         <Col sm={6} xs={12}>
        <ComponentErrorBoundary value="Super user for context">
         <FormControl fullWidth>
         <UserAutocomplete
         label="Super User"
         placeholder="Select an option"
         value={this.state.contextSuperUser}
         dropDownOptions={this.props.userList}
         onSelectValue={this.selectAutocompleteValue.bind(this,'contextSuperUser')}
         validateAutocomplete={this.validateList.bind(this,'contextSuperUser')}
         errorStatus={this.state.contextSuperUserErrorStatus}
         errorText={this.state.contextSuperUserErrorText} />
         </FormControl>
         </ComponentErrorBoundary>
         </Col>
       </Row>

       </div>



       <div className="margin-top-30">
       <Row>
       <Col sm={4} xs={12} className=''>

         <Button label="Save" className='btn btn-action' disabled={this.state.saveButtonStatus} onClick={this.saveFlow.bind(this)}  />

         <Button label="Cancel" className='btn btn-default' onClick={this.cancelFlow.bind(this)} />

       </Col>

       </Row>
       </div>

      </div>
     }


     return (

       <div>{pageContent}</div>

     );
   }
}


const mapStateToProps = (state) => {
  return{
    isLoading: state.contextReducer.isLoading,
    errors: state.contextReducer.errorMessage,
    envList:state.commonReducer.envList,
    userList:state.commonReducer.userList,
    envTypeList: state.commonReducer.envTypeList
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getEnvList : (orgId) => {
      dispatch(loadEnvList(orgId));
    },
    getUsersList : (orgId) => {
      dispatch(loadUsersList(orgId));
    },
    getEnvTypeList : () => {
      dispatch(loadEnvTypeList());
    }
  }
}


export default connect(mapStateToProps,mapDispatchToProps) (AddEditContext);
