import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Preloader from '../../../components/preloader';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '../../../components/button';
import ComponentErrorBoundary from '../../../components/component-error-boundary';
import UserAutocomplete from '../../../components/user-autocomplete';
import EmptyState from '../../../components/empty-state-container';

import {connect} from 'react-redux';
import {removeItemFromArray} from "../../../util/util";

import {
          loadUsersListWithParams
        } from '../../../common-redux/common.actions';


class EditUserGroup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userId:this.props.userGroupDetails.userGroupId,
      userName:this.props.userGroupDetails.userGroupName,
      userDesc:"Default user group",
      userList:this.props.userGroupDetails.userList,
      nameErrorStatus:false,
      nameErrorText:"",
      descErrorStatus:false,
      descErrorText:"",
      userListErrorStatus:false,
      userListErrorText:"",
      saveButtonStatus:true,
      formErrorState:[],
      changeTracker:[],
      sourceFilterCriteria:""
    };

  }


  componentDidUpdate(prevProps) {
  if (this.props.userGroupDetails !== prevProps.userGroupDetails) {
    this.setState({
      userId:this.props.userGroupDetails.userGroupId,
      userName:this.props.userGroupDetails.userGroupName,
      userDesc:"Default user group",
      userList:this.props.userGroupDetails.userList,
      nameErrorStatus:false,
      nameErrorText:"",
      descErrorStatus:false,
      descErrorText:"",
      userListErrorStatus:false,
      userListErrorText:"",
      saveButtonStatus:true,
      formErrorState:[],
      changeTracker:[]
    })
    this.props.getUsersList(this.props.userGroupDetails.userGroupId,'false','2');
  }
 }

 cancelFlow=()=>{
    this.props.cancelFlow();
 }

 saveFlow=()=>{



   let deletedUsers=this.props.userGroupDetails.userList.filter(item1 => !this.state.userList.find(item2 => item2.userId === item1.userId)).map(v => ({...v, operation: "remove"}));
   let addedUsers=this.state.userList.filter(item1 => !this.props.userGroupDetails.userList.find(item2 => item2.userId === item1.userId)).map(v => ({...v, operation: "add"}));

   let userObj={
     userGroupId:this.props.userGroupDetails.userGroupId,
     userGroupName:this.props.userGroupDetails.userGroupName,
     userList:deletedUsers.concat(addedUsers)
   };

   console.log("updateUserGroup",userObj);
   this.props.saveFlow(userObj,'update',this.state.userName);
 }

  handleTextfield = (event,item) => {
      this.setState({[item]:event.target.value});
  }




  //---- change tracker ------

  formChangeTrackerInsertValue=(attr)=>{
    if (!this.state.changeTracker.find(o => o === attr)){
      this.setState({changeTracker:[...this.state.changeTracker,attr]});
    }
  }


  formChangeTrackerRemoveValue=(attr)=>{
    if (this.state.changeTracker.find(o => o === attr)){
      this.setState({changeTracker:this.state.changeTracker.filter(val=> val !== attr)});
    }
  }

  formErrorInsertValue=(attr)=>{

    if (!this.state.formErrorState.find(o => o === attr)){
      this.setState({formErrorState:[...this.state.formErrorState,attr]});
    }
  }

  formErrorRemoveValue=(attr)=>{
    if (this.state.formErrorState.find(o => o === attr)){
      this.setState({formErrorState:this.state.formErrorState.filter(val=> val !== attr)});
    }
  }

  validateName=()=>{

    if(this.state.userName.length>2)
    {

      this.formErrorRemoveValue('userName');
      this.setState({nameErrorStatus:false,nameErrorText:""},()=>{this.enableSaveButton();});
      return true;
    }
    else{

      this.formErrorInsertValue('userName');
      this.setState({nameErrorStatus:true,nameErrorText:"Please enter a valid name."},()=>{this.enableSaveButton();});
      return false;
    }

  }

  validateDesc=()=>{

    if(this.state.userDesc.length>2)
    {

      this.formErrorRemoveValue('userDesc');
      this.setState({descErrorStatus:false,descErrorText:""},()=>{this.enableSaveButton();});
      return true;
    }
    else{

      this.formErrorInsertValue('userDesc');
      this.setState({descErrorStatus:true,descErrorText:"Please enter a valid description."},()=>{this.enableSaveButton();});
      return false;
    }

  }

  validateUserList=()=>{
    if(this.state.userList.length>0)
    {

      this.formErrorRemoveValue('userList');
      this.setState({userListErrorStatus:false,userListErrorText:""},()=>{this.enableSaveButton();});
      return true;
    }
    else{

      this.formErrorInsertValue('userList');
      this.setState({userListErrorStatus:true,userListErrorText:"Please add a user."},()=>{this.enableSaveButton();});
      return false;
    }
  }


  selectAutocompleteValue = (item,values) => {
    this.setState({[item]:values,saveButtonStatus:false});
  }

  enableSaveButton=()=>{
            if(this.state.formErrorState.length===0)
              {
              this.setState({saveButtonStatus:false});
            } else {
              this.setState({saveButtonStatus:true});
            }
  }




handleClickShowPassword = (field) => {
    this.setState({[field]: !this.state[field] });
  };

handleSwitch = (field,label) => {

      var numberOfActiveUsers= this.props.userList.filter(x => x.active === 1);

      if(numberOfActiveUsers.length>=5 && this.state[field]===0 ){
        this.props.userLicenseLimit();
      }

      else{
        this.formChangeTrackerInsertValue("status");
        this.setState({[field]: this.state[field]===1?0:1 });
        this.enableSaveButton();
      }
    };


 handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

addUser=()=>{

//this.setState({userList:removeItemFromArray(this.state.userList,user)})
}

removeUser=(user)=>{
  this.setState({userList:removeItemFromArray(this.state.userList,user),saveButtonStatus:false})
}



   render() {


     let userListContent,pageContent;
     const {isLoading} = this.props;

     if(isLoading.includes('usergroupdetails')){
       userListContent=""
     }else if(this.state.userList !== undefined){
     let filterCriteria=this.state.sourceFilterCriteria;
     let sourceList = this.state.userList.filter(function (e) {  return e.name.toLowerCase().includes(filterCriteria.toLowerCase()); } );

      if(sourceList.length>0){
        userListContent=<Table className='data-table short-first-column'>
        <TableHead><TableRow><TableCell>Name</TableCell><TableCell>Email</TableCell><TableCell></TableCell></TableRow></TableHead>
        <TableBody>
             {sourceList.map((user,index) => <tr key={index}>
               <TableCell>{user.name}</TableCell>
               <TableCell>{user.email}</TableCell>
               <TableCell><a onClick={this.removeUser.bind(this,user)}><Tooltip title="Remove" placement="right"><i className="material-icons">delete</i></Tooltip></a></TableCell>
               </tr>)}
       </TableBody>
        </Table>
      } else{
        userListContent=<EmptyState className="margin-top-0" message="No users found." />;
      }
    }
     return (
       isLoading.includes('usergroupdetails') ?  <Preloader loading={true} /> :
       this.state.userList !== undefined ?
       <div>
       <div className="rounded-card">
       <Row className=''>
         <Col sm={3} xs={12}>
        <ComponentErrorBoundary value="admin add user group name">
         <FormControl fullWidth>
          <InputLabel htmlFor="standard-adornment-password">Name</InputLabel>
          <Input
            type="text"
            disabled={true}
            value={this.state.userName}
            onChange={(e)=>this.handleTextfield(e,"userName")}
            onBlur={this.validateName}
            error={this.state.nameErrorStatus}
          />
          <FormHelperText className="Mui-error">{this.state.nameErrorText}</FormHelperText>
         </FormControl>
         </ComponentErrorBoundary>
         </Col>
         <Col sm={6} xs={12}>
         <ComponentErrorBoundary value="admin add user group desc">
         <FormControl fullWidth>
          <InputLabel htmlFor="standard-adornment-password">Short description</InputLabel>
          <Input
            type="text"
            disabled={true}
            value={this.state.userDesc}
            onChange={(e)=>this.handleTextfield(e,"userDesc")}
            onBlur={this.validateDesc}
            error={this.state.descErrorStatus}
          />
          <FormHelperText className="Mui-error">{this.state.descErrorText}</FormHelperText>
         </FormControl>
         </ComponentErrorBoundary>
         </Col>
       </Row>
       </div>

       <div className="rounded-card">
       <Row className=''>
         <Col sm={12} xs={12}>
         <ComponentErrorBoundary value="add user groups - user list">
           <UserAutocomplete
           label="Users in the group"
           placeholder="Add user"
           value={this.state.userList}
           dropDownOptions={this.props.userListOptions}
           onSelectValue={this.selectAutocompleteValue.bind(this,"userList")}
           validateAutocomplete={this.validateUserList}
           errorStatus={this.state.userListErrorStatus}
           errorText={this.state.userListErrorText}
           />
         </ComponentErrorBoundary>
         </Col>
       </Row>
       <Row className="search-btn-container">
        <Col sm={4} xs={12}>
       <ComponentErrorBoundary value="source1 search field">
       <TextField
         label="Search a user"
         fullWidth
         value={this.state.sourceFilterCriteria}
         onChange={ e => this.setState({ sourceFilterCriteria:e.target.value }) }
       />
       </ComponentErrorBoundary>
       </Col>
       </Row>
       <Row>
       <Col sm={12} xs={12}>{userListContent}</Col>
       </Row>
       </div>
       <div className="margin-top-30">
       <Row>
       <Col sm={4} xs={12}>

        <Button label="Save" className='btn btn-action' disabled={this.state.saveButtonStatus} onClick={this.saveFlow.bind(this)}  />

        <Button label="Cancel" className='btn btn-default' onClick={this.cancelFlow.bind(this)} />

       </Col>

       </Row>
       </div>
     </div> : null
   );
   }
}


const mapStateToProps = (state) => {
  return{
    isLoading: state.usersReducer.isLoading,
    errors: state.usersReducer.errorMessage,
    userListOptions:state.commonReducer.userList,
    userGroupDetails:state.usersReducer.userGroupDetails
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getUsersList : (groupId,mapState,ignoreRole) => {
      dispatch(loadUsersListWithParams(groupId,mapState,ignoreRole));
    }
  }
}


export default connect(mapStateToProps,mapDispatchToProps) (EditUserGroup);
