import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Modal from '../../../components/modal';
import Preloader from '../../../components/preloader';
import EmptyState from '../../../components/empty-state-container';
import IconMenu from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import {connect} from 'react-redux';

let userList;

import {
              loadUsersListWithParams
            } from '../../../common-redux/common.actions';

class ManageUserGroups extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userFilterCriteria:"",
      emptyState:"No users found.",
      suList:[]
    };
  }


componentDidMount=()=>{
  this.setState({
    userFilterCriteria:""
  });
}

componentDidUpdate(prevProps) {
  if(this.props.userGroupList !== prevProps.userGroupList && this.props.userGroupList !==undefined){
    this.props.getUsersList(this.props.userGroupList[0].userGroupId,'true','3');
  }

  if(this.props.isLoading !== prevProps.isLoading){
    this.props.isLoading.includes('userlist') ? this.setState({ suList:[]}) : this.setState({ suList:this.props.userListOptions});
  }
}


editUser = (user) => {
    //this.props.getRuleSetContent("edit-account");
    this.props.onClick({user});
}

deleteUser = (user) => {
    //this.props.getRuleSetContent(value);
    //this.props.onClick(value);
    this.setState({
      modalOpen:true,
      modalTitle:"Delete user group",
      modalContent:"Are you sure you want to delete \""+user.name+"\"?",
      modalPrimaryButton:"Yes",
      modalSecondaryButton:"No",
      modalState:"delete",
      deleteUserval:user
    });
}

  createNewUserGroup = () => {
  this.props.onClick('new');
}





   render() {






     const {isLoading} = this.props;
     let manageUsersContent,userListContent,superUserListContent;

     if(isLoading.includes('usergrouplist') || isLoading.includes('userlist')){
       manageUsersContent=<Preloader loading={true} /> ;
     }
     else{

      this.state.suList.length===0 ? superUserListContent=<span className='user-bubble'>None</span> : superUserListContent=this.state.suList.map((user,index) => <span key={index} className='user-bubble'>{user.name}</span>)

      const filterCriteria=this.state.userFilterCriteria;
      userList = this.props.userGroupList.filter(function (e) {  return e.userGroupName.toLowerCase().includes(filterCriteria.toLowerCase()); } );


      if(userList.length>0){
        userListContent=<table className='manage-ruleset-table'>
        <tbody>
             {userList.map((user,index) => <tr key={index}>
               <td onClick={this.editUser.bind(this,user)} className='user' >{user.userGroupName}</td>
               <td>
                </td>
               </tr>)}
       </tbody>
        </table>
      }else{
        userListContent=<EmptyState className="margin-top-0" message={this.state.emptyState} />
      }
       manageUsersContent=<div>
       <Row><Col sm={12} xs={12} className="usergroup-su"><label>Super Users for the context:</label> {superUserListContent}</Col></Row>
       <Row>

       <Col sm={4} xs={6}>{userListContent}</Col>
       <Col sm={4} xs={6} className="manage-rule-buttons">

          <Button href=""  onClick={this.createNewUserGroup.bind(this)} label="Add a new user group" className='hide createruleset-button'></Button>
       </Col>

       </Row></div>
     }





     return (
       <div className="manage-ruleset">








        <Row className="search-section hide" id="searchBar">
        <Col sm={4} xs={6}>



          <input type="hidden" value="prayer" />

          <TextField
            label="Search a user group"
            fullWidth
            value={ this.state.userFilterCriteria }
            onChange={ e => this.setState({ userFilterCriteria:e.target.value }) }
          />



        </Col>




        </Row>

       {manageUsersContent}



         </div>

     );
   }
}


const mapStateToProps = (state) => {
  return{
    isLoading:state.usersReducer.isLoading.concat(state.commonReducer.isLoading),
    errors: state.usersReducer.errorMessage,
    userListOptions:state.commonReducer.userList
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getUsersList : (groupId,mapState,ignoreRole) => {
      dispatch(loadUsersListWithParams(groupId,mapState,ignoreRole));
    }
  }
}


export default connect(mapStateToProps,mapDispatchToProps) (ManageUserGroups);
