import axios from 'axios';
import Auth from '../../auth/auth';

export const analyticsActions ={
  GET_TOTAL_RULESET_REQUEST : 'GET_TOTAL_RULESET_REQUEST',
  GET_TOTAL_RULESET_SUCCESS : 'GET_TOTAL_RULESET_SUCCESS',
  GET_TOTAL_RULESET_FAILURE : 'GET_TOTAL_RULESET_FAILURE',
  GET_TOTAL_RUNS_TODAY_REQUEST : 'GET_TOTAL_RUNS_TODAY_REQUEST',
  GET_TOTAL_RUNS_TODAY_SUCCESS : 'GET_TOTAL_RUNS_TODAY_SUCCESS',
  GET_TOTAL_RUNS_TODAY_FAILURE : 'GET_TOTAL_RUNS_TODAY_FAILURE',
  GET_TOTAL_RUNS_PAST_TIME_REQUEST : 'GET_TOTAL_RUNS_PAST_TIME_REQUEST',
  GET_TOTAL_RUNS_PAST_TIME_SUCCESS : 'GET_TOTAL_RUNS_PAST_TIME_SUCCESS',
  GET_TOTAL_RUNS_PAST_TIME_FAILURE : 'GET_TOTAL_RUNS_PAST_TIME_FAILURE',
  GET_MOST_FREQ_RULESET_REQUEST : 'GET_MOST_FREQ_RULESET_REQUEST',
  GET_MOST_FREQ_RULESET_SUCCESS : 'GET_MOST_FREQ_RULESET_SUCCESS',
  GET_MOST_FREQ_RULESET_FAILURE : 'GET_MOST_FREQ_RULESET_FAILURE',
  GET_MOST_DVP_RULESET_REQUEST : 'GET_MOST_DVP_RULESET_REQUEST',
  GET_MOST_DVP_RULESET_SUCCESS : 'GET_MOST_DVP_RULESET_SUCCESS',
  GET_MOST_DVP_RULESET_FAILURE : 'GET_MOST_DVP_RULESET_FAILURE',
  GET_RULESET_TREND_REQUEST : 'GET_RULESET_TREND_REQUEST',
  GET_RULESET_TREND_SUCCESS : 'GET_RULESET_TREND_SUCCESS',
  GET_RULESET_TREND_FAILURE : 'GET_RULESET_TREND_FAILURE',
}

const auth = new Auth();
const { getAccessToken } = auth;





//--------- Load Total Ruleset  -----------

export function loadTotalRuleset(){
  return function(dispatch){
    dispatch({ type: analyticsActions.GET_TOTAL_RULESET_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/dashboard/getTotalRulesets',{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: analyticsActions.GET_TOTAL_RULESET_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: analyticsActions.GET_TOTAL_RULESET_FAILURE, payload: error, error: true }));
   }
}


//--------- Load Total Runs Today  -----------

export function loadTotalRunsToday(){
  return function(dispatch){
    dispatch({ type: analyticsActions.GET_TOTAL_RUNS_TODAY_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/dashboard/getTotalRuns?day=1',{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: analyticsActions.GET_TOTAL_RUNS_TODAY_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: analyticsActions.GET_TOTAL_RUNS_TODAY_FAILURE, payload: error, error: true }));
   }
}

//--------- Load Total Runs Today  -----------

export function loadTotalRunsPastTime(){
  return function(dispatch){
    dispatch({ type: analyticsActions.GET_TOTAL_RUNS_PAST_TIME_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/dashboard/getTotalRuns?day=7',{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: analyticsActions.GET_TOTAL_RUNS_PAST_TIME_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: analyticsActions.GET_TOTAL_RUNS_PAST_TIME_FAILURE, payload: error, error: true }));
   }
}

//--------- Load Most Frquent Ruleset -----------

export function loadMostFreqRuleset(day){
  return function(dispatch){
    dispatch({ type: analyticsActions.GET_MOST_FREQ_RULESET_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/dashboard/getTopRunsDvps?day='+day,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: analyticsActions.GET_MOST_FREQ_RULESET_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: analyticsActions.GET_MOST_FREQ_RULESET_FAILURE, payload: error, error: true }));
   }
}


//--------- Load Most DVP Ruleset -----------

export function loadMostDvpRuleset(day){
  return function(dispatch){
    dispatch({ type: analyticsActions.GET_MOST_DVP_RULESET_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/dashboard/getTopDvpsRuns?day='+day,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: analyticsActions.GET_MOST_DVP_RULESET_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: analyticsActions.GET_MOST_DVP_RULESET_FAILURE, payload: error, error: true }));
   }
}


//--------- Load Ruleset Trend -----------

export function loadRulesetTrend(rulesetId){
  return function(dispatch){
    dispatch({ type: analyticsActions.GET_RULESET_TREND_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/dashboard/getRulesetTrend?rulesetId='+rulesetId,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: analyticsActions.GET_RULESET_TREND_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: analyticsActions.GET_RULESET_TREND_FAILURE, payload: error, error: true }));
   }
}
