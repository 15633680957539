import { usersActions } from './users.actions';
import {removeItemFromArray} from "../../util/util";
export const initialState = {
    userGroupList: [],
    isLoading: [],
    error:[],
    userGroupDetails:""
};

export function usersReducer (state= initialState, action){

  let status;

  switch(action.type){

    //-------Add User Request-------------
    case usersActions.ADD_USER_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'adduser']
    };

    //-------Add User Success-------------
    case usersActions.ADD_USER_SUCCESS:
    return{
      ...state,
      isLoading:removeItemFromArray(state.isLoading,'adduser')
    };

    //-------Add User Failure-------------
    case usersActions.ADD_USER_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

    //-------Update User Request-------------
    case usersActions.UPDATE_USER_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'updateuser']
    };

    //-------Update User Success-------------
    case usersActions.UPDATE_USER_SUCCESS:
    return{
      ...state,
      isLoading:removeItemFromArray(state.isLoading,'updateuser')
    };

    //-------Update User Failure-------------
    case usersActions.UPDATE_USER_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

    //-------Delete User Request-------------
    case usersActions.DELETE_USER_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'deleteuser']
    };

    //-------Delete User Success-------------
    case usersActions.DELETE_USER_SUCCESS:
    return{
      ...state,
      isLoading:removeItemFromArray(state.isLoading,'deleteuser')
    };

    //-------Delete User Failure-------------
    case usersActions.DELETE_USER_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

    //-------User group list Request-------------
    case usersActions.GET_USER_GROUP_LIST_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'usergrouplist']
    };

    //-------User group list Success-------------
    case usersActions.GET_USER_GROUP_LIST_SUCCESS:
    return{
      ...state,
      userGroupList:action.payload,
      isLoading:removeItemFromArray(state.isLoading,'usergrouplist')
    };

    //-------User group list Failure-------------
    case usersActions.GET_USER_GROUP_LIST_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

    //-------User group details Request-------------
    case usersActions.GET_USER_GROUP_DETAILS_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'usergroupdetails']
    };

    //-------User group details Success-------------
    case usersActions.GET_USER_GROUP_DETAILS_SUCCESS:
    return{
      ...state,
      userGroupDetails:action.payload,
      isLoading:removeItemFromArray(state.isLoading,'usergroupdetails')
    };

    //-------User group details Failure-------------
    case usersActions.GET_USER_GROUP_DETAILS_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };


    //-------Add User Group Request-------------
    case usersActions.ADD_USER_GROUP_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'addusergroup']
    };

    //-------Add User Group Success-------------
    case usersActions.ADD_USER_GROUP_SUCCESS:
    return{
      ...state,
      isLoading:removeItemFromArray(state.isLoading,'addusergroup')
    };

    //-------Add User Group Failure-------------
    case usersActions.ADD_USER_GROUP_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

    //-------Update User Group Request-------------
    case usersActions.UPDATE_USER_GROUP_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'updateusergroup']
    };

    //-------Update User Group Success-------------
    case usersActions.UPDATE_USER_GROUP_SUCCESS:
    return{
      ...state,
      isLoading:removeItemFromArray(state.isLoading,'updateusergroup')
    };

    //-------Update User Group Failure-------------
    case usersActions.UPDATE_USER_GROUP_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

    //-------Delete User Group Request-------------
    case usersActions.DELETE_USER_GROUP_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'deleteusergroup']
    };

    //-------Delete User Group Success-------------
    case usersActions.DELETE_USER_GROUP_SUCCESS:
    return{
      ...state,
      isLoading:removeItemFromArray(state.isLoading,'deleteusergroup')
    };

    //-------Delete User Group Failure-------------
    case usersActions.DELETE_USER_GROUP_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

      default:
        return state;
  }
}
