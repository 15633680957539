import { contextActions } from './context.actions';
import {removeItemFromArray} from "../../util/util";

export const initialState = {
    isLoading: [],
    error:[],
    contextDetails:"",
    assgnContextList:[],
    setContextStatus:null
};

export function contextReducer (state= initialState, action){

  let status;

  switch(action.type){

    //-------Add Context Request-------------
    case contextActions.ADD_CONTEXT_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'addcontext']
    };

    //-------Add Context Success-------------
    case contextActions.ADD_CONTEXT_SUCCESS:
    return{
      ...state,
      isLoading:removeItemFromArray(state.isLoading,'addcontext')
    };

    //-------Add Context Failure-------------
    case contextActions.ADD_CONTEXT_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };





    //-------Update Context Request-------------
    case contextActions.UPDATE_CONTEXT_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'updatecontext']
    };

    //-------Update Context Success-------------
    case contextActions.UPDATE_CONTEXT_SUCCESS:
    return{
      ...state,
      isLoading:removeItemFromArray(state.isLoading,'updatecontext')
    };

    //-------Update Context Failure-------------
    case contextActions.UPDATE_CONTEXT_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };



    //-------Load Context Details Request-------------
    case contextActions.LOAD_CONTEXT_DETAILS_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'contextdetails']
    };

    //-------Load Context Details Success-------------
    case contextActions.LOAD_CONTEXT_DETAILS_SUCCESS:
    return{
      ...state,
      contextDetails:action.payload,
      isLoading:removeItemFromArray(state.isLoading,'contextdetails')
    };

    //-------Load Context Details Failure-------------
    case contextActions.LOAD_CONTEXT_DETAILS_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

    //-------Delete Context Request-------------
    case contextActions.DELETE_CONTEXT_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'deletecontext']
    };

    //-------Delete Context Success-------------
    case contextActions.DELETE_CONTEXT_SUCCESS:
    return{
      ...state,
      isLoading:state.isLoading.splice(...state.isLoading.indexOf('deletecontext'), 1 )
    };

    //-------Delete Context Failure-------------
    case contextActions.DELETE_CONTEXT_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };


    //-------Get Assigned Context List Req-------------
    case contextActions.GET_ASSIGNED_CONTEXT_LIST_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'assignedcontext']
    };

    //-------Get Assigned Context List Success-------------
    case contextActions.GET_ASSIGNED_CONTEXT_LIST_SUCCESS:
    return{
      ...state,
      assgnContextList:action.payload.contextList,
      isLoading:removeItemFromArray(state.isLoading,'assignedcontext')
    };

    //-------Get Assigned Context List Failure-------------
    case contextActions.GET_ASSIGNED_CONTEXT_LIST_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

    //-------Set Context Request-------------
    case contextActions.SET_CONTEXT_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'setcontext']
    };

    //-------Set Context Success-------------
    case contextActions.SET_CONTEXT_SUCCESS:
    return{
      ...state,
      setContextStatus:action.payload,
      isLoading:removeItemFromArray(state.isLoading,'setcontext')
    };

    //-------Set Context Failure-------------
    case contextActions.SET_CONTEXT_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };



      default:
        return state;
  }
}
