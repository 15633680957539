import React, { Component } from 'react';
import Backdrop from '@mui/material/Backdrop';
import ManageEnv from './components/db-manage-env';
import OrgEnv from './components/db-add-edit-env';
import Snackbar from '@mui/material/Snackbar';
import Banner from '../../components/banner';
import Modal from '../../components/modal';
import ErrorBoundary from '../../components/error-boundary';
import Auth from '../../auth/auth';
import {connect} from 'react-redux';
import {
      addEnv,
      updateEnv,
      deleteEnv,
      loadEnvDetails
    } from './database.actions';

import {
          loadEnvList
        } from '../../common-redux/common.actions';


const auth = new Auth();
let orgId;


class DatabaseEnv extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pageNavContainer:"hide",
      backdrop:false,
      envDetails:[],
      step1:"",
      step2:"hide",
      snackbarStatus: false,
      bannerType: "danger",
      showBanner: false,
      bannerTitle:"",
      bannerMessage:"",
      actionTriggered:false,
      triggeredItemName:"",
      actionName:"",
      modalOpen:false,
      modalTitle:"",
      modalState:"",
      modalContent:"",
      modalPrimaryButton:"",
      modalSecondaryButton:"",
      envPageTitle:"",
      saveEnvId:null,
      pageState:0
    };
    orgId  = auth.getOrgId();
    this.props.getEnvList(orgId);
  }





  componentDidUpdate(prevProps) {
    if (prevProps.isLoading !== this.props.isLoading && this.state.actionTriggered === true) {

      if(this.props.isLoading.includes('addenv')){
       this.setState({snackbarStatus:true,actionName:"add"});
      }
      else if(this.props.isLoading.includes('updateenv')){
       this.setState({snackbarStatus:true,actionName:"update"});
      }
      else if(this.props.isLoading.includes('deleteenv')){
       this.setState({snackbarStatus:true,actionName:"delete"});
      }
      else{
        if(this.state.actionName==="add"){this.saveSuccess();}
        else if(this.state.actionName==="update"){this.updateSuccess();}
        else if(this.state.actionName==="delete"){this.deleteSuccess();}
        this.setState({snackbarStatus:false,actionTriggered:false,actionName:""});
      }
    }

    // ---- Error handing ------
    if (this.props.error !== prevProps.error && this.props.error.length>0) {
      this.setState({snackbarStatus:false,showBanner:true,bannerType:"danger",bannerTitle:"Oh snap! Something went wrong!",bannerMessage:"Please contact your admin."});
    }


    if (this.props.envDetails !== prevProps.envDetails) {
      this.setState({
        envDetails:this.props.envDetails
      });
    }


  }

saveSuccess=()=>{
  this.props.getEnvList(orgId);
  this.navigateToBegin();
  var message = this.state.triggeredItemName+' has been added successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

updateSuccess=()=>{
  this.props.getEnvList(orgId);
  this.navigateToBegin();
  var message = this.state.triggeredItemName+' has been updated successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

deleteSuccess=()=>{
  this.props.getEnvList(orgId);
  var message = this.state.triggeredItemName+' has been deleted successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

navigateToBegin=()=>{
  this.props.parentNavBar(1);
  this.setState({
    pageState:0,
    pageNavContainer:"hide"
  });
}

cancelFlow=()=>{
  this.navigateToBegin();
}

saveEnvFlow=(data,flowName,name)=>{
  this.setState({actionTriggered:true,triggeredItemName:name,saveEnvId:data.id});
  flowName==='new' ? this.props.addEnv(data) : this.props.updateEnv(data)
}

deleteEnv=(data)=>{
this.setState({actionTriggered:true,triggeredItemName:data.envName});
this.props.deleteEnv(data);
}




toggleBackdrop=()=>{
  this.state.backdrop ?
  this.setState({backdrop:false,pageNavContainer:'page-nav-container', appContentContainer:"app-content-container in"}) :
  this.setState({backdrop:true,pageNavContainer:'page-nav-container reduce-zindex', appContentContainer:"app-content-container in has-backdrop"})
}

hideBackNavigation=()=>{
  this.setState({
    appContentContainer:"app-content-container",
    pageNavContainer:"hide"
  });
}



  goToTop=()=>{
    document.getElementById("app").scrollTo(0,0);
  }

  dropdownSelector = (name,id,value) => {
              this.setState({[name]:value});
          }

  dismissBanner = () => {
                this.setState({showBanner:false});
         }

 snackHandler = (state) =>{
   this.setState({snackbarStatus:state});
 }



 modalClose=()=>{
   this.setState({
     modalOpen:false
   });
 }

 modalConfirm=()=>{
   this.setState({
     modalOpen:false
   });
 }


 selectEnv= (value) => {

   this.dismissBanner();
   this.props.parentNavBar(2);

   if(value==="new"){
     this.setState({
       pageState:1,
       pageNavContainer:"page-nav-container overlap",
       envDetails:{envId:null,envName:"",envType:null,envDesc:""},
       envPageTitle:"Add a new environment"
     });
   }

   else {
     this.props.getEnvDetails(value.env.envId);
     this.setState({
       pageState:1,
       pageNavContainer:"page-nav-container overlap",
       envPageTitle:"View & Edit environment",
       envDetails:this.props.envDetails
     });
   }
 }






  goBack = (level) => {
                    this.props.parentNavBar(1);
                    if(level===0){
                      this.setState({
                        pageState:0,
                        pageNavContainer:"hide"
                      });
                    }
                }


   render() {

     return (
       <div className="sub-module">

       <Backdrop open={this.state.backdrop}></Backdrop>

       <Snackbar
           open={this.state.snackbarStatus}
           message="Please wait..."
           className="snack-bar"
         />

       <div className={this.state.pageNavContainer}>
         <a onClick={this.goBack.bind(this,0)} className={this.state.level0}>&lt; Manage Environemnt Names & Codes</a>
       </div>


       <Modal
        open={this.state.modalOpen}
        title={this.state.modalTitle}
        content={this.state.modalContent}
        primaryButton={this.state.modalPrimaryButton}
        secondaryButton={this.state.modalSecondaryButton}
        close={this.modalClose}
        confirm={this.modalConfirm}
        />

       <Banner
          type={this.state.bannerType}
          hasAlerts={this.state.showBanner}
          header={this.state.bannerTitle}
          body={this.state.bannerMessage}
          dismissBanner={this.dismissBanner.bind(this)}
          />


{
    this.state.pageState===0 ?
      <ErrorBoundary>
      <h2>Manage Data Environment Names & Codes</h2>
      <ManageEnv
        onClick={this.selectEnv.bind(this)}
        envList={this.props.envList}
        orgId={orgId}
        deleteEnv={this.deleteEnv}
        isLoading={this.props.isLoading}
        />
      </ErrorBoundary> :

      <ErrorBoundary>
      <h2>{this.state.envPageTitle}</h2>
        <OrgEnv
          cancelFlow={this.cancelFlow}
          saveFlow={this.saveEnvFlow}
          orgId={orgId}
          envDetails={this.state.envDetails}
          envId={this.state.envId}
          envList={this.props.envList}
          hideBackNavigation={this.hideBackNavigation}
          toggleBackdrop={this.toggleBackdrop.bind(this)}
          />
        </ErrorBoundary>

  }

       </div>
     );
   }
}

const mapStateToProps = (state) => {
  return{
    error: state.databaseReducer.error.concat(state.commonReducer.error),
    envList:state.commonReducer.envList,
    envDetails:state.databaseReducer.envDetails,
    isLoading:state.databaseReducer.isLoading.concat(state.commonReducer.isLoading)
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    getEnvList : (orgId) => {
      dispatch(loadEnvList(orgId));
    },
    addEnv : (data) => {
      dispatch(addEnv(data));
    },
    updateEnv : (data) => {
      dispatch(updateEnv(data));
    },
    deleteEnv : (data) => {
      dispatch(deleteEnv(data));
    },
    getEnvDetails : (envId) => {
      dispatch(loadEnvDetails(envId));
    }

  }
}


export default connect(mapStateToProps,mapDispatchToProps) (DatabaseEnv);
