import React from "react";
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconMenu from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

export default function Tags(props) {



const [values, setValues] = React.useState({
   error:  false,
   errorText: '',
   disabled: true,
   currentValue:props.field.name,
   value:props.field.name,
   id:props.field.colId,
   isOpen:false,
   menuPosition:""
 });

  const handleTextfield = event => {
   setValues({ ...values, value: event.target.value });
  }



  const checkForDuplicates=(index,sourceFields,newField) => {

    let duplicateFieldIndex=sourceFields.findIndex(el => el.name.toLowerCase() === newField.name.toLowerCase())

    if(duplicateFieldIndex === -1){
      return "notduplicate";
    }else if(duplicateFieldIndex === Number(index)){
      return "samefield";
    }else{
      return "duplicate";
    }
  }



  const handleClickEdit = event => {
    if(!values.disabled) {

      if(values.value===""){

        setValues({ ...values, error: true, errorText: 'Enter a valid name.'});

      } else{

    let changedSourceFields,changedCombinedFields,changedPrimaryId,refForPrimaryId;

    changedSourceFields=props.sourceList;





     let changedFieldValue={
       colId:values.id,
       name:values.value
     }



     let isDuplicated=checkForDuplicates(props.fieldIndex,changedSourceFields,changedFieldValue);

     if(isDuplicated==="duplicate"){
       setValues({ ...values, error: true, errorText: 'There is another field with same name.'});

     } else if(isDuplicated==="samefield"){
       setValues({ ...values, value: values.value, disabled: !values.disabled, error: false, errorText: ''});
       props.listofFieldsBeingEdited("remove",props.field);
     }

     else{



         //--- update source fields ----


         changedSourceFields[changedSourceFields.findIndex(el => JSON.stringify(el) === JSON.stringify(props.field))] = changedFieldValue;
         props.updateSourceFields(changedSourceFields,changedFieldValue);
          setValues({ ...values, value: values.value, disabled: !values.disabled, error: false, errorText: ''});
          props.listofFieldsBeingEdited("remove",props.field);

       }

     }

    } else{
      //--- edit clicked ---
      setValues({ ...values, disabled: !values.disabled });
      props.listofFieldsBeingEdited("add",props.field);
    }


  }

  const handleClickDiscard = event => {
    //----- delete clicked -----
    if(values.disabled){
    //  props.deleteField(props.field,props.matchingRules,props.isPrimary);
      props.deleteField(props.field);
    }
    //----- undo clicked --------
    else{
      setValues({ ...values, disabled: !values.disabled,value: values.currentValue,error: false, errorText: ''  });
      props.listofFieldsBeingEdited("remove",props.field);
    }

  }

  const handleMenu=e=>{
    console.log("e.currentTarget",e.currentTarget);
    setValues({ ...values, isOpen: !values.isOpen, menuPosition:e.currentTarget });
  }

  const closeMenu=e=>{
    setValues({isOpen: false});
  }




  return (
    <div>
    {
    String(values.id).substr(0,2)==="n_" ?

    <FormControl fullWidth className="inline-editable-field">
     <Input
       type="text"
       value={values.value}
       onChange={handleTextfield}
       error={values.error}
       disabled={values.disabled}
       endAdornment={
         <InputAdornment position="end">
         {
         values.disabled ?
         <IconMenu
             iconButtonElement={<IconButton size="large"><i className='material-icons'>more_horiz</i></IconButton>}
             anchorOrigin={{horizontal: 'right', vertical: 'top'}}
             targetOrigin={{horizontal: 'right', vertical: 'top'}}
             className='actions-menu'
           >
             <MenuItem
             primaryText="Edit"
             className='wl-group-card-menu'
             onClick={handleClickEdit}  />
             <MenuItem
             primaryText="Delete"
             className='wl-group-card-menu'
             onClick={handleClickDiscard}  />
         </IconMenu>:
         <div>
         <IconButton aria-label="edit" onClick={handleClickEdit} size="large">
             {values.disabled ?
               <Tooltip title="Edit" placement="right"><i className="material-icons">edit</i></Tooltip> :
               <Tooltip title="Done" placement="right"><i className="material-icons">done</i></Tooltip>
             }
           </IconButton>

           <IconButton aria-label="edit" onClick={handleClickDiscard} size="large">
              {values.disabled ?
               <Tooltip title="Delete" placement="right"><i className="material-icons">delete</i></Tooltip> :
               <Tooltip title="Undo" placement="right"><i className="material-icons">undo</i></Tooltip>
             }
           </IconButton>
           </div>
        }

         </InputAdornment>
       }
     />
     <FormHelperText className="Mui-error">{values.errorText}</FormHelperText>
    </FormControl>

    : values.value
    }
    </div>
  );
}
