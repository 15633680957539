import React, { Component } from 'react';
import Backdrop from '@mui/material/Backdrop';
import ReconciliationManageRulesets from './components/recon-manage-rulesets';
import NewRuleConfigSetting from './components/newrule-config-setting';
import EditRuleConfigSetting from './components/editrule-config-setting';
import Snackbar from '@mui/material/Snackbar';
import Banner from '../../components/banner';
import ErrorBoundary from '../../components/error-boundary';
import {connect} from 'react-redux';
import {
      loadRecon,
      reloadReconList,
      addNewRuleset,
      updateRuleset,
      duplicateRuleset,
      exportRuleset,
      deleteRuleset
    } from './settings.actions';

    import {
              loadRuleSet,
              loadRuleSetContent
            } from '../../common-redux/common.actions';

class RuleSets extends Component {

  constructor(props) {
    super(props);
    this.state = {
      appContentContainer:"app-content-container",
      reconQuestionsClass: "page-content select-container in",
      reconNewRule: "page-content results-container",
      reconEditRule: "page-content results-container",
      pageNavContainer:"hide",
      backdrop:false,
      ruleListValue:"",
      step1:"",
      step2:"hide",
      reconSelectedRecord:"",
      snackbarStatus: false,
      bannerType: "danger",
      showBanner: false,
      bannerTitle:"",
      bannerMessage:"",
      actionTriggered:false,
      triggeredRulesetName:"",
      actionName:"",
      goBack:0,
      fieldsUpdate:false,
      pageState:0
    };
    this.props.getRuleSetListItems();
  }



  componentDidUpdate(prevProps) {
    if (prevProps.isLoading !== this.props.isLoading && this.state.actionTriggered === true) {

      if(this.props.isLoading.includes('addrule')){
       this.setState({snackbarStatus:true,actionName:"add"});
      }
      else if(this.props.isLoading.includes('updaterule')){
       this.setState({snackbarStatus:true,actionName:"update"});
      }
      else if(this.props.isLoading.includes('deleterule')){
       this.setState({snackbarStatus:true,actionName:"delete"});
      }
      else if(this.props.isLoading.includes('exportrule')){
       this.setState({snackbarStatus:true,actionName:"export"});
      }
      else{
        if(this.state.actionName==="add"){this.saveSuccess();}
        else if(this.state.actionName==="update"){this.updateSuccess();}
        else if(this.state.actionName==="delete"){this.deleteSuccess();}
        this.setState({snackbarStatus:false,actionTriggered:false,actionName:""});
      }
    }

    // ---- Error handing ------

    if (this.props.error !== prevProps.error) {
      this.setState({snackbarStatus:false,showBanner:true,bannerType:"danger",bannerTitle:"Oh snap! Something went wrong!",bannerMessage:"Please contact your admin."});
    }

  }

saveSuccess=()=>{
  this.props.getRuleSetListItems();
  this.navigateToBegin();
  var message = this.state.triggeredRulesetName+' has been added successfully.';
  this.setState({pageState:0,showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

updateSuccess=()=>{
  this.props.getRuleSetListItems();
  this.navigateToBegin();
  var message = this.state.triggeredRulesetName+' has been updated successfully.';
  this.setState({pageState:0,showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

deleteSuccess=()=>{
  this.props.getRuleSetListItems();
  var message = this.state.triggeredRulesetName+' has been deleted.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

navigateToBegin=()=>{
  this.setState({
    appContentContainer:"app-content-container",
    reconQuestionsClass: "page-content select-container in",
    reconNewRule: "page-content results-container",
    reconEditRule: "page-content results-container",
    pageNavContainer:"hide"
  });
}

cancelFlow=()=>{
  this.setState({pageState:0});
  this.navigateToBegin();
}

deleteRule=(data)=>{
this.setState({actionTriggered:true,triggeredRulesetName:data.rulesetName});
this.props.deleteRuleSet(data);
}

duplicateRule=(data)=>{
this.setState({actionTriggered:true,triggeredRulesetName:data.rulesetName});
this.props.duplicateRuleset(data);
}

exportRule=(data)=>{
  this.setState({actionTriggered:true,triggeredRulesetName:data.rulesetName});
  this.props.exportRuleset(data)
}

importRule=(data)=>{
  this.setState({actionTriggered:true,triggeredRulesetName:data.rulesetName});
  this.props.addNewRuleSet(data);
}






saveFlow=(data,flowName)=>{

  //console.log("Create New rule",data);
  this.setState({actionTriggered:true,triggeredRulesetName:data.rulesetName});
  flowName==='new' ? this.props.addNewRuleSet(data) : this.props.updateRuleset(data)
}

toggleBackdrop=()=>{
  this.state.backdrop ?
  this.setState({backdrop:false,pageNavContainer:'page-nav-container', appContentContainer:"app-content-container in"}) :
  this.setState({backdrop:true,pageNavContainer:'page-nav-container reduce-zindex', appContentContainer:"app-content-container in has-backdrop"})
}

hideBackNavigation=()=>{
  this.setState({
    appContentContainer:"app-content-container",
    pageNavContainer:"hide"
  });
}


  goToTop=()=>{
    document.getElementById("app").scrollTo(0,0);
  }

  dropdownSelector = (name,id,value) => {
              this.setState({[name]:value});
          }

  dismissBanner = () => {
                this.setState({showBanner:false});
         }

 snackHandler = (state) =>{
   this.setState({snackbarStatus:state});
 }

 selectRule= (value) => {

   this.dismissBanner();

   if(value==="new"){
     this.setState({
       pageState:1,
       appContentContainer:"app-content-container in",
       reconQuestionsClass: "page-content select-container",
       reconNewRule: "page-content results-container in",
       pageNavContainer:"page-nav-container"
     });
   }

   else {
     this.props.getRuleSetContent(value);
     this.setState({
       pageState:2,
       appContentContainer:"app-content-container in",
       reconQuestionsClass: "page-content select-container",
       reconEditRule: "page-content results-container in",
       pageNavContainer:"page-nav-container"
     });
   }
 }

  selectRecon = (data) => {
                    this.setState({
                      appContentContainer:"app-content-container in",
                      reconQuestionsClass: "page-content select-container",
                      reconResultsClass: "page-content results-container in",
                      pageNavContainer:"page-nav-container",
                      reconSelectedRecord:data
                    });
                    this.props.getReconItems(data.file);
                    this.goToTop();


                }

   modifySourceFields=()=>{
                  //if(!this.state.fieldsUpdate){this.setState({snackbarStatus:true})}
                  this.state.fieldsUpdate?this.setState({appContentContainer:"app-content-container in", pageNavContainer: "page-nav-container"}):this.setState({appContentContainer:"app-content-container", pageNavContainer:"hide"});
                  this.setState({fieldsUpdate: !this.state.fieldsUpdate});
                  this.goToTop();
      }


  goBack = (level) => {
                    if(level===0){
                      this.setState({
                        pageState:0,
                        appContentContainer:"app-content-container",
                        reconQuestionsClass: "page-content select-container in",
                        reconNewRule: "page-content results-container",
                        reconEditRule: "page-content results-container",
                        pageNavContainer:"hide"
                      });
                    }
                }


   render() {

     return (
       <div className={this.state.appContentContainer}>

       <Backdrop open={this.state.backdrop}></Backdrop>

       <Snackbar
           open={this.state.snackbarStatus}
           message="Please wait..."
           className="snack-bar"
         />

       <div className={this.state.pageNavContainer}>
         <a onClick={this.goBack.bind(this,this.state.goBack)}>&lt; Back</a>
       </div>

       <Banner
          type={this.state.bannerType}
          hasAlerts={this.state.showBanner}
          header={this.state.bannerTitle}
          body={this.state.bannerMessage}
          dismissBanner={this.dismissBanner.bind(this)}
          />


        {
          this.state.pageState === 0 ?  <div id='' className={this.state.reconQuestionsClass}>
            <h2>Manage Rulesets</h2>
            <ErrorBoundary>
              <ReconciliationManageRulesets
                onClick={this.selectRule.bind(this)}
                ruleSetList={this.props.ruleSetList}
                exportRule={this.exportRule}
                importRule={this.importRule}
                duplicateRule={this.duplicateRule}
                deleteRule={this.deleteRule}
                isLoading={this.props.isLoading}
                />
              </ErrorBoundary>
          </div> : null
        }

        {
          this.state.pageState === 1 ?  <div id='' className={this.state.reconNewRule}>
            <h2>Create a new Ruleset</h2>
            <ErrorBoundary>
              <NewRuleConfigSetting
                ruleSetList={this.props.ruleSetList}
                cancelFlow={this.cancelFlow}
                saveFlow={this.saveFlow}
                hideBackNavigation={this.hideBackNavigation}
                reconNewRule={this.state.reconNewRule}
                toggleBackdrop={this.toggleBackdrop.bind(this)}
                />
              </ErrorBoundary>
          </div> : null
        }


        {
          this.state.pageState === 2 ?  <div id='' className={this.state.reconEditRule}>
            <h2>View & Edit a Ruleset</h2>
              <ErrorBoundary>
                <EditRuleConfigSetting
                  ruleSetList={this.props.ruleSetList}
                  ruleSetContent={this.props.ruleSetContent}
                  isLoading={this.props.isLoading}
                  cancelFlow={this.cancelFlow}
                  saveFlow={this.saveFlow}
                  reconEditRule={this.state.reconEditRule}
                  toggleBackdrop={this.toggleBackdrop.bind(this)}
                  actionTriggered={this.state.actionTriggered}
                  fieldsUpdate={this.state.fieldsUpdate}
                  modifySourceFields={this.modifySourceFields.bind(this)}
                  snackHandler={this.snackHandler.bind(this)}
                  />
                </ErrorBoundary>
          </div> : null
        }







       </div>
     );
   }
}

const mapStateToProps = (state) => {
  return{
    error: state.settingsReducer.error,
    ruleSetContent: state.commonReducer.ruleSetContent,
    ruleSetList: state.commonReducer.ruleSetList,
    isLoading:state.settingsReducer.isLoading.concat(state.commonReducer.isLoading),
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    getReconItems : (filename) => {
      dispatch(loadRecon(filename));
    },
    reloadReconItems : (json) => {
      dispatch(reloadReconList(json));
    },
    getRuleSetContent : (value) => {
      dispatch(loadRuleSetContent(value));
    },
    getRuleSetListItems : () => {
      dispatch(loadRuleSet());
    },
    addNewRuleSet : (data) => {
      dispatch(addNewRuleset(data));
    },
    updateRuleset : (data) => {
      dispatch(updateRuleset(data));
    },
    duplicateRuleset : (data) => {
      dispatch(duplicateRuleset(data));
    },
    exportRuleset:(data)=> {
      dispatch(exportRuleset(data));
    },
    deleteRuleSet : (data) => {
      dispatch(deleteRuleset(data));
    }
  }
}


export default connect(mapStateToProps,mapDispatchToProps) (RuleSets);
