import React, { Component } from 'react';
import XLSX from 'xlsx';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '../../../components/button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import RuleAutocomplete from './rule-autocomplete';
import ValueMapAutocomplete from './valuemap-autocomplete';
import FieldSelectorAutoComplete from './field-selector-autocomplete';
import ComponentErrorBoundary from '../../../components/component-error-boundary';
import FieldConfig from './field-config';
import {Col, Row} from 'react-bootstrap';



const operation = [
  { label: 'equal to(=)', origin: 'Operation' },
  { label: 'concatinated with(&)', origin: 'Operation' },
  { label: 'plus(+)', origin: 'Operation' },
  { label: 'minus(-)', origin: 'Operation' },
  { label: 'times(*)', origin: 'Operation' }
];

let maintainRuleState;

class RulesetPrimaryDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cardStyle:'rounded-card',
      id:this.props.rulesetPrimaryDetails.id,
      rulesetName:this.props.rulesetPrimaryDetails.rulesetName,
      ruleContent:[],
      valueMap:this.props.rulesetPrimaryDetails.valueMap,
      fieldConfig:this.props.rulesetPrimaryDetails.fieldConfig,
      fieldConfigIDS:[],
      RulesetnameErrorStatus:false,
      RulesetnameErrorText:"",
      ruleContentErrorStatus:false,
      ruleContentErrorText:"",
      valuemapChkBox:false,
      valuemapValues:this.props.rulesetPrimaryDetails.valueMap,
      valuemapClass:'more-options',
      fieldConfigCheckBox:false,
      fieldConfigValues:[],
      filteredFieldList:[],
      openMoreOptionsFlag:false,
      fieldConfigState:false,
      anchorEl:null,
      moreMenu:false
    };
  }

  componentDidMount(){
    this.props.rulesetPrimaryDetails.valueMap.length>0 ? this.setState({valuemapChkBox:true}) : this.setState({valuemapChkBox:false});

    if(this.state.fieldConfig===undefined){
      this.setState({fieldConfig:[]})
      this.setState({
        fieldConfigCheckBox:false,
        fieldConfigIDS:['fc_n_0'],
        fc_n_0:{
                  id: 'fc_n_0',
                  field:null,
                  dataType: "",
                  operationType:"",
                  fieldConfigValue:""
          }
        })
    }

    else{
      this.setupFieldConfig();

     this.filterFieldListforConfig();
    }

  }

  componentDidUpdate(prevProps) {
  if (this.props.rulesetPrimaryDetails !== prevProps.rulesetPrimaryDetails) {
    this.setState({
      id:this.props.rulesetPrimaryDetails.id,
      rulesetName:this.props.rulesetPrimaryDetails.rulesetName,
      ruleContent:this.props.rulesetPrimaryDetails.ruleContent,
      valueMap:this.props.rulesetPrimaryDetails.valueMap,
      fieldConfig:this.props.rulesetPrimaryDetails.fieldConfig

    })

   if(this.props.rulesetPrimaryDetails.fieldConfig !== prevProps.rulesetPrimaryDetails.fieldConfig){   this.filterFieldListforConfig();  }
   else if(this.props.rulesetPrimaryDetails.ruleContent !== prevProps.rulesetPrimaryDetails.ruleContent){ this.filterFieldListforConfig();  }

  }


}


setupFieldConfig=()=>{
  if(this.state.fieldConfig.length>0) {
    this.setState({fieldConfigCheckBox:true});
    let fieldConfigIDS=[],fieldConfigValue, dataType, operationType;
    for(var i=0; i<this.state.fieldConfig.length; i++){

      switch (this.state.fieldConfig[i].dataType.value){
        case "string":
        dataType={ label: 'Text', value: 'string' };
        break;

        case "number":
        dataType={ label: 'Number', value: 'number' };
        break;

        case "date":
        dataType={ label: 'Date', value: 'date' };
        break;

        default:

    }


    switch (this.state.fieldConfig[i].operationType.value){
      case "upper":
      operationType={ label: 'Convert to upper case', value: 'upper', operationCategory:'case' };
      break;

      case "lower":
      operationType={ label: 'Convert to lower case', value: 'lower', operationCategory:'case' };
      break;

      case "near":
      operationType={ label: 'Set the decimal value', value: 'near', operationCategory:'round' };
      break;

      case "cast":
      operationType={ label: 'Set the format', value: 'cast', operationCategory:'convert' };
      break;

      default:

  }


      fieldConfigValue={
        id:this.state.fieldConfig[i].id,
        field:this.state.fieldConfig[i].field,
        dataType: dataType,
        operationType:operationType,
        fieldConfigValue:this.state.fieldConfig[i].fieldConfigValue
      }

      fieldConfigIDS.push(this.state.fieldConfig[i].id);
      this.setState({[this.state.fieldConfig[i].id]:fieldConfigValue});
    }
    this.setState({fieldConfigIDS: fieldConfigIDS});
  }

  else{
    this.setState({
      fieldConfigCheckBox:false,
      fieldConfigIDS:['fc_n_0'],
      fc_n_0:{
                id: 'fc_n_0',
                field:null,
                dataType: "",
                operationType:"",
                fieldConfigValue:""
        }
      })
    }
}





  updateValueSet=(item,value)=>{
    let id=this.props.rulesetPrimaryDetails.id
    let rulesetName=this.props.rulesetPrimaryDetails.rulesetName;
    let ruleContent=this.props.rulesetPrimaryDetails.ruleContent;
    let valueMap=this.props.rulesetPrimaryDetails.valueMap;
    let fieldConfig=this.props.rulesetPrimaryDetails.fieldConfig;

    let valueSet={id:id,rulesetName:rulesetName,ruleContent:ruleContent,valueMap:valueMap,fieldConfig:fieldConfig,status:"changed"}
    valueSet[item]=value;
    this.props.updateRulesetPrimaryDetails(valueSet)

  }

  validateRules=()=>{
    let valueSet={id:this.props.value.id,ruleName:this.state.ruleName,ruleContent:this.state.ruleContent,threshold:this.state.threshold,valueMap:this.state.valueMap,fieldConfig:this.state.fieldConfig,status:"validated"}
    this.setState({validateRuleLabel:"Validated",validateRuleState:true});
    this.props.updateValues(valueSet)
  }

  deleteCard=()=>{
    this.props.deleteRule(this.state.ruleId)
  }



  updateDoneButtonState=(state)=>{

    this.setState({fieldConfigState:state});

  }

  handleMenuClick = (event) => {
    this.setState({anchorEl:event.currentTarget,moreMenu:true})
    };

   handleMenuClose = () => {
     this.setState({anchorEl:null,moreMenu:false})
      };


  openMoreOptions=()=>{
    this.handleMenuClose();
    maintainRuleState=this.props.rulesetPrimaryDetails;
    //this.filterFieldListforConfig();
    this.setupFieldConfig();

    this.setState({
      cardStyle:'rounded-card expanded-card',
      openMoreOptionsFlag:true
    });
    this.props.toggleBackdrop();
  }

  closeMoreOptions=()=>{
      this.props.updateRulesetPrimaryDetails(maintainRuleState)
      this.setState({
        cardStyle:'rounded-card',
        openMoreOptionsFlag:false
      });
      this.props.toggleBackdrop();
      this.updateDoneButtonState(false);
  }

  doneWithMoreOptions=()=>{
      this.checkRuleCompleteness();
      this.setState({
        cardStyle:'rounded-card',
        openMoreOptionsFlag:false
      });
      this.props.toggleBackdrop();
  }


  filterFieldListforConfig=()=>{
    var filteredFieldList=this.props.rulesetPrimaryDetails.ruleContent,field;
    for(var i=0;i<this.props.rulesetPrimaryDetails.fieldConfig.length;i++){
     field=this.props.rulesetPrimaryDetails.fieldConfig[i].field;
     filteredFieldList=filteredFieldList.filter(function (e) { return e !== field; });
    }


    this.setState({ filteredFieldList:filteredFieldList });
  }

  updateFieldConfigonRule=()=>{

    let ruleFieldConfigValues=[];
    for(var i=0; i<this.state.fieldConfigIDS.length;i++){
      if(this.state[this.state.fieldConfigIDS[i]].field !== null && this.state[this.state.fieldConfigIDS[i]].dataType !== "" && this.state[this.state.fieldConfigIDS[i]].operationType !== "")
      {
        ruleFieldConfigValues.push(this.state[this.state.fieldConfigIDS[i]]);
      }
    }

    this.updateValueSet("fieldConfig",ruleFieldConfigValues);
  }

  deleteRuleExpandedCard=()=>{
    this.deleteCard();
    this.closeMoreOptions();
  }

  checkboxStatus=(event)=>{

    this.setState({[event.target.name]: event.target.checked });

  }





 validateValueMaps=()=>{
   //console.log("valueMapValues",this.state.valueMap);
   this.checkRuleCompleteness();
 }

 validateTheRule=()=>{
   var isSource1Found = this.state.ruleContent.some(i => i.origin.includes('source1'));
   var isSource2Found = this.state.ruleContent.some(i => i.origin.includes('source2'));
   // var isOptFound = this.state.ruleContent.some(i => i.origin.includes('Operation'));
   var relOptFound = this.state.ruleContent.some(i =>
    i.origin.includes('Operation') &&
    (i.label.includes('=')));

   if(  this.state.ruleContent.length < 2 || this.state.ruleContent.length%2 === 0 || !relOptFound || !isSource1Found || !isSource2Found ){
     this.setState({ruleContentErrorStatus:true,ruleContentErrorText:"Your primary key doesn't look right. Please verify."},()=>{this.checkRuleCompleteness();})
   }
   else{
     this.setState({ruleContentErrorStatus:false,ruleContentErrorText:""},()=>{this.checkRuleCompleteness();})
   }
 }

 checkRuleCompleteness=()=>{

   let valueSet={rulesetName:this.state.rulesetName,primarKey:this.state.primarKey,valueMap:this.state.valueMap,fieldConfig:this.state.fieldConfig,status:"changed"}

   if(JSON.stringify(this.props.rulesetPrimaryDetails) !== JSON.stringify(valueSet)){
     if(this.state.rulesetName.trim() !== "" && this.state.RulesetnameErrorStatus===false && this.state.ruleContent.length !== 0 && this.state.ruleContentErrorStatus===false){
        valueSet={id:this.state.id,rulesetName:this.state.rulesetName.trim(),ruleContent:this.state.ruleContent,valueMap:this.state.valueMap,fieldConfig:this.state.fieldConfig,status:"validated"}
       this.props.updateRulesetPrimaryDetails(valueSet)
     }
   }else{
     //do nothing
   }

 }


  selectRuleValue = (values) => {
    this.setState({"ruleContent":values});
    this.updateValueSet("ruleContent",values);
  }

  selectValueMap = (values) => {

    let fieldValue;
    this.state.valueMap.length ===0 ? fieldValue= null : fieldValue=this.state.valueMap[0].field

    this.setState({"valuemapValues":values,"valueMap":[{field:fieldValue,values:values}]})
    this.updateValueSet("valueMap",[{field:fieldValue,values:values}]);
  }

  selectDropdownValue = (fileid,value) =>{
    let vMapValues;
    this.state.valueMap.length ===0 ? vMapValues=[] : vMapValues=this.state.valueMap[0].values;

    this.setState({[fileid]:value,"valueMap":[{field:value,values:vMapValues}]});
    this.updateValueSet("valueMap",[{field:value,values:vMapValues}]);
  }

  handleChange = (inputId) => {

     const input = document.getElementById(inputId);
     if(input.files[0] !==undefined){
         this.parseValuemapFile(input,this.updateValuemaps);
    }
    else {
      //  do nothing
    }
}


handleRulesetName = (event) => {
  let hasMatch=[];

  hasMatch=this.props.ruleSetList.filter(item=> item.name.toLowerCase() === event.target.value.toLowerCase());

  if(hasMatch.length>0){
    this.setState({RulesetnameErrorStatus:true,RulesetnameErrorText:"There is a matching name. Please try a different one.",rulesetName:event.target.value});
  }else{
    this.setState({RulesetnameErrorStatus:false,RulesetnameErrorText:"",rulesetName:event.target.value});
  }

  this.updateValueSet("rulesetName",event.target.value);
}

  parseValuemapFile=(input,callback) =>
     {
       var reader = new FileReader();

       reader.onload = function(e) {
        var data = e.target.result;
        let valuemapVals=[]
        var workbook = XLSX.read(data, {type: 'binary'});
        const wsname = workbook.SheetNames[0];
        const ws = workbook.Sheets[wsname];
        const sheetdata = XLSX.utils.sheet_to_json(ws, {header:1});
        for(let i=1;i<sheetdata.length;i++){
                  if(sheetdata[i][0]!=="" || sheetdata[i][0] !== undefined || sheetdata[i][1]!=="" || sheetdata[i][1] !== undefined )
                        valuemapVals.push(sheetdata[i][0]+"="+sheetdata[i][1])
                      }
        callback(valuemapVals);
        }
        reader.readAsBinaryString(input.files[0]);
     }

updateValuemaps=(valuemapVals)=>{
  this.selectValueMap(valuemapVals);
  //this.setState({valueMap:valuemapVals})
}




addNewConfig=()=> {

  var newConfig = 'fc_n_'+Date.now();
  this.setState({
     [newConfig]:{
      id: newConfig,
      field:null,
      dataType: "",
      operationType:"",
      fieldConfigValue:""
   }

   });
   this.setState(prevState => ({ fieldConfigIDS: prevState.fieldConfigIDS.concat([newConfig]) }));
}

deleteConfig=(configid)=> {
                 var fieldConfigArray = [...this.state.fieldConfigIDS];
                 var index = fieldConfigArray.indexOf(configid)
                   if (index !== -1) {
                   fieldConfigArray.splice(index, 1);

                   this.setState({fieldConfigIDS: fieldConfigArray},()=>{this.updateFieldConfigonRule();});
                   }
         }

  updateConfigValues=(configid,value)=>{
    this.setState({[configid]:value},()=>{this.updateFieldConfigonRule();});
  }



  render() {

  var fieldConfigContent

  if(this.state.openMoreOptionsFlag===true){
    fieldConfigContent=this.state.fieldConfigIDS.map(fieldConfigID => <FieldConfig key={fieldConfigID}
    ruleContent={this.state.ruleContent}
    fieldConfigValues={this.state[fieldConfigID]}
    filteredFieldList={this.state.filteredFieldList}
    updateConfigValues={this.updateConfigValues.bind(this)}
    deleteConfig={this.deleteConfig.bind(this)}
    updateDoneButtonState={this.updateDoneButtonState.bind(this)}
    checkRuleCompleteness={this.checkRuleCompleteness.bind(this)}
    />);
  }
  else{
    fieldConfigContent="";
  }



let numberofFieldsinRule = this.state.ruleContent.filter(function(item){ return item.origin==="source1" || item.origin==="source2" ;}).length;



    return (
      <div className={this.state.cardStyle}>

      <h3>Select a unique identifier for your data source and enter a name for the ruleset</h3>

      <Row className='rule-content'>

        <Col sm={8} xs={12}>
         <RuleAutocomplete
         fileColumns={this.props.fileColumns}
         operation={operation}
         onSelectValue={this.selectRuleValue}
         label="Setup the primary identifier for the ruleset."
         value={this.state.ruleContent}
         validateTheRule={this.validateTheRule.bind(this)}
         ruleContentErrorStatus={this.state.ruleContentErrorStatus}
         ruleContentErrorText={this.state.ruleContentErrorText}
         />
        </Col>

        <Col sm={3} xs={12}>
      <ComponentErrorBoundary value="rule name textfield">
        <TextField
          label="Name for the ruleset"
          value={this.state.rulesetName}
          fullWidth
          inputProps={{maxLength: 45}}
          onChange={(e)=>this.handleRulesetName(e)}
          onBlur={this.checkRuleCompleteness.bind(this)}
          error={this.state.RulesetnameErrorStatus}
          helperText={this.state.RulesetnameErrorText}
          variant="standard"
        />
        </ComponentErrorBoundary>
        </Col>

        <Col sm={1} xs={12}>


        <IconButton
             aria-label="more"
             id="moreMenu"
             aria-controls={open ? 'moreMenu' : undefined}
             aria-expanded={open ? 'true' : undefined}
             aria-haspopup="true"
             className="actions-menu"
             onClick={this.handleMenuClick.bind(this)}
           >
                 <i className='material-icons'>more_horiz</i>
        </IconButton>

        <Menu
          id='moreMenu'
          anchorEl={this.state.anchorEl}
          open={this.state.moreMenu}
          className="flyout-menu"
          onClose={this.handleMenuClose.bind(this)}
        >
            <MenuItem className='wl-group-card-menu'  onClick={this.openMoreOptions}>More options</MenuItem>
        </Menu>
        </Col>

      </Row>



      <Row className='more-options rounded-card'>
        <Col sm={12} xs={12}>
        <h5>Add value mapping</h5>
        <FormControlLabel
          control={<Checkbox checked={this.state.valuemapChkBox}
          onChange={(e)=>this.checkboxStatus(e)}
          color="primary"
          name="valuemapChkBox" />}
          label="Include value mapping"
          className="hide"
        />

        <div className={this.state.valuemapChkBox?'show':'show'}>

        <Row className='more-options options-inner-container'>
          <Col sm={12} xs={12}><label className="margin-bottom-10">Add a value map for one of the field used in your rule</label></Col>
          <Col sm={3} xs={12}>
          <FieldSelectorAutoComplete
            label='Select a field'
            fileColumns={this.state.ruleContent}
            onSelectValue={this.selectDropdownValue.bind(this,'valuemapColumn')}
            checkRuleCompleteness={this.validateValueMaps.bind(this)}
            value={this.state.valueMap.length!==0?this.state.valueMap[0].field:null}
            />
          </Col>
          <Col sm={3} xs={12}>
           <ValueMapAutocomplete
           fileColumns={this.props.fileColumns}
           onSelectValue={this.selectValueMap}
           checkRuleCompleteness={this.validateValueMaps.bind(this)}
           value={this.state.valueMap.length!==0?this.state.valueMap[0].values:[]}
           />
          </Col>
          <Col sm={6} xs={12}>
           <label>You could also upload a excel file with all your value maps</label>
           <input type="file" id={'valumapbrowse'+this.state.ruleId} className="btn" onChange={this.handleChange.bind(this,'valumapbrowse'+this.state.ruleId)} />
          </Col>
        </Row>
        </div>

        </Col>
      </Row>



      <Row className='more-options rounded-card'>
          <Col sm={12} xs={12}>
          <h5>Add field configuration</h5>
          <FormControlLabel
            control={<Checkbox checked={this.state.fieldConfigCheckBox}
            onChange={(e)=>this.checkboxStatus(e)}
            color="primary"
            name="fieldConfigCheckBox" />}
            label="Include field configuration"
            className="hide"
          />



          <div className={this.state.fieldConfigCheckBox?'show':'show'}>
          <Row className="more-options options-inner-container">
            <Col sm={12} xs={12}><label className="margin-bottom-10">Add a field level configuration as needed for your rule</label></Col>
              {fieldConfigContent}

              <Col sm={12} xs={12} className={this.state.fieldConfigIDS.length<numberofFieldsinRule?"show btn-container":"hide"}>
                <Button label="Add another config" disabled={this.state.fieldConfigState} className="btn btn-default" onClick={this.addNewConfig.bind()} />
              </Col>

          </Row>

        </div>
        </Col>
        </Row>




      <Row className='more-options rule-actions-container btn-container'>

         <Col sm={12} xs={6}>
         <Button label="Done" className='btn btn-action' disabled={this.state.fieldConfigState} onClick={this.doneWithMoreOptions} />
         <Button label="Cancel" className='btn btn-default' onClick={this.closeMoreOptions} />

         </Col>



      </Row>


    </div>
    );
  }
}


export default RulesetPrimaryDetails;
