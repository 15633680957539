import React, { Component } from 'react';
import Backdrop from '@mui/material/Backdrop';
import ManageContext from './components/manage-context';
import AddEditContext from './components/add-edit-context';
import Snackbar from '@mui/material/Snackbar';
import Banner from '../../components/banner';
import Modal from '../../components/modal';
import ErrorBoundary from '../../components/error-boundary';
import Auth from '../../auth/auth';
import {connect} from 'react-redux';
import {
      addContext,
      updateContext,
      deleteContext,
      loadContextDetails
    } from './context.actions';


    import {
          loadContextList
        } from '../../common-redux/common.actions';

const auth = new Auth();
let orgId;



class Context extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pageNavContainer:"hide",
      backdrop:false,
      contextDetails:null,
      step1:"",
      step2:"hide",
      snackbarStatus: false,
      bannerType: "danger",
      showBanner: false,
      bannerTitle:"",
      bannerMessage:"",
      actionTriggered:false,
      triggeredItemName:"",
      actionName:"",
      modalOpen:false,
      modalTitle:"",
      modalState:"",
      modalContent:"",
      modalPrimaryButton:"",
      modalSecondaryButton:"",
      envPageTitle:"",
      saveContextId:null,
      pageState:0
    };
    orgId  = auth.getOrgId();
    this.props.getContextList();
  }





  componentDidUpdate(prevProps) {
    if (prevProps.isLoading !== this.props.isLoading && this.state.actionTriggered === true) {

      if(this.props.isLoading.includes('addcontext')){
       this.setState({snackbarStatus:true,actionName:"add"});
      }
      else if(this.props.isLoading.includes('updatecontext')){
       this.setState({snackbarStatus:true,actionName:"update"});
      }
      else if(this.props.isLoading.includes('deletecontext')){
       this.setState({snackbarStatus:true,actionName:"delete"});
      }
      else{
        if(this.state.actionName==="add"){this.saveSuccess();}
        else if(this.state.actionName==="update"){this.updateSuccess();}
        else if(this.state.actionName==="delete"){this.deleteSuccess();}
        this.setState({snackbarStatus:false,actionTriggered:false,actionName:""});
      }
    }

    // ---- Error handing ------

    if (this.props.error !== prevProps.error) {
      this.setState({snackbarStatus:false,showBanner:true,bannerType:"danger",bannerTitle:"Oh snap! Something went wrong!",bannerMessage:"Please contact your admin."});
    }


    if (this.props.contextDetails !== prevProps.contextDetails) {
      this.setState({
        contextDetails:this.props.contextDetails
      });
    }



  }

saveSuccess=()=>{
  this.props.getContextList(orgId);
  this.navigateToBegin();
  var message = this.state.triggeredItemName+' has been added successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

updateSuccess=()=>{
  this.props.getContextList(orgId);
  this.navigateToBegin();
  var message = this.state.triggeredItemName+' has been updated successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

deleteSuccess=()=>{
  this.props.getContextList(orgId);
  var message = this.state.triggeredItemName+' has been deleted successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

navigateToBegin=()=>{
  this.props.parentNavBar(1);
  this.setState({
    pageState:0,
    pageNavContainer:"hide"
  });
}

cancelFlow=()=>{
  this.navigateToBegin();
}

saveContextFlow=(data,flowName,name)=>{
  this.setState({actionTriggered:true,triggeredItemName:name,saveContextId:data.id});
  flowName==='new' ? this.props.addContext(data) : this.props.updateContext(data)
}

deleteContext=(data)=>{
this.setState({actionTriggered:true,triggeredItemName:data.envName});
this.props.deleteContext(data);
}




toggleBackdrop=()=>{
  this.state.backdrop ?
  this.setState({backdrop:false,pageNavContainer:'page-nav-container', appContentContainer:"app-content-container in"}) :
  this.setState({backdrop:true,pageNavContainer:'page-nav-container reduce-zindex', appContentContainer:"app-content-container in has-backdrop"})
}

hideBackNavigation=()=>{
  this.setState({
    appContentContainer:"app-content-container",
    pageNavContainer:"hide"
  });
}



  goToTop=()=>{
    document.getElementById("app").scrollTo(0,0);
  }

  dropdownSelector = (name,id,value) => {
              this.setState({[name]:value});
          }

  dismissBanner = () => {
                this.setState({showBanner:false});
         }

 snackHandler = (state) =>{
   this.setState({snackbarStatus:state});
 }



 modalClose=()=>{
   this.setState({
     modalOpen:false
   });
 }

 modalConfirm=()=>{
   this.setState({
     modalOpen:false
   });
 }


 selectContext= (value) => {

   this.dismissBanner();
   this.props.parentNavBar(2);

   if(value==="new"){
     this.setState({
       pageState:1,
       pageNavContainer:"page-nav-container overlap",
       contextDetails:{contextId:null,name:"",desc:"",env:null,superUser:[]},
       envPageTitle:"Add a new context"
     });
   }

   else {
     this.props.getContextDetails(value.context.contextId);
     this.setState({
       pageState:1,
       pageNavContainer:"page-nav-container overlap",
       envPageTitle:"View & Edit context",
       contextDetails:this.state.contextDetails
     });
   }
 }






  goBack = (level) => {
                    this.props.parentNavBar(1);
                    if(level===0){
                      this.setState({
                        pageState:0,
                        pageNavContainer:"hide"
                      });
                    }
                }


   render() {

     return (
       <div className="sub-module">

       <Backdrop open={this.state.backdrop}></Backdrop>

       <Snackbar
           open={this.state.snackbarStatus}
           message="Please wait..."
           className="snack-bar"
         />

       <div className={this.state.pageNavContainer}>
         <a onClick={this.goBack.bind(this,0)} className={this.state.level0}>&lt; Manage Context</a>
       </div>


       <Modal
        open={this.state.modalOpen}
        title={this.state.modalTitle}
        content={this.state.modalContent}
        primaryButton={this.state.modalPrimaryButton}
        secondaryButton={this.state.modalSecondaryButton}
        close={this.modalClose}
        confirm={this.modalConfirm}
        />

       <Banner
          type={this.state.bannerType}
          hasAlerts={this.state.showBanner}
          header={this.state.bannerTitle}
          body={this.state.bannerMessage}
          dismissBanner={this.dismissBanner.bind(this)}
          />

<CustomButton label="Sign out"
  id="signoutBtn"
  onClick={this.signOut.bind(this)}
  className='btn btn-action'
  />


{
    this.state.pageState===0 ?
      <ErrorBoundary>
      <h2>Manage Context</h2>
      <ManageContext
        onClick={this.selectContext.bind(this)}
        contextList={this.props.contextList}
        orgId={orgId}
        deleteContext={this.deleteContext}
        isLoading={this.props.isLoading}
        />
      </ErrorBoundary> :

      <ErrorBoundary>
      <h2>{this.state.envPageTitle}</h2>
        <AddEditContext
          cancelFlow={this.cancelFlow}
          saveFlow={this.saveContextFlow}
          orgId={orgId}
          contextList={this.props.contextList}
          contextDetails={this.state.contextDetails}
          hideBackNavigation={this.hideBackNavigation}
          toggleBackdrop={this.toggleBackdrop.bind(this)}
          />
        </ErrorBoundary>

  }

       </div>
     );
   }
}

const mapStateToProps = (state) => {
  return{
    error: state.contextReducer.error,
    contextList: state.commonReducer.contextList,
    contextDetails: state.contextReducer.contextDetails,
    isLoading:state.contextReducer.isLoading.concat(state.commonReducer.isLoading)
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    getContextList : (orgId) => {
      dispatch(loadContextList(orgId));
    },
    addContext : (data) => {
      dispatch(addContext(data));
    },
    updateContext : (data) => {
      dispatch(updateContext(data));
    },
    deleteContext : (data) => {
      dispatch(deleteContext(data));
    },
    getContextDetails : (dbId) => {
      dispatch(loadContextDetails(dbId));
    }

  }
}


export default connect(mapStateToProps,mapDispatchToProps) (Context);
