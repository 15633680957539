import React, { Component } from 'react';
import Backdrop from '@mui/material/Backdrop';
import ManageUsers from './components/manage-users';
import AddUser from './components/add-user';
import EditUser from './components/edit-user';
import Snackbar from '@mui/material/Snackbar';
import Banner from '../../components/banner';
import Modal from '../../components/modal';
import ErrorBoundary from '../../components/error-boundary';
import Auth from '../../auth/auth';
import {connect} from 'react-redux';
import {
      addUser,
      updateUser,
      deleteUser
    } from './users.actions';

import {
          loadUsersList
        } from '../../common-redux/common.actions';


const auth = new Auth();
let orgId;

class AppUsers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      appContentContainer:"app-content-container",
      userListClass: "page-content select-container in",
      addUserClass: "page-content results-container",
      editUserClass: "page-content results-container",
      pageNavContainer:"hide",
      backdrop:false,
      userData:{user:{name:"",email:"",id:""}},
      step1:"",
      step2:"hide",
      userDetailsPagetTile:"",
      snackbarStatus: false,
      bannerType: "danger",
      showBanner: false,
      bannerTitle:"",
      bannerMessage:"",
      actionTriggered:false,
      triggeredUserName:"",
      actionName:"",
      modalOpen:false,
      modalTitle:"",
      modalState:"",
      modalContent:"",
      modalPrimaryButton:"",
      modalSecondaryButton:"",
      pageState:"manage"
    };
    orgId  = auth.getOrgId();
    this.props.getUserList(orgId);
  }





  componentDidUpdate(prevProps) {
    if (prevProps.isLoading !== this.props.isLoading && this.state.actionTriggered === true) {

      if(this.props.isLoading.includes('adduser')){
       this.setState({snackbarStatus:true,actionName:"add"});
      }
      else if(this.props.isLoading.includes('updateuser')){
       this.setState({snackbarStatus:true,actionName:"update"});
      }
      else if(this.props.isLoading.includes('deleteuser')){
       this.setState({snackbarStatus:true,actionName:"delete"});
      }
      else{
        if(this.state.actionName==="add"){this.saveSuccess();}
        else if(this.state.actionName==="update"){this.updateSuccess();}
        else if(this.state.actionName==="delete"){this.deleteSuccess();}
        this.setState({snackbarStatus:false,actionTriggered:false,actionName:""});
      }
    }

    // ---- Error handing ------

    if (this.props.error !== prevProps.error) {
      this.setState({snackbarStatus:false,showBanner:true,bannerType:"danger",bannerTitle:"Oh snap! Something went wrong!",bannerMessage:"Please contact your admin."});
    }

  }

saveSuccess=()=>{
  this.props.getUserList(orgId);
  this.navigateToBegin();
  var message = this.state.triggeredUserName+' has been added successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

updateSuccess=()=>{
  this.props.getUserList(orgId);
  this.navigateToBegin();
  var message = this.state.triggeredUserName+' has been updated successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

deleteSuccess=()=>{
  this.props.getUserList(orgId);
  var message = this.state.triggeredUserName+' has been deleted successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

navigateToBegin=()=>{
  this.props.parentNavBar(1);
  this.setState({
    appContentContainer:"app-content-container",
    userListClass: "page-content select-container in",
    addUserClass: "page-content results-container",
    editUserClass: "page-content results-container",
    pageNavContainer:"hide",
    pageState:"manage"
  });
}

cancelFlow=()=>{
  this.navigateToBegin();
}

deleteUser=(data)=>{
this.setState({actionTriggered:true,triggeredUserName:data.name});
this.props.deleteUserRequest(data);
}


saveFlow=(data,flowName,userName)=>{

  this.setState({actionTriggered:true,triggeredUserName:userName});
  flowName==='new' ? this.props.addUserRequest(data) : this.props.updateUserRequest(data)
}

toggleBackdrop=()=>{
  this.state.backdrop ?
  this.setState({backdrop:false,pageNavContainer:'page-nav-container', appContentContainer:"app-content-container in"}) :
  this.setState({backdrop:true,pageNavContainer:'page-nav-container reduce-zindex', appContentContainer:"app-content-container in has-backdrop"})
}

hideBackNavigation=()=>{
  this.setState({
    appContentContainer:"app-content-container",
    pageNavContainer:"hide"
  });
}


  goToTop=()=>{
    document.getElementById("app").scrollTo(0,0);
  }

  dropdownSelector = (name,id,value) => {
              this.setState({[name]:value});
          }

  dismissBanner = () => {
                this.setState({showBanner:false});
         }

 snackHandler = (state) =>{
   this.setState({snackbarStatus:state});
 }

 userLicenseLimit=()=>{
   this.setState({
     modalOpen:true,
     modalTitle:"User license limit",
     modalContent:"You've reached the maximum user license. If you require more licenses, please contact Caninsoft team.",
     modalPrimaryButton:"Ok",
     modalState:"limit",
     modalSecondaryButton:"null"
   });
 }

 modalClose=()=>{
   this.setState({
     modalOpen:false
   });
 }

 modalConfirm=()=>{
   this.setState({
     modalOpen:false
   });
 }


 selectUser= (value) => {

   this.dismissBanner();
   this.props.parentNavBar(2);

   if(value==="new"){
     this.setState({
       pageState:"new",
       pageNavContainer:"page-nav-container overlap",
       userData:{user:{userID:"new", name:"", email:""}}
     });
   }

   else {
     //this.props.getRuleSetContent(value);
     this.setState({
       pageState:"edit",
       pageNavContainer:"page-nav-container overlap",
       userDetailsPagetTile:"Edit user details",
       userData:value
     });
   }
 }



  goBack = (level) => {
                    this.props.parentNavBar(1);
                    if(level===0){
                      this.setState({
                        pageState:"manage",
                        appContentContainer:"app-content-container",
                        userListClass: "page-content select-container in",
                        addUserClass: "page-content results-container",
                        editUserClass: "page-content results-container",
                        pageNavContainer:"hide"
                      });
                    }
                }


   render() {

     return (
       <div className="sub-module">

       <Backdrop open={this.state.backdrop}></Backdrop>

       <Snackbar
           open={this.state.snackbarStatus}
           message="Please wait..."
           className="snack-bar"
         />

       <div className={this.state.pageNavContainer}>
         <a onClick={this.goBack.bind(this,0)} className={this.state.level0}>&lt; Manage Users</a>
       </div>


       <Modal
        open={this.state.modalOpen}
        title={this.state.modalTitle}
        content={this.state.modalContent}
        primaryButton={this.state.modalPrimaryButton}
        secondaryButton={this.state.modalSecondaryButton}
        close={this.modalClose}
        confirm={this.modalConfirm}
        />

       <Banner
          type={this.state.bannerType}
          hasAlerts={this.state.showBanner}
          header={this.state.bannerTitle}
          body={this.state.bannerMessage}
          dismissBanner={this.dismissBanner.bind(this)}
          />


      {
        this.state.pageState==="manage" ? <div>
          <h2>Manage Users</h2>
          <ErrorBoundary>
            <ManageUsers
              onClick={this.selectUser.bind(this)}
              userList={this.props.userList}
              orgId={orgId}
              deleteUser={this.deleteUser}
              isLoading={this.props.isLoading}
              userLicenseLimit={this.userLicenseLimit.bind(this)}
              />
          </ErrorBoundary>
        </div> : null
      }

      {
        this.state.pageState==="new" ? <div>
        <h2>Add user</h2>
        <ErrorBoundary>
          <AddUser
            cancelFlow={this.cancelFlow}
            saveFlow={this.saveFlow}
            orgId={orgId}
            userData={this.state.userData}
            hideBackNavigation={this.hideBackNavigation}
            toggleBackdrop={this.toggleBackdrop.bind(this)}
            />
          </ErrorBoundary>
        </div> : null
      }


      {
        this.state.pageState==="edit" ? <div>
        <h2>Edit user</h2>
        <ErrorBoundary>
          <EditUser
            cancelFlow={this.cancelFlow}
            saveFlow={this.saveFlow}
            orgId={orgId}
            userData={this.state.userData}
            hideBackNavigation={this.hideBackNavigation}
            toggleBackdrop={this.toggleBackdrop.bind(this)}
            userList={this.props.userList}
            userLicenseLimit={this.userLicenseLimit.bind(this)}
            />
          </ErrorBoundary>
        </div> : null
      }




       </div>
     );
   }
}

const mapStateToProps = (state) => {
  return{
    error: state.usersReducer.error,
    userList: state.commonReducer.userList,
    isLoading:state.usersReducer.isLoading.concat(state.commonReducer.isLoading)
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    getUserList : (orgId) => {
      dispatch(loadUsersList(orgId));
    },
    addUserRequest: (userObj) => {
      dispatch(addUser(userObj));
    },
    updateUserRequest: (userObj) => {
      dispatch(updateUser(userObj));
    },
    deleteUserRequest: (userObj) => {
      dispatch(deleteUser(userObj));
    }
  }
}


export default connect(mapStateToProps,mapDispatchToProps) (AppUsers);
