import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
// import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Modal from '../../../components/modal';
import Preloader from '../../../components/preloader';
import EmptyState from '../../../components/empty-state-container';
import ComponentErrorBoundary from '../../../components/component-error-boundary';
import Button from '../../../components/button';
import IconMenu from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import EditableTextField from './editable-text-field';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import {connect} from 'react-redux';

let dsList;

class ViewEditDs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dbFilterCriteria:"",
      emptyState:"You do not have any data sources.",
      deleteVal:"",
      modalOpen:false,
      modalTitle:"",
      modalContent:"",
      modalPrimaryButton:"",
      modalSecondaryButton:"",
      modalIdentifier:"",
      sourceList:[],
      fieldsBeingEdited:[],
      sourceFilterCriteria:"",
      deleteFieldVal:"",
      updatedSourceFields:[],
      deletedSourceFields:[],
      dsName:"",
      dsNameErrorStatus:false,
      dsNameErrorText:"",
      formErrorState:[],
      dsComment:"",
      dsCommentErrorStatus:false,
      dsCommentErrorText:"",
    };
  }


componentDidMount=()=>{
  this.setState({
    dbFilterCriteria:""
  });
}

componentDidUpdate(prevProps,prevState) {
  if(this.props.dsDetails !== prevProps.dsDetails){
    this.setState({
      sourceList:this.props.dsDetails.datasourceCols,
      dsName:this.props.dsDetails.datasourceName,
      dsNameErrorStatus:false,
      dsNameErrorText:"",
      formErrorState:['dsComment'],
      saveButtonStatus:true,
      dsComment:"",
      dsCommentErrorStatus:false,
      dsCommentErrorText:""
    })
  }

  if(this.state.sourceList !== prevState.sourceList || this.state.fieldsBeingEdited !== prevState.fieldsBeingEdited){
    this.enableSaveButton();
  }


}



editDs = (ds) => {
    this.props.onClick({ds});
}

modalClose=()=>{
  this.setState({
    modalOpen:false
  });
}

modalConfirm=()=>{
  if(this.state.modalIdentifier==="delete"){
    this.deleteFieldConfirmed(this.state.deleteFieldVal);
    this.setState({
      modalOpen:false
    });
  } else if(this.state.modalIdentifier==="add"){
    this.addNewFieldConfirmed(this.state.newFieldSource);
  }
}



formErrorRemoveValue=(attr)=>{
  if (this.state.formErrorState.find(o => o === attr)){
    this.setState({formErrorState:this.state.formErrorState.filter(val=> val !== attr)});
  }
}

formErrorInsertValue=(attr)=>{
  if (!this.state.formErrorState.find(o => o === attr)){
    this.setState({formErrorState:[...this.state.formErrorState,attr]});
  }
}

handleTextfield = (event,item) => {
  this.setState({[item]:event.target.value});
}


validateField=(field)=>{

  let errorStatus=field+"ErrorStatus";
  let errorText=field+"ErrorText";


  if(this.state[field].length>2)
  {
    if(field==="dsName"){
      this.checkNameDuplicates(this.state.dsName);
    } else{
      this.formErrorRemoveValue(field);
      this.setState({[errorStatus]:false,[errorText]:""},()=>{this.enableSaveButton();});
      return true;
    }
  }
  else{

    this.formErrorInsertValue(field);
    this.setState({[errorStatus]:true,[errorText]:"Invalid entry. Enter at least 2 characters."},()=>{this.enableSaveButton();});
    return false;
  }
}

checkNameDuplicates=(values)=>{
  let hasMatchingName = this.props.dsList.filter(function (item) { return item.datasourceName.toLowerCase() === values.toLowerCase(); });

  if(hasMatchingName.length !=0){

    if(hasMatchingName[0].dsId!==this.props.dsDetails.datasourceId){
      this.setState({dsNameErrorText:"You used this name already. Please try another.",dsNameErrorStatus:true},()=>{this.enableSaveButton();});
      this.formErrorInsertValue("dsName");
      return false;
    } else{
      this.setState({dsNameErrorText:"",dsNameErrorStatus:false},()=>{this.enableSaveButton();});
      this.formErrorRemoveValue("dsName");
      return true;
    }

  }

  else{
    this.setState({dsNameErrorText:"",dsNameErrorStatus:false},()=>{this.enableSaveButton();});
    this.formErrorRemoveValue("dsName");
    return true;
  }
}


updateSourceFields = (changedSourceFields,changedFieldValue) =>{
  let updatedSourceFields=this.state.updatedSourceFields;
  updatedSourceFields=updatedSourceFields.filter((item)=>{return item.colId != changedFieldValue.colId})
  updatedSourceFields.push(changedFieldValue);

  this.setState({sourceList:changedSourceFields,updatedSourceFields:updatedSourceFields});
}


deleteField = (field) => {
                let deleteFieldModalContent=[];

                deleteFieldModalContent.push(<div key={1}>Are you sure you want to delete the field "{field.name}"?</div>);

                // if(matchingRules.length>0){
                //   let deleteFieldRuleNames=[];
                //   matchingRules.forEach((ruleItems)=>{
                //     deleteFieldRuleNames.push(<div key={ruleItems.ruleName}>{ruleItems.ruleName}</div>);
                //   });
                //   deleteFieldModalContent.push(<div key={2} className="deleting-rule-names">By proceeding you'll delete the following rule(s): {deleteFieldRuleNames} </div>);
                // }

                this.setState({
                  modalOpen:true,
                  modalTitle:"Delete the field",
                  modalContent:deleteFieldModalContent,
                  modalIdentifier:"delete",
                  deleteFieldVal:field,
                  // deleteMatchingRules:matchingRules,
                  // deletePrimaryKeyState:isPrimary,
                  modalPrimaryButton:"Yes",
                  modalSecondaryButton:"No",
                });
    }

deleteFieldConfirmed=(field)=> {
  let changedSourceFields=this.state.sourceList;
  changedSourceFields=changedSourceFields.filter((item)=>{return item.colId != field.colId});

  let updatedSourceFields=this.state.updatedSourceFields;
  updatedSourceFields=updatedSourceFields.filter((item)=>{return item.colId != field.colId});

  let deletedSourceFields=this.state.deletedSourceFields;
  deletedSourceFields.push(field)
  this.setState({sourceList:changedSourceFields,updatedSourceFields:updatedSourceFields,deletedSourceFields:deletedSourceFields});
}

 addNewField = (source) => {
                    let addFieldModalContent=[];
                    this.setState({
                      modalOpen:true,
                      modalTitle:"Add new field",
                      modalContent:addFieldModalContent,
                      modalPrimaryButton:"Add",
                      modalSecondaryButton:"Cancel",
                      modalIdentifier:"add",
                      newFieldValue:""
                    });
        }


  addNewFieldConfirmed=(source)=>{

            if(this.state.newFieldValue===""){
              this.setState({
                addFieldError:true,
                addFieldHelperText:"Enter a valid name."
              });
            } else{

            let newField={
              colId:"n_"+Date.now(),
              name:this.state.newFieldValue
            }

            let isDuplicate=this.checkForDuplicates(newField);


            if(isDuplicate==="duplicate"){
              this.setState({
                addFieldError:true,
                addFieldHelperText:"There is another field with same name."
              });

            } else{

                let changedSourceFields=this.state.sourceList;
                    changedSourceFields.push(newField);

                let updatedSourceFields=this.state.updatedSourceFields;
                    updatedSourceFields.push(newField);

                this.setState({sourceList:changedSourceFields,updatedSourceFields:updatedSourceFields});


              this.setState({
                addFieldError:false,
                addFieldHelperText:"",
                modalOpen:false
              });
            }
          }
          setTimeout(function(){document.getElementById("app").scrollTo(0,document.getElementById("app").scrollHeight)}, 501);
      }


checkForDuplicates=(newField) => {
        let duplicateFieldIndex=this.state.sourceList.findIndex(el => el.name.toLowerCase() === newField.name.toLowerCase())
        if(duplicateFieldIndex === -1){
          return "notduplicate";
        }else{
          return "duplicate";
        }
      }

listofFieldsBeingEdited = (action,value) => {

  switch (action){
    case "add":
    this.setState({fieldsBeingEdited:[...this.state.fieldsBeingEdited,value]});
    break;

    case "remove":
    this.setState({fieldsBeingEdited:this.state.fieldsBeingEdited.filter(val=> val !== value)});

    break;
 }

}

saveUpdatedDs=()=>{

  let changedFields=[],fieldValue;
  // --- get all new & updated fields ---
  this.state.updatedSourceFields.forEach((field,index) => {
      if(String(field.colId).substr(0,2)==="n_"){
         changedFields.push(field)
      } else{
        field.colId="u_"+field.colId
        changedFields.push(field)
      }

  })

  // --- get all deleted fields ---
  this.state.deletedSourceFields.forEach((field,index) => {
    if(String(field.colId).substr(0,2)!=="n_"){
      field.colId="d_"+field.colId;
      changedFields.push(field);
    }
  })

  let dsObj={
    datasourceId:this.props.dsDetails.datasourceId,
    datasourceName: this.state.dsName,
    datasourceComment:this.state.dsComment,
    datasourceChangedFields: changedFields,
  }
  this.props.saveFlow(dsObj,'update',this.state.dsName);
}

enableSaveButton=()=>{
  if(this.state.formErrorState.length===0 && this.state.fieldsBeingEdited.length===0)
    {
    this.setState({saveButtonStatus:false});
    } else{
    this.setState({saveButtonStatus:true});
    }
}
cancelFlow=()=>{
   this.props.cancelFlow();
}

   render() {




let dsList;

     const {isLoading} = this.props;
     let manageContent,dsColsContent;
     let renderedModalContent=[];

     if(isLoading.includes('dsdetails')){
       manageContent=<Preloader loading={true} /> ;
     }
     else if(this.props.dsDetails !== undefined){

       let filterCriteria=this.state.sourceFilterCriteria;
       let sourceList = this.state.sourceList.filter(function (e) {  return e.name.toLowerCase().includes(filterCriteria.toLowerCase()); } );

      if(sourceList.length>0){
        dsColsContent=<Table className='data-table short-first-column'>
        <TableHead><TableRow><TableCell>Field name</TableCell><TableCell>Rule</TableCell><TableCell>Primary Key</TableCell></TableRow></TableHead>
        <TableBody>
             {sourceList.map((ds,index) => <tr key={index+ds.name}>
               <TableCell className='user' >
               <EditableTextField
               field={ds}
               fieldIndex={index}
               deleteField={this.deleteField.bind(this)}
               updateSourceFields={this.updateSourceFields.bind(this)}
               sourceList={this.state.sourceList}
               listofFieldsBeingEdited={this.listofFieldsBeingEdited.bind(this)}
               />

               </TableCell>
               <TableCell></TableCell>
               <TableCell></TableCell>
               </tr>)}
       </TableBody>
        </Table>
      }else{
        dsColsContent=<EmptyState className="margin-top-0" message={this.state.emptyState} />
      }
       manageContent=<div>
       <Row className="rounded-card">
              <Col sm={3} xs={6}>
              <ComponentErrorBoundary value="Data Source Name">
               <FormControl fullWidth>
                <InputLabel htmlFor="Data Source Name">Data Source Name</InputLabel>
                <Input
                  type="text"
                  value={this.state.dsName}
                  onChange={(e)=>this.handleTextfield(e,"dsName")}
                  error={this.state.dsNameErrorStatus}
                  onBlur={this.validateField.bind(this,"dsName")}
                />
                <FormHelperText className="Mui-error">{this.state.dsNameErrorText}</FormHelperText>
               </FormControl>
               </ComponentErrorBoundary>
              </Col>
          <Col sm={3} xs={6} className="key-value"><label>Original source name</label><span>{this.props.dsDetails.type==="query" ? this.props.dsDetails.properties.queryName : this.props.dsDetails.properties.fileName}</span></Col>
          <Col sm={3} xs={6} className="key-value"><label>Original source type</label><span>{this.props.dsDetails.type}</span></Col>
          {
            this.props.dsDetails.type==="file" ?
            <Col sm={3} xs={6} className="key-value"><label>Sheet name</label>{this.props.dsDetails.properties.sheetName}</Col> :
            null
          }
       </Row>


       <Row className="rounded-card"><Col sm={12} xs={12}>
       <Row className="search-btn-container">
        <Col sm={6} xs={12}>
       <ComponentErrorBoundary value="source1 search field">
       <TextField
         label="Search a field"
         fullWidth
         value={ this.state.sourceFilterCriteria }
         onChange={ e => this.setState({ sourceFilterCriteria:e.target.value }) }
       />
       </ComponentErrorBoundary>
       </Col>
       <Col sm={6} xs={12} className="text-right margin-top-15"><Button label="Add a new field" className='btn btn-default' onClick={this.addNewField.bind(this)} /></Col>
       </Row>
       <Row>
       <Col sm={12} xs={12}>{dsColsContent}</Col>
       </Row>
       </Col>
       </Row>
       <Row className="rounded-card">
       <Col sm={6} xs={6}>
       <div className="note"><span>Note:</span> Please add a valid comment that will help you track the changes happening on your data source.</div>
      <ComponentErrorBoundary value="DS Comments">
       <FormControl fullWidth>
        <InputLabel htmlFor="Change Comments">Change Comments</InputLabel>
        <Input
          type="text"
          value={this.state.dsComment}
          onChange={(e)=>this.handleTextfield(e,"dsComment")}
          error={this.state.dsCommentErrorStatus}
          onBlur={this.validateField.bind(this,"dsComment")}
        />
        <FormHelperText className="Mui-error">{this.state.dsCommentErrorText}</FormHelperText>
       </FormControl>
       </ComponentErrorBoundary>
       </Col>
       </Row>
       <Row>
       <Col sm={4} xs={12} className=''>
        <Button label="Save" className='btn btn-action' disabled={this.state.saveButtonStatus} onClick={this.saveUpdatedDs.bind(this)}  />
        <Button label="Cancel" className='btn btn-default' onClick={this.cancelFlow.bind(this)} />
       </Col>
       </Row>
       </div>
     }



     if(this.state.modalOpen){


       if(this.state.modalIdentifier==="add"){
         renderedModalContent.push(<div key={1}>Please note: The new field would be added to the bottom of the list.</div>);
         renderedModalContent.push(<div key={2}><TextField label="Enter field name" error={this.state.addFieldError} helperText={this.state.addFieldHelperText}  fullWidth value={this.state.newFieldValue} onChange={e => this.setState({ newFieldValue:e.target.value })} /></div>);
       } else if(this.state.modalIdentifier==="delete"){
         renderedModalContent=this.state.modalContent;
       }

      }

     return (
       <div>
       <Modal
        open={this.state.modalOpen}
        title={this.state.modalTitle}
        content={renderedModalContent}
        primaryButton={this.state.modalPrimaryButton}
        secondaryButton={this.state.modalSecondaryButton}
        close={this.modalClose}
        confirm={this.modalConfirm}
        />

       <div className="manage-ruleset margin-top-30">

       {manageContent}

       </div>


         </div>

     );
   }
}


const mapStateToProps = (state) => {
  return{
    isLoading: state.databaseReducer.isLoading.concat(state.commonReducer.isLoading),
    errors: state.databaseReducer.errorMessage
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {

  }
}


export default connect(mapStateToProps,mapDispatchToProps) (ViewEditDs);
