import React from "react";
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';

export default function Tags(props) {



const [values, setValues] = React.useState({
   error:  false,
   errorText: '',
   disabled: true,
   currentValue:props.field.value,
   value:props.field.value
 });

  const handleTextfield = event => {
   setValues({ ...values, value: event.target.value });
  }



  const checkForDuplicates=(index,sourceFields,newField) => {

    let duplicateFieldIndex=sourceFields.findIndex(el => el.value.toLowerCase() === newField.value.toLowerCase())


    if(duplicateFieldIndex === -1){
      return "notduplicate";
    }else if(duplicateFieldIndex === Number(index)-1){
      return "samefield";
    }else{
      return "duplicate";
    }
  }



  const handleClickEdit = event => {
    if(!values.disabled) {

      if(values.value===""){

        setValues({ ...values, error: true, errorText: 'Enter a valid name.'});

      } else{

    let changedSourceFields,changedCombinedFields,changedPrimaryId,refForPrimaryId;

    switch (props.field.origin){
      case "source1":
      changedSourceFields=props.file1column;
      changedPrimaryId=props.file1primary;
      refForPrimaryId="firstfilePrimaryId";
      break;

      case "source2":
      changedSourceFields=props.file2column;
      changedPrimaryId=props.file2primary;
      refForPrimaryId="secondfilePrimaryId";
      break;
  }

     let changedFieldValue={
       label:values.value,
       value:values.value,
       origin:props.field.origin,
       datatype:props.field.datatype
     }



     let isDuplicated=checkForDuplicates(props.fieldIndex,changedSourceFields,changedFieldValue);

     if(isDuplicated==="duplicate"){
       setValues({ ...values, error: true, errorText: 'There is another field with same name.'});

     } else if(isDuplicated==="samefield"){
       setValues({ ...values, value: values.value, disabled: !values.disabled, error: false, errorText: ''});
       props.listofFieldsBeingEdited("remove",props.field);
     }

     else{



         //--- update source fields ----


         changedCombinedFields=props.fileColumnsMerged;
         changedSourceFields[changedSourceFields.findIndex(el => JSON.stringify(el) === JSON.stringify(props.field))] = changedFieldValue;
         changedCombinedFields[changedCombinedFields.findIndex(el => JSON.stringify(el) === JSON.stringify(props.field))] = changedFieldValue;
         props.updateSourceFields(props.field.origin,changedSourceFields,changedCombinedFields);

          //--- update primary key ----

          if(props.isPrimary){
            changedPrimaryId[changedPrimaryId.findIndex(el => JSON.stringify(el) === JSON.stringify(props.field))] = changedFieldValue;
            props.selectPrimaryIdValue(refForPrimaryId,changedPrimaryId);
          }

          //--- update rules content ---

          if(props.matchingRules.length>0){

            let valueSet,changedRuleContent,changedVMap,changedFieldConfig,fieldConfigIndex;

            props.matchingRules.forEach((ruleItems)=>{

            changedRuleContent=ruleItems.ruleContent;
            changedFieldConfig=ruleItems.fieldConfig;
            changedVMap=ruleItems.valueMap;

              // ----- update rule  ----

            changedRuleContent[changedRuleContent.findIndex(el => JSON.stringify(el) === JSON.stringify(props.field))] = changedFieldValue;

            // ----- update field config  ----

            fieldConfigIndex=changedFieldConfig.findIndex(el => JSON.stringify(el.field) === JSON.stringify(props.field));

            if(fieldConfigIndex !== -1){
              changedFieldConfig[fieldConfigIndex]= {dataType:changedFieldConfig[fieldConfigIndex].dataType,field:changedFieldValue,fieldConfigValue:changedFieldConfig[fieldConfigIndex].fieldConfigValue,id:changedFieldConfig[fieldConfigIndex].id,operationType:changedFieldConfig[fieldConfigIndex].operationType}
            }

            // ----- update value map ----
            if(ruleItems.valueMap.length>0){
              if(JSON.stringify(ruleItems.valueMap[0].field) === JSON.stringify(props.field)){
                changedVMap = [{field:changedFieldValue,values:ruleItems.valueMap[0].values}]
              }
            }




              valueSet={
                id:ruleItems.id,
                ruleName:ruleItems.ruleName,
                ruleContent:changedRuleContent,
                threshold:ruleItems.threshold,
                valueMap:changedVMap,
                fieldConfig:changedFieldConfig,
                status:"validated"
              }
              props.updateValues(valueSet)


            })
          }

          setValues({ ...values, value: values.value, disabled: !values.disabled, error: false, errorText: ''});
          props.listofFieldsBeingEdited("remove",props.field);

       }

     }

    } else{
      //--- edit clicked ---
      setValues({ ...values, disabled: !values.disabled });
      props.listofFieldsBeingEdited("add",props.field);
    }


  }

  const handleClickDiscard = event => {
    //----- delete clicked -----
    if(values.disabled){
      props.deleteField(props.field,props.matchingRules,props.isPrimary);
    }
    //----- undo clicked --------
    else{
      setValues({ ...values, disabled: !values.disabled,value: values.currentValue,error: false, errorText: ''  });
      props.listofFieldsBeingEdited("remove",props.field);
    }

  }




  return (
    <FormControl fullWidth>
     <Input
       type="text"
       value={values.value}
       onChange={handleTextfield}
       error={values.error}
       disabled={values.disabled}
       endAdornment={
         <InputAdornment position="end">
           <IconButton aria-label="edit" onClick={handleClickEdit} size="large">
             {values.disabled ?
               <Tooltip title="Edit" placement="right"><i className="material-icons">edit</i></Tooltip> :
               <Tooltip title="Save" placement="right"><i className="material-icons">done</i></Tooltip>
             }
           </IconButton>

           <IconButton aria-label="edit" onClick={handleClickDiscard} size="large">
              {values.disabled ?
               <Tooltip title="Delete" placement="right"><i className="material-icons">delete</i></Tooltip> :
               <Tooltip title="Undo" placement="right"><i className="material-icons">undo</i></Tooltip>
             }
           </IconButton>
         </InputAdornment>
       }
     />
     <FormHelperText className="Mui-error">{values.errorText}</FormHelperText>
    </FormControl>
  );
}
