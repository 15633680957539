import { databaseActions } from './database.actions';
import {removeItemFromArray} from "../../util/util";

export const initialState = {
    dbList: [],
    dbTestStatus:"",
    queryTestStatus:"",
    isLoading: [],
    error:[],
    queryDetails:"",
    dbDetails:"",
    envDetails:"",
    dbTablesList:[],
    dsDetails:""
};

export function databaseReducer (state= initialState, action){

  let status;

  switch(action.type){
    //-------Db list Request-------------
    case databaseActions.GET_DB_LIST_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'dblist']
    };

    //-------Db list Success-------------
    case databaseActions.GET_DB_LIST_SUCCESS:
    return{
      ...state,
      dbList:action.payload.response,
      isLoading:removeItemFromArray(state.isLoading,'dblist')
    };

    //-------Db list Failure-------------
    case databaseActions.GET_DB_LIST_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };



    //-------Test Db Request-------------
    case databaseActions.TEST_DB_CONN_REQUEST:
    return{
      ...state,
      dbTestStatus:"",
      isLoading:[...state.isLoading,'testconn']
    };

    //-------Test Db Success-------------
    case databaseActions.TEST_DB_CONN_SUCCESS:

    return{
      ...state,
      dbTestStatus:action.payload,
      isLoading:removeItemFromArray(state.isLoading,'testconn')
    };

    //-------Test Db Failure-------------
    case databaseActions.TEST_DB_CONN_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

    //-------Add DB Request-------------
    case databaseActions.ADD_DB_CONN_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'adddb']
    };

    //-------Add DB Success-------------
    case databaseActions.ADD_DB_CONN_SUCCESS:
    return{
      ...state,
      isLoading:removeItemFromArray(state.isLoading,'adddb')
    };

    //-------Add DB Failure-------------
    case databaseActions.ADD_DB_CONN_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

    //-------Delete DB Request-------------
    case databaseActions.DELETE_DB_CONN_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'deletedb']
    };

    //-------Delete DB Success-------------
    case databaseActions.DELETE_DB_CONN_SUCCESS:
    return{
      ...state,
      isLoading:removeItemFromArray(state.isLoading,'deletedb')
    };

    //-------Delete DB Failure-------------
    case databaseActions.DELETE_DB_CONN_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };


    //-------Load DB Details Request-------------
    case databaseActions.LOAD_DB_DETAILS_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'dbdetails']
    };

    //-------Load DB Details Success-------------
    case databaseActions.LOAD_DB_DETAILS_SUCCESS:
    return{
      ...state,
      dbDetails:action.payload.response,
      isLoading:removeItemFromArray(state.isLoading,'dbdetails')
    };

    //-------Load DB Details Failure-------------
    case databaseActions.LOAD_DB_DETAILS_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };


    //-------Load DB Table List Request-------------
    case databaseActions.GET_DB_TABLE_LIST_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'dbtableslist']
    };

    //-------Load DB Table List Success-------------
    case databaseActions.GET_DB_TABLE_LIST_SUCCESS:
    return{
      ...state,
      dbTablesList:action.payload.response,
      isLoading:removeItemFromArray(state.isLoading,'dbtableslist')
    };

    //-------Load DB Table List Failure-------------
    case databaseActions.GET_DB_TABLE_LIST_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };





    //-------Test Query Request-------------
    case databaseActions.TEST_QUERY_REQUEST:
    return{
      ...state,
      dbTestStatus:"",
      isLoading:[...state.isLoading,'testquery']
    };

    //-------Test Query Success-------------
    case databaseActions.TEST_QUERY_SUCCESS:

    return{
      ...state,
      queryTestStatus:action.payload,
      isLoading:removeItemFromArray(state.isLoading,'testquery')
    };

    //-------Test Query Failure-------------
    case databaseActions.TEST_QUERY_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

    //-------Add Query Request-------------
    case databaseActions.ADD_QUERY_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'addquery']
    };

    //-------Add Query Success-------------
    case databaseActions.ADD_QUERY_SUCCESS:
    return{
      ...state,
      isLoading:removeItemFromArray(state.isLoading,'addquery')
    };

    //-------Add Query Failure-------------
    case databaseActions.ADD_QUERY_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

    //-------Load Query Details Request-------------
    case databaseActions.LOAD_QUERY_DETAILS_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'querydetails']
    };

    //-------Load Query Details Success-------------
    case databaseActions.LOAD_QUERY_DETAILS_SUCCESS:
    return{
      ...state,
      queryDetails:action.payload.response,
      isLoading:removeItemFromArray(state.isLoading,'querydetails')
    };

    //-------Load Query Details Failure-------------
    case databaseActions.LOAD_QUERY_DETAILS_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

    //-------Delete Query Request-------------
    case databaseActions.DELETE_QUERY_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'deletequery']
    };

    //-------Delete Query Success-------------
    case databaseActions.DELETE_QUERY_SUCCESS:
    return{
      ...state,
      isLoading:removeItemFromArray(state.isLoading,'deletequery')
    };

    //-------Delete Query Failure-------------
    case databaseActions.DELETE_QUERY_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };


    //-------Add Env Request-------------
    case databaseActions.ADD_ENV_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'addenv']
    };

    //-------Add Env Success-------------
    case databaseActions.ADD_ENV_SUCCESS:
    return{
      ...state,
      isLoading:removeItemFromArray(state.isLoading,'addenv')
    };

    //-------Add Env Failure-------------
    case databaseActions.ADD_ENV_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };


    //-------Update Env Request-------------
    case databaseActions.UPDATE_ENV_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'updateenv']
    };

    //-------Update Env Success-------------
    case databaseActions.UPDATE_ENV_SUCCESS:
    return{
      ...state,
      isLoading:removeItemFromArray(state.isLoading,'updateenv')
    };

    //-------Update Env Failure-------------
    case databaseActions.UPDATE_ENV_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

    //-------Load Env Details Request-------------
    case databaseActions.LOAD_ENV_DETAILS_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'envdetails']
    };

    //-------Load Env Details Success-------------
    case databaseActions.LOAD_ENV_DETAILS_SUCCESS:
    return{
      ...state,
      envDetails:action.payload,
      isLoading:removeItemFromArray(state.isLoading,'envdetails')
    };

    //-------Load Env Details Failure-------------
    case databaseActions.LOAD_ENV_DETAILS_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

    //-------Delete Env Request-------------
    case databaseActions.DELETE_ENV_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'deleteenv']
    };

    //-------Delete Env Success-------------
    case databaseActions.DELETE_ENV_SUCCESS:
    return{
      ...state,
      isLoading:removeItemFromArray(state.isLoading,'deleteenv')
    };

    //-------Delete Env Failure-------------
    case databaseActions.DELETE_ENV_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

    //-------Add Data Source Request-------------
    case databaseActions.ADD_DATA_SOURCE_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'addds']
    };

    //-------Add Data Source Success-------------
    case databaseActions.ADD_DATA_SOURCE_SUCCESS:
    return{
      ...state,
      isLoading:removeItemFromArray(state.isLoading,'addds')
    };

    //-------Add Data Source Failure-------------
    case databaseActions.ADD_DATA_SOURCE_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };


    //-------Update Data Source Request-------------
    case databaseActions.UPDATE_DATA_SOURCE_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'updateds']
    };

    //-------Update Data Source Success-------------
    case databaseActions.UPDATE_DATA_SOURCE_SUCCESS:
    return{
      ...state,
      isLoading:removeItemFromArray(state.isLoading,'updateds')
    };

    //-------Update Data Source Failure-------------
    case databaseActions.UPDATE_DATA_SOURCE_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };



    //-------Delete Data Source Request-------------
    case databaseActions.DELETE_DATA_SOURCE_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'deleteds']
    };

    //-------Delete Data Source Success-------------
    case databaseActions.DELETE_DATA_SOURCE_SUCCESS:
    return{
      ...state,
      isLoading:removeItemFromArray(state.isLoading,'deleteds')
    };

    //-------Delete Data Source Failure-------------
    case databaseActions.DELETE_DATA_SOURCE_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };



      default:
        return state;
  }
}
