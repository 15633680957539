import history from '../history';
import { Auth } from 'aws-amplify';

export default class Author {

  getAccessToken() {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('No access token found');
    }
    return accessToken;
  }


  getContext() {
    const yourContext = localStorage.getItem('context');
    if (!yourContext) {
      return null;
    }
    return JSON.parse(yourContext);
  }

  setContextSession(context){
    localStorage.setItem('context',JSON.stringify(context));
  }

  getOrgId() {
    const orgId = localStorage.getItem('orgId');
    if (!orgId) {
        throw new Error('No org id found');
    }
    return orgId;
  }

  getRoleId() {
    const roleId = localStorage.getItem('roleId');
    if (!roleId) {
      return Number(0);
    }
    return Number(roleId);
  }


  async logout() {
    try {
        await Auth.signOut();
    } catch (error) {
        console.log('error signing out: ', error);
    }
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('saganUsername');
    localStorage.removeItem('orgId');
    localStorage.removeItem('roleId');
    localStorage.removeItem('context');
    // navigate to the home route
    history.replace('/login');
  }

  setSession(){
    // let expiresAt = JSON.stringify((user.expireIn * 1000) + new Date().getTime());
    // localStorage.setItem('access_token', user.token);
    // localStorage.setItem('expires_at', expiresAt);
    // localStorage.setItem('saganUsername',user.username);
    // localStorage.setItem('orgId',user.org);
    // localStorage.setItem('roleId',user.role);
    // localStorage.setItem('context',JSON.stringify(user.context));
    //localStorage.removeItem('expires_at');
    Auth.currentSession()
            .then((response) => {

              let expiresAt = JSON.stringify((response.getAccessToken().payload.exp * 1000) + new Date().getTime());
              localStorage.setItem('expires_at', expiresAt);
              localStorage.setItem('access_token', response.getAccessToken().getJwtToken());

            })
            .catch(
              (response) => {
                console.log("Auth2-Eror",err);
              }

          );
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time

    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    //console.log("I'm called",new Date().getTime()<expiresAt);
    return new Date().getTime() < expiresAt;
  }
}
