import React, { Component } from 'react';
import Responsive from 'react-responsive-decorator';
import {Col, Row} from 'react-bootstrap';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import {connect} from 'react-redux';


//import Notification from './notification';

class SecuredLayoutWOMenu extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    }




  render() {



    return (
      <div>
        <section className="app-main-container">
          {this.props.children}
        </section>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return{
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
  }
}


export default connect(mapStateToProps,mapDispatchToProps) (Responsive(SecuredLayoutWOMenu));
