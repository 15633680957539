import React, { Component } from 'react';
import Responsive from 'react-responsive-decorator';
import {Col, Row} from 'react-bootstrap';
import Header from './header';
import MainMenu from './menu';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { Redirect } from 'react-router';
import { Switch, Route } from "react-router-dom";
import history from '../history';
import {connect} from 'react-redux';
import {
      hideDownloadList
    } from '../pages/recon/recon.actions';

import {
          clearCommonData
        } from '../common-redux/common.actions';



import Auth from '../auth/auth';


const auth = new Auth();
const { getContext,getRoleId } = auth;

//import Notification from './notification';

class SecuredLayout extends Component {

  constructor(props) {
    super(props);
    this.state = {isMobile: false};
  }

  componentDidMount() {
      this.props.media({ minWidth: 768 }, () => {
        this.setState({
          isMobile: false
        });
      });

      this.props.media({ maxWidth: 768 }, () => {
        this.setState({
          isMobile: true
        });
      });
    }

    componentWillUnmount(){
      this.props.clearCommonData();
    }


  render() {

    //console.log("getContext()123",getContext());

    // if(getContext() === null && getRoleId() !==1){
    //   return(
    //     <Redirect to="/context" />
    //   )
    // } else if(getContext() === null && getRoleId() ===1){
    //   return(
    //     <Redirect to="/admin" />
    //   )
    // }
    // else {
      let downloadFilesList=this.props.downloadingList.map(filename => <Chip key={filename} className="animate-flicker" label={"Downloading..."+filename+".xlsx"} />);

      return (
        <div>
          <Header />
          <MainMenu isMobile={this.state.isMobile} />
          <section className="app-main-container">
            {this.props.children}
          </section>
          <Row className={this.props.isDownloadListOpen?"show-file-download-progress":"hide-file-download-progress"}>
            <Col sm={11} xs={10}>
              {downloadFilesList}
            </Col>
            <Col sm={1} xs={2}>
              <Tooltip title="Hide" placement="top"><span className='material-icons' onClick={this.props.hideDownloadList.bind(this)}>close</span></Tooltip>
            </Col>

          </Row>

        </div>
      );
    //}

  }
}


const mapStateToProps = (state) => {
  return{
    downloadingList: state.reconReducer.downloadingList,
    isDownloadListOpen:state.reconReducer.isDownloadListOpen
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    hideDownloadList : () => {
      dispatch(hideDownloadList());
    },
    clearCommonData:()=>{
      dispatch(clearCommonData());
    }
  }
}


export default connect(mapStateToProps,mapDispatchToProps) (Responsive(SecuredLayout));
