import React, { Component } from 'react';
import Backdrop from '@mui/material/Backdrop';
import ManageContext from './components/manage-context';
import AddEditContext from './components/add-edit-context';
import Button from '../../components/button';
import Snackbar from '@mui/material/Snackbar';
import Banner from '../../components/banner';
import Modal from '../../components/modal';
import ErrorBoundary from '../../components/error-boundary';
import EmptyState from '../../components/empty-state-container';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {Col, Row} from 'react-bootstrap';
import caninsoftLogo from '../../images/caninsoft-logo.svg';
import Drawer from '@mui/material/Drawer';
import Preloader from '../../components/preloader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import ComponentErrorBoundary from '../../components/component-error-boundary';
import {connect} from 'react-redux';
import history from '../../history';
import {
      loadAssgnContextList,
      setContext
    } from './context.actions';
import { Auth } from 'aws-amplify';
import CustomButton from '../../components/button';



// const auth = new Auth();
//
// const { getContext } = auth;
//
// const { setContextSession } = auth;




class ContextCheck extends Component {

  constructor(props) {
    super(props);
    this.state = {
      continueButtonStatus:true,
      contextVal:"",
      pageState:0,
      actionTriggered:false,
      snackbarStatus:false,
      selectedContext:null,
      superUserList:[],
      validatorList:[],
      ruleFilterCriteria:""
    };
  this.props.getAssgnContextList();

  }

  async signOut() {
    console.log("hey");
      try {
          await Auth.signOut();
      } catch (error) {
          console.log('error signing out: ', error);
      }
  }

  dismissBanner = () => {
                this.setState({showBanner:false});
         }


  componentDidUpdate(prevProps) {
    if (prevProps.assgnContextList !== this.props.assgnContextList) {

      this.props.assgnContextList.length===0 ? this.setState({pageState:2}) : this.setState({pageState:1})

      //let status = this.props.contextList.find(x => x.contextId === 119);

      //status === undefined ? this.setState({pageState:1}) : this.setContext(status.contextId)

      let superUser = this.props.assgnContextList.filter(item => item.userType === 'SuperUser');

      let validator = this.props.assgnContextList.filter(item => item.userType === 'Validator');

      this.setState({superUserList:superUser,validatorList:validator})

    }

    // ---- Error handing ------

    if (this.props.error !== prevProps.error) {
      this.setState({snackbarStatus:false,showBanner:true,bannerType:"danger",bannerTitle:"Oh snap! Something went wrong!",bannerMessage:"Please contact your admin."});
    }

    if (prevProps.isLoading !== this.props.isLoading && this.state.actionTriggered === true) {
      if(this.props.isLoading.includes('setcontext')){
       this.setState({snackbarStatus:true,showBanner:false});
     } else{
       if(this.props.setContextStatus !== undefined){
         if(this.props.setContextStatus.data.contextSet){
           setContextSession(this.state.selectedContext);
           this.setState({snackbarStatus:false});
           history.replace('/comparizone')

         } else{
           this.setState({snackbarStatus:false,showBanner:true,bannerType:"danger",bannerTitle:"Oh snap! Something went wrong!",bannerMessage:"Please try again."});
         }
       }
     }
    }


  }


setContext = () =>{
  this.setState({actionTriggered:true})
  this.props.setContext(this.state.selectedContext);
}





radioSelect=(context,e)=>{
   this.setState({
     continueButtonStatus:false,
     contextVal:e.target.value,
     selectedContext:context
   });
}

// signOut=()=>{
//   auth.logout();
// }

goBack=()=>{
  history.goBack();
}




   render() {

    // console.log("getContext() on context page",getContext());


    const filterCriteria=this.state.ruleFilterCriteria;
    let superUserList = this.state.superUserList.filter(function (e) {  return e.contextName.toLowerCase().includes(filterCriteria.toLowerCase()); } );
    let validatorList = this.state.validatorList.filter(function (e) {  return e.contextName.toLowerCase().includes(filterCriteria.toLowerCase()); } );


     let pageContent;
     pageContent= <Table
       className='data-table context-select-table'
     >
       <TableHead className="hide">
         <TableRow>
           <TableCell>Name</TableCell>
           <TableCell>Role</TableCell>
           <TableCell>Description</TableCell>
         </TableRow>
       </TableHead>
       <TableBody>
       {
        superUserList.length>0 ? <TableRow>
         <TableCell colSpan={3} className='table-cell-title'>Super User</TableCell>
         </TableRow> : null
       }


       {
         superUserList.map((context,index) => <TableRow key={index}>
         <TableCell>
           <FormControl>
               <RadioGroup
                 aria-labelledby="demo-radio-buttons-group-label"
                 defaultValue=""
                 name="radio-buttons-group"
                 onChange={this.radioSelect.bind(this,context)}
                 value={this.state.contextVal}
               >
               <FormControlLabel value={context.contextId} control={<Radio />} label={context.contextName} />
               </RadioGroup>
           </FormControl>
         </TableCell>
         <TableCell>{context.userType}</TableCell>
         <TableCell>{context.contextDesc}</TableCell>
         </TableRow>)
       }

       {
        validatorList.length>0 ? <TableRow>
         <TableCell colSpan={3} className='table-cell-title'>Validator</TableCell>
         </TableRow> : null
       }


       {
         validatorList.map((context,index) => <TableRow key={index}>
         <TableCell>
           <FormControl>
               <RadioGroup
                 aria-labelledby="demo-radio-buttons-group-label"
                 defaultValue=""
                 name="radio-buttons-group"
                 onChange={this.radioSelect.bind(this,context)}
                 value={this.state.contextVal}
               >
               <FormControlLabel value={context.contextId} control={<Radio />} label={context.contextName} />
               </RadioGroup>
           </FormControl>
         </TableCell>
         <TableCell>{context.userType}</TableCell>
         <TableCell>{context.contextDesc}</TableCell>
         </TableRow>)
       }

       {
        superUserList.length===0 &&  validatorList.length===0 ? <TableRow>
         <TableCell colSpan={3} className='table-cell-title'>No matching context.</TableCell>
         </TableRow> : null
       }
       </TableBody>
      </Table>

     return (
       <div className="context-check">

       <Banner
          type={this.state.bannerType}
          hasAlerts={this.state.showBanner}
          header={this.state.bannerTitle}
          body={this.state.bannerMessage}
          dismissBanner={this.dismissBanner.bind(this)}
          />

      <Snackbar
            open={this.state.snackbarStatus}
            message="Please wait..."
            className="snack-bar"
          />



          {
            this.state.pageState===1 ?
            <div>
            <Drawer
            //docked={this.state.docked}
            width={600}
            open={this.state.open}
            // overlayClassName={'menu-overlay'}
            variant={'permanent'}
            hideBackdrop={true}
            className='context-check-menu'
            //onRequestChange={(open) => this.setState({open})}
            >
            <img src={caninsoftLogo} width="120" height="" align="center" alt="Caninsoft" />
            {
              getContext() === null ?
              <div className="margin-top-40 btn-container"><Button label="Log out" className='btn btn-action' onClick={this.signOut.bind(this)}  /></div> : <div className="margin-top-40 btn-container"><Button label="&lt; Back" className='btn btn-default' onClick={this.goBack.bind(this)}  /></div>
            }
            </Drawer>
            <ErrorBoundary>

            <Row className="search-section margin-top-40 margin-bottom-10" id="searchBar">
            <Col sm={4} xs={12}></Col>
            <Col sm={4} xs={6}>
                <ComponentErrorBoundary value="Search context">
                <TextField
                  label="Search context"
                  fullWidth
                  value={ this.state.ruleFilterCriteria }
                  onChange={ e => this.setState({ ruleFilterCriteria:e.target.value }) }
                />
                </ComponentErrorBoundary>

                </Col>
            </Row>
            <Row>
              <Col sm={4} xs={12}></Col>
              <Col sm={8} xs={12}>
              <h3>Choose a workspace</h3>
              <div>{pageContent}</div>
              <div className='margin-top-30 margin-bottom-30 btn-container'>
               <Button label="Continue" className='btn btn-action' disabled={this.state.continueButtonStatus} onClick={this.setContext.bind(this)}  />
              </div>
              </Col>
              <Col sm={2} xs={12}></Col>
            </Row>
            </ErrorBoundary>
            </div> : null
          }


          {
            this.state.pageState===0 ?
            <div className='container-w-centered-content btn-container'>
            <img src={caninsoftLogo} width="200" height="" align="center" alt="Caninsoft" />
            <Preloader loading={true} />
            <CustomButton label="Sign out"
              id="signoutBtn"
              onClick={this.signOut.bind(this)}
              className='btn btn-action'
              />
            </div> : null
          }

          {
            this.state.pageState===2 ?
            <div className='container-w-centered-content btn-container'>
            <img src={caninsoftLogo} width="200" height="" align="center" alt="Caninsoft" />
            <EmptyState message="You do not have any context assgined to you. Please contact your admin." />
            <Button label="Log out" className='btn btn-action' onClick={this.signOut.bind(this)}  />
            </div> : null
          }



       </div>
     );
   }
}

const mapStateToProps = (state) => {
  return{
    error: state.contextReducer.error,
    assgnContextList: state.contextReducer.assgnContextList,
    isLoading:state.contextReducer.isLoading,
    setContextStatus:state.contextReducer.setContextStatus,
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    getAssgnContextList : () => {
      dispatch(loadAssgnContextList());
    },
    setContext : (data) => {
      dispatch(setContext(data));
    }



  }
}


export default connect(mapStateToProps,mapDispatchToProps) (ContextCheck);
