import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import ComponentErrorBoundary from './component-error-boundary';

export default function Tags(props) {
  return (
    <div>
    <ComponentErrorBoundary value="one option autocomplete">
      <Autocomplete
        autoHighlight
        disableClearable={true}
        options={props.fileColumns}
        getOptionLabel={option => option.name+" ("+option.dbConnName+")" }
        value={props.value}
        onChange={(event, newValue) => {props.onSelectValue(newValue);}}
        renderInput={params => {
          return (
            <TextField
              {...params}
              variant="standard"
              label={props.label}
              margin="normal"
              fullWidth
            />
          );
        }}
      />
     </ComponentErrorBoundary>
    </div>
  );
}
