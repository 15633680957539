import React, { Component } from 'react';
import Responsive from 'react-responsive-decorator';
import {Col, Row} from 'react-bootstrap';
import Header from './header';
import EmptyState from '../components/empty-state-container';
import MainMenu from './menu';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { Redirect } from 'react-router';
import { Switch, Route } from "react-router-dom";
import history from '../history';
import {connect} from 'react-redux';
import {
      hideDownloadList
    } from '../pages/recon/recon.actions';

import Auth from '../auth/auth';


const auth = new Auth();
const { getContext,getRoleId } = auth;

//import Notification from './notification';

class AdminLayout extends Component {

  constructor(props) {
    super(props);
    this.state = {isMobile: false};
  }

  componentDidMount() {
      this.props.media({ minWidth: 768 }, () => {
        this.setState({
          isMobile: false
        });
      });

      this.props.media({ maxWidth: 768 }, () => {
        this.setState({
          isMobile: true
        });
      });




    }




  render() {

    //console.log("getContext()",getContext());

      return (
        <div>
          <Header />
          <MainMenu isMobile={this.state.isMobile} />
          <section className="app-main-container">
          {
            getRoleId() !== 1 ? <EmptyState message="You do not have any access to this page." /> : this.props.children
          }


          </section>
        </div>
      );
  }
}


const mapStateToProps = (state) => {
  return{
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
  }
}


export default connect(mapStateToProps,mapDispatchToProps) (Responsive(AdminLayout));
