import { profileActions } from './profile.actions'

export const initialState = {
    isLoading: [],
    error:[]
};

export function profileReducer (state = initialState , action){

  let status;

    switch(action.type){

      //------- Update Password Request-------------
      case profileActions.UPDATE_PASSWORD_REQUEST:
      return{
        ...state,
        isLoading:[...state.isLoading,'updatepassword']
      };

      //------- Update Password Success-------------
      case profileActions.UPDATE_PASSWORD_SUCCESS:
      return{
        ...state,
        isLoading:state.isLoading.splice(...state.isLoading.indexOf('updatepassword'), 1 )
      };

      //------- Update Password Failure-------------
      case profileActions.UPDATE_PASSWORD_FAILURE:
      status="";
      action.payload.response===undefined?status="404":status=action.payload.response.status;
      return{
        ...state,
        error:[...state.error,status]
      };

        default:
            return state;
    }
}
