import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import EmptyState from '../../../components/empty-state-container';
import Preloader from '../../../components/preloader';
import TrendCard from './trend-card';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from '@mui/material/Table';
import {connect} from 'react-redux';
import {
      loadRuleSet
    } from '../../../common-redux/common.actions';


class TrendSummary extends Component {

  constructor(props) {
    super(props);
    this.state = {
        fixedHeader: true,
        fixedFooter: true,
        stripedRows: false,
        showRowHover: false,
        selectable: true,
        multiSelectable: false,
        enableSelectAll: false,
        deselectOnClickaway: true,
        showCheckboxes: false,
    };

    this.props.getRuleSetListItems();
  }

  componentDidMount(){

  }






   handleClick = (item) => {
            var clickedItem=this.props.reconList.length-item-1;
            this.props.onClick(this.props.reconList[clickedItem]);
          }



rulesetTrend=(ruleset)=>{

  this.props.rulesetTrend(ruleset)
}






   render() {

    //let trendSummary=this.props.data.map(ruleset => <Col sm={6} xs={6} key={ruleset.rulesetName} > <TrendCard data={ruleset}  /></Col>);

    let trendSummary= <Col sm={12} xs={12}><TrendCard data={this.props.data} rulesetTrend={this.rulesetTrend.bind(this)} ruleSetList={this.props.ruleSetList}  isLoading={this.props.isLoading}  /></Col>;






     return (

       <div>
       <Row>
       {trendSummary}
      </Row>

       </div>
     );
   }
}

const mapStateToProps = (state) => {
  return{
    isLoading: state.analyticsReducer.isLoading.concat(state.commonReducer.isLoading),
    ruleSetList: state.commonReducer.ruleSetList
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    getRuleSetListItems : () => {
      dispatch(loadRuleSet());
    }
  }
}


export default connect(mapStateToProps,mapDispatchToProps) (TrendSummary);
