import React, { Component } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Banner from '../../components/banner';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import TextField from '@mui/material/TextField';
import ComponentErrorBoundary from '../../components/component-error-boundary';
import Button from '../../components/button';
import {
      updatePassword
    } from './profile.actions';

class Profile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      newPassword:"",
      newPasswordErrorStatus:false,
      newPasswordErrorText:"",
      confirmPassword:"",
      confirmPasswordErrorStatus:false,
      confirmPasswordErrorText:"",
      passwordUpdateBtnStatus:true,
      snackbarStatus: false,
      bannerType: "danger",
      bannerTitle:"",
      bannerMessage:"",
      showBanner: false,
      actionTriggered:false,
      actionName:""
    };
  }


  componentDidUpdate(prevProps) {
  if (prevProps.isLoading !== this.props.isLoading && this.state.actionTriggered === true) {
    if(this.props.isLoading.includes('updatepassword')){
     this.setState({snackbarStatus:true,actionName:"updatePassword"});
    }
    else{
      if(this.state.actionName==="updatePassword"){this.updatePasswordSuccess();}
      this.setState({snackbarStatus:false,actionTriggered:false,actionName:""});
    }
  }
}


updatePasswordSuccess=()=>{
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:"Your password has been updated successfully."});
  this.resetPageFields();
  this.goToTop();
}

  resetPageFields=()=>{
    this.setState({
      newPassword:"",
      confirmPassword:"",
      passwordUpdateBtnStatus:true
    });
  }

  goToTop=()=>{
    document.getElementById("app").scrollTo(0,0);
  }

  checkPasswordStrength=()=>{
    if(this.state.newPassword.length > 0){
      if(this.state.newPassword.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,16}$/))
        {
        this.setState({newPasswordErrorStatus:false,newPasswordErrorText:""},()=>{this.enableUpdateButton();});
        return true;
        }
      else
        {
        this.setState({newPasswordErrorStatus:true,newPasswordErrorText:"Password is not matching the requirements. Please check."},()=>{this.enableUpdateButton();});
        return false;
        }

      }
  }

  checkPasswordMatch=()=>{
    if(this.state.newPassword !== this.state.confirmPassword){
      this.setState({confirmPasswordErrorStatus:true,confirmPasswordErrorText:"Passwords didn't match. Please check."},()=>{this.enableUpdateButton();});
    }
    else{
      this.setState({confirmPasswordErrorStatus:false,confirmPasswordErrorText:""},()=>{this.enableUpdateButton();});
    }
  }

 enableUpdateButton=()=>{
   if(this.state.confirmPasswordErrorStatus===false && this.state.newPasswordErrorStatus===false && this.state.newPassword !== "" && this.state.confirmPassword !== ""){
     this.setState({passwordUpdateBtnStatus:false});
   }else{
     this.setState({passwordUpdateBtnStatus:true});
   }
 }

  dismissBanner = () => {
                this.setState({showBanner:false});
         }

 snackHandler = (state) =>{
   this.setState({snackbarStatus:state});
 }


 updatePassword = () => {
   this.setState({actionTriggered:true});
   let data={
     email: localStorage.getItem('saganUsername'),
     password: this.state.newPassword,
     confirmPassword: this.state.confirmPassword,
    }
   this.props.updatePasswordRequest(data);
 }

 handleTextfield = (event,item) => {
     this.setState({[item]:event.target.value});
 }




   render() {
     const displayName = localStorage.getItem('saganUsername');

     return (
       <div className='app-content-container'>

       <Snackbar
           open={this.state.snackbarStatus}
           message="Please wait..."
           className="snack-bar"
         />


       <Banner
          type={this.state.bannerType}
          hasAlerts={this.state.showBanner}
          header={this.state.bannerTitle}
          body={this.state.bannerMessage}
          dismissBanner={this.dismissBanner.bind(this)}
          />

        <div className="page-content select-container in">
          <h2>Your Profile</h2>

            <Row>
              <Col sm={6} xs={12}>
              <div className='rounded-card'>
              <Row className='label-value-pair'>
                <Col sm={6} xs={12}>
                <label>Name</label>
                <div>{displayName}</div>
                </Col>
              </Row>
                </div>

                <div className='rounded-card'>
                <h4>Update Password</h4>
                <Row className='label-value-pair'>
                  <Col sm={6} xs={12}>
                <ComponentErrorBoundary value="profile create password">
                  <TextField
                      id="outlined-password-input"
                      label="New password"
                      type="password"
                      inputProps={{maxLength: 16}}
                      value={this.state.newPassword}
                      onChange={(e)=>this.handleTextfield(e,"newPassword")}
                      onBlur={this.checkPasswordStrength}
                      error={this.state.newPasswordErrorStatus}
                      helperText={this.state.newPasswordErrorText}
                    />
                  </ComponentErrorBoundary>
                    <div className='password-requirements'>
                    <div className='title'>Password requirements:</div>
                    <ul>
                      <li>8-16 characters long</li>
                      <li>at least one uppercase and one lowercase character</li>
                      <li>at least one number</li>
                    </ul>
                    </div>
                  </Col>
                </Row>
                <Row className='label-value-pair'>
                  <Col sm={6} xs={12}>
                <ComponentErrorBoundary value="profile re-enter password">
                  <TextField
                      id="outlined-confirmpassword-input"
                      label="Re-enter password"
                      type="password"
                      inputProps={{maxLength: 16}}
                      value={this.state.confirmPassword}
                      onChange={(e)=>this.handleTextfield(e,"confirmPassword")}
                      onBlur={this.checkPasswordMatch}
                      error={this.state.confirmPasswordErrorStatus}
                      helperText={this.state.confirmPasswordErrorText}
                    />
                  </ComponentErrorBoundary>
                  </Col>
                </Row>

                <Row className='label-value-pair'>
                  <Col sm={6} xs={12}>
                  <Button
                  label="Update"
                  className='btn btn-action'
                  disabled={this.state.passwordUpdateBtnStatus}
                  onClick={this.updatePassword.bind(this)}
                   />
                  </Col>
                </Row>


                  </div>
              </Col>
            </Row>


        </div>

       </div>
     );
   }
}


const mapStateToProps = (state) => {
  return{
    isLoading:state.profileReducer.isLoading
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    updatePasswordRequest : (data) => {
      dispatch(updatePassword(data));
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps) (Profile);
