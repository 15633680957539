import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import Preloader from '../../../components/preloader';
import OneOptAutocomplete from './oneopt-autocomplete';
// import TouchRipple from '@mui/material/internal/TouchRipple';
import ComponentErrorBoundary from '../../../components/component-error-boundary';
import MomentUtils from '@date-io/moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDateTimePicker,
//   KeyboardDatePicker,
//   DatePicker
// } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';


class QueryParamsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fieldValue:this.props.param.defaultValue,
      ignoreParam:false
    };
  }


componentDidUpdate(prevProps){



}

handleTextfield = (event,item) => {
  this.setState({[item]:event.target.value});
}

updateTextValue=()=>{
  this.props.updateParams(this.props.param.id,"defaultValue",this.state.fieldValue)
}


handleDateChange=(item,date)=>{
  var momentDate= moment(date).format(this.state.dateFormat);
  this.setState({[item]:momentDate});
  this.props.updateParams(this.props.param.id,"defaultValue",momentDate)

  //moment(momentDate,this.state.dateFormat, true).isValid() ? this.props.updateParams(this.props.param.id,"defaultValue",momentDate) : this.updateConfig("state","incomplete")
   }

   dropdownSelector = (name,value) => {
               this.setState({[name]:value});
               this.props.updateParams(this.props.param.id,"defaultValue",value)
             }


  checkboxStatus=(event)=>{

               this.setState({[event.target.name]: event.target.checked });
               this.props.updateParams(this.props.param.id,"ignore",event.target.checked)
             }


   render() {
     let pageConent=""

     if(this.props.param.dataType==="Text"){
       pageConent= <TextField
         label={this.props.param.name+" (Param for"+this.props.param.ref+")"}
         onChange={(e)=>this.handleTextfield(e,'fieldValue')}
         onBlur={this.updateTextValue.bind(this)}
         value={this.state.fieldValue}
         disabled={this.state.ignoreParam}
         variant="standard" 
         fullWidth
       />
     } else if(this.props.param.dataType==="List"){
       pageConent= <OneOptAutocomplete
            label={this.props.param.name+" (Param for"+this.props.param.ref+")"}
            fileColumns={this.props.param.listValue}
            onSelectValue={this.dropdownSelector.bind(this,'fieldValue')}
            value={this.state.fieldValue}
            disabled={this.state.ignoreParam}
             />

     } else if(this.props.param.dataType==="Date"){
  //      pageConent=<MuiPickersUtilsProvider utils={MomentUtils}>
  //      <KeyboardDatePicker
  //        disableToolbar
  //        autoOk={true}
  //        variant="inline"
  //        disabled={this.state.ignoreParam}
  //        format={this.props.param.dateFormat}
  //        id="date-picker-inline"
  //        label={this.props.param.name+" (Param for"+this.props.param.ref+")"}
  //        value={this.state.fieldValue}
  //        onChange={this.handleDateChange.bind(this,'fieldValue')}
  //        KeyboardButtonProps={{
  //          'aria-label': 'change date',
  //        }}
  //      />
  //  </MuiPickersUtilsProvider>
        pageConent=<LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disableToolbar
          autoOk={true}
          variant="inline"
          disabled={this.state.ignoreParam}
          format={this.props.param.dateFormat}
          id="date-picker-inline"
          label={this.props.param.name+" (Param for"+this.props.param.ref+")"}
          value={this.state.fieldValue}
          onChange={this.handleDateChange.bind(this,'fieldValue')}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </LocalizationProvider>
     }

     return (<div>
       {pageConent}
       <div><FormControlLabel
         control={<Checkbox checked={this.state.ignoreParam}
         onChange={(e)=>this.checkboxStatus(e)}
         color="primary"
         name="ignoreParam" />}
         label="Ignore"
       /></div>
       </div>)
 }
}


export default QueryParamsComponent;
