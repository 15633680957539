import axios from 'axios';
import Auth from '../../auth/auth';



export const reconResultsActions ={
  RELOAD_RECONLIST_SUCCESS: 'RELOAD_RECONLIST_SUCCESS',
  GET_RECON_REQUEST : 'GET_RECON_REQUEST',
  GET_RECON_SUCCESS : 'GET_RECON_SUCCESS',
  GET_RECON_FAILURE : 'GET_RECON_FAILURE',
  GET_SELCETED_RECON_SUMMARY_REQUEST:'GET_SELCETED_RECON_SUMMARY_REQUEST',
  GET_SELCETED_RECON_SUMMARY_SUCCESS:'GET_SELCETED_RECON_SUMMARY_SUCCESS',
  GET_SELCETED_RECON_SUMMARY_FAILURE:'GET_SELCETED_RECON_SUMMARY_FAILURE',
  GET_SELCETED_RECON_RESULTS_REQUEST:'GET_SELCETED_RECON_RESULTS_REQUEST',
  GET_SELCETED_RECON_RESULTS_SUCCESS:'GET_SELCETED_RECON_RESULTS_SUCCESS',
  GET_SELCETED_RECON_RESULTS_FAILURE:'GET_SELCETED_RECON_RESULTS_FAILURE',
  GET_SELCETED_RECON_EXCEPTIONS_REQUEST:'GET_SELCETED_RECON_EXCEPTIONS_REQUEST',
  GET_SELCETED_RECON_EXCEPTIONS_SUCCESS:'GET_SELCETED_RECON_EXCEPTIONS_SUCCESS',
  GET_SELCETED_RECON_EXCEPTIONS_FAILURE:'GET_SELCETED_RECON_EXCEPTIONS_FAILURE',
  RECON_PROCESS_REQUEST : 'RECON_PROCESS_REQUEST',
  RECON_PROCESS_SUCCESS : 'RECON_PROCESS_SUCCESS',
  RECON_PROCESS_FAILURE : 'RECON_PROCESS_FAILURE',
  UPDATE_RECONRUN_DETAILS : 'UPDATE_RECONRUN_DETAILS',
  DOWNLOAD_XLS_REQUEST : 'DOWNLOAD_XLS_REQUEST',
  DOWNLOAD_XLS_SUCCESS : 'DOWNLOAD_XLS_SUCCESS',
  DOWNLOAD_XLS_FAILURE : 'DOWNLOAD_XLS_FAILURE',
  HIDE_DOWNLOAD_LIST : 'HIDE_DOWNLOAD_LIST'
}

const auth = new Auth();
const { getAccessToken } = auth;


//--------- Load Recon-----------

export function loadRecon(filename){
  return function(dispatch){
    dispatch({ type: reconResultsActions.GET_RECON_REQUEST });

    axios({
      method:'get',
      url:process.env.REACT_APP_API_URL+'/comparizone/getExecutedFile?filename='+filename,
      timeout: 60*4*1000,
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-access-token':getAccessToken()
        }
    }).then (response => response.data)
        .then ((json) => {  dispatch({ type: reconResultsActions.GET_RECON_SUCCESS, payload: json } )  } )
        .catch((error) => dispatch({ type: reconResultsActions.GET_RECON_FAILURE, payload: error, error: true}));
   }
}


















//--------- Reload ReconList group-----------

export function reloadReconList(json){
  return function(dispatch){
    dispatch({ type: reconResultsActions.RELOAD_RECONLIST_SUCCESS, payload: json })
  }
}

//--------- Load Recon-----------

export function downloadXlsFile(fileName,filterValue,reconName,startRec,endRec,part){

  var filterLabel;
  if(filterValue==='pass'){filterLabel="No Difference"}
  else if(filterValue==='fail'){filterLabel="Difference"}
  else{filterLabel=filterValue}

  return function(dispatch){
    dispatch({ type: reconResultsActions.DOWNLOAD_XLS_REQUEST, downloadFileName:reconName+'-'+filterLabel+part });
      axios.get(process.env.REACT_APP_API_URL+'/comparizone/downloadxls?filename='+fileName+"&status="+filterValue+"&reconname="+reconName+"&startRec="+startRec+"&endRec="+endRec,{
        responseType: 'blob',
        // onDownloadProgress: (progressEvent) => {
        //    let percentCompleted = Math.round(progressEvent.loaded * 100 /
        //    progressEvent.total);
        //    console.log(progressEvent.lengthComputable);
        //    console.log(percentCompleted);
        //
        // },
        headers: {
          'x-access-token':getAccessToken()
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', reconName+'-'+filterLabel+part+'.xlsx');
        document.body.appendChild(link);
        link.click();
        dispatch({ type: reconResultsActions.DOWNLOAD_XLS_SUCCESS, downloadFileName:reconName+'-'+filterLabel+part});
      })
      .catch((error) => dispatch({ type: reconResultsActions.DOWNLOAD_XLS_FAILURE, payload: error, error: true }));
   }
}


//--------- Hide download list-----------

export function hideDownloadList(){
  return function(dispatch){
    dispatch({ type: reconResultsActions.HIDE_DOWNLOAD_LIST })
  }
}




//--------- Start reconcile -----------

export function startReconiliation_current(data){

  return function(dispatch){
    dispatch({ type: reconResultsActions.RECON_PROCESS_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/comparizone/startcompare',data,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
    //.then (response => response.json())
    .then ((json) => dispatch({ type: reconResultsActions.RECON_PROCESS_SUCCESS }))
    .catch((error) => dispatch({ type: reconResultsActions.RECON_PROCESS_FAILURE, payload: error, error: true }));
   }
}



export function startReconiliation(data){

  return function(dispatch){
    dispatch({ type: reconResultsActions.RECON_PROCESS_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/comparizone/startcompareDb',data,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
    //.then (response => response.json())
    .then ((json) => dispatch({ type: reconResultsActions.RECON_PROCESS_SUCCESS }))
    .catch((error) => dispatch({ type: reconResultsActions.RECON_PROCESS_FAILURE, payload: error, error: true }));
   }
}
