import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Button from '../../../components/button';
import ComponentErrorBoundary from '../../../components/component-error-boundary';

import {connect} from 'react-redux';


class EditUser extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userId:this.props.userData.user.userID,
      userName:this.props.userData.user.name,
      userEmail:this.props.userData.user.email,
      userPassword:"",
      status:this.props.userData.user.active,
      nameErrorStatus:false,
      disableNameEdit:true,
      nameErrorText:"",
      emailErrorStatus:false,
      emailErrorText:"",
      disableEmailEdit:true,
      editPassword:true,
      newPassword:"",
      newPasswordErrorStatus:false,
      showNewPassword:false,
      newPasswordErrorText:"",
      confirmPassword:"",
      confirmPasswordErrorStatus:false,
      confirmPasswordErrorText:"",
      showConfirmPassword:false,
      saveButtonStatus:true,
      formErrorState:[],
      changeTracker:[]
    };
  }


  componentDidUpdate(prevProps) {
  if (this.props.userData !== prevProps.userData) {
    this.setState({
      userId:this.props.userData.user.userID,
      userName:this.props.userData.user.name,
      userEmail:this.props.userData.user.email,
      userPassword:"",
      status:this.props.userData.user.active,
      disableNameEdit:true,
      disableEmailEdit:true,
      editPassword:true,
      newPassword:"",
      newPasswordErrorStatus:false,
      newPasswordErrorText:"",
      confirmPassword:"",
      confirmPasswordErrorStatus:false,
      confirmPasswordErrorText:"",
      saveButtonStatus:true,
      formErrorState:[],
      changeTracker:[]
    })
  }
 }

 cancelFlow=()=>{
    this.props.cancelFlow();
 }

 saveFlow=()=>{

   let userObj={userId:this.state.userId,orgId: Number(this.props.orgId)},key,value;

   for(var i=0;i<this.state.changeTracker.length;i++){
     key=this.state.changeTracker[i];
     value=this.state[this.state.changeTracker[i]]
    userObj={...userObj,...{[key]:value}}
   }

   this.props.saveFlow(userObj,'update',this.state.userName);
 }

  handleTextfield = (event,item) => {
      this.setState({[item]:event.target.value});
  }


  formErrorRemoveValue=(attr)=>{
    if (this.state.formErrorState.find(o => o === attr)){
      this.setState({formErrorState:this.state.formErrorState.filter(val=> val !== attr)});
    }
  }

  //---- change tracker ------

  formChangeTrackerInsertValue=(attr)=>{
    if (!this.state.changeTracker.find(o => o === attr)){
      this.setState({changeTracker:[...this.state.changeTracker,attr]});
    }
  }


  formChangeTrackerRemoveValue=(attr)=>{
    if (this.state.changeTracker.find(o => o === attr)){
      this.setState({changeTracker:this.state.changeTracker.filter(val=> val !== attr)});
    }
  }

  formErrorInsertValue=(attr)=>{
    if (!this.state.formErrorState.find(o => o === attr)){
      this.setState({formErrorState:[...this.state.formErrorState,attr]});
    }
  }


  validateName=()=>{

    if(this.state.userName.length>2)
    {

      this.formErrorRemoveValue('name');
      this.setState({nameErrorStatus:false,nameErrorText:""},()=>{this.enableSaveButton();});
      return true;
    }
    else{

      this.formErrorInsertValue('name');
      this.setState({nameErrorStatus:true,nameErrorText:"Please enter a valid name."},()=>{this.enableSaveButton();});
      return false;
    }

  }

  validateEmail=()=>{

    if(this.state.userEmail.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
    {

      this.formErrorRemoveValue('email');
      this.setState({emailErrorStatus:false,emailErrorText:""},()=>{this.enableSaveButton();});
      return true;
    }
    else{
      this.formErrorInsertValue('email');
      this.setState({emailErrorStatus:true,emailErrorText:"Please enter a valid email address."});
      return false;
    }
  }

  checkPasswordStrength=()=>{
    if(this.state.newPassword.length > 0){
      if(this.state.newPassword.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,16}$/))
        {
        this.formErrorRemoveValue('newPassword');
        this.setState({newPasswordErrorStatus:false,newPasswordErrorText:""},()=>{this.enableSaveButton();});
        return true;
        }
      else
        {
        this.formErrorInsertValue('newPassword');
        this.setState({newPasswordErrorStatus:true,newPasswordErrorText:"Password is not matching the requirements. Please check."},()=>{this.enableSaveButton();});
        return false;
        }

      }
  }

  checkPasswordMatch=()=>{
    if(this.state.newPassword !== this.state.confirmPassword){
      this.formErrorInsertValue('confirmPassword');
      this.setState({confirmPasswordErrorStatus:true,confirmPasswordErrorText:"Passwords didn't match. Please check."},()=>{this.enableSaveButton();});
    }
    else{
      this.formErrorRemoveValue('confirmPassword');
      this.setState({confirmPasswordErrorStatus:false,confirmPasswordErrorText:""},()=>{this.enableSaveButton();});
    }
  }

  enableSaveButton=()=>{
            if(this.state.formErrorState.length===0)
              {
              this.setState({saveButtonStatus:false});
            } else {
              this.setState({saveButtonStatus:true});
            }
  }

handleClickEdit = (field,value) => {
    this.setState({[field]: !this.state[field] });
    !this.state[field]?this.formChangeTrackerRemoveValue(value):this.formChangeTrackerInsertValue(value);
    this.setState({saveButtonStatus:false});

    if(value==="newPassword"){
          if(!this.state[field]){
            this.formErrorRemoveValue('newPassword');
            this.formErrorRemoveValue('confirmPassword');
          } else{
              this.formErrorInsertValue('newPassword');
              this.formErrorInsertValue('confirmPassword');
              this.setState({saveButtonStatus:true});
          }
    }
  };


handleClickShowPassword = (field) => {
    this.setState({[field]: !this.state[field] });
  };

handleSwitch = (field,label) => {

      var numberOfActiveUsers= this.props.userList.filter(x => x.active === 1);

      if(numberOfActiveUsers.length>=5 && this.state[field]===0 ){
        this.props.userLicenseLimit();
      }

      else{
        this.formChangeTrackerInsertValue("status");
        this.setState({[field]: this.state[field]===1?0:1 });
        this.enableSaveButton();
      }
    };


 handleMouseDownPassword = (event) => {
    event.preventDefault();
  };






   render() {

     return (
       <div>




       <Row className='label-value-pair'>
         <Col sm={4} xs={12}>
         <ComponentErrorBoundary value="admin edit user name">
         <FormControl fullWidth>
          <InputLabel htmlFor="user-name">Name</InputLabel>
          <Input
            type="text"
            value={this.state.disableNameEdit ? this.props.userData.user.name : this.state.userName}
            onChange={(e)=>this.handleTextfield(e,"userName")}
            onBlur={this.validateName}
            error={this.state.nameErrorStatus}
            disabled={this.state.disableNameEdit}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="edit name"
                  onClick={()=>this.handleClickEdit("disableNameEdit","userName")}
                  size="large">
                  {this.state.disableNameEdit ? <Tooltip title="Edit" placement="right"><i className="material-icons">edit</i></Tooltip>:<Tooltip title="Undo" placement="right"><i className="material-icons">undo</i></Tooltip>}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText className="Mui-error">{this.state.nameErrorText}</FormHelperText>
         </FormControl>
         </ComponentErrorBoundary>
         </Col>


         </Row>

         <Row>
         <Col sm={4} xs={12}>
         <ComponentErrorBoundary value="admin edit user status switch">
           <FormControlLabel
            control={
              <Switch
                checked={this.state.status===1?true:false}
                onChange={()=>this.handleSwitch('status')}
                name="status"
                color="primary"
              />
            }
            label={this.state.status==1?'Status: Active':'Status: Inactive'}
            labelPlacement="end"
          />
          </ComponentErrorBoundary>
         </Col>
         </Row>






       <Row className='label-value-pair'>
         <Col sm={4} xs={12}>
         <ComponentErrorBoundary value="admin edit user email">
         <FormControl fullWidth>
          <InputLabel htmlFor="email">Email address</InputLabel>
          <Input
            type="email"
            value={this.state.disableEmailEdit ? this.props.userData.user.email : this.state.userEmail}
            onChange={(e)=>this.handleTextfield(e,"userEmail")}
            onBlur={this.validateEmail}
            error={this.state.emailErrorStatus}
            disabled={this.state.disableEmailEdit}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="edit name"
                  onClick={()=>this.handleClickEdit("disableEmailEdit","userEmail")}
                  onMouseDown={(e)=>this.handleMouseDownPassword(e)}
                  size="large">
                  {this.state.disableEmailEdit ? <Tooltip title="Edit" placement="right"><i className="material-icons">edit</i></Tooltip>:<Tooltip title="Undo" placement="right"><i className="material-icons">undo</i></Tooltip>}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText className="Mui-error">{this.state.emailErrorText}</FormHelperText>
         </FormControl>
         </ComponentErrorBoundary>
         </Col>
       </Row>

       <Row className={this.state.editPassword?'show label-value-pair':'hide'}>
         <Col sm={4} xs={12}>
         <ComponentErrorBoundary value="admin edit user dummy password">
         <FormControl fullWidth>
          <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
          <Input
            type='password'
            value='placeholder'
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>this.handleClickEdit("editPassword","newPassword")}
                  size="large">
                  <Tooltip title="Edit" placement="right"><i className="material-icons">edit</i></Tooltip>
                </IconButton>
              </InputAdornment>
            }
          />
         </FormControl>
         </ComponentErrorBoundary>
         </Col>
       </Row>



       <Row className={this.state.editPassword?'hide':'show label-value-pair'}>
         <Col sm={4} xs={12}>
         <ComponentErrorBoundary value="admin edit user enter password">
         <FormControl fullWidth>
          <InputLabel htmlFor="standard-adornment-password">Enter password</InputLabel>
          <Input
            type={this.state.showNewPassword ? 'text' : 'password'}
            value={this.state.newPassword}
            inputProps={{maxLength: 16}}
            onChange={(e)=>this.handleTextfield(e,"newPassword")}
            onBlur={this.checkPasswordStrength}
            error={this.state.newPasswordErrorStatus}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>this.handleClickShowPassword('showNewPassword')}
                  onMouseDown={(e)=>this.handleMouseDownPassword(e)}
                  size="large">
                  {this.state.showNewPassword ? <Tooltip title="Hide" placement="right"><i className="material-icons">visibility</i></Tooltip> : <Tooltip title="Show" placement="right"><i className="material-icons">visibility_off</i></Tooltip>}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText className="Mui-error">{this.state.newPasswordErrorText}</FormHelperText>
         </FormControl>
         </ComponentErrorBoundary>
           <div className='password-requirements'>
           <div className='title'>Password requirements:</div>
           <ul>
             <li>8-16 characters long</li>
             <li>at least one uppercase and one lowercase character</li>
             <li>at least one number</li>
           </ul>
           </div>
         </Col>
         <Col sm={4} xs={12}>
           <IconButton
             aria-label="edit password"
             className="margin-top-10"
             onClick={()=>this.handleClickEdit("editPassword","newPassword")}
             size="large">
             <Tooltip title="Undo" placement="right"><i className="material-icons">undo</i></Tooltip>
           </IconButton>
         </Col>
       </Row>
       <Row  className={this.state.editPassword?'hide':'label-value-pair'}>
         <Col sm={4} xs={12}>
         <ComponentErrorBoundary value="admin edit user re-enter password">
         <FormControl fullWidth>
          <InputLabel htmlFor="standard-adornment-password">Re-enter password</InputLabel>
          <Input
            type={this.state.showConfirmPassword ? 'text' : 'password'}
            value={this.state.confirmPassword}
            inputProps={{maxLength: 16}}
            onChange={(e)=>this.handleTextfield(e,"confirmPassword")}
            onBlur={this.checkPasswordMatch}
            error={this.state.confirmPasswordErrorStatus}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>this.handleClickShowPassword('showConfirmPassword')}
                  onMouseDown={(e)=>this.handleMouseDownPassword(e)}
                  size="large">
                  {this.state.showConfirmPassword ? <Tooltip title="Hide" placement="right"><i className="material-icons">visibility</i></Tooltip> : <Tooltip title="Show" placement="right"><i className="material-icons">visibility_off</i></Tooltip>}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText className="Mui-error">{this.state.confirmPasswordErrorText}</FormHelperText>
         </FormControl>
         </ComponentErrorBoundary>
         </Col>

       </Row>

       <Row>
       <Col sm={4} xs={12} className='text-right'>
        <Button label="Cancel" className='btn btn-default' onClick={this.cancelFlow.bind(this)} />

        <Button label="Save" className='btn btn-action' disabled={this.state.saveButtonStatus} onClick={this.saveFlow.bind(this)}  />

       </Col>

       </Row>


         </div>
     );
   }
}


const mapStateToProps = (state) => {
  return{
    isLoading: state.usersReducer.isLoading,
    errors: state.usersReducer.errorMessage
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {

  }
}


export default connect(mapStateToProps,mapDispatchToProps) (EditUser);
