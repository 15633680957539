import axios from 'axios';
import Auth from '../../auth/auth';
export const loginActions ={
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE'
  }


  const auth = new Auth();
  const { setSession } = auth;


//--------- Login -----------

// export function loginRequest(credentials){
//   return function(dispatch){
//     dispatch({ type: loginActions.LOGIN_REQUEST });
//       return fetch('http://localhost:'+process.env.REACT_APP_API_URL+'/auth/login', {
//       method: "POST",
//       body: JSON.stringify(credentials),
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json'
//     },
//     })
//     // console.log('path', process.env.REACT_APP_API_URL);
//     // return axios({
//     //   method:'post',
//     //   url:'http://localhost:'+process.env.REACT_APP_API_URL+'/auth/login',
//     //   data : credentials,
//     //   timeout: 60*4*1000,
//     //   headers: {
//     //       'Accept': 'application/json',
//     //       'Content-Type': 'application/json'
//     //     }
//     // })
//     .then(response =>
//        response.json().then(user => ({ user, response }))
//            ).then(({ user, response }) =>  {
//        if (!response.ok) {
//          dispatch({ type: loginActions.LOGIN_FAILURE});
//        } else {
//          //console.log("user",user);
//          setSession(user);
//          dispatch({ type: loginActions.LOGIN_SUCCESS});
//        }
//      }).catch(err => console.log("Error: ", err))
//    }
// }

export function loginRequest(credentials){
  return function(dispatch){
    dispatch({ type: loginActions.LOGIN_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/auth/login',JSON.stringify(credentials),{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
         setSession(response.data);
         dispatch({ type: loginActions.LOGIN_SUCCESS});
     }).catch(err => {
       console.log("Error: ", err);
       dispatch({ type: loginActions.LOGIN_FAILURE});
     })
   }
}
