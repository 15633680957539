import React, { Component } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Col, Row} from 'react-bootstrap';
import Plot from 'react-plotly.js';
import ComponentErrorBoundary from '../../../components/component-error-boundary';
import OneOptAutocomplete from '../../recon/components/oneopt-autocomplete';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Preloader from '../../../components/preloader';


class TrendCard extends Component {


  constructor(props) {
    super(props);
    this.state = {
      listView:false,
      chartView:true,
      ruleSetListValue:null,
      currentView:"chart"
    };
  }


componentDidMount()  {
}


componentDidUpdate(prevProps) {
  if(prevProps.ruleSetList !== this.props.ruleSetList && this.props.ruleSetList.length !== 0){
    this.setState({
    ruleSetListValue:this.props.ruleSetList[0]
  })

  this.props.rulesetTrend(this.props.ruleSetList[0]);
  }
}


handleClick=() => {

  this.setState({
    listView: !this.state.listView,
    chartView: !this.state.chartView
  })

  }


  dropdownSelector = (name,value) => {
              this.setState({[name]:value});
              this.props.rulesetTrend(value);
          }

  toggleClick=(event,value)=>{
    this.setState({
      currentView:value,
      listView: !this.state.listView,
      chartView: !this.state.chartView
    });
  }


  render() {

    let lisViewContent,chartRulesTrend,chartRecordTrend,chartDvpTrend;


    let chartDateData=[];
    let passedRulesData=[];
    let failedRulesData=[];

    let passedRecordsData=[];
    let failedRecordsData=[];
    let exceptionsRecordsData=[];

    let passedDvpData=[];
    let failedDvpData=[];

    if(this.props.isLoading.includes('rulesettrend')){
      lisViewContent =  <Preloader loading={true} /> ;
      chartRulesTrend =  <Preloader loading={true} /> ;
      chartRecordTrend =  <Preloader loading={true} /> ;
      chartDvpTrend =  <Preloader loading={true} /> ;
    }

    else{
        if(this.props.data.length!==0){

    lisViewContent=<Table className='data-table'>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Passed rules</TableCell>
          <TableCell>Failed rules</TableCell>
          <TableCell>Passed records</TableCell>
          <TableCell>Failed records</TableCell>
          <TableCell>Exception records</TableCell>
          <TableCell>Passed DVP</TableCell>
          <TableCell>Failed DVP</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          this.props.data.map((runs,index) => <TableRow key={index}>
           <TableCell> {runs.exeDate} </TableCell>
           <TableCell> {runs.passedRules.toLocaleString()} </TableCell>
           <TableCell> {runs.failedRules.toLocaleString()} </TableCell>
           <TableCell> {runs.passedRecords.toLocaleString()} </TableCell>
           <TableCell> {runs.failedRecords.toLocaleString()} </TableCell>
           <TableCell> {runs.exceptionRecords.toLocaleString()} </TableCell>
           <TableCell> {runs.passedDvp.toLocaleString()} </TableCell>
           <TableCell> {runs.failedDvp.toLocaleString()} </TableCell>
           </TableRow>)
        }
      </TableBody>
     </Table>


     //--- chart view content --

     this.props.data.forEach((runs) => {
         chartDateData.push(runs.exeDate);
         passedRulesData.push(runs.passedRules);
         failedRulesData.push(runs.failedRules);
         passedRecordsData.push(runs.passedRecords);
         failedRecordsData.push(runs.failedRecords);
         exceptionsRecordsData.push(runs.exceptionRecords);
         passedDvpData.push(runs.passedDvp);
         failedDvpData.push(runs.failedDvp);
       })

     let rulesTrendData = [
       {
         x:chartDateData,
         y:passedRulesData,
         name: 'Passed Rules'
       },
       {
         x:chartDateData,
         y:failedRulesData,
         name: 'Failed Rules'
       }
     ];


     let recordsTrendData = [
       {
         x:chartDateData,
         y:passedRecordsData,
         name: 'Passed Records',
         type: 'bar',
         marker: {
           color: '#43a047'
         }
       },
       {
         x:chartDateData,
         y:failedRecordsData,
         name: 'Failed Records',
         type: 'bar',
         marker: {
           color: '#d32f2f'
         }
       },
       {
         x:chartDateData,
         y:exceptionsRecordsData,
         name: 'Exception Records',
         type: 'bar',
         marker: {
           color: '#9e9e9e'
         }
       }
     ];



     let dvpTrendData = [
       {
         x:chartDateData,
         y:passedDvpData,
         name: 'Passed DVP',
         type: 'bar',
         marker: {
           color: '#4caf50'
         }
       },
       {
         x:chartDateData,
         y:failedDvpData,
         name: 'Failed DVP',
         type: 'bar',
         marker: {
           color: '#f44336'
         }
       }
     ];



     chartRulesTrend=<Plot
       data={rulesTrendData}
       layout={{width:600, height:400, title: 'Rules Trend'}}
       />


      chartRecordTrend=<Plot
         data={recordsTrendData}
         layout={{width:600, height:400,title: 'Records Trend', barmode: 'stack'}}
         />


      chartDvpTrend=<Plot
           data={dvpTrendData}
           layout={{width:1000, title: 'Data Valition Points Trend'}}
           />

 }
}







  return (
    <div>
    <ComponentErrorBoundary value="trend card">
     <div className='rounded-card recon-results'>

     <Row>
        <Col sm={8} xs={8}><h4>Your ruleset trend</h4></Col>
        <Col sm={4} xs={4} className="text-right">
        <ToggleButtonGroup
            color="primary"
            value={this.state.currentView}
            exclusive
            onChange={this.toggleClick.bind(this)}
          >
            <ToggleButton value="chart">Chart view</ToggleButton>
            <ToggleButton value="list">List view</ToggleButton>
          </ToggleButtonGroup>
      </Col>
     </Row>

       <Row className='recon-input'>
        <Col sm={3} xs={12}>
         <OneOptAutocomplete
              label='Select ruleset'
              fileColumns={this.props.ruleSetList}
              onSelectValue={this.dropdownSelector.bind(this,'ruleSetListValue')}
              value={this.state.ruleSetListValue} />
       </Col>
       </Row>


        <div className={this.state.listView?"show":"hide"}>
            {lisViewContent}
        </div>

        <div className={this.state.chartView?"show":"hide"}>

        <Row>
          <Col sm={6} xs={12}>
            {chartRulesTrend}
          </Col>
          <Col sm={6} xs={12}>
            {chartRecordTrend}
          </Col>
        </Row>

        <Row>
          <Col sm={12} xs={12}>
            {chartDvpTrend}
          </Col>
        </Row>

        </div>
      </div>
     </ComponentErrorBoundary>
    </div>
  );
 }
}

export default TrendCard;
