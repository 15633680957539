import React, {Component} from 'react';

class ErrorMessageReload extends Component {

  handleClick = () => {
   window.location.reload(true)
 };

  render() {
    return (
      <div className='empty-state-container'>
          <i className="material-icons">cancel</i>
          <div>{this.props.message} <a onClick={this.handleClick}>Reload the page</a></div>
      </div>
    );
  }
}


export default ErrorMessageReload;
