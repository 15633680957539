import React, { Component } from 'react';
import MenuItem from '@mui/material/MenuItem';
import {Col, Row} from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import EmptyState from '../../../components/empty-state-container';
import Preloader from '../../../components/preloader';
import Button from '../../../components/button';
import OneOptAutocomplete from './oneopt-autocomplete';
import QueryParams from './query-params';
import XLSX from 'xlsx';
import ComponentErrorBoundary from '../../../components/component-error-boundary';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ReconFilters from './recon-filters';
import SelectQuery from '../../../components/query-select';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {connect} from 'react-redux';
import {
  startReconiliation,
  checkParams
} from '../recon.actions';
import {loadQueryList} from '../../../common-redux/common.actions';

let reconDisplayList,reconDisplayListLength;
let paramsForSource1="",paramsForSource2="";

class ReconciliationExecute extends Component {

  constructor(props) {
    super(props);
    this.state = {
        fixedHeader: true,
        fixedFooter: true,
        stripedRows: false,
        showRowHover: false,
        selectable: true,
        multiSelectable: false,
        enableSelectAll: false,
        deselectOnClickaway: true,
        showCheckboxes: false,
      firstdropdown: <MenuItem value={0} label="None" primaryText="None" />,
      seconddropdown:<MenuItem value={0} label="None" primaryText="None" />,
      ruleSetListName:"",
      file1Columns:[],
      file2Columns:[],
      file1HelpTxt:"",
      file2HelpTxt:"",
      errorForFile1:"",
      errorForFile2:"",
      selectBtnState: true,
      step1:"",
      step2:"hide",
      compareBtnStatus:true,
      ruleSetListValue:null,
      uploadFile1:null,
      uploadFile2:null,
      tableItemCount:10,
      runExecuteFilterCriteria:"",
      sourceSelect1:"",
      sourceSelect2:"",
      source1Query:null,
      source2Query:null,
      source1DataState:false,
      source2DataState:false,
      displaySourceFile1:"hide",
      displaySourceFile2:"hide",
      displaySourceQuery1:"hide",
      displaySourceQuery2:"hide",
      availableDB:["TradeDB","VendorDB"],
      availableQuery:["Trade Data","Vendor Data"],
      filterContainer:false,
      reconHistoryList:[],
      filterList:[],
      checkingParamFor:"",
      source1QueryParams:[],
      source2QueryParams:[]
    };

    this.props.getQueryList(1);
  }


  componentDidUpdate(prevProps) {

    if (this.props.reconList !== prevProps.reconList) {
      this.setState({reconHistoryList:this.props.reconList});
  }

  if (this.props.queryParam !== prevProps.queryParam) {
    if(this.props.queryParam.parameter !== undefined){
      if(this.props.queryParam.parameter.length > 0){
        this.setState({[this.state.checkingParamFor+'Params']:this.props.queryParam.parameter});
      }
     else{
      this.setState({[this.state.checkingParamFor+'Params']:[]});
     }
   }
}


}



updateParamValues=(item,val)=>{
this.setState({[item]:val});
}


  loadMore = () => {
    let value=this.state.tableItemCount+10;
    this.setState({tableItemCount:value});
  }

  filterReconList=(value,filterList)=>{
      this.setState({
        reconHistoryList:value,
        filterList:filterList
      });
  }



  selectRuleSetVal = (selecteditem) =>{
    this.setState({ruleSetListValue:selecteditem});
    for(var i=0; i<this.props.ruleSetList.length;i++){
      if(this.props.ruleSetList[i].id===selecteditem.value){
        this.setState({ruleSetListName:this.props.ruleSetList[i].name,
          // disabled file validation
                      // file1Columns:this.props.ruleSetList[i].file1Columns,
                      // file2Columns:this.props.ruleSetList[i].file2Columns,
                       selectBtnState: false,
                       compareBtnStatus: true,
                       uploadFile1:null,
                       uploadFile2:null
                     });
        document.getElementById("input1").value="";
        document.getElementById("input2").value="";
      }
    }
  }


  dropdownSelector = (name,value) => {
              this.setState({[name]:value});

              if(name==="ruleSetListValue"){
                for(var i=0; i<this.props.ruleSetList.length;i++){
                  if(this.props.ruleSetList[i].id===value.id){
                    // let file1HelpTxt=this.props.ruleSetList[i].file1Columns.join(", ");
                    // let file2HelpTxt=this.props.ruleSetList[i].file2Columns.join(", ");
                    this.setState({ruleSetListName:this.props.ruleSetList[i].name,
                      // disabbled file validation
                            //       file1Columns:this.props.ruleSetList[i].file1Columns,
                            //       file2Columns:this.props.ruleSetList[i].file2Columns,
                                   //file1HelpTxt:file1HelpTxt,
                                   //file2HelpTxt:file2HelpTxt,
                                   helpText:"recon-help-text",
                                   selectBtnState: false,
                                   compareBtnStatus: false,
                                   uploadFile1:null,
                                   uploadFile2:null,
                                   sourceSelect1:"",
                                   sourceSelect2:"",
                                   source1Query:null,
                                   source2Query:null,
                                   source1QueryParams:[],
                                   source2QueryParams:[],
                                   checkingParamFor:"",
                                   displaySourceFile1:"hide",
                                   displaySourceFile2:"hide",
                                   displaySourceQuery1:"hide",
                                   displaySourceQuery2:"hide"
                                 });
                    paramsForSource1="";
                    paramsForSource2="";
                    document.getElementById("input1").value="";
                    document.getElementById("input2").value="";
                  }
                }
              }

              else if(name === "source1Query"){
                this.setState({source1DataState:true,uploadFile1:null},() => { this.checkIfDataReady();});
                this.checkParamForQuery(name,value.queryId);
              }
              else if(name === "source2Query"){
                this.setState({source2DataState:true,uploadFile2:null},() => { this.checkIfDataReady();});
                this.checkParamForQuery(name,value.queryId);
              }


              //this.enableBtn(value,this.state.uploadFile1,this.state.uploadFile2);
          }

   handleClick = (file) => {
            this.props.onClick(file);
          }

   checkParamForQuery=(name,queryId)=>{
     this.setState({checkingParamFor:name});
     this.props.checkParams(queryId);
   }


  handleChange = (id) => {
    const input = document.getElementById("input"+id);
    let fileCol = (id==="1" ? this.state.file1Columns : this.state.file2Columns);

    if(input.files[0] !==undefined){

      this.parseSelectedFile(input, fileCol,id,this.validateSelectedFile);

      // if(input.files[0].size<6000000){
      //   this.parseSelectedFile(input, fileCol,id,this.validateSelectedFile);
      //   this.setState({["errorForFile"+id]:""});
      // }else {
      //   this.setState({["errorForFile"+id]:"The max file size allowed is 2MB."});
      // }


      }

    else {
      //  do nothing
    }
  }


 toggleFilterContainer=()=>{
   this.setState({filterContainer: !this.state.filterContainer});
 }


  parseSelectedFile=(input,fileCol,id,callback) =>
     {
       var reader = new FileReader();
       reader.onload = function(e) {
        var data = e.target.result;
        let headerColumn=[]

        let uploadedFileTye=input.files[0].name.split('.').pop().toLowerCase();

        if(uploadedFileTye==="xls" || uploadedFileTye==="xlsx" || uploadedFileTye==="csv"  ){

        var workbook = XLSX.read(data, {type: 'binary',sheetRows: 1});
        const wsname = workbook.SheetNames[0];
        const ws = workbook.Sheets[wsname];
        const sheetdata = XLSX.utils.sheet_to_json(ws, {header:1});

        headerColumn=sheetdata[0];
      }
      else if(uploadedFileTye==="txt"){
        //----- txt files-----
        data.split(/\n|\r/,1).map(function(line){
                  headerColumn=line.split(/\||\t/);
          })
      }
      else{
        headerColumn=[]
      }

        let fileValidationStatus = fileCol.every(item => headerColumn.indexOf(item) !== -1 );




        let missingFields = fileCol.filter(entry1 => !headerColumn.some(entry2 => entry1 === entry2));




        callback(id,fileValidationStatus,missingFields);
        }
        reader.readAsBinaryString(input.files[0]);
     }


 validateSelectedFile = (id,status,missingFields) =>{
   console.log("File validation disabled");
   const input = document.getElementById("input"+id);
   this.setState({["uploadFile"+id]:input.files[0],["source"+id+"DataState"]:true});

 //   this.setState({["source"+id+"Query"]:""});
 //
 //   if(status===false){
 //     this.setState({["errorForFile"+id]:"You have selected a incorrect file. Please check",compareBtnStatus:true,["source"+id+"DataState"]:false});
 //     this.setState({["file"+id+"HelpTxt"]:missingFields.join("\r\n")});
 // }
 //
 //   else{
 //     this.setState({["errorForFile"+id]:"",["file"+id+"HelpTxt"]:""});
 //     const input = document.getElementById("input"+id);
 //     this.setState({["uploadFile"+id]:input.files[0],["source"+id+"DataState"]:true},() => { this.checkIfDataReady();});
 //
 //   }
 }

 checkIfDataReady=()=>{
   if(this.state.source1DataState === true && this.state.source2DataState === true )
       {
         this.setState({compareBtnStatus:false});
       }
   else{
         this.setState({compareBtnStatus:true});
       }
 }


  startReconcile = () => {
                   const data = new FormData();
                   data.append('ruleSetValue', this.state.ruleSetListValue.id);
                   data.append('ruleSetName', this.state.ruleSetListName);
                   data.append('file', this.state.uploadFile1);
                   data.append('file', this.state.uploadFile2);
                   data.append('firstSourceType', this.state.sourceSelect1);
                   data.append('secondSourceType', this.state.sourceSelect2);

                   this.state.source1Query===null ? (data.append('queryId',"")) : (data.append('queryId', this.state.source1Query.queryId),data.append('queryParam', JSON.stringify(this.state.source1QueryParams)))

                   this.state.source2Query===null ? (data.append('queryId',"")) : (data.append('queryId', this.state.source2Query.queryId),data.append('queryParam', JSON.stringify(this.state.source2QueryParams)))

                   //for (var value of data.values()) {console.log(value);}
                   this.props.startRecon(data);
                   this.setState({
                     ruleSetListValue:"",
                     uploadFile1:null,
                     uploadFile2:null,
                     sourceSelect1:"",
                     sourceSelect2:"",
                     source1Query:null,
                     source2Query:null,
                     helpText:"hide",
                     source1QueryParams:[],
                     source2QueryParams:[],
                     selectBtnState:true,
                     compareBtnStatus:true
                   });
                   document.getElementById("input1").value="";
                   document.getElementById("input2").value="";
                        }

   handleRadioButton = (event,typeofsource,sourceid) => {

          if(typeofsource==="sourceselect"){
            this.setState({
              ['sourceSelect'+sourceid]: event.target.value
                });

                if(event.target.value==="1"){
                  //----- db selected-----
                    this.setState({
                       ['displaySourceQuery'+sourceid]: 'show',
                       ['displaySourceFile'+sourceid]: 'hide'
                        });
                       //this.props.getAvailableDBList();
                       }
                       else{
                         //----- file selected-----
                       this.setState({
                       ['displaySourceFile'+sourceid]: 'show'
                        });
                        this.resetDBSelection(sourceid);
                        }
                 }
              }

   resetDBSelection=(sourceid)=>{
       this.setState({
           ['displaySourceDB'+sourceid]: 'hide',
           ['displaySourceQuery'+sourceid]: 'hide',
           ['source'+sourceid+'DB']: '',
           ['source'+sourceid+'Query']: ''
           });
      }


   render() {


     let runtable;
     const {isLoading} = this.props;
     if(isLoading.includes('reconlist')){
       runtable=  <Preloader loading={true} /> ;
     }

     else{

     if(this.state.reconHistoryList.length!==0){

       const runExecuteFilterCriteria=this.state.runExecuteFilterCriteria;
       //reconDisplayList = this.props.reconList.filter(function (e) {  return e.ruleName.toLowerCase().includes(runExecuteFilterCriteria.toLowerCase()) || e.date.toLowerCase().includes(runExecuteFilterCriteria.toLowerCase()); } );

       reconDisplayList=this.state.reconHistoryList;
       reconDisplayListLength=reconDisplayList.length;
       var key = 0;

       if(reconDisplayListLength===0){
         runtable=<EmptyState message="No record found." />;
       }


       else{

       //------------ Iterate table body -----------

       let tableRow = [...reconDisplayList.slice(0, this.state.tableItemCount)].map((row) => {
           var i;
           var colNumber=0
           var returnValue = [];
           for (i in row){
               key = key+1;
               returnValue.push(
                   <TableCell key={key}>
                      { row[i]}
                   </TableCell>
               )
               colNumber=colNumber+1;
           };
           key = key+1;
           return (<TableRow key={key} className={row.status} onClick={this.handleClick.bind(this,row.file)} >
               {returnValue.slice(2)}
               </TableRow>
           );

       });

     runtable=<div><Table
       className='data-table recon-audit-list clickable'
     >
       <TableHead>
         <TableRow>
           <TableCell>Date</TableCell>
           <TableCell>Ruleset name</TableCell>
           <TableCell>Run status</TableCell>
           <TableCell>Run time</TableCell>
           <TableCell>Totals rules</TableCell>
           <TableCell>Failed rules</TableCell>
         </TableRow>
       </TableHead>
       <TableBody>
         {tableRow}
       </TableBody>
      </Table>
      <div className={reconDisplayListLength>this.state.tableItemCount? 'show-more' : 'hide'}  onClick={this.loadMore.bind(this)}>
        Show more
      </div>
      </div>
        }

      }
      else{
        runtable=<EmptyState message="You don't have any records to display." />;
      }

  }

  //---- params for Source 1 & Source2 -----------



  if(this.state.checkingParamFor==="source1Query" && this.props.isLoading.includes('param')) {
    paramsForSource1=<Preloader loading={true} />
  } else if(this.state.checkingParamFor==="source1Query"){
      if(this.state.source1QueryParams.length > 0){
       //paramsForSource1=this.generateParamsUI(this.props.queryParam.parameter);
       paramsForSource1=<QueryParams params={this.state.source1QueryParams} source={this.state.checkingParamFor} updateParamValues={this.updateParamValues.bind(this)} />
      }
     else{
      paramsForSource1="";
     }
  }

  if(this.state.checkingParamFor==="source2Query" && this.props.isLoading.includes('param')) {
    paramsForSource2=<Preloader loading={true} />
  } else if(this.state.checkingParamFor==="source2Query"){
      if(this.state.source2QueryParams.length > 0){
       //paramsForSource2=this.generateParamsUI(this.props.queryParam.parameter);
       paramsForSource2=<QueryParams params={this.state.source2QueryParams} source={this.state.checkingParamFor} updateParamValues={this.updateParamValues.bind(this)} />
      }
    else{
      paramsForSource2="";
    }
}

     return (

       <div className='cz-container'>


            <ReconFilters
            filterContainerState={this.state.filterContainer}
            toggleFilterContainer={this.toggleFilterContainer.bind(this)}
            reconList={this.props.reconList}
            rulesetList={this.props.ruleSetList}
            filterReconList={this.filterReconList.bind(this)}
            />


            <div className='rounded-card cz-exe-container'>
            <h3>Start your data validation by selecting a ruleset</h3>
              <Row className='recon-input'>
               <Col sm={3} xs={12}>
                <OneOptAutocomplete
                     label='Select ruleset'
                     fileColumns={this.props.ruleSetList}
                     onSelectValue={this.dropdownSelector.bind(this,'ruleSetListValue')}
                     value={this.state.ruleSetListValue} />
              </Col>

             </Row>



             <Row className={this.state.ruleSetListValue===null? "hide" : ""}>
              <Col sm={4} xs={12} className={this.state.helpText}>
              <div  className='input-file'>

              <Row>
               <Col sm={10} xs={6}>
               <div className="cz-source-label">Data source1</div>
               <RadioGroup aria-label="position" className="radio-group" name="position" value={this.state.sourceSelect1} onChange={(e)=>this.handleRadioButton(e,'sourceselect','1')} row>
                    <FormControlLabel
                      value="0"
                      control={<Radio color="primary" />}
                      label={'File'}
                      labelPlacement="end"
                    />

                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label={'Query'}
                      labelPlacement="end"
                    />
                </RadioGroup>


               <div className={this.state.displaySourceFile1}>
                  <input type="file" id="input1" className="btn" disabled={this.state.selectBtnState} onChange={this.handleChange.bind(this,"1")} />
                </div>


                <div className={this.state.displaySourceQuery1}>
                  <SelectQuery label='Select a query' fileColumns={this.props.queryList} onSelectValue={this.dropdownSelector.bind(this,'source1Query')} value={this.state.source1Query} />
               </div>

               {paramsForSource1}


               </Col>
              </Row>

              <div className={this.state.file1HelpTxt===""?"hide":"file-error-help-text"}>
                <div className="error">{this.state.errorForFile1}</div>
                <div className="error-message">
                  <div>Missing field(s):</div>
                  <div>{this.state.file1HelpTxt}</div>
                </div>
              </div>
              </div>
              </Col>



              <Col sm={4} xs={12} className={this.state.helpText}>
              <div  className='input-file'>

              <Row>
               <Col sm={10} xs={6}>
               <div className="cz-source-label">Data source2</div>
               <RadioGroup aria-label="position" className="radio-group" name="position" value={this.state.sourceSelect2} onChange={(e)=>this.handleRadioButton(e,'sourceselect','2')} row>
                    <FormControlLabel
                      value="0"
                      control={<Radio color="primary" />}
                      label={'File'}
                      labelPlacement="end"
                    />

                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label={'Query'}
                      labelPlacement="end"
                    />
                </RadioGroup>


               <div className={this.state.displaySourceFile2}>
                  <input type="file" id="input2" className="btn" disabled={this.state.selectBtnState} onChange={this.handleChange.bind(this,"2")} />
                </div>


                <div className={this.state.displaySourceQuery2}>
                  <SelectQuery label='Select a query' fileColumns={this.props.queryList} onSelectValue={this.dropdownSelector.bind(this,'source2Query')} value={this.state.source2Query} />
               </div>

               {paramsForSource2}

               </Col>
              </Row>

              <div className={this.state.file2HelpTxt===""?"hide":"file-error-help-text"}>
                <div className="error">{this.state.errorForFile2}</div>
                <div className="error-message">
                  <div>Missing field(s):</div>
                  <div>{this.state.file2HelpTxt}</div>
                </div>
              </div>
              </div>
              </Col>



            </Row>
            <Row className={this.state.ruleSetListValue===null? "hide" : ""}>
             <Col sm={12} xs={12} className='recon-btn-container btn-container'>
                <Button label="Start validation" className='btn btn-action' disabled={this.state.compareBtnStatus} onClick={this.startReconcile.bind(this)} />
             </Col>
            </Row>
           </div>


           <div className='rounded-card cz-recon-results'>
           <Row className="top-section">
            <Col sm={6} xs={6}><h3>Validation history </h3></Col>
            <Col sm={6} xs={6} className="text-right"><a onClick={this.toggleFilterContainer.bind(this)} className={this.state.filterList.length > 0 ? 'filter-btn active' : 'filter-btn'}> <span className="material-icons">tune</span>Filters {this.state.filterList.length > 0 ? '('+this.state.filterList.length+')' : ''}</a></Col>
           </Row>



            <Row className="search-section hide">
            <Col sm={4} xs={6}>
            <ComponentErrorBoundary value="executed list search field">
            <TextField
             label="Search based on ruleset name or date"
             fullWidth
             value={ this.state.runExecuteFilterCriteria }
             onChange={ e => this.setState({ runExecuteFilterCriteria:e.target.value }) }
           />
           </ComponentErrorBoundary>
           </Col>


           </Row>

           {runtable}

            </div>


       </div>
     );
   }
}

const mapStateToProps = (state) => {
  return{
    isLoading: state.reconReducer.isLoading.concat(state.commonReducer.isLoading),
    errors: state.reconReducer.error.concat(state.commonReducer.error),
    queryList: state.commonReducer.queryList,
    queryParam:state.reconReducer.queryParam
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    startRecon : (rule) => {
      dispatch(startReconiliation(rule));
    },
    getQueryList : (orgId) => {
      dispatch(loadQueryList(orgId));
    },
    checkParams : (queryId) => {
      dispatch(checkParams(queryId));
    }
  }
}


export default connect(mapStateToProps,mapDispatchToProps) (ReconciliationExecute);
