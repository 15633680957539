import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Modal from '../../../components/modal';
import Preloader from '../../../components/preloader';
import EmptyState from '../../../components/empty-state-container';
import IconMenu from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import {connect} from 'react-redux';
import {

} from '../users.actions';

let userList;

class ManageUsers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userFilterCriteria:"",
      emptyState:"No users found."
    };
  }


componentDidMount=()=>{
  this.setState({
    userFilterCriteria:""
  });
}




editUser = (user) => {
    //this.props.getRuleSetContent("edit-account");
    this.props.onClick({user});
}

deleteUser = (user) => {
    //this.props.getRuleSetContent(value);
    //this.props.onClick(value);
    this.setState({
      modalOpen:true,
      modalTitle:"Delete user",
      modalContent:"Are you sure you want to delete \""+user.name+"\"?",
      modalPrimaryButton:"Yes",
      modalSecondaryButton:"No",
      modalState:"delete",
      deleteUserval:user
    });
}

  createNewUser = () => {

  var numberOfActiveUsers= this.props.userList.filter(x => x.active === 1);

  if(numberOfActiveUsers.length>=5){
    this.props.userLicenseLimit();
  }

  else{

    this.props.onClick('new');
  }

}





   render() {






     const {isLoading} = this.props;
     let manageUsersContent,userListContent;





     if(isLoading.includes('userlist')){
       manageUsersContent=<Preloader loading={true} /> ;
     }
     else{

      const filterCriteria=this.state.userFilterCriteria;
      userList = this.props.userList.filter(function (e) {  return e.name.toLowerCase().includes(filterCriteria.toLowerCase()); } );


      if(userList.length>0){
        userListContent=<table className='manage-ruleset-table'>
        <tbody>
             {userList.map(user => <tr key={user.userId}>
               <td onClick={this.editUser.bind(this,user)} className='user' > <Tooltip title={user.active ? 'Active':'Inactive'} placement="top"><i className={ user.active ? 'material-icons active':'material-icons inactive'}>circle</i></Tooltip> {user.name}</td>
               <td>
                </td>
               </tr>)}
       </tbody>
        </table>
      }else{
        userListContent=<EmptyState className="margin-top-0" message={this.state.emptyState} />
      }
       manageUsersContent=<Row>

       <Col sm={4} xs={6}>{userListContent}</Col>
       <Col sm={4} xs={6} className="manage-rule-buttons">

          <Button href=""  onClick={this.createNewUser.bind(this)} label="Add a new user" className='createruleset-button'>
          </Button>
       </Col>

       </Row>
     }

     return (
       <div className="manage-ruleset">








        <Row className="search-section" id="searchBar">
        <Col sm={4} xs={6}>



          <input type="hidden" value="prayer" />

          <TextField
            label="Search an existing user"
            fullWidth
            value={ this.state.userFilterCriteria }
            onChange={ e => this.setState({ userFilterCriteria:e.target.value }) }
          />



        </Col>




        </Row>

       {manageUsersContent}



         </div>

     );
   }
}


const mapStateToProps = (state) => {
  return{
    errors: state.usersReducer.errorMessage
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {

  }
}


export default connect(mapStateToProps,mapDispatchToProps) (ManageUsers);
