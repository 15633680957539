import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import Button from '../../../components/button';
import ComponentErrorBoundary from '../../../components/component-error-boundary';
import UserAutocomplete from '../../../components/user-autocomplete';

import {connect} from 'react-redux';



class AddUserGroup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userId:this.props.userData.user.userID,
      userName:this.props.userData.user.name,
      userDesc:this.props.userData.user.desc,
      userList:this.props.userData.user.list,
      nameErrorStatus:false,
      nameErrorText:"",
      descErrorStatus:false,
      descErrorText:"",
      userListErrorStatus:false,
      userListErrorText:"",
      saveButtonStatus:true,
      formErrorState:['userName','userDesc','userList']
    };
  }


  componentDidUpdate(prevProps) {
  if (this.props.userData !== prevProps.userData) {
    this.setState({
      userId:this.props.userData.user.userID,
      userName:this.props.userData.user.name,
      userDesc:this.props.userData.user.desc,
      userList:this.props.userData.user.list,
      saveButtonStatus:true,
      formErrorState:['userName','userDesc','userList']
    })
  }
 }

 cancelFlow=()=>{
    this.props.cancelFlow();
 }

 saveFlow=()=>{

   let userObj={
     name: this.state.userName,
     desc: this.state.userDesc,
     list:this.state.userList,
     orgId: Number(this.props.orgId)
   }

   this.props.saveFlow(userObj,'new',this.state.userName);
 }

  handleTextfield = (event,item) => {
      this.setState({[item]:event.target.value});
  }


  formErrorRemoveValue=(attr)=>{
    if (this.state.formErrorState.find(o => o === attr)){
      this.setState({formErrorState:this.state.formErrorState.filter(val=> val !== attr)});
    }
  }

  formErrorInsertValue=(attr)=>{
    if (!this.state.formErrorState.find(o => o === attr)){
      this.setState({formErrorState:[...this.state.formErrorState,attr]});
    }
  }


  validateName=()=>{

    if(this.state.userName.length>2)
    {

      this.formErrorRemoveValue('userName');
      this.setState({nameErrorStatus:false,nameErrorText:""},()=>{this.enableSaveButton();});
      return true;
    }
    else{

      this.formErrorInsertValue('userName');
      this.setState({nameErrorStatus:true,nameErrorText:"Please enter a valid name."},()=>{this.enableSaveButton();});
      return false;
    }

  }

  validateDesc=()=>{

    if(this.state.userDesc.length>2)
    {

      this.formErrorRemoveValue('userDesc');
      this.setState({descErrorStatus:false,descErrorText:""},()=>{this.enableSaveButton();});
      return true;
    }
    else{

      this.formErrorInsertValue('userDesc');
      this.setState({descErrorStatus:true,descErrorText:"Please enter a valid description."},()=>{this.enableSaveButton();});
      return false;
    }

  }

  validateUserList=()=>{
    if(this.state.userList.length>0)
    {

      this.formErrorRemoveValue('userList');
      this.setState({userListErrorStatus:false,userListErrorText:""},()=>{this.enableSaveButton();});
      return true;
    }
    else{

      this.formErrorInsertValue('userList');
      this.setState({userListErrorStatus:true,userListErrorText:"Please add a user."},()=>{this.enableSaveButton();});
      return false;
    }
  }


  selectAutocompleteValue = (item,values) => {
    this.setState({[item]:values});
  }



  enableSaveButton=()=>{
    if(this.state.formErrorState.length===0)
      {
      this.setState({saveButtonStatus:false});
      } else{
      this.setState({saveButtonStatus:true});
      }

}


handleClickShowPassword = (field) => {
    this.setState({[field]: !this.state[field] });
  };

 handleMouseDownPassword = (event) => {
    event.preventDefault();
  };






   render() {

     return (
       <div>

       <Row className=''>
         <Col sm={4} xs={12}>
        <ComponentErrorBoundary value="admin add user group name">
         <FormControl fullWidth>
          <InputLabel htmlFor="standard-adornment-password">Name</InputLabel>
          <Input
            type="text"
            value={this.state.userName}
            onChange={(e)=>this.handleTextfield(e,"userName")}
            onBlur={this.validateName}
            error={this.state.nameErrorStatus}
          />
          <FormHelperText className="Mui-error">{this.state.nameErrorText}</FormHelperText>
         </FormControl>
         </ComponentErrorBoundary>
         </Col>
       </Row>

       <Row className=''>
         <Col sm={6} xs={12}>
         <ComponentErrorBoundary value="admin add user group desc">
         <FormControl fullWidth>
          <InputLabel htmlFor="standard-adornment-password">Short description</InputLabel>
          <Input
            type="text"
            value={this.state.userDesc}
            onChange={(e)=>this.handleTextfield(e,"userDesc")}
            onBlur={this.validateDesc}
            error={this.state.descErrorStatus}
          />
          <FormHelperText className="Mui-error">{this.state.descErrorText}</FormHelperText>
         </FormControl>
         </ComponentErrorBoundary>
         </Col>
       </Row>

       <Row className=''>
         <Col sm={6} xs={12}>
         <ComponentErrorBoundary value="add user groups - user list">
           <UserAutocomplete
           label="Users list"
           placeholder="Add user"
           value={this.state.userList}
           dropDownOptions={this.props.userListOption}
           onSelectValue={this.selectAutocompleteValue.bind(this,"userList")}
           validateAutocomplete={this.validateUserList}
           errorStatus={this.state.userListErrorStatus}
           errorText={this.state.userListErrorText}
           />
         </ComponentErrorBoundary>
         </Col>
       </Row>








       <Row>
       <Col sm={4} xs={12}>
        <Button label="Save" className='btn btn-action' disabled={this.state.saveButtonStatus} onClick={this.saveFlow.bind(this)}  />

        <Button label="Cancel" className='btn btn-default' onClick={this.cancelFlow.bind(this)} />
       </Col>

       </Row>


         </div>

     );
   }
}


const mapStateToProps = (state) => {
  return{
    isLoading: state.usersReducer.isLoading,
    errors: state.usersReducer.errorMessage
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {

  }
}


export default connect(mapStateToProps,mapDispatchToProps) (AddUserGroup);
