import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import Button from '../../../components/button';
import Dropdown from './oneopt-autocomplete';
import Envlist from './env-autocomplete';
import ComponentErrorBoundary from '../../../components/component-error-boundary';
import Auth from '../../../auth/auth';
import Preloader from '../../../components/preloader';

import {connect} from 'react-redux';
import {
          loadEnvList
        } from '../../../common-redux/common.actions';

const auth = new Auth();
let orgId;
class DBConnection extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dbName:"",
      dbUrl:"",
      dbUid:"",
      dbPwd:"",
      dbPort:"",
      dbEnv:null,
      databaseName:"",
      nameErrorStatus:false,
      nameErrorText:"",
      emailErrorStatus:false,
      emailErrorText:"",
      newPassword:"",
      newPasswordErrorStatus:false,
      showNewPassword:false,
      newPasswordErrorText:"",
      confirmPassword:"",
      confirmPasswordErrorStatus:false,
      confirmPasswordErrorText:"",
      showConfirmPassword:false,
      saveButtonStatus:true,
      dbTestStatus:"",
      dbTypes:["mssql","oracle"],
      dbType:null,
      formErrorState:['connError','dbEnv','dbName']
    };
    orgId  = auth.getOrgId();
    this.props.getEnvList(orgId);
  }


  componentDidUpdate(prevProps) {
  if (this.props.dbData !== prevProps.dbData && this.props.dbData.length !==0) {
    this.setState({
      dbName:this.props.dbData.name,
      dbUrl:this.props.dbData.server,
      dbUid:this.props.dbData.user,
      dbPwd:this.props.dbData.password,
      dbPort:this.props.dbData.port,
      dbEnv:this.props.dbData.env,
      dbType:this.props.dbData.type,
      databaseName:this.props.dbData.databaseName,
      newPassword:"",
      newPasswordErrorStatus:false,
      newPasswordErrorText:"",
      confirmPassword:"",
      confirmPasswordErrorStatus:false,
      confirmPasswordErrorText:"",
      saveButtonStatus:true,
      formErrorState:[]
    })
  }

  if (this.props.dbTestStatus !== prevProps.dbTestStatus){
    this.setState({dbTestStatus:this.props.dbTestStatus},()=>{this.enableSaveButton();});
    if(this.props.dbTestStatus===false){
      this.formErrorInsertValue('connError');
    } else{
      this.formErrorRemoveValue('connError');
    }
  }
 }

 cancelFlow=()=>{
    this.props.cancelFlow();
 }

 saveFlow=()=>{

   let dbObj={
     id:this.props.connId,
     name: this.state.dbName,
     server: this.state.dbUrl,
     user:this.state.dbUid,
     database: this.state.databaseName,
     password:this.state.dbPwd,
     port:this.state.dbPort,
     env:this.state.dbEnv,
     type:this.state.dbType,
     orgId: Number(this.props.orgId)
   }


   this.props.connId === null ? this.props.saveFlow(dbObj,'new',this.state.dbName) : this.props.saveFlow(dbObj,'update',this.state.dbName);

 }


 testConnection=()=>{

   let dbObj={
     dbUrl: this.state.dbUrl,
     dbUid:this.state.dbUid,
     dbPwd:this.state.dbPwd,
     type:this.state.dbType,
     database: this.state.databaseName,
     dbPort:this.state.dbPort,
     orgId: Number(this.props.orgId)
   }

this.props.testDBConnect(dbObj);
 }

  handleTextfield = (event,item) => {
      this.setState({[item]:event.target.value});
  }


  formErrorRemoveValue=(attr)=>{
    if (this.state.formErrorState.find(o => o === attr)){
      this.setState({formErrorState:this.state.formErrorState.filter(val=> val !== attr)});
    }
  }

  formErrorInsertValue=(attr)=>{
    if (!this.state.formErrorState.find(o => o === attr)){
      this.setState({formErrorState:[...this.state.formErrorState,attr]});
    }
  }


  validateField=(field)=>{

    let errorStatus=field+"ErrorStatus";
    let errorText=field+"ErrorText";


    if(this.state[field].length>2)
    {
      if(field==="dbName"){
        this.checkNameDuplicates();
      } else{
        this.formErrorRemoveValue(field);
        this.setState({[errorStatus]:false,[errorText]:""},()=>{this.enableSaveButton();});
        return true;
      }
    }
    else{

      this.formErrorInsertValue(field);
      this.setState({[errorStatus]:true,[errorText]:"Invalid entry. Enter at least 2 characters."},()=>{this.enableSaveButton();});
      return false;
    }

  }


  checkNameDuplicates=()=>{
    let hasMatchingName = this.props.dbList.filter(function (item) { return item.name.toLowerCase() === event.target.value.toLowerCase(); });

    if(hasMatchingName.length !=0){

      if(hasMatchingName[0].connId!==this.props.connId){
        this.setState({dbNameErrorText:"You used this name already. Please try another.",dbNameErrorStatus:true},()=>{this.enableSaveButton();});
        this.formErrorInsertValue("dbName");
        return false;
      } else{
        this.setState({dbNameErrorText:"",dbNameErrorStatus:false},()=>{this.enableSaveButton();});
        this.formErrorRemoveValue("dbName");
        return true;
      }

    }

    else{
      this.setState({dbNameErrorText:"",dbNameErrorStatus:false},()=>{this.enableSaveButton();});
      this.formErrorRemoveValue("dbName");
      return true;
    }
  }




  enableSaveButton=()=>{
    if(this.state.formErrorState.length===0)
      {
      this.setState({saveButtonStatus:false});
      } else{
      this.setState({saveButtonStatus:true});
      }

}


handleClickShowPassword = (field) => {
    this.setState({[field]: !this.state[field] });
  };

 handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  dropdownSelector = (name,value) => {
              this.setState({[name]:value},()=>{this.enableSaveButton();});
              this.formErrorRemoveValue(name);
            }




   render() {

     const {isLoading} = this.props;
     let pageContent;
     let testDBStatus="";

     if(isLoading.includes('testconn')){
       testDBStatus=<Preloader loading={true} /> ;
     } else{
       if(this.state.dbTestStatus ===true){
         testDBStatus=<div className="display-status padding-20"><i className='material-icons active'>circle</i> The database connection was successful.</div>
       } else if(this.state.dbTestStatus ===false){
         testDBStatus=<div className="display-status padding-20"><i className='material-icons inactive'>circle</i>We couldn't connect to the database. Please check the details and try again.</div>
       } else {
         testDBStatus=""
       }
     }


     if(isLoading.includes('dbdetails')){
       pageContent=<Preloader loading={true} /> ;
     } else{
       pageContent=<div>
       <div className="rounded-card">

       <Row>
        <Col sm={3} xs={12}>
         <Dropdown
              label='Select database type'
              fileColumns={this.state.dbTypes}
              onSelectValue={this.dropdownSelector.bind(this,'dbType')}
              value={this.state.dbType} />
       </Col>
       </Row>

       <Row className='label-value-pair'>
         <Col sm={6} xs={12}>
        <ComponentErrorBoundary value="DB URL">
         <FormControl fullWidth>
          <InputLabel htmlFor="DB Url">URL</InputLabel>
          <Input
            type="text"
            value={this.state.dbUrl}
            onChange={(e)=>this.handleTextfield(e,"dbUrl")}
            error={this.state.urlErrorStatus}
          />
          <FormHelperText className="Mui-error">{this.state.urlErrorText}</FormHelperText>
         </FormControl>
         </ComponentErrorBoundary>
         </Col>
       </Row>

       <Row className='label-value-pair'>
         <Col sm={6} xs={12}>
        <ComponentErrorBoundary value="Database Name">
         <FormControl fullWidth>
          <InputLabel htmlFor="DB Name">DB Name</InputLabel>
          <Input
            type="text"
            value={this.state.databaseName}
            onChange={(e)=>this.handleTextfield(e,"databaseName")}
            error={this.state.nameErrorStatus}
          />
          <FormHelperText className="Mui-error">{this.state.urlErrorText}</FormHelperText>
         </FormControl>
         </ComponentErrorBoundary>
         </Col>
       </Row>

       <Row className='label-value-pair'>
         <Col sm={4} xs={12}>
        <ComponentErrorBoundary value="DB user id">
         <FormControl fullWidth>
          <InputLabel htmlFor="DB Id">Id</InputLabel>
          <Input
            type="text"
            value={this.state.dbUid}
            onChange={(e)=>this.handleTextfield(e,"dbUid")}
            error={this.state.dbUidErrorStatus}
          />
          <FormHelperText className="Mui-error">{this.state.dbUidErrorText}</FormHelperText>
         </FormControl>
         </ComponentErrorBoundary>
         </Col>
       </Row>

       <Row className='label-value-pair'>
         <Col sm={4} xs={12}>
         <ComponentErrorBoundary value="DB password">
         <FormControl fullWidth>
          <InputLabel htmlFor="DB password">Password</InputLabel>
          <Input
            type={this.state.showNewPassword ? 'text' : 'password'}
            value={this.state.dbPwd}
            inputProps={{maxLength: 50}}
            autoComplete="new-password"
            onChange={(e)=>this.handleTextfield(e,"dbPwd")}
            onBlur={this.checkPasswordStrength}
            error={this.state.dbPwdErrorStatus}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>this.handleClickShowPassword('showNewPassword')}
                  onMouseDown={(e)=>this.handleMouseDownPassword(e)}
                  size="large">
                  {this.state.showNewPassword ? <Tooltip title="Hide" placement="right"><i className="material-icons">visibility</i></Tooltip> : <Tooltip title="Show" placement="right"><i className="material-icons">visibility_off</i></Tooltip>}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText className="Mui-error">{this.state.dbPwdErrorText}</FormHelperText>
         </FormControl>
         </ComponentErrorBoundary>
         </Col>
       </Row>

       <Row className='label-value-pair'>
         <Col sm={4} xs={12}>
        <ComponentErrorBoundary value="DB port">
         <FormControl fullWidth>
          <InputLabel htmlFor="DB Id">Port</InputLabel>
          <Input
            type="number"
            value={this.state.dbPort}
            onChange={(e)=>this.handleTextfield(e,"dbPort")}
            error={this.state.dbPortErrorStatus}
          />
          <FormHelperText className="Mui-error">{this.state.dbPortErrorText}</FormHelperText>
         </FormControl>
         </ComponentErrorBoundary>
         </Col>
       </Row>

       <Row>
       <Col sm={4} xs={12} className=''>

         <Button label="Test connection" className='btn btn-default' onClick={this.testConnection.bind(this)} />

       </Col>

       </Row>
       {testDBStatus}
       </div>
       <div className="rounded-card margin-top-30">
       <div className="note"><span>Note:</span> Please make sure you test the connection and it's successful. You won't be able to save unsuccessful connections.</div>
       <Row>
        <Col sm={4} xs={12}>
         <Envlist
              label='Assign an environment code'
              fileColumns={this.props.envList}
              onSelectValue={this.dropdownSelector.bind(this,'dbEnv')}
              value={this.state.dbEnv} />
       </Col>
       </Row>
       <Row>
       <Col sm={4} xs={12}>
       <ComponentErrorBoundary value="DB connection name">
        <FormControl fullWidth>
         <InputLabel htmlFor="DB Id">Connection name</InputLabel>
         <Input
           type="text"
           value={this.state.dbName}
           onChange={(e)=>this.handleTextfield(e,"dbName")}
           error={this.state.dbNameErrorStatus}
           onBlur={this.validateField.bind(this,"dbName")}
         />
         <FormHelperText className="Mui-error">{this.state.dbNameErrorText}</FormHelperText>
        </FormControl>
        </ComponentErrorBoundary>
       </Col>
       </Row>
       <Row>
       <Col sm={4} xs={12} className=''>

        <Button label="Save" className='btn btn-action' disabled={this.state.saveButtonStatus} onClick={this.saveFlow.bind(this)}  />

         <Button label="Cancel" className='btn btn-default' onClick={this.cancelFlow.bind(this)} />

       </Col>

       </Row>
       </div>

         </div>
     }





     return (
       <div>

       {pageContent}

         </div>

     );
   }
}


const mapStateToProps = (state) => {
  return{
    isLoading: state.databaseReducer.isLoading,
    errors: state.databaseReducer.errorMessage,
    envList:state.commonReducer.envList
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getEnvList : (orgId) => {
      dispatch(loadEnvList(orgId));
    }
  }
}


export default connect(mapStateToProps,mapDispatchToProps) (DBConnection);
