import React, { Component } from 'react';
import XLSX from 'xlsx';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import {Col, Row} from 'react-bootstrap';
import Button from '../../../components/button';
import RuleContent from './rule-content';
import RulesetPrimaryDetails from './ruleset-primary-details';
import Modal from '../../../components/modal';
import EmptyState from '../../../components/empty-state-container';
import RulePrimaryKeyAutocomplete from './primaryid-autocomplete';
import ComponentErrorBoundary from '../../../components/component-error-boundary';

let hasChanged;



class NewRuleConfigSetting extends Component {

  constructor(props) {
    super(props);
    this.state = {
      startConfigButtonStatus:true,
      fileColumns:[],
      colsForSource1: <MenuItem value={0} label="None">None</MenuItem>,
      source1:[],
      firstfilePrimaryId:[],
      colsForSource2:<MenuItem value={0} label="None">None</MenuItem>,
      source2:[],
      secondfilePrimaryId:[],
      createNew:"",
      rulesetContent:"hide",
      rulesetPrimaryDetails:{ id:"",rulesetName:"", ruleContent: [], valueMap: [], fieldConfig: [], status:"changed"},
      RulesetnameErrorText:"",
      rules: ['n_0'],
      n_0:{id:'n_0',ruleName:"",ruleContent:[],threshold:"",valueMap:[],fieldConfig:[],status:"changed"},
      addRuleButtonStatus:true,
      saveButtonStatus:true,
      ruleNameArray:[],
      sourceSelect1:"file",
      sourceSelect2:"file",
      displaySourceFile1:"show",
      displaySourceFile2:"show",
      displaySourceDB1:"hide",
      displaySourceDB2:"hide",
      displayTablesFromSourceDB1:"hide",
      displayTablesFromSourceDB2:"hide",
      source1DB:"",
      source2DB:"",
      source1DBTbl:"",
      source2DBTbl:"",
      colGeneratorID:null,
      autocompleteSource1:[],
      autocompleteSource2:[],
      modalOpen:false,
      modalTitle:"",
      modalContent:"",
      deleteRuleVal:"",
      uploadFileStatus:"hide",
    };

  }


  componentDidUpdate(prevProps) {

   if(prevProps.reconNewRule !== this.props.reconNewRule){
     if(this.props.reconNewRule==="page-content results-container"){
       this.resetRulesListForm();
     }
   }

   if (prevProps.isLoading !== this.props.isLoading) {
       if(this.state.colGeneratorID !==null){
         if(prevProps.availableDBTblColforSource1 !== this.props.availableDBTblColforSource1 && this.state.colGeneratorID==="1"){
           this.generateColumnValuesFromDB(1);
           this.setState({colGeneratorID:null});
         }
         else if(prevProps.availableDBTblColforSource2 !== this.props.availableDBTblColforSource2 && this.state.colGeneratorID==="2"){
           this.generateColumnValuesFromDB(2);
           this.setState({colGeneratorID:null});
         }
       }
     }
 }





  startConfig = (prevStep,Nextstep) =>{
    this.setState({
      [prevStep]:"hide",
      [Nextstep]:""
    });
    this.props.hideBackNavigation();
    this.updateRulename();
  }

  toggleBackdrop=()=>{
    this.state.backdrop ? this.setState({backdrop:false}) : this.setState({backdrop:true})
  }

  saveConfig=()=>{
    let finalRules=[];
    let itemName,source1Primary,source1Columns,source2Primary,source2Columns;
    for(let i=0; i< this.state.rules.length; i++)
    {
      itemName=this.state.rules[i];
      finalRules.push(this.state[itemName]);
    }

    var k;

//-- generating primary for source1--

    if(this.state.sourceSelect1==="db"){
      source1Primary=this.state.firstfilePrimaryId.COLUMN_NAME;
      source1Columns=[]
      for(k=0;k<this.state.source1.length;k++){
        source1Columns.push(this.state.source1[k].COLUMN_NAME)
      }
    } else{
      source1Primary=this.state.firstfilePrimaryId;
      source1Columns=this.state.source1;
    }

//-- generating primary for source2--

    if(this.state.sourceSelect2==="db"){
      source2Primary=this.state.secondfilePrimaryId.COLUMN_NAME
      source2Columns=[]
      for(k=0;k<this.state.source2.length;k++){
        source2Columns.push(this.state.source2[k].COLUMN_NAME)
      }
    } else{
      source2Primary=this.state.secondfilePrimaryId;
      source2Columns=this.state.source2;
    }


    let ruleObj={
      rulesetName: this.state.rulesetPrimaryDetails.rulesetName,
      primaryKey:this.state.rulesetPrimaryDetails,
      //file1primary:source1Primary,
      //file2primary:source2Primary,
      //file1Columns:source1Columns,
      //file2Columns:source2Columns,
      fileColumnsMerged:this.state.fileColumns,
      rules:finalRules
    }
    console.log("NewFinalOBJ",ruleObj);
    this.props.saveFlow(ruleObj,'new');
  }

  resetRulesListForm=()=>{
    var newruleId="n_"+Date.now()
    this.setState({
      startConfigButtonStatus:true,
      fileColumns:[],
      colsForSource1: <MenuItem value={0} label="None">None</MenuItem>,
      source1:[],
      firstfilePrimaryId:[],
      colsForSource2: <MenuItem value={0} label="None">None</MenuItem>,
      source2:[],
      secondfilePrimaryId:[],
      createNew:"",
      rulesetContent:"hide",
      rulesetName:"",
      RulesetnameErrorStatus:false,
      RulesetnameErrorText:"",
      rules: [newruleId],
      [newruleId]:{id:newruleId,ruleName:"",ruleContent:[],threshold:"",valueMap:[],fieldConfig:[],status:"changed"},
      addRuleButtonStatus:true,
      saveButtonStatus:true,
      sourceSelect1:"file",
      sourceSelect2:"file",
      displaySourceFile1:"show",
      displaySourceFile2:"show",
      displaySourceDB1:"hide",
      displaySourceDB2:"hide",
      displayTablesFromSourceDB1:"hide",
      displayTablesFromSourceDB2:"hide",
      source1DB:"",
      source2DB:"",
      source1DBTbl:"",
      source2DBTbl:"",
      colGeneratorID:null
    });
      document.getElementById("input1").value="";
      document.getElementById("input2").value="";

  }

  cancelConfig=()=>{
     this.props.cancelFlow();
  }

  handleChange = (dropdownName,file,inputId) => {
     const input = document.getElementById(inputId);
     if(input.files[0] !==undefined){

        this.parseSelectedFile(input,dropdownName,file,this.generateColumnValuesFromFile);
    }
    else {
      //  do nothing
    }
}

// uploadRuleTemplate=(input,dropdownName,file,callback) =>
//    {
//      var reader = new FileReader();
//      reader.onload = function(e) {
//       var data = e.target.result;
//
//       var workbook = XLSX.read(data, {type: 'binary'});
//
//       const wsname = workbook.SheetNames[0];
//       const ws = workbook.Sheets[wsname];
//       const sheetdata = XLSX.utils.sheet_to_json(ws, {header:1,blankRows:false});
//       let headerColumn=sheetdata[0];
//       for(let i=0;i<headerColumn.length;i++){
//                       autocompleteVals.push({ label: headerColumn[i] , value: headerColumn[i] , origin: file , datatype:"" })
//                       dropdownval.push(<MenuItem key={i} value={headerColumn[i]} label={headerColumn[i]} primaryText={headerColumn[i]} />);
//                        fileArray.push(headerColumn[i]);
//                     }
//       callback(dropdownName,file,dropdownval,fileArray,autocompleteVals);
//       }
//       reader.readAsBinaryString(input.files[0]);
//    }



parseSelectedFile=(input,dropdownName,file,callback) =>
   {
     var reader = new FileReader();
     reader.onload = function(e) {
      var data = e.target.result;



      let dropdownval=[];
      let fileArray=[];
      let autocompleteVals=[]
      let headerColumn=[]
      let uploadedFileTye=input.files[0].name.split('.').pop().toLowerCase();

      if(uploadedFileTye==="xls" || uploadedFileTye==="xlsx" || uploadedFileTye==="csv"  ){
      var workbook = XLSX.read(data, {type: 'binary',sheetRows: 1});
      const wsname = workbook.SheetNames[0];
      const ws = workbook.Sheets[wsname];
      const sheetdata = XLSX.utils.sheet_to_json(ws, {header:1});
      headerColumn=sheetdata[0];
           }
      else if(uploadedFileTye==="txt"){
        //----- txt files-----
        data.split(/\n|\r/,1).map(function(line){
                  headerColumn=line.split(/\||\t/);
          })

      }
      else{
        headerColumn=[<MenuItem value={0} label="Invalid file">Invalid file</MenuItem>]
      }

      for(let i=0;i<headerColumn.length;i++){
                      autocompleteVals.push({ label: headerColumn[i] , value: headerColumn[i] , origin: file , datatype:"" })
                      dropdownval.push(<MenuItem key={i} value={headerColumn[i]} label={headerColumn[i]}>{headerColumn[i]}</MenuItem>);
                       fileArray.push(headerColumn[i]);
                    }
      callback(dropdownName,file,dropdownval,fileArray,autocompleteVals);
      }
      reader.readAsBinaryString(input.files[0]);
   }

   generateColumnValuesFromFile = (dropdownName,file,dropdownval,fileArray,autocompleteVals) =>{
     this.setState({[dropdownName]:dropdownval,[file]:autocompleteVals});
       if(file==="source1"){
         this.setState({autocompleteSource1:autocompleteVals},()=>{
         this.checkStartConfigStatus();
       })
     }
       else {
         this.setState({autocompleteSource2:autocompleteVals},()=>{
         this.checkStartConfigStatus();
       })
     }
   }

addNewRule=()=> {
        var newRule = 'n_'+Date.now();
        this.setState({
           [newRule]:{id:newRule,ruleName:"",ruleContent:[],threshold:"",valueMap:[],fieldConfig:[],status:"changed"},
           addRuleButtonStatus:true,
           saveButtonStatus:true,

         });
        this.setState(prevState => ({ rules: prevState.rules.concat([newRule]) }));
       }

deleteRuleConfirmed=(ruleid)=> {
          var rulesArray = [...this.state.rules];
          var index = rulesArray.indexOf(ruleid)
            if (index !== -1) {
            rulesArray.splice(index, 1);
            this.setState({rules: rulesArray},()=>{this.checkStatus();});
            }


            var ruleNameArray = [...this.state.ruleNameArray]


            var ruleNameIndex = ruleNameArray.findIndex(x => x.ruleName === this.state[ruleid].ruleName )
              if (ruleNameIndex !== -1) {
              ruleNameArray.splice(ruleNameIndex, 1);
              this.setState({ruleNameArray: ruleNameArray});
              }
  }

deleteRule = (ruleid) => {
              if(this.state[ruleid].ruleName==="" && this.state[ruleid].ruleContent.length===0){
                this.deleteRuleConfirmed(ruleid);
              }
              else{
                this.setState({
                  modalOpen:true,
                  modalTitle:"Delete the rule",
                  modalContent:"Are you sure you want to delete \""+this.state[ruleid].ruleName+"\"?",
                  deleteRuleVal:ruleid
                });

              }
    }


  modalClose=()=>{   this.setState({ modalOpen:false }); }

  modalConfirm=()=>{
    this.setState({
      modalOpen:false
    });
    this.deleteRuleConfirmed(this.state.deleteRuleVal);
  }



updateRulesetPrimaryDetails=(value)=>{
  this.setState({
    rulesetPrimaryDetails:value
  },()=>{this.checkSaveButtonStatus();});

}



checkStatus=()=>{
  hasChanged=false;
  for(var i=0;i<this.state.rules.length;i++){
    var ruleId=this.state.rules[i];
    if(this.state[ruleId].status==="changed"){
      hasChanged=true;
      break;
    }
  }

  if(hasChanged===true){
    this.setState({addRuleButtonStatus:true});
  }else{
    this.setState({addRuleButtonStatus:false},()=>{this.checkSaveButtonStatus();});
  }
}

checkSaveButtonStatus=()=>{
  if(this.state.rulesetPrimaryDetails.status==="validated" && this.state.addRuleButtonStatus===false){
      this.setState({saveButtonStatus:false});
  }
  else{
    this.setState({saveButtonStatus:true});
  }
}


updateValues=(value)=> {
    this.setState({
       [value.id]:{id:value.id,ruleName:value.ruleName,ruleContent:value.ruleContent,threshold:value.threshold,valueMap:value.valueMap,fieldConfig:value.fieldConfig,status:value.status}
     },()=>{this.checkStatus();});
  }

updateRulename=()=>{
   var ruleNameArray=[]
    for(var i=0;i<this.state.rules.length;i++){
      var ruleId=this.state.rules[i];
      ruleNameArray.push({id:this.state[ruleId].id,ruleName:this.state[ruleId].ruleName});
    }
    this.setState({ruleNameArray});
  }

  handleRulesetName = (event) => {
    let hasMatch=false;
    //this.updateValueSet(item,event.target.value);
    for(var i=0;i<this.props.ruleSetList.length;i++){
      if(this.props.ruleSetList[i].name.toLowerCase()===event.target.value.toLowerCase()){
        hasMatch=true;
        break;
      }
    }

    if(hasMatch===true){
      this.setState({RulesetnameErrorStatus:true,RulesetnameErrorText:"There is a matching name. Please try a different one.",ruleSetName:event.target.value});
    }else{
      this.setState({RulesetnameErrorStatus:false,RulesetnameErrorText:"",ruleSetName:event.target.value},()=>{this.checkSaveButtonStatus();});
    }
  }

  uploadRuleset = () => {

    var uploadFile = document.getElementById('upload-ruleset').files[0]

     if(uploadFile !== undefined){

      if(uploadFile.name.split('.').pop()==="xlsx"){
        this.setState({
          uploadFileStatus:"hide"
        });

      this.uploadFileReader(uploadFile,this.displayUploadFileError)

      }
      else{
        this.displayUploadFileError();
      }
    }
    else {
      //  do nothing
    }
  }


  displayUploadFileError=()=>{
    this.setState({
      uploadFileStatus:"show file-error"
    });
  }

  uploadFileReader=(input,displayUploadFileError,callback) =>
     {
       var reader = new FileReader();
       reader.onload = function(e) {
        var data = e.target.result;
        let headerColumn;

        var workbook = XLSX.read(data, {type: 'binary'});
        const wsname = workbook.SheetNames[0];
        const ws = workbook.Sheets[wsname];
        const sheetdata = XLSX.utils.sheet_to_json(ws, {header:1});
        headerColumn=sheetdata[0];
        console.log("headercolumnupload",sheetdata);
        }
        reader.readAsBinaryString(input);
     }


 selectPrimaryIdValue = (fileid,value) =>{
   this.setState({[fileid]:value},()=>{this.checkSaveButtonStatus();});
 }

// DB Related Code
//  resetDBSelection=(sourceid)=>{
//   this.setState({
//     ['displaySourceDB'+sourceid]: 'hide',
//     ['displayTablesFromSourceDB'+sourceid]: 'hide',
//     ['source'+sourceid+'DB']: '',
//     ['source'+sourceid+'DBTbl']: ''
//    });
// }
//  handleRadioButton = (event,typeofsource,sourceid) => {
//    if(typeofsource==="sourceselect"){
//      this.setState({
//         ['sourceSelect'+sourceid]: event.target.value
//       });
//      if(event.target.value==='db'){
//        this.setState({
//           ['displaySourceDB'+sourceid]: 'show',
//           ['displaySourceFile'+sourceid]: 'hide'
//         });
//        this.props.getAvailableDBList();
//      }
//      else{
//        this.setState({
//          ['displaySourceFile'+sourceid]: 'show'
//         });
//         this.resetDBSelection(sourceid);
//      }
//    }
// };
// selectDB = (sourceid,value) =>{
//   this.props.getAvailableDBTablesList(sourceid,value);
//   this.setState({
//     ['displayTablesFromSourceDB'+sourceid]: 'show',
//     ['source'+sourceid+'DB']: value
//    });
// }
// selectDBTable = (sourceid,value) =>{
//   this.props.getAvailableDBTablesColumnsList(sourceid,this.state['source'+sourceid+'DB'],value);
//   this.setState({
//     ['source'+sourceid+'DBTbl']: value,
//     ['colsForSource'+sourceid]:<Preloader loading={true} />,
//     colGeneratorID:sourceid
//   });
// }
// generateColumnValuesFromDB = (sourceid) =>{
//   let colsToDisplay;
//   switch(sourceid){
//     case 1:
//             if(this.props.availableDBTblColforSource1.length>0){
//               colsToDisplay=[],
//               autocompleteValsSource1=[]
//               for(var i=0;i<this.props.availableDBTblColforSource1.length;i++){
//                 autocompleteValsSource1.push({ label: this.props.availableDBTblColforSource1[i].COLUMN_NAME , origin: "source1", datatype:this.props.availableDBTblColforSource1[i].DATA_TYPE })
//                 colsToDisplay.push(<MenuItem key={i} value={this.props.availableDBTblColforSource1[i].COLUMN_NAME} label={this.props.availableDBTblColforSource1[i].COLUMN_NAME} primaryText={this.props.availableDBTblColforSource1[i].COLUMN_NAME} />);
//               }
//             }
//             else{
//               colsToDisplay=<EmptyState message="This table doesn't have any fields." />
//             }
//             this.setState({colsForSource1:colsToDisplay,source1:this.props.availableDBTblColforSource1,autocompleteSource1:autocompleteValsSource1},() => {this.checkStartConfigStatus();});
//             break;
//     case 2:
//             if(this.props.availableDBTblColforSource2.length>0){
//               colsToDisplay=[],
//               autocompleteValsSource2=[]
//               for(var i=0;i<this.props.availableDBTblColforSource2.length;i++){
//                 autocompleteValsSource2.push({ label: this.props.availableDBTblColforSource2[i].COLUMN_NAME , origin: "source2", datatype:this.props.availableDBTblColforSource2[i].DATA_TYPE })
//                 colsToDisplay.push(<MenuItem key={i} value={this.props.availableDBTblColforSource2[i].COLUMN_NAME} label={this.props.availableDBTblColforSource2[i].COLUMN_NAME} primaryText={this.props.availableDBTblColforSource2[i].COLUMN_NAME} />);
//               }
//             }
//             else{
//               colsToDisplay=<EmptyState message="This table doesn't have any fields." />
//             }
//             this.setState({colsForSource2:colsToDisplay,source2:this.props.availableDBTblColforSource2,autocompleteSource2:autocompleteValsSource2},() => {this.checkStartConfigStatus();});
//             break;
//     default:
//       break;
//   }
//      }
 checkStartConfigStatus=()=>{
   if(this.state.source1.length>0 && this.state.source2.length>0){
     let autocompleteVals=this.state.autocompleteSource1.concat(this.state.autocompleteSource2)
      this.setState({startConfigButtonStatus:false,fileColumns:autocompleteVals});
   }else{
     this.setState({startConfigButtonStatus:true})
   }
 }



   render() {
     if(this.state.rules.length!==0){
     var rulesContentVar=this.state.rules.map(rule => <RuleContent key={rule}
       value={this.state[rule]}
       fileColumns={this.state.fileColumns}
       updateValues={this.updateValues.bind(this)}
       deleteRule={this.deleteRule.bind(this)}
       updateRulename={this.updateRulename.bind(this)}
       ruleNameArray={this.state.ruleNameArray}
       toggleBackdrop={this.props.toggleBackdrop}
       />);
   }
   else{
     rulesContentVar=<EmptyState message="You don't have any rules. If you like to add one please click the Add new rule button." />
   }

     return (
       <div>

       <Modal
        open={this.state.modalOpen}
        title={this.state.modalTitle}
        content={this.state.modalContent}
        primaryButton="Yes"
        secondaryButton="No"
        close={this.modalClose}
        confirm={this.modalConfirm}
        />

       <div className={this.state.createNew}>



        <Row>

          <Col sm={4} xs={6}>

          <div className='rounded-card'>

          <div className={this.state.displaySourceFile1}>
             <label>Import your Source1 file</label>
             <input type="file" id="input1" onChange={this.handleChange.bind(this,"colsForSource1","source1","input1")} />
           </div>

           </div>

          </Col>


          <Col sm={4} xs={6}>

          <div className='rounded-card'>

           <div className={this.state.displaySourceFile2}>
              <label>Import your Source2 file</label>
              <input type="file" id="input2" onChange={this.handleChange.bind(this,"colsForSource2","source2","input2")} />
           </div>


           </div>

          </Col>

          <Col sm={4} xs={12} className="btn-container">
              <Button label="Start configuration" className='btn btn-action btn-start-config' disabled={this.state.startConfigButtonStatus} onClick={this.startConfig.bind(this,"createNew","rulesetContent")}  />
          </Col>


        </Row>

        <Row>

          <Col sm={4} xs={12}>

          <div className='rounded-card'>

           <h3>Columns from Source1</h3>
           <div className='createrule-filecolumns'>{this.state.colsForSource1}</div>
           </div>

          </Col>


          <Col sm={4} xs={12}>

          <div className='rounded-card'>
           <h3>Columns from Source2</h3>
           <div className='createrule-filecolumns'>{this.state.colsForSource2}</div>
           </div>

          </Col>




        </Row>
       </div>
       <div className={this.state.rulesetContent}>
            <h3>Get started with your business rule(s)</h3>
             <div className='rules-bucket'> {rulesContentVar} </div>

             <Row> <Col sm={12} xs={12} className='text-right btn-container'>
              <Button label="Add new rule" className='btn btn-action' disabled={this.state.addRuleButtonStatus}  onClick={this.addNewRule.bind()}  />
             </Col> </Row>

             <div className='rules-bucket'>
                <RulesetPrimaryDetails
                ruleSetList={this.props.ruleSetList}
                rulesetPrimaryDetails={this.state.rulesetPrimaryDetails}
                fileColumns={this.state.fileColumns}
                toggleBackdrop={this.props.toggleBackdrop}
                updateRulesetPrimaryDetails={this.updateRulesetPrimaryDetails.bind(this)}
                />
             </div>

             <Row className="hide"> <Col sm={12} xs={12}>
             <div className='rounded-card'>
              <h3>Enter a name for the Ruleset and select a unique identifier for your data source</h3>
               <Row>
                 <Col sm={4} xs={12}>
                 <ComponentErrorBoundary value="new ruleset name textfield">
                 <TextField
                   error={this.state.RulesetnameErrorStatus}
                   label="Name for the ruleset"
                   value={this.state.ruleSetName}
                   fullWidth
                   inputProps={{maxLength: 45}}
                   onChange={(e)=>this.handleRulesetName(e)}
                   helperText={this.state.RulesetnameErrorText}
                 />
                 </ComponentErrorBoundary>
                 </Col>
                 <Col sm={4} xs={12}>
                 <RulePrimaryKeyAutocomplete
                 fileColumns={this.state.source1}
                 onSelectValue={this.selectPrimaryIdValue.bind(this,'firstfilePrimaryId')}
                 value={this.state.firstfilePrimaryId}
                 label='Pick a unique identifier for source 1'
                 />
                 </Col>
                 <Col sm={4} xs={12}>
                 <RulePrimaryKeyAutocomplete
                 fileColumns={this.state.source2}
                 onSelectValue={this.selectPrimaryIdValue.bind(this,'secondfilePrimaryId')}
                 value={this.state.secondfilePrimaryId}
                 label='Pick a unique identifier for source 2'
                 />
                 </Col>
              </Row>
             </div>
             </Col>
          </Row>

             <Row>
             <Col sm={12} xs={12} className='text-right btn-container'>
              <Button label="Cancel" className='btn btn-default' onClick={this.cancelConfig.bind(this)} />

              <Button label="Finish" className='btn btn-action' disabled={this.state.saveButtonStatus} onClick={this.saveConfig.bind(this)}  />

             </Col>

             </Row>
       </div>

         </div>
     );
   }
}




export default NewRuleConfigSetting;
