import React, { Component } from 'react';
import Backdrop from '@mui/material/Backdrop';
import ManageQueries from './components/db-manage-queries';
import DBQuery from './components/db-add-edit-query';
import Snackbar from '@mui/material/Snackbar';
import Banner from '../../components/banner';
import Modal from '../../components/modal';
import ErrorBoundary from '../../components/error-boundary';
import Auth from '../../auth/auth';
import {connect} from 'react-redux';
import {
      loadDBList,
      testQuery,
      addQuery,
      deleteQuery,
      loadDBDetails,
      loadQueryDetails,
      loadDBTablesList
    } from './database.actions';

import {loadQueryList} from '../../common-redux/common.actions';

const auth = new Auth();
let orgId;


class DatabaseQuery extends Component {

  constructor(props) {
    super(props);
    this.state = {
      appContentContainer:"app-content-container",
      manageListClass: "page-content select-container in",
      dbQueryClass: "page-content results-container",
      editUserClass: "page-content results-container",
      pageNavContainer:"hide",
      backdrop:false,
      dbQuery:{qy:{name:"",dbvalue:"",qyvalue:""}},
      step1:"",
      step2:"hide",
      snackbarStatus: false,
      bannerType: "danger",
      showBanner: false,
      bannerTitle:"",
      bannerMessage:"",
      actionTriggered:false,
      triggeredItemName:"",
      actionName:"",
      modalOpen:false,
      modalTitle:"",
      modalState:"",
      modalContent:"",
      modalPrimaryButton:"",
      modalSecondaryButton:"",
      dbConnectionTitle:"",
      saveQyId:null,
      pageState:0
    };
    orgId  = auth.getOrgId();
    this.props.getDBList(orgId);
    this.props.getQueryList(orgId);
  }





  componentDidUpdate(prevProps) {
    if (prevProps.isLoading !== this.props.isLoading && this.state.actionTriggered === true) {


      if(this.props.isLoading.includes('addquery')){
       this.state.saveQyId === null ? this.setState({snackbarStatus:true,actionName:"add"}) : this.setState({snackbarStatus:true,actionName:"update"});
      }
      else if(this.props.isLoading.includes('deletequery')){
       this.setState({snackbarStatus:true,actionName:"delete"});
      }
      else{
        if(this.state.actionName==="add"){this.saveSuccess();}
        else if(this.state.actionName==="update"){this.updateSuccess();}
        else if(this.state.actionName==="delete"){this.deleteSuccess();}
        this.setState({snackbarStatus:false,actionTriggered:false,actionName:""});
      }
    }

    // ---- Error handing ------



    if (this.props.error !== prevProps.error && this.props.error.length>0) {

      this.setState({snackbarStatus:false,showBanner:true,bannerType:"danger",bannerTitle:"Oh snap! Something went wrong!",bannerMessage:"Please contact your admin."});
    }


    if (this.props.dbDetails !== prevProps.dbDetails) {
      this.setState({
        dbData:this.props.dbDetails
      });
    }

    if (this.props.queryDetails !== prevProps.queryDetails) {
      this.setState({
        dbQuery:this.props.queryDetails
      });
    }


  }

saveSuccess=()=>{
  this.props.getQueryList(orgId);
  this.navigateToBegin();
  var message = this.state.triggeredItemName+' has been added successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

updateSuccess=()=>{
  this.props.getQueryList(orgId);
  this.navigateToBegin();
  var message = this.state.triggeredItemName+' has been updated successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

deleteSuccess=()=>{
  this.props.getQueryList(orgId);
  var message = this.state.triggeredItemName+' has been deleted successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

navigateToBegin=()=>{
  this.props.parentNavBar(1);
  this.setState({
    pageState:0,
    pageNavContainer:"hide"
  });
}

cancelFlow=()=>{
  this.navigateToBegin();
}



saveQueryFlow=(data,flowName,queryName)=>{
  this.setState({actionTriggered:true,triggeredItemName:queryName,saveQyId:data.id});
  flowName==='new' ? this.props.addQueryReq(data) : this.props.addQueryReq(data)
}

deleteQuery=(data)=>{
this.setState({actionTriggered:true,triggeredItemName:data.dbQryName});
this.props.deleteQueryReq(data);
}



testQueryConnect=(data)=>{
  this.props.testQueryConnReq(data);
}

toggleBackdrop=()=>{
  this.state.backdrop ?
  this.setState({backdrop:false,pageNavContainer:'page-nav-container', appContentContainer:"app-content-container in"}) :
  this.setState({backdrop:true,pageNavContainer:'page-nav-container reduce-zindex', appContentContainer:"app-content-container in has-backdrop"})
}

hideBackNavigation=()=>{
  this.setState({
    appContentContainer:"app-content-container",
    pageNavContainer:"hide"
  });
}


getDBTablesList=(db)=>{
  this.props.getDBTablesList(db)
}




  goToTop=()=>{
    document.getElementById("app").scrollTo(0,0);
  }

  dropdownSelector = (name,id,value) => {
              this.setState({[name]:value});
          }

  dismissBanner = () => {
                this.setState({showBanner:false});
         }

 snackHandler = (state) =>{
   this.setState({snackbarStatus:state});
 }



 modalClose=()=>{
   this.setState({
     modalOpen:false
   });
 }

 modalConfirm=()=>{
   this.setState({
     modalOpen:false
   });
 }

 selectQuery= (value) => {

   this.dismissBanner();
   this.props.parentNavBar(2);

   if(value==="new"){
     this.setState({
       pageState:1,
       pageNavContainer:"page-nav-container overlap",
       dbConnectionTitle:"Add a new database query",
       dbQuery:"",
       queryId:null
     });
   }

   else {

     this.props.getQueryDetails(value.qy.queryId);
     this.setState({
       pageState:1,
       pageNavContainer:"page-nav-container overlap",
       dbConnectionTitle:"View & Edit a database query",
       dbQuery:this.props.queryDetails,
       queryId:value.qy.queryId
     });
   }
 }



  goBack = (level) => {
                    this.props.parentNavBar(1);
                    if(level===0){
                      this.setState({
                        pageState:0,
                        pageNavContainer:"hide"
                      });
                    }
                }


   render() {

     return (
       <div className="sub-module">

       <Backdrop open={this.state.backdrop}></Backdrop>

       <Snackbar
           open={this.state.snackbarStatus}
           message="Please wait..."
           className="snack-bar"
         />

       <div className={this.state.pageNavContainer}>
         <a onClick={this.goBack.bind(this,0)} className={this.state.level0}>&lt; Manage Database Queries</a>
       </div>


       <Modal
        open={this.state.modalOpen}
        title={this.state.modalTitle}
        content={this.state.modalContent}
        primaryButton={this.state.modalPrimaryButton}
        secondaryButton={this.state.modalSecondaryButton}
        close={this.modalClose}
        confirm={this.modalConfirm}
        />

       <Banner
          type={this.state.bannerType}
          hasAlerts={this.state.showBanner}
          header={this.state.bannerTitle}
          body={this.state.bannerMessage}
          dismissBanner={this.dismissBanner.bind(this)}
          />



{
    this.state.pageState===0 ?
      <ErrorBoundary>
      <h2>Manage Database Queries</h2>
      <ManageQueries
        onClick={this.selectQuery.bind(this)}
        queriesList={this.props.queryList}
        orgId={orgId}
        deleteQuery={this.deleteQuery}
        isLoading={this.props.isLoading}
        />
      </ErrorBoundary> :

      <ErrorBoundary>
      <h2>{this.state.dbConnectionTitle}</h2>
        <DBQuery
          cancelFlow={this.cancelFlow}
          saveFlow={this.saveQueryFlow}
          orgId={orgId}
          dbList={this.props.dbList}
          queryId={this.state.queryId}
          dbQuery={this.state.dbQuery}
          getDBTablesList={this.getDBTablesList}
          isLoading={this.props.isLoading}
          testQueryConnect={this.testQueryConnect.bind(this)}
          queryTestStatus={this.props.queryTestStatus}
          hideBackNavigation={this.hideBackNavigation}
          toggleBackdrop={this.toggleBackdrop.bind(this)}
          dbFields={this.props.dbTablesList}
          queriesList={this.props.queryList} 
          dbQueryClass={this.state.dbQueryClass}
          />
        </ErrorBoundary>

  }


       </div>
     );
   }
}

const mapStateToProps = (state) => {
  return{
    error: state.databaseReducer.error.concat(state.commonReducer.error),
    dbList: state.databaseReducer.dbList,
    queryList: state.commonReducer.queryList,
    queryTestStatus: state.databaseReducer.queryTestStatus,
    dbDetails: state.databaseReducer.dbDetails,
    queryDetails: state.databaseReducer.queryDetails,
    dbTablesList:state.databaseReducer.dbTablesList,
    isLoading:state.databaseReducer.isLoading.concat(state.commonReducer.isLoading)
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    getDBList : (orgId) => {
      dispatch(loadDBList(orgId));
    },
    getDBDetails : (dbId) => {
      dispatch(loadDBDetails(dbId));
    },
    getDBTablesList : (db) => {
      dispatch(loadDBTablesList(db));
    },
    getQueryList : (orgId) => {
      dispatch(loadQueryList(orgId));
    },
    testQueryConnReq : (data) => {
      dispatch(testQuery(data));
    },
    addQueryReq : (data) => {
      dispatch(addQuery(data));
    },
    deleteQueryReq : (data) => {
      dispatch(deleteQuery(data));
    },
    getQueryDetails : (qyId) => {
      dispatch(loadQueryDetails(qyId));
    }

  }
}


export default connect(mapStateToProps,mapDispatchToProps) (DatabaseQuery);
