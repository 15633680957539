import React, { Component } from 'react';
import Backdrop from '@mui/material/Backdrop';
import ManageDs from './components/db-manage-ds';
import AddDs from './components/db-add-ds';
import ViewEditDs from './components/db-edit-ds';
import Snackbar from '@mui/material/Snackbar';
import Banner from '../../components/banner';
import Modal from '../../components/modal';
import ErrorBoundary from '../../components/error-boundary';
import Auth from '../../auth/auth';
import {connect} from 'react-redux';
import {
      addDs,
      updateDs,
      deleteDs
    } from './database.actions';

import {loadDsList,loadDsDetails} from '../../common-redux/common.actions';

const auth = new Auth();
let orgId;




class DataSource extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pageNavContainer:"hide",
      backdrop:false,
      dsDetails:"",
      step1:"",
      step2:"hide",
      snackbarStatus: false,
      bannerType: "danger",
      showBanner: false,
      bannerTitle:"",
      bannerMessage:"",
      actionTriggered:false,
      triggeredItemName:"",
      actionName:"",
      modalOpen:false,
      modalTitle:"",
      modalState:"",
      modalContent:"",
      modalPrimaryButton:"",
      modalSecondaryButton:"",
      dsPageTitle:"",
      saveEnvId:null,
      pageState:0
    };
    orgId  = auth.getOrgId();
    this.props.getDsList();
  }





  componentDidUpdate(prevProps) {
    if (prevProps.isLoading !== this.props.isLoading && this.state.actionTriggered === true) {

      if(this.props.isLoading.includes('addds')){
       this.setState({snackbarStatus:true,actionName:"add"});
      }
      else if(this.props.isLoading.includes('updateds')){
       this.setState({snackbarStatus:true,actionName:"update"});
      }
      else if(this.props.isLoading.includes('deleteds')){
       this.setState({snackbarStatus:true,actionName:"delete"});
      }
      else{
        if(this.state.actionName==="add"){this.saveSuccess();}
        else if(this.state.actionName==="update"){this.updateSuccess();}
        else if(this.state.actionName==="delete"){this.deleteSuccess();}
        this.setState({snackbarStatus:false,actionTriggered:false,actionName:""});
      }
    }

    // ---- Error handing ------

    if (this.props.error !== prevProps.error) {
      this.setState({snackbarStatus:false,showBanner:true,bannerType:"danger",bannerTitle:"Oh snap! Something went wrong!",bannerMessage:"Please contact your admin."});
    }


    if (this.props.dsDetails !== prevProps.dsDetails) {
      this.setState({
        dsDetails:this.props.dsDetails
      });
    }

    if (this.props.queryDetails !== prevProps.queryDetails) {
      this.setState({
        dbQuery:this.props.queryDetails
      });
    }


  }

saveSuccess=()=>{
  this.props.getDsList();
  this.navigateToBegin();
  var message = this.state.triggeredItemName+' has been added successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

updateSuccess=()=>{
  this.props.getDsList();
  this.navigateToBegin();
  var message = this.state.triggeredItemName+' has been updated successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

deleteSuccess=()=>{
  this.props.getDsList();
  var message = this.state.triggeredItemName+' has been deleted successfully.';
  this.setState({showBanner:true,bannerType:"success",bannerTitle:"Done!",bannerMessage:message});
  this.goToTop();
}

navigateToBegin=()=>{
  this.props.parentNavBar(1);
  this.goToTop();
  this.setState({
    pageState:0,
    pageNavContainer:"hide"
  });
}

cancelFlow=()=>{
  this.navigateToBegin();
}

saveDsFlow=(data,flowName,name)=>{
  //console.log("data",flowName,data);
  this.setState({actionTriggered:true,triggeredItemName:name,saveEnvId:data.id});
  flowName==='new' ? this.props.addDs(data) : this.props.updateDs(data)
}

deleteDs=(data)=>{
this.setState({actionTriggered:true,triggeredItemName:data.envName});
this.props.deleteDs(data);
}




toggleBackdrop=()=>{
  this.state.backdrop ?
  this.setState({backdrop:false,pageNavContainer:'page-nav-container', appContentContainer:"app-content-container in"}) :
  this.setState({backdrop:true,pageNavContainer:'page-nav-container reduce-zindex', appContentContainer:"app-content-container in has-backdrop"})
}

hideBackNavigation=()=>{
  this.setState({
    appContentContainer:"app-content-container",
    pageNavContainer:"hide"
  });
}



  goToTop=()=>{
    document.getElementById("app").scrollTo(0,0);
  }

  dropdownSelector = (name,id,value) => {
              this.setState({[name]:value});
          }

  dismissBanner = () => {
                this.setState({showBanner:false});
         }

 snackHandler = (state) =>{
   this.setState({snackbarStatus:state});
 }



 modalClose=()=>{
   this.setState({
     modalOpen:false
   });
 }

 modalConfirm=()=>{
   this.setState({
     modalOpen:false
   });
 }


 selectDs= (value) => {

   this.dismissBanner();
   this.props.parentNavBar(2);

   if(value==="new"){
     this.setState({
       pageState:1,
       pageNavContainer:"page-nav-container overlap",
       dsPageTitle:"Add a new data source"
     });
   }

   else {
     this.props.getDsDetails(value.ds.datasourceId);
     this.setState({
       pageState:2,
       pageNavContainer:"page-nav-container overlap",
     });
   }
 }






  goBack = (level) => {
                    this.props.parentNavBar(1);
                    if(level===0){
                      this.setState({
                        pageState:0,
                        pageNavContainer:"hide"
                      });
                    }
                }


   render() {

     return (
       <div className="sub-module">

       <Backdrop open={this.state.backdrop}></Backdrop>

       <Snackbar
           open={this.state.snackbarStatus}
           message="Please wait..."
           className="snack-bar"
         />

       <div className={this.state.pageNavContainer}>
         <a onClick={this.goBack.bind(this,0)} className={this.state.level0}>&lt; Manage Data Sources</a>
       </div>


       <Modal
        open={this.state.modalOpen}
        title={this.state.modalTitle}
        content={this.state.modalContent}
        primaryButton={this.state.modalPrimaryButton}
        secondaryButton={this.state.modalSecondaryButton}
        close={this.modalClose}
        confirm={this.modalConfirm}
        />

       <Banner
          type={this.state.bannerType}
          hasAlerts={this.state.showBanner}
          header={this.state.bannerTitle}
          body={this.state.bannerMessage}
          dismissBanner={this.dismissBanner.bind(this)}
          />


{
    this.state.pageState===0 ?
      <ErrorBoundary>
      <h2>Manage Data Sources</h2>
      <ManageDs
        onClick={this.selectDs.bind(this)}
        dsList={this.props.dsList}
        deleteDs={this.deleteDs}
        isLoading={this.props.isLoading}
        />
      </ErrorBoundary> : null

  }




  {
      this.state.pageState===1 ?
        <ErrorBoundary>
        <h2>{this.state.dsPageTitle}</h2>
          <AddDs
            cancelFlow={this.cancelFlow}
            saveFlow={this.saveDsFlow}
            dsList={this.props.dsList}
            dsDetails={this.state.dsDetails}
            envId={this.state.envId}
            hideBackNavigation={this.hideBackNavigation}
            toggleBackdrop={this.toggleBackdrop.bind(this)}
            />
          </ErrorBoundary> : null

    }



    {
        this.state.pageState===2 ?
          <ErrorBoundary>
          <h2>View & Edit Data Source</h2>
          <ViewEditDs
            cancelFlow={this.cancelFlow}
            saveFlow={this.saveDsFlow}
            dsDetails={this.props.dsDetails}
            isLoading={this.props.isLoading}
            dsList={this.props.dsList}
            />
          </ErrorBoundary> : null

      }

       </div>
     );
   }
}

const mapStateToProps = (state) => {
  return{
    error: state.databaseReducer.error,
    isLoading:state.databaseReducer.isLoading.concat(state.commonReducer.isLoading),
    dsList: state.commonReducer.dsList,
    dsDetails:state.commonReducer.dsDetails
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    getDsList : (orgId) => {
      dispatch(loadDsList(orgId));
    },
    addDs : (data) => {
      dispatch(addDs(data));
    },
    updateDs : (data) => {
      dispatch(updateDs(data));
    },
    deleteDs : (data) => {
      dispatch(deleteDs(data));
    },
    getDsDetails : (dsId) => {
      dispatch(loadDsDetails(dsId));
    }

  }
}


export default connect(mapStateToProps,mapDispatchToProps) (DataSource);
