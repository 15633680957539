import React, {Component} from 'react';

class EmptyState extends Component {

  render() {
    return (
      <div className='empty-state-container'>
          <i className="material-icons">error</i>
          <div>{this.props.message}</div>
      </div>
    );
  }
}


export default EmptyState;
