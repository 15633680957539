import Auth from '../auth/auth';
import axios from 'axios';
export const commonActions ={
  RECEIVE_MENU : 'RECEIVE_MENU',
  GET_CATEGORY : 'GET_CATEGORY',
  GET_STAGES : 'GET_STAGES',
  SELECT_CATEGORY : 'SELECT_CATEGORY',
  SELECT_STAGE : 'SELECT_STAGE',
  GET_NOTIFICATION_REQUEST : 'GET_NOTIFICATION_REQUEST',
  GET_NOTIFICATION_SUCCESS : 'GET_NOTIFICATION_SUCCESS',
  GET_NOTIFICATION_FAILURE : 'GET_NOTIFICATION_FAILURE',
  GET_NOTIFICATION_RELOAD_SUCCESS: 'GET_NOTIFICATION_RELOAD_SUCCESS',
  GET_RULESET_REQUEST : 'GET_RULESET_REQUEST',
  GET_RULESET_SUCCESS : 'GET_RULESET_SUCCESS',
  GET_RULESET_FAILURE : 'GET_RULESET_FAILURE',
  GET_RULESETCONTENT_REQUEST : 'GET_RULESETCONTENT_REQUEST',
  GET_RULESETCONTENT_SUCCESS : 'GET_RULESETCONTENT_SUCCESS',
  GET_RULESETCONTENT_FAILURE : 'GET_RULESETCONTENT_FAILURE',
  GET_QUERY_LIST_REQUEST : 'GET_QUERY_LIST_REQUEST',
  GET_QUERY_LIST_SUCCESS : 'GET_QUERY_LIST_SUCCESS',
  GET_QUERY_LIST_FAILURE : 'GET_QUERY_LIST_FAILURE',
  GET_ENV_LIST_REQUEST : 'GET_ENV_LIST_REQUEST',
  GET_ENV_LIST_SUCCESS : 'GET_ENV_LIST_SUCCESS',
  GET_ENV_LIST_FAILURE : 'GET_ENV_LIST_FAILURE',
  GET_DATA_SOURCE_LIST_REQUEST : 'GET_DATA_SOURCE_LIST_REQUEST',
  GET_DATA_SOURCE_LIST_SUCCESS : 'GET_DATA_SOURCE_LIST_SUCCESS',
  GET_DATA_SOURCE_LIST_FAILURE : 'GET_DATA_SOURCE_LIST_FAILURE',
  GET_USER_LIST_REQUEST : 'GET_USER_LIST_REQUEST',
  GET_USER_LIST_SUCCESS : 'GET_USER_LIST_SUCCESS',
  GET_USER_LIST_FAILURE : 'GET_USER_LIST_FAILURE',
  GET_COLS_FOR_QUERY_REQUEST : 'GET_COLS_FOR_QUERY_REQUEST',
  GET_COLS_FOR_QUERY_SUCCESS : 'GET_COLS_FOR_QUERY_SUCCESS',
  GET_COLS_FOR_QUERY_FAILURE : 'GET_COLS_FOR_QUERY_FAILURE',
  LOAD_DATA_SOURCE_DETAILS_REQUEST : 'LOAD_DATA_SOURCE_DETAILS_REQUEST',
  LOAD_DATA_SOURCE_DETAILS_SUCCESS : 'LOAD_DATA_SOURCE_DETAILS_SUCCESS',
  LOAD_DATA_SOURCE_DETAILS_FAILURE : 'LOAD_DATA_SOURCE_DETAILS_FAILURE',
  GET_CONTEXT_LIST_REQUEST : 'GET_CONTEXT_LIST_REQUEST',
  GET_CONTEXT_LIST_SUCCESS : 'GET_CONTEXT_LIST_SUCCESS',
  GET_CONTEXT_LIST_FAILURE : 'GET_CONTEXT_LIST_FAILURE',
  GET_ENV_TYPE_LIST_REQUEST : 'GET_ENV_TYPE_LIST_REQUEST',
  GET_ENV_TYPE_LIST_SUCCESS : 'GET_ENV_TYPE_LIST_SUCCESS',
  GET_ENV_TYPE_LIST_FAILURE : 'GET_ENV_TYPE_LIST_FAILURE',
  CLEAR_ALL_DATA : 'CLEAR_ALL_DATA'
}

const auth = new Auth();
const { getAccessToken } = auth;


// -- Load Menu Item -----
export const loadMenuItem = (menu) => ({
	type: commonActions.RECEIVE_MENU,
	payload: menu
});

function fetchMenuJson(){
  return fetch('../../local-db/menu.json')
   .then (response => response.json())
}

export function loadMenu(){
  return function(dispatch){
    return fetchMenuJson()
     .then (json => dispatch(loadMenuItem(json.data.menu)))
  }
}


//--------- load notification -----------

export function loadNotifications(){
  return function(dispatch){
    dispatch({ type: commonActions.GET_NOTIFICATION_REQUEST});
    //return fetch("http://localhost:8080/mySpace/recentrun?time="+filterValues[0], {
    return fetch("/notification", {
    // return fetch('../../../local-db/notification.json',{
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
    }
    })
      .then (response => response.json())
        .then ((json) => dispatch({ type: commonActions.GET_NOTIFICATION_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: commonActions.GET_NOTIFICATION_FAILURE, payload: error, error: true }));

   }
}

//--------- Reload notification -----------
export function reloadNotifications(json){
  return function(dispatch){
    dispatch({ type: commonActions.GET_NOTIFICATION_RELOAD_SUCCESS, payload: json });
   }
}

// -- Load Category Item -----

export const loadCategoryItem = (category) => ({
	type: commonActions.GET_CATEGORY,
	payload: category
});

export const loadStageItem = (stages) => ({
	type: commonActions.GET_STAGES,
	payload: stages
});

export const selectCategoryItem = (selectedCategory) => ({
	type: commonActions.SELECT_CATEGORY,
	payload: selectedCategory
});

export const selectStageItem = (selectedStage) => ({
	type: commonActions.SELECT_STAGE,
	payload: selectedStage
});

function fetchCategoryJson(){
  return fetch('../../local-db/category.json')
   .then (response => response.json())
  // var request = new Request('/api/category')
  //  return fetch(request)
  //  .then (response => response.json())
}

export function loadCategory(){
  return function(dispatch){
    return fetchCategoryJson()
     .then (json => dispatch(loadCategoryItem(json.data.category)))
      .then (category => dispatch(loadStageItem(category)))

  }
}


//--------- Load Ruleset List-----------

export function loadRuleSet(){
  return function(dispatch){
    dispatch({ type: commonActions.GET_RULESET_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/comparizone/ruleset',{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: commonActions.GET_RULESET_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: commonActions.GET_RULESET_FAILURE, payload: error, error: true }));
   }
}

//--------- Load Ruleset Content -----------

export function loadRuleSetContent(data){

  return function(dispatch){
    dispatch({ type: commonActions.GET_RULESETCONTENT_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/comparizone/rulesetContent',data,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: commonActions.GET_RULESETCONTENT_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: commonActions.GET_RULESETCONTENT_FAILURE, payload: error, error: true }));
   }
}


//--------- Load Query List-----------

export function loadQueryList(orgId){
  return function(dispatch){
    dispatch({ type: commonActions.GET_QUERY_LIST_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/manageDb/getDbQuery?orgId='+orgId,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: commonActions.GET_QUERY_LIST_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: commonActions.GET_QUERY_LIST_FAILURE, payload: error, error: true }));
   }
}


//--------- Load ENV List-----------

export function loadEnvList(){
  return function(dispatch){
    dispatch({ type: commonActions.GET_ENV_LIST_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/getEnvList',{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: commonActions.GET_ENV_LIST_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: commonActions.GET_ENV_LIST_FAILURE, payload: error, error: true }));
   }
}

//--------- Load Data Source List-----------

export function loadDsList(){
  return function(dispatch){
    dispatch({ type: commonActions.GET_DATA_SOURCE_LIST_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/getDatasourceList',{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: commonActions.GET_DATA_SOURCE_LIST_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: commonActions.GET_DATA_SOURCE_LIST_FAILURE, payload: error, error: true }));
   }
}

//--------- Load Users List-----------

export function loadUsersList(){
  return function(dispatch){
    dispatch({ type: commonActions.GET_USER_LIST_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/getUsers',{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: commonActions.GET_USER_LIST_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: commonActions.GET_USER_LIST_FAILURE, payload: error, error: true }));
   }
}


//--------- Load Users List-----------

export function loadUsersListWithParams(groupId,mapState,ignoreRole){
  console.log("groupId,mapState,ignoreRole",groupId,mapState,ignoreRole);
  return function(dispatch){
    dispatch({ type: commonActions.GET_USER_LIST_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/getUsers?userGroupId='+groupId+'&mapped='+mapState+'&excludeRoleId='+ignoreRole,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: commonActions.GET_USER_LIST_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: commonActions.GET_USER_LIST_FAILURE, payload: error, error: true }));
   }
}


//--------- Load Cols for Query -----------

export function loadColsForQuery(query){
  return function(dispatch){
    dispatch({ type: commonActions.GET_COLS_FOR_QUERY_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/manageDb/getQueryCols?dbConnectionId='+query.dbId+'&queryId='+query.queryId,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: commonActions.GET_COLS_FOR_QUERY_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: commonActions.GET_COLS_FOR_QUERY_FAILURE, payload: error, error: true }));
   }
}

//--------- Load Data Source Details -----------

export function loadDsDetails(dsID){
  return function(dispatch){
    dispatch({ type: commonActions.LOAD_DATA_SOURCE_DETAILS_REQUEST });
     axios.get(process.env.REACT_APP_API_URL+'/getDatasourceDetails?datasourceId='+dsID,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: commonActions.LOAD_DATA_SOURCE_DETAILS_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: commonActions.LOAD_DATA_SOURCE_DETAILS_FAILURE, payload: error, error: true }));
   }
}



//--------- Load ENV List-----------

export function loadContextList(orgId){
  return function(dispatch){
    dispatch({ type: commonActions.GET_CONTEXT_LIST_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/getContextList',{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: commonActions.GET_CONTEXT_LIST_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: commonActions.GET_CONTEXT_LIST_FAILURE, payload: error, error: true }));
   }
}


//--------- Load Env Type List -----------

export function loadEnvTypeList(){
  return function(dispatch){
    dispatch({ type: commonActions.GET_ENV_TYPE_LIST_REQUEST });
     axios.get(process.env.REACT_APP_API_URL+'/getEnvTypeList',{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: commonActions.GET_ENV_TYPE_LIST_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: commonActions.GET_ENV_TYPE_LIST_FAILURE, payload: error, error: true }));
   }
}

//--------- Clear Reducers group-----------

export function clearCommonData(){
  return function(dispatch){
    dispatch({ type: commonActions.CLEAR_ALL_DATA})
  }
}
