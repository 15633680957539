import React, {Component} from 'react';
import {Alert} from 'react-bootstrap';

class Banner extends Component {

    handleDismiss() {
      this.props.dismissBanner();
    }


    render() {
      if (this.props.hasAlerts) {
        return (
          <Alert variant={this.props.type} onClose={this.handleDismiss.bind(this)} dismissible>
            <h4>{this.props.header}</h4>
            <p>{this.props.body}  </p>
          </Alert>
        );
      }
      return null;
    }
}


export default Banner;
