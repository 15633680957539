import React, { Component } from 'react';
import ErrorBoundary from '../../components/error-boundary';
import AppUsers from '../users/app-users';
import DatabaseConnection from '../database/database-connection';
import DatabaseEnv from '../database/database-env';
import Context from '../context/context';
import AccessForbidden from '../../components/access-forbidden-container';

import Auth from '../../auth/auth';
import {Col, Row} from 'react-bootstrap';


const auth = new Auth();

class Admin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      appContentContainer:"app-content-container",
      mainPageClass: "page-content select-container in",
      subPageClass: "page-content results-container",
      pageNavContainer:"hide",
      showModule:null,
    };
  }





  componentDidUpdate(prevProps) {
  }



navigateToBegin=()=>{
  this.setState({
    appContentContainer:"app-content-container",
    mainPageClass: "page-content select-container in",
    subPageClass: "page-content results-container",
    pageNavContainer:"hide"
  });
}


hideBackNavigation=()=>{
  this.setState({
    appContentContainer:"app-content-container",
    pageNavContainer:"hide"
  });
}


  goToTop=()=>{
    document.getElementById("app").scrollTo(0,0);
  }

  dropdownSelector = (name,id,value) => {
              this.setState({[name]:value});
          }

  dismissBanner = () => {
                this.setState({showBanner:false});
         }

 snackHandler = (state) =>{
   this.setState({snackbarStatus:state});
 }







 selectModule=(name)=>{
   this.setState({
     showModule:name,
     appContentContainer:"app-content-container in",
     mainPageClass: "page-content select-container",
     subPageClass: "page-content results-container in",
     pageNavContainer:"page-nav-container",
   });
 }



  goBack = (level) => {
                    if(level===0){
                      this.setState({
                        appContentContainer:"app-content-container",
                        mainPageClass: "page-content select-container in",
                        subPageClass: "page-content results-container",
                        pageNavContainer:"hide",
                        showModule:null
                      });
                    } else if (level===1){
                      this.setState({
                        pageNavContainer:"page-nav-container"
                      });
                    } else if (level===2){
                      this.setState({
                        pageNavContainer:"hide"
                      });
                    }
                }


   render() {

     return (
       <div className={this.state.appContentContainer}>



       <div className={this.state.pageNavContainer}>
         <a onClick={this.goBack.bind(this,0)} className={this.state.level0}>&lt; Admin Panel</a>
       </div>



        <div id='' className={this.state.mainPageClass}>
          <h2>Admin Panel</h2>
          <Row className='extendable-height'>

              <Col sm={4} xs={6}>
               <div className='recon-results-card menu-card' onClick={this.selectModule.bind(this,"user")}>
               <span className="material-icons">group</span>
               <h3>Users</h3>
               <div className="desc">Manage users in Skokie.</div>
               </div>
              </Col>

              <Col sm={4} xs={6}>
              <div className='recon-results-card menu-card' onClick={this.selectModule.bind(this,"context")}>
+               <span className="material-icons">picture_in_picture</span>
+               <h3>Workspace</h3>
+               <div className="desc">Manage workspace for your projects or tasks.</div>
               </div>
              </Col>

              <Col sm={4} xs={6}>
              <div className='recon-results-card menu-card' onClick={this.selectModule.bind(this,"env")}>
+                 <span className="material-icons">call_made</span>
+                  <h3>Environment</h3>
+                  <div className="desc">Manage data environment names & codes.</div>
+                  </div>
              </Col>

          </Row>

          {/*  <Row className='extendable-height'>

              <Col sm={4} xs={6}>
+           <div className='recon-results-card menu-card' onClick={this.selectModule.bind(this,"connection")}>
+           <span className="material-icons">storage</span>
+           <h3>Database Connection</h3>
+           <div className="desc">Manage your database connections.</div>
+           </div>
+          </Col>

          </Row> */}

        </div>


        <div className={this.state.subPageClass}>

        {this.state.showModule==="user"? <AppUsers parentNavBar={this.goBack.bind(this)} /> : null}

        {this.state.showModule==="connection"? <DatabaseConnection parentNavBar={this.goBack.bind(this)} /> : null}

        {this.state.showModule==="env"? <DatabaseEnv parentNavBar={this.goBack.bind(this)} /> : null}

        {this.state.showModule==="context"? <Context parentNavBar={this.goBack.bind(this)} /> : null}


        </div>



       </div>
     );
   }
}



export default Admin;
