import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import XLSX from 'xlsx';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import ComponentErrorBoundary from '../../../components/component-error-boundary';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Button from '../../../components/button';
import Preloader from '../../../components/preloader';
import SelectQuery from '../../../components/query-select';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import MenuItem from '@mui/material/MenuItem';

import {connect} from 'react-redux';
import {
  loadQueryList,
  loadColsForQuery
} from '../../../common-redux/common.actions';


let columnValsDisplay=[];
let columnVals=[]

class AddDs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dsName:"",
      dsFields:[],
      dsType:"file",
      properties:"",
      dsNameErrorStatus:false,
      dsNameErrorText:"",
      saveButtonStatus:true,
      formErrorState:[],
      formErrorState:['dsName','dsFields'],
      sourceSelect:"",
      displaySourceFile:"",
      displaySourceQuery:"hide",
      sourceQuery:null,
      uploadFile:null,
      dsFields:[],
      colsForSource:""
    };

    this.props.getQueryList(1);
  }


  componentDidUpdate(prevProps) {

    if(this.props.isLoading !== prevProps.isLoading){
      if(this.props.isLoading.includes('colsforquery')){
            this.setState({colsForSource:<Preloader loading={true} />});
      } else if(this.props.colsForQuery !== prevProps.colsForQuery){
          if(this.props.colsForQuery.length !==0){
          this.formErrorRemoveValue('dsFields');
          this.generateValuesFromQuery();
        } else{
          this.setState({colsForSource:<EmptyState className="margin-top-0" message="Sorry, we couldn't find any fields. Please try another one." />});
        }
      }
    }
 }

 cancelFlow=()=>{
    this.props.cancelFlow();
 }

 saveFlow=()=>{

   let dsObj={
     datasourceId:null,
     datasourceName: this.state.dsName,
     datasourceCols: this.state.dsFields,
     type:this.state.dsType,
     properties:this.state.properties,
     orgId: Number(this.props.orgId)
   }
  this.props.saveFlow(dsObj,'new',this.state.dsName);

 }


 generateValuesFromQuery=()=>{

   columnValsDisplay=[];
   columnVals=[]

   for(let i=0;i<this.props.colsForQuery.length;i++){
                   columnVals.push({ name: this.props.colsForQuery[i].name , value: this.props.colsForQuery[i].name , datatype:"" })
                   columnValsDisplay.push(<MenuItem key={i} value={this.props.colsForQuery[i].name}>{this.props.colsForQuery[i].name}</MenuItem>);
                 }


   let queryProps = {
     queryId:this.state.sourceQuery.queryId,
     queryName:this.state.sourceQuery.name,
   }

   this.setState({
     dsFields:columnVals,
     colsForSource:columnValsDisplay,
     properties:queryProps,
     dsName:this.state.sourceQuery.name
   },()=>{this.checkNameDuplicates(this.state.sourceQuery.name);});


 }


  handleTextfield = (event,item) => {
    this.setState({[item]:event.target.value});
  }

  checkNameDuplicates=(values)=>{
    let hasMatchingName = this.props.dsList.filter(function (item) { return item.datasourceName.toLowerCase() === values.toLowerCase(); });

    if(hasMatchingName.length !=0){

      if(hasMatchingName[0].dsId!==this.props.dsDetails.dsId){
        this.setState({dsNameErrorText:"You used this name already. Please try another.",dsNameErrorStatus:true},()=>{this.enableSaveButton();});
        this.formErrorInsertValue("dsName");
        return false;
      } else{
        this.setState({dsNameErrorText:"",dsNameErrorStatus:false},()=>{this.enableSaveButton();});
        this.formErrorRemoveValue("dsName");
        return true;
      }

    }

    else{
      this.setState({dsNameErrorText:"",dsNameErrorStatus:false},()=>{this.enableSaveButton();});
      this.formErrorRemoveValue("dsName");
      return true;
    }
  }


  formErrorRemoveValue=(attr)=>{
    if (this.state.formErrorState.find(o => o === attr)){
      this.setState({formErrorState:this.state.formErrorState.filter(val=> val !== attr)});
    }
  }

  formErrorInsertValue=(attr)=>{
    if (!this.state.formErrorState.find(o => o === attr)){
      this.setState({formErrorState:[...this.state.formErrorState,attr]});
    }
  }


  validateField=(field)=>{

    let errorStatus=field+"ErrorStatus";
    let errorText=field+"ErrorText";


    if(this.state[field].length>2)
    {
      if(field==="dsName"){
        this.checkNameDuplicates(this.state.dsName);
      } else{
        this.formErrorRemoveValue(field);
        this.setState({[errorStatus]:false,[errorText]:""},()=>{this.enableSaveButton();});
        return true;
      }
    }
    else{

      this.formErrorInsertValue(field);
      this.setState({[errorStatus]:true,[errorText]:"Invalid entry. Enter at least 2 characters."},()=>{this.enableSaveButton();});
      return false;
    }

  }

  handleRadioButton = (event,typeofsource,sourceid) => {

    this.setState({
      sourceSelect: event.target.value,
      dsFields:[],
      colsForSource:""
     });

    if(event.target.value==="1"){
                 //----- db selected-----
                   this.setState({
                      displaySourceQuery: 'show',
                      displaySourceFile: 'hide',
                      dsType:'query'
                       });
                       document.getElementById("inputFile").value=""
                      }
                      else{
                        //----- file selected-----
                      this.setState({
                       displaySourceFile: 'show',
                       dsType:'file'
                       });
                       this.resetDBSelection();
                       }
             }


   resetDBSelection=()=>{
                 this.setState({
                     displaySourceQuery: 'hide',
                     sourceQuery: null
                     });
                }


   dropdownSelector = (name,value) => {
                         this.setState({[name]:value});
                         this.props.getColsForQuery(value)
                     }

 handleChange = (dropdownName,file,inputId) => {
                        const input = document.getElementById(inputId);
                        if(input.files[0] !==undefined){

                           this.parseSelectedFile(input,dropdownName,file,this.generateColumnValuesFromFile);
                       }
                       else {
                         //  do nothing
                       }
                   }

parseSelectedFile=(input,dropdownName,file,callback) =>
                      {
                        var reader = new FileReader();
                        reader.onload = function(e) {
                         var data = e.target.result;
                         columnValsDisplay=[];
                         columnVals=[]
                         let fileArray=[];
                         let headerColumn=[]
                         let wsname
                         let uploadedFileTye=input.files[0].name.split('.').pop().toLowerCase();

                         if(uploadedFileTye==="xls" || uploadedFileTye==="xlsx" || uploadedFileTye==="csv"  ){
                         var workbook = XLSX.read(data, {type: 'binary',sheetRows: 1});
                         wsname = workbook.SheetNames[0];
                         const ws = workbook.Sheets[wsname];
                         const sheetdata = XLSX.utils.sheet_to_json(ws, {header:1});
                         headerColumn=sheetdata[0];
                              }
                         else if(uploadedFileTye==="txt"){
                           //----- txt files-----
                           data.split(/\n|\r/,1).map(function(line){
                                     headerColumn=line.split(/\||\t/);
                             })

                           wsname="";

                         }
                         else{
                           columnVals=[];
                           columnValsDisplay=[<MenuItem value={0}>Invalid file</MenuItem>]
                         }

                         let findDuplicates = headerColumn.filter((item, index) => headerColumn.indexOf(item) != index);

                         if(findDuplicates.length>0){
                           columnVals=[];
                           columnValsDisplay=[<MenuItem key={0} value={0}>File has duplicate fields. Please verify and try again.</MenuItem>]
                         }else{
                           for(let i=0;i<headerColumn.length;i++){
                                           columnVals.push({ name: headerColumn[i] , value: headerColumn[i] , datatype:"" })
                                           columnValsDisplay.push(<MenuItem key={i} value={headerColumn[i]}>{headerColumn[i]}</MenuItem>);
                                         }
                          }

                          callback(dropdownName,file,columnValsDisplay,columnVals,input,wsname);
                         }
                         reader.readAsBinaryString(input.files[0]);
                      }

generateColumnValuesFromFile = (dropdownName,file,columnValsDisplay,columnVals,input,wsname) =>{

                      let fileProps={
                      //  fileName:input.files[0].name.substr(0,input.files[0].name.lastIndexOf('.')),
                          fileName:input.files[0].name,
                        fileType:input.files[0].name.substr(input.files[0].name.lastIndexOf('.')+1,input.files[0].name.length),
                        sheetName:wsname
                      }

                       if(columnVals.length > 0){
                            this.formErrorRemoveValue(file);
                            this.setState({
                              dsName:input.files[0].name.substr(0,input.files[0].name.lastIndexOf('.')),
                              properties:fileProps
                            });
                            this.checkNameDuplicates(input.files[0].name.substr(0,input.files[0].name.lastIndexOf('.')));
                        }
                          else {
                            this.formErrorInsertValue(file);
                        }
                        this.setState({[dropdownName]:columnValsDisplay,[file]:columnVals},()=>{this.enableSaveButton();});
                      }

  enableSaveButton=()=>{
    if(this.state.formErrorState.length===0)
      {
      this.setState({saveButtonStatus:false});
      } else{
      this.setState({saveButtonStatus:true});
      }
}











   render() {

     const {isLoading} = this.props;
     let pageContent;


     return (
       <div>
       <Row>
        <Col sm={6} xs={12}>

        <div className='rounded-card'>

        <Row>
         <Col sm={12} xs={12}>

        {/*
        <div className="font-16 margin-bottom-10">What is the input for your data source?</div>
        <RadioGroup aria-label="position" className="radio-group" name="position" value={this.state.sourceSelect} onChange={(e)=>this.handleRadioButton(e,'sourceSelect','1')} row>
             <FormControlLabel
               value="0"
               control={<Radio color="primary" />}
               label={'File'}
               labelPlacement="end"
             />

             <FormControlLabel
               value="1"
               control={<Radio color="primary" />}
               label={'Query'}
               labelPlacement="end"
             />
         </RadioGroup>
        */}

        <div className="font-16 margin-bottom-10">Choose a file to create yout data source?</div>
        <div className={this.state.displaySourceFile}>
           <input type="file" id="inputFile" onChange={this.handleChange.bind(this,"colsForSource","dsFields","inputFile")} />
         </div>


         <div className={this.state.displaySourceQuery}>
           <SelectQuery
           label='Select a query'
           fileColumns={this.props.queryList}
           onSelectValue={this.dropdownSelector.bind(this,'sourceQuery')}
           value={this.state.sourceQuery} />
        </div>
        </Col>
      </Row>

      <Row>
        <Col sm={12} xs={12}>
       <ComponentErrorBoundary value="Data Source Name">
        <FormControl fullWidth>
         <InputLabel htmlFor="Env Name">Data Source Name</InputLabel>
         <Input
           type="text"
           value={this.state.dsName}
           onChange={(e)=>this.handleTextfield(e,"dsName")}
           error={this.state.dsNameErrorStatus}
           onBlur={this.validateField.bind(this,"dsName")}
         />
         <FormHelperText className="Mui-error">{this.state.dsNameErrorText}</FormHelperText>
        </FormControl>
        </ComponentErrorBoundary>
        </Col>
      </Row>


       </div>

       <Row>

         <Col sm={12} xs={12} className='margin-top-30'>

          <Button label="Save" className='btn btn-action' disabled={this.state.saveButtonStatus} onClick={this.saveFlow.bind(this)}  />

           <Button label="Cancel" className='btn btn-default' onClick={this.cancelFlow.bind(this)} />

         </Col>
        </Row>
      </Col>


         <Col sm={4} xs={12}>
              {
                this.state.colsForSource !== "" ?
                <div className='rounded-card'>
                 <h3>Fields from the Source</h3>
                 <div className='fields-from-source'>{this.state.colsForSource}</div>
                 </div> : null
              }


         </Col>




       </Row>
       </div>

     );
   }
}


const mapStateToProps = (state) => {
  return{
    isLoading: state.databaseReducer.isLoading.concat(state.commonReducer.isLoading),
    errors: state.databaseReducer.errorMessage,
    queryList: state.commonReducer.queryList,
    colsForQuery: state.commonReducer.colsForQuery,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getQueryList : (orgId) => {
      dispatch(loadQueryList(orgId));
    },
    getColsForQuery : (query) => {
      dispatch(loadColsForQuery(query));
    }


  }
}


export default connect(mapStateToProps,mapDispatchToProps) (AddDs);
