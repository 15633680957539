import {createStore, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
//import {createEpicMiddleware, combineEpics} from 'redux-observable';


import {commonReducer} from './common-redux/common.reducers';
import {loginReducer} from './pages/login/login.reducers';
import {reconReducer} from './pages/recon/recon.reducers';
import {reconResultsReducer} from './pages/recon-results/recon-results.reducers';
import {analyticsReducer} from './pages/analytics/analytics.reducers';
import {impactorReducer} from './pages/impactor/impactor.reducers';
import {settingsReducer} from './pages/settings/settings.reducers';
import {profileReducer} from './pages/profile/profile.reducers';
import {usersReducer} from './pages/users/users.reducers';
import {databaseReducer} from './pages/database/database.reducers';
import {contextReducer} from './pages/context/context.reducers';


const rootReducer = combineReducers({
	commonReducer,
	loginReducer,
	reconReducer,
	reconResultsReducer,
	analyticsReducer,
	impactorReducer,
	settingsReducer,
	profileReducer,
	usersReducer,
	databaseReducer,
	contextReducer
});



const appStore = createStore(rootReducer, applyMiddleware(thunk));

export default appStore;
