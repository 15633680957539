import React, { Component } from 'react';
import ErrorBoundary from '../../components/error-boundary';
import DatabaseQuery from '../database/database-query';
import DatabaseConnection from '../database/database-connection';
import AppUserGroups from '../users/app-user-groups';
import DataSource from '../database/data-sources';
import EmptyState from '../../components/empty-state-container';

import Auth from '../../auth/auth';
import {Col, Row} from 'react-bootstrap';


const auth = new Auth();
const { getContext } = auth;

class SuperUserPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      appContentContainer:"app-content-container",
      mainPageClass: "page-content select-container in",
      subPageClass: "page-content results-container",
      pageNavContainer:"hide",
      showModule:null,
    };
  }





  componentDidUpdate(prevProps) {
  }



navigateToBegin=()=>{
  this.setState({
    appContentContainer:"app-content-container",
    mainPageClass: "page-content select-container in",
    subPageClass: "page-content results-container",
    pageNavContainer:"hide"
  });
}


hideBackNavigation=()=>{
  this.setState({
    appContentContainer:"app-content-container",
    pageNavContainer:"hide"
  });
}


  goToTop=()=>{
    document.getElementById("app").scrollTo(0,0);
  }

  dropdownSelector = (name,id,value) => {
              this.setState({[name]:value});
          }

  dismissBanner = () => {
                this.setState({showBanner:false});
         }

 snackHandler = (state) =>{
   this.setState({snackbarStatus:state});
 }







 selectModule=(name)=>{
   this.setState({
     showModule:name,
     appContentContainer:"app-content-container in",
     mainPageClass: "page-content select-container",
     subPageClass: "page-content results-container in",
     pageNavContainer:"page-nav-container",
   });
 }



  goBack = (level) => {
                    if(level===0){
                      this.setState({
                        appContentContainer:"app-content-container",
                        mainPageClass: "page-content select-container in",
                        subPageClass: "page-content results-container",
                        pageNavContainer:"hide",
                        showModule:null
                      });
                    } else if (level===1){
                      this.setState({
                        pageNavContainer:"page-nav-container"
                      });
                    } else if (level===2){
                      this.setState({
                        pageNavContainer:"hide"
                      });
                    }
                }


   render() {

     const currentContext = getContext();

     if(currentContext.userType=== 'SuperUser'){
     return (
       <div className={this.state.appContentContainer}>



       <div className={this.state.pageNavContainer}>
         <a onClick={this.goBack.bind(this,0)} className={this.state.level0}>&lt; Super User Panel</a>
       </div>



        <div id='' className={this.state.mainPageClass}>
          <h2>Super User Panel</h2>
          <Row className='extendable-height'>

           <Col sm={4} xs={6}>
               <div className='recon-results-card menu-card' onClick={this.selectModule.bind(this,"connection")}>
               <span className="material-icons">storage</span>
               <h3>Database Connection</h3>
               <div className="desc">Manage your database connections.</div>
               </div>
              </Col>

              <Col sm={4} xs={6}>
               <div className='recon-results-card menu-card' onClick={this.selectModule.bind(this,"query")}>
               <span className="material-icons">code</span>
               <h3>Database Queries</h3>
               <div className="desc">Manage your database queries.</div>
               </div>
              </Col>

              <Col sm={4} xs={6}>
               <div className='recon-results-card menu-card' onClick={this.selectModule.bind(this,"datasource")}>
               <span className="material-icons">source</span>
               <h3>Data Source</h3>
               <div className="desc">Manage your data source used for rulesets.</div>
               </div>
              </Col>
          <Col sm={4} xs={6}>
           <div className='recon-results-card menu-card' onClick={this.selectModule.bind(this,"usergroup")}>
           <span className="material-icons">group_add</span>
           <h3>User Groups</h3>
           <div className="desc">Manage your users & user groups.</div>
           </div>
          </Col>
          </Row>
        </div>


        <div className={this.state.subPageClass}>

        {this.state.showModule==="query"? <DatabaseQuery parentNavBar={this.goBack.bind(this)} /> : null}

        {this.state.showModule==="connection"? <DatabaseConnection parentNavBar={this.goBack.bind(this)} /> : null}

        {this.state.showModule==="usergroup"? <AppUserGroups parentNavBar={this.goBack.bind(this)} /> : null}

        {this.state.showModule==="datasource"? <DataSource parentNavBar={this.goBack.bind(this)} /> : null}




        </div>



       </div>
     );
   } else{
     return (<div><EmptyState message="You do not have any access to this page." /></div>)
   }
   }
}



export default SuperUserPanel;
