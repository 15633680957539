import React, {Component} from 'react';
import { Switch, Route } from "react-router-dom";
import { Redirect } from 'react-router';
import SecuredLayout from './layout/secured-layout'
import AdminLayout from './layout/admin-layout'
import SecuredLayoutWOMenu from './layout/secured-layout-wo-menu'
import Author from './auth/auth';

import Login from './pages/login/login';
import Reconciliation from './pages/recon/recon';
import Analytics from './pages/analytics/analytics';
import Impactor from './pages/impactor/impactor';
import RuleSets from './pages/settings/rulesets';
import Profile from './pages/profile/profile';
import SuperUserPanel from './pages/super-user-panel/super-user';
import Admin from './pages/admin-panel/admin';
import ContextCheck from './pages/context/context-check';

const auth = new Author();
const { isAuthenticated } = auth;

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => isAuthenticated()===true
    ? ( <Layout> <Component  auth={auth} {...props} /></Layout> )
    : ( <Redirect to="/login" /> )
  } />
)


// const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
//   <Route {...rest} render={props =>   ( <Layout> <Component  auth={auth} {...props} /></Layout> )
//   } />
// )

class Main extends Component{
  render(){

    return(
      <main>
          <Switch>
            <Route path="/login" render={(props) => <Login auth={auth} {...props} />} />
            <AppRoute path='/comparizone' layout={SecuredLayout} component={Reconciliation} />
            <AppRoute path='/analytics' layout={SecuredLayout} component={Analytics} />
            <AppRoute path='/impactor' layout={SecuredLayout} component={Impactor} />
            <AppRoute path='/rulesets' layout={SecuredLayout} component={RuleSets} />
            <AppRoute path='/profile' layout={SecuredLayout} component={Profile} />
            <AppRoute path='/superuser' layout={SecuredLayout} component={SuperUserPanel} />
            <AppRoute path='/admin' layout={AdminLayout} component={Admin} />
            <AppRoute path='/adminprofile' layout={AdminLayout} component={Profile} />
            <AppRoute path='/context' layout={SecuredLayoutWOMenu} component={ContextCheck} />
            <AppRoute path='/*' layout={SecuredLayout} component={Reconciliation} />
          </Switch>
      </main>
    )
  }
}

export default Main;
