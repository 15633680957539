import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import ComponentErrorBoundary from '../../../components/component-error-boundary';

export default function Tags(props) {
  return (
    <div>
    <ComponentErrorBoundary value="field selector autocomplete">
      <Autocomplete
        autoHighlight
        disableClearable={true}
        options={props.fileColumns}
        filterSelectedOptions
        filterOptions={(option)=>{return(option.filter( (option)=>{return option.origin!=="Operation" && option.origin!=="custom"} ))}}
        getOptionLabel={option => option.label + ' ('+option.origin+')'}
        value={props.value}
        onChange={(event, newValue) => {props.onSelectValue(newValue);}}
        onBlur={()=>{props.checkRuleCompleteness();}}
        renderInput={params => {
          return (
            <TextField
              {...params}
              variant="standard"
              label={props.label}
              margin="normal"
              fullWidth
            />
          );
        }}
      />
     </ComponentErrorBoundary>
    </div>
  );
}
