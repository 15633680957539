import React, { Component } from 'react';
import Preloader from '../../../components/preloader';
import TextField from '@mui/material/TextField';
import {Col, Row} from 'react-bootstrap';
import Modal from '../../../components/modal';
import Button from '../../../components/button';
import RuleContent from './rule-content';
import RulesetPrimaryDetails from './ruleset-primary-details';
import EmptyState from '../../../components/empty-state-container';
import RulePrimaryKeyAutocomplete from './primaryid-autocomplete';
import FieldsList from './fields-list';
import ErrorBoundary from '../../../components/error-boundary';
import ComponentErrorBoundary from '../../../components/component-error-boundary';


let hasChanged,hasValidated;
let deletedRules=[];

class EditRuleConfigSetting extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fileColumns:[],
      firstfile:[],
      firstfilePrimaryId:[],
      secondfile:[],
      secondfilePrimaryId:[],
      createNew:"",
      rulesetContent:"hide",
      ruleSetID:"",
      ruleSetName:"",
      RulesetnameErrorState:false,
      RulesetnameError:"",
      rulesContent: [],
      rules: [],
      addRuleButtonStatus:false,
      saveButtonStatus:true,
      ruleNameArray:[],
      modalOpen:false,
      modalTitle:"",
      modalContent:"",
      rulesetPrimaryDetails:{ rulesetName:"", ruleContent: [], valueMap: [], fieldConfig: [], status:"changed"},
      RulesetnameErrorText:"",
      ruleFilterCriteria:""

    };
  }



  componentDidUpdate(prevProps) {
    if(prevProps.reconEditRule !== this.props.reconEditRule){
      if(this.props.reconEditRule==="page-content results-container"){
        this.resetRulesListForm();
      }
    }


    if (this.props.isLoading !== prevProps.isLoading && this.props.actionTriggered===false ) {
      if(this.props.reconEditRule==="page-content results-container in" && this.props.ruleSetContent.id !== undefined){
      let rules=[];
      for(var i=0;i<this.props.ruleSetContent.rulesContent.length;i++){
        if(this.props.ruleSetContent.rulesContent[i].uniqueKey===0){
        rules.push(this.props.ruleSetContent.rulesContent[i].id);
        this.setState({
           [this.props.ruleSetContent.rulesContent[i].id]:{
             id:this.props.ruleSetContent.rulesContent[i].id,
             ruleName:this.props.ruleSetContent.rulesContent[i].ruleName,
             ruleContent:this.props.ruleSetContent.rulesContent[i].ruleContent,
             threshold:this.props.ruleSetContent.rulesContent[i].threshold,
             valueMap:this.props.ruleSetContent.rulesContent[i].valueMap,
             fieldConfig:this.props.ruleSetContent.rulesContent[i].fieldConfig,
             status:"saved"
           }
         });
       } else if(this.props.ruleSetContent.rulesContent[i].uniqueKey===1){
         let rulesetPrimaryDetails={
           id: this.props.ruleSetContent.rulesContent[i].id,
           rulesetName:this.props.ruleSetContent.rulesetName,
           ruleContent: this.props.ruleSetContent.rulesContent[i].ruleContent,
           valueMap: this.props.ruleSetContent.rulesContent[i].valueMap,
           fieldConfig: this.props.ruleSetContent.rulesContent[i].fieldConfig,
           status:"saved"
         }
         this.setState({rulesetPrimaryDetails});
       }
      }
      this.setState({
        ruleSetID:this.props.ruleSetContent.id,
        ruleSetName:this.props.ruleSetContent.rulesetName,
        fileColumns:this.props.ruleSetContent.fileColumnsMerged,
        rules:rules,
        saveButtonStatus:true
      },()=>{this.updateRulename();})
     }
    }



if (this.props.fieldsUpdate !== prevProps.fieldsUpdate && this.props.fieldsUpdate===true){
  let renderedRulesContent=[];
  this.state.rules.forEach((ruleItem)=>{ renderedRulesContent.push(this.state[ruleItem]);  });
  this.setState({rulesContent:renderedRulesContent});
 }

}


resetRulesListForm=()=>{
  this.setState({
    fileColumns:[],
    firstfile:[],
    firstfilePrimaryId:[],
    secondfile:[],
    secondfilePrimaryId:[],
    createNew:"",
    rulesetContent:"hide",
    ruleSetID:"",
    ruleSetName:"",
    RulesetnameErrorState:false,
    RulesetnameError:"",
    rulesContent: [],
    rules: [],
    addRuleButtonStatus:false,
    saveButtonStatus:true,
    ruleNameArray:[],
    source1FieldsChanged:false,
    source2FieldsChanged:false
  });

}

updateSourceFields=(sourceid,sourceFields,combinedFields)=>{

  switch (sourceid){
    case "source1":
    this.setState({firstfile:sourceFields,fileColumns:combinedFields,source1FieldsChanged:true});
    break;

    case "source2":
    this.setState({secondfile:sourceFields,fileColumns:combinedFields,source2FieldsChanged:true});
    break;
}
  this.checkSaveButtonStatus();


}


  saveConfig=()=>{
    let updatedRules=[];
    let modifiedFile1column=[];
    let modifiedFile2column=[];
    let modifiedFileColumnsMerged=[];

    let itemName,itemVal;
    for(let i=0; i< this.state.rules.length; i++)
    {
      itemName=this.state.rules[i];
      if(this.state[itemName].status !=="saved"){
          if(String(this.state[itemName].id).substring(0, 2)!=="n_"){
            itemVal={id:'u_'+this.state[itemName].id,ruleName:this.state[itemName].ruleName,ruleContent:this.state[itemName].ruleContent,threshold:this.state[itemName].threshold,valueMap:this.state[itemName].valueMap,fieldConfig:this.state[itemName].fieldConfig,status:"validated"};
            updatedRules.push(itemVal);
          }
          else{
            updatedRules.push(this.state[itemName]);
          }
      }
    }

    var finalRules=updatedRules.concat(deletedRules);

    let primaryKeyDetails = this.state.rulesetPrimaryDetails

    if(primaryKeyDetails.status==="validated"){

      primaryKeyDetails.id='u_'+primaryKeyDetails.id;

    }

    let ruleObj={
      id:this.state.ruleSetID,
      rulesetName: this.state.rulesetPrimaryDetails.rulesetName,
      rulesetPrimaryDetails:primaryKeyDetails,
      rules:finalRules
    }
    console.log("EditRuleObj",ruleObj);
    this.props.saveFlow(ruleObj,'edit');
  }



  cancelConfig=()=>{
     this.props.cancelFlow();
  }

  handleChange = (dropdownName,file,inputId) => {
     const input = document.getElementById(inputId);
     if(input.files[0] !==undefined){
         this.parseSelectedFile(input,dropdownName,file,this.generateColumnValues);
    }
    else {
      //  do nothing
    }
}



addNewRule=()=> {
        var newRule = 'n_'+Date.now();
        this.setState({
           [newRule]:{id:newRule,ruleName:"",ruleContent:[],threshold:"",valueMap:[],fieldConfig:[],status:"changed"},
           addRuleButtonStatus:true,
           saveButtonStatus:true,
         });
        this.setState(prevState => ({ rules: prevState.rules.concat([newRule]) }));
       }

deleteRuleConfirmed=(ruleid)=> {
      if(String(this.state[ruleid].id).substring(0,2)!=="n_"){
        this.setState({[ruleid]:{id:'d_'+this.state[ruleid].id,ruleName:this.state[ruleid].ruleName,ruleContent:this.state[ruleid].ruleContent,threshold:this.state[ruleid].threshold,valueMap:this.state[ruleid].valueMap,fieldConfig:this.state[ruleid].fieldConfig,status:"deleted"}},()=>{
          this.checkSaveButtonStatus();
          deletedRules.push(this.state[ruleid]);
        });
      }
      var rulesArray = [...this.state.rules];
      var index = rulesArray.indexOf(ruleid)
        if (index !== -1) {
        rulesArray.splice(index, 1);
        this.setState({rules: rulesArray},()=>{this.checkStatus();});
        }


      var ruleNameArray = [...this.state.ruleNameArray]


      var ruleNameIndex = ruleNameArray.findIndex(x => x.ruleName === this.state[ruleid].ruleName )
        if (ruleNameIndex !== -1) {
        ruleNameArray.splice(ruleNameIndex, 1);
        this.setState({ruleNameArray: ruleNameArray});
        }
}



massDeleteRule=(rules)=> {

  var rulesArray = [...this.state.rules];
  var ruleNameArray = [...this.state.ruleNameArray]

  rules.forEach((ruleItems)=>{

    if(String(this.state[ruleItems.id].id).substring(0,2)!=="n_"){
      this.setState({[ruleItems.id]:{id:'d_'+this.state[ruleItems.id].id,ruleName:this.state[ruleItems.id].ruleName,ruleContent:this.state[ruleItems.id].ruleContent,threshold:this.state[ruleItems.id].threshold,valueMap:this.state[ruleItems.id].valueMap,fieldConfig:this.state[ruleItems.id].fieldConfig,status:"deleted"}},()=>{
        deletedRules.push(this.state[ruleItems.id]);
      });
    }


    var index = rulesArray.indexOf(ruleItems.id)
      if (index !== -1) {
      rulesArray.splice(index, 1);
      }




      var ruleNameIndex = ruleNameArray.findIndex(x => x.ruleName === this.state[ruleItems.id].ruleName )
        if (ruleNameIndex !== -1) {
        ruleNameArray.splice(ruleNameIndex, 1);
        }


  });



  this.setState({rules: rulesArray, ruleNameArray: ruleNameArray},()=>{this.checkStatus();});
  this.checkSaveButtonStatus();


}


deleteRule = (ruleid) => {
            if(this.state[ruleid].ruleName==="" && this.state[ruleid].ruleContent.length===0){
              this.deleteRuleConfirmed(ruleid);
              this.setState({saveButtonStatus:false});
            }
            else{
              this.setState({
                modalOpen:true,
                modalTitle:"Delete the rule",
                modalContent:"Are you sure you want to delete \""+this.state[ruleid].ruleName+"\"?",
                deleteRuleVal:ruleid
              });

            }
  }


modalClose=()=>{   this.setState({ modalOpen:false }); }

modalConfirm=()=>{
          this.setState({
            modalOpen:false
          });
          this.deleteRuleConfirmed(this.state.deleteRuleVal);
        }





checkStatus=()=>{
  hasChanged=false;
  hasValidated=false;


  for(var i=0;i<this.state.rules.length;i++){
    var ruleId=this.state.rules[i];
    if(this.state[ruleId].status==="changed"){
      hasChanged=true;
      break;
    }
    else if(this.state[ruleId].status==="validated") {
      hasValidated=true;
      break;
    }
    else{

    }
  }

  if(hasChanged===true){
    this.setState({addRuleButtonStatus:true});
  } else if(hasValidated===true){
    this.setState({addRuleButtonStatus:false},()=>{this.checkSaveButtonStatus();});
  }
  else{
    this.setState({addRuleButtonStatus:false});
  }
}


checkSaveButtonStatus=()=>{
  if(this.state.rulesetPrimaryDetails.status==="validated" || this.state.rulesetPrimaryDetails.status==="saved" && this.state.addRuleButtonStatus===false){
      this.setState({saveButtonStatus:false});
  }
  else{
    this.setState({saveButtonStatus:true});
  }
}


updateValues=(value)=> {
    this.setState({
       [value.id]:{id:value.id,ruleName:value.ruleName,ruleContent:value.ruleContent,threshold:value.threshold,valueMap:value.valueMap,fieldConfig:value.fieldConfig,status:value.status}
     },()=>{this.checkStatus();});
  }

  updateRulename=()=>{
     var ruleNameArray=[]
      for(var i=0;i<this.state.rules.length;i++){
        var ruleId=this.state.rules[i];
        ruleNameArray.push({id:this.state[ruleId].id,ruleName:this.state[ruleId].ruleName});
      }
      this.setState({ruleNameArray});
    }

  handleRulesetName = (event) => {
    let hasMatch=false;
    //this.updateValueSet(item,event.target.value);
    for(var i=0;i<this.props.ruleSetList.length;i++){
      if(this.props.ruleSetList[i].name.toLowerCase()===event.target.value.toLowerCase()){
        hasMatch=true;
        break;
      }
    }


    if(hasMatch===true){
      this.setState({RulesetnameErrorState:true,RulesetnameError:"There is a matching name. Please try a different one.",ruleSetName:event.target.value});
    }else{
      this.setState({RulesetnameErrorState:false,RulesetnameError:"",ruleSetName:event.target.value},()=>{this.checkSaveButtonStatus();});
    }
  }


 selectPrimaryIdValue = (fileid,value) =>{
   this.setState({[fileid]:value},()=>{this.checkSaveButtonStatus();});
 }

 updateRulesetPrimaryDetails=(value)=>{
   this.setState({
     rulesetPrimaryDetails:value
   },()=>{this.checkSaveButtonStatus();});

 }




   render() {

     const {isLoading} = this.props;
     let editRulesetContent,ruleList,ruleList1;
     const filterCriteria=this.state.ruleFilterCriteria;
     ruleList=[];

     ruleList=this.state.rules.filter(function (e) {
       return this.state[e].ruleName.toLowerCase().includes(filterCriteria.toLowerCase());
     },this)


     //ruleList=this.state.rules.filter(function (e) {  return this.state[e].ruleName.toLowerCase().includes(filterCriteria.toLowerCase()); } )
     if(ruleList.length!==0){
     var rulesContentVar=ruleList.map(rule => <RuleContent key={rule}
       value={this.state[rule]}
       fileColumns={this.state.fileColumns}
       updateValues={this.updateValues.bind(this)}
       deleteRule={this.deleteRule.bind(this)}
       updateRulename={this.updateRulename.bind(this)}
       ruleNameArray={this.state.ruleNameArray}
       toggleBackdrop={this.props.toggleBackdrop}
       />);
   }
   else{
     rulesContentVar=<div className='rounded-card'><EmptyState message="No rules found." /></div>
   }


     if(isLoading.includes('rulsetcontent')){
       editRulesetContent=<Preloader loading={true} /> ;
     }
     else{

       editRulesetContent=<div>
       <Row className=''>
           <Col sm={2} xs={12}>
            <div className='recon-results-card text-center'>
              <h3>Total rules</h3>
              <div>{this.state.rules.length}</div>
            </div>
           </Col>

           <Col sm={6} xs={12}>
           <ComponentErrorBoundary value="Search rules">
           <TextField
             label="Search rules"
             fullWidth
             value={ this.state.ruleFilterCriteria }
             onChange={ e => this.setState({ ruleFilterCriteria:e.target.value }) }
             variant="standard"
           />
           </ComponentErrorBoundary>
           </Col>
          </Row>


             <div className='rules-bucket'> {rulesContentVar} </div>
             <Row> <Col sm={12} xs={12} className='text-right btn-container'>
              <Button label="Add new rule" className='btn btn-action' disabled={this.state.addRuleButtonStatus}  onClick={this.addNewRule.bind()}  />
             </Col> </Row>

             <div className='rules-bucket'>
                <RulesetPrimaryDetails
                ruleSetList={this.props.ruleSetList}
                rulesetPrimaryDetails={this.state.rulesetPrimaryDetails}
                fileColumns={this.state.fileColumns}
                toggleBackdrop={this.props.toggleBackdrop}
                updateRulesetPrimaryDetails={this.updateRulesetPrimaryDetails.bind(this)}
                />
             </div>




             <Row>
             <Col sm={12} xs={12} className='text-right btn-container'>
              <Button label="Cancel" className='btn btn-default' onClick={this.cancelConfig.bind(this)} />

              <Button label="Finish" className='btn btn-action' disabled={this.state.saveButtonStatus} onClick={this.saveConfig.bind(this)}  />

             </Col>

             </Row>
       </div>;

     }


     let pageContent;

     this.props.fieldsUpdate ? pageContent = <ErrorBoundary>
        <FieldsList
          ruleSetContent={this.props.ruleSetContent}
          fieldsUpdate={this.props.fieldsUpdate}
          fileColumnsMerged={this.state.fileColumns}
          file1column={this.state.firstfile}
          file2column={this.state.secondfile}
          file1primary={this.state.firstfilePrimaryId}
          file2primary={this.state.secondfilePrimaryId}
          rulesContent={this.state.rulesContent}
          cancelFlow={this.cancelFlow}
          saveFlow={this.saveFlow}
          reconEditRule={this.state.reconEditRule}
          updateValues={this.updateValues.bind(this)}
          selectPrimaryIdValue={this.selectPrimaryIdValue.bind(this)}
          updateSourceFields={this.updateSourceFields.bind(this)}
          deleteRule={this.massDeleteRule.bind(this)}
          modifySourceFields={this.props.modifySourceFields}
          />
    </ErrorBoundary> :
     pageContent= editRulesetContent;

     return (
       <div>
           <Modal
            open={this.state.modalOpen}
            title={this.state.modalTitle}
            content={this.state.modalContent}
            primaryButton="Yes"
            secondaryButton="No"
            close={this.modalClose}
            confirm={this.modalConfirm}
            />

            {pageContent}
       </div>

     );
   }
}

export default EditRuleConfigSetting;
