import React, { Component } from 'react';
import ReconciliationExecute from './components/recon-execute';
import ReconciliationResults from './components/recon-results';
import ErrorBoundary from '../../components/error-boundary';
import Snackbar from '@mui/material/Snackbar';
import Banner from '../../components/banner';
import {connect} from 'react-redux';
import io from 'socket.io-client';
import {
      loadRecon,
      loadSelectedReconSummary,
      loadSelectedReconResults,
      loadSelectedReconExceptions,
      loadReconList,
      reloadReconList,
      clearAllData
    } from './recon.actions';

import {
          loadRuleSet
        } from '../../common-redux/common.actions';

let socket;

class Reconciliation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      appContentContainer:"app-content-container",
      reconQuestionsClass: "page-content select-container in",
      reconResultsClass: "page-content results-container",
      pageNavContainer:"hide",
      ruleListValue:"",
      step1:"",
      step2:"hide",
      reconSelectedRecord:"",
      snackbarStatus: false,
      bannerType: "danger",
      showBanner: false,
      pageState:0
    };

      // socket = io("http://localhost:5000/comparizone", {transports: ['websocket']});
      socket = io("/comparizone", {transports: ['websocket']});
      this.props.getRuleSetListItems();
      this.props.getReconListItems();
  }


  componentDidMount(){

    socket.on('connect', (value) => {
      socket.emit('reloadReconlist', { customId: localStorage.getItem('access_token') });
    });

    socket.on('reloadReconlist', (value) => {
        this.props.reloadReconItems(value);
    });

    socket.on('disconnect', function(){
      socket.disconnect();
    });
  }

  componentWillUnmount(){
    socket.disconnect();
    this.props.clearAllData();
  }

  componentDidUpdate(prevProps) {
  if (this.props.error !== prevProps.error) {
    this.setState({showBanner:true});
  }
}


  goToTop=()=>{
    document.getElementById("app").scrollTo(0,0);
  }

  dropdownSelector = (name,id,value) => {
              this.setState({[name]:value});
          }

  dismissBanner = () => {
                this.setState({showBanner:false});
         }

 snackHandler = (state) =>{
   this.setState({snackbarStatus:state});
 }

  selectRecon = (data) => {
                    this.setState({
                      pageState:1,
                      appContentContainer:"app-content-container in",
                      reconQuestionsClass: "page-content select-container",
                      reconResultsClass: "page-content results-container in",
                      pageNavContainer:"page-nav-container",
                      filterValue:"all",
                      reconSelectedRecord:data
                    });
                    this.props.getReconItems(data);
                    //this.props.getReconSummary(data);
                    //this.props.getReconResults(data);

                    this.goToTop();
                }


 getReconExceptionData = () =>{
      this.props.getReconExceptions(this.state.reconSelectedRecord)
    }

  goBack = (level) => {
                    if(level===0){
                      this.setState({
                        pageState:0,
                        appContentContainer:"app-content-container",
                        reconQuestionsClass: "page-content select-container in",
                        reconResultsClass: "page-content results-container",
                        pageNavContainer:"hide"
                      });
                    } else if (level===1){
                      this.setState({
                        pageState:1,
                        pageNavContainer:"page-nav-container"
                      });
                    } else if (level===2){
                      this.setState({
                        pageNavContainer:"hide"
                      });
                    }
                }


   render() {


     return (
       <div className={this.state.appContentContainer}>

       <Snackbar
           open={this.state.snackbarStatus}
           message="Please wait..."
           className="snack-bar"
         />



          <div className={this.state.pageNavContainer}>
            <a onClick={this.goBack.bind(this,0)} className={this.state.level0}>&lt; CompariZone</a>
          </div>


       <Banner
          type={this.state.bannerType}
          hasAlerts={this.state.showBanner}
          header="Oh snap! Something went wrong."
          body="Please refresh the page and try again."
          dismissBanner={this.dismissBanner.bind(this)}
          />


        <div id='' className={this.state.reconQuestionsClass}>
          <h2>CompariZone</h2>
            <ReconciliationExecute
            onClick={this.selectRecon.bind(this)}
            ruleSetList={this.props.ruleSetList}
            reconList={this.props.reconList}
            />
        </div>

  {
    this.state.pageState === 1 ?
        <div id='' className={this.state.reconResultsClass}>
          <ErrorBoundary>
          <ReconciliationResults
            reconSelectedRecord={this.state.reconSelectedRecord}
            snackHandler={this.snackHandler.bind(this)}
            pageNavContainer={this.state.pageNavContainer}
            getReconExceptions={this.getReconExceptionData.bind(this)}
            parentNavBar={this.goBack.bind(this)}
            />
          </ErrorBoundary>
        </div> : null
  }


       </div>
     );
   }
}

const mapStateToProps = (state) => {
  return{
    error: state.reconReducer.error,
    ruleSetList: state.commonReducer.ruleSetList,
    reconList: state.reconReducer.reconList,
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    getReconItems : (filename) => {
      dispatch(loadRecon(filename));
    },
    getReconSummary : (filename) => {
      dispatch(loadSelectedReconSummary(filename));
    },
    getReconResults : (filename) => {
      dispatch(loadSelectedReconResults(filename));
    },
    getReconExceptions : (filename) => {
      dispatch(loadSelectedReconExceptions(filename));
    },
    reloadReconItems : (json) => {
      dispatch(reloadReconList(json));
    },
    getReconListItems : () => {
      dispatch(loadReconList());
    },
    getRuleSetListItems : () => {
      dispatch(loadRuleSet());
    },
    clearAllData:()=>{
      dispatch(clearAllData());
    }
  }
}


export default connect(mapStateToProps,mapDispatchToProps) (Reconciliation);
