import React, { Component } from 'react';
import TrendSummary from './components/trend-summary';
import RunsOverview from './components/runs-overview';
import Overview from './components/overview';
import {Col, Row} from 'react-bootstrap';
import Snackbar from '@mui/material/Snackbar';
import Banner from '../../components/banner';
import {connect} from 'react-redux';
import {
      loadMostFreqRuleset,
      loadMostDvpRuleset,
      loadRulesetTrend,
      loadTotalRuleset,
      loadTotalRunsToday,
      loadTotalRunsPastTime
    } from './analytics.actions';




let trendData=[
    {
      rulesetName:"HFPOS",
      runs:[
        {
          date:"Jun 21, 2021",
          passedRules: 12,
          failedRules:8,
          passedRecords:10,
          failedRecords:12,
          exceptionRecords:22,
          passedDvp:10000,
          failedDvp:7000,
        },
        {
          date:"Jun 20, 2021",
          passedRules: 10,
          failedRules:7,
          passedRecords:10,
          failedRecords:12,
          exceptionRecords:22,
          passedDvp:10000,
          failedDvp:7000,
        },
        {
          date:"Jun 15, 2021",
          passedRules: 8,
          failedRules:6,
          passedRecords:2,
          failedRecords:6,
          exceptionRecords:12,
          passedDvp:15000,
          failedDvp:5000,
        },
        {
          date:"Jun 10, 2021",
          passedRules: 15,
          failedRules:8,
          passedRecords:10,
          failedRecords:12,
          exceptionRecords:22,
          passedDvp:14000,
          failedDvp:7400,
        },
        {
          date:"Jun 01, 2021",
          passedRules: 12,
          failedRules:2,
          passedRecords:6,
          failedRecords:11,
          exceptionRecords:25,
          passedDvp:12000,
          failedDvp:5000,
        }
       ]
     }
  ];





class Analytics extends Component {

  constructor(props) {
    super(props);
    this.state = {
      appContentContainer:"app-content-container",
      reconQuestionsClass: "page-content select-container in",
      reconResultsClass: "page-content results-container",
      pageNavContainer:"hide",
      ruleListValue:"",
      step1:"",
      step2:"hide",
      reconSelectedRecord:"",
      snackbarStatus: false,
      bannerType: "danger",
      showBanner: false
    };

    this.props.getTotalRuleset();
    this.props.getTotalRunsToday();
    this.props.getTotalRunsPastTime();
    this.props.getMostFreqRuleset(7);
    this.props.getMostDvpRuleset(7);

  }






  componentDidUpdate(prevProps) {
  if (this.props.error !== prevProps.error) {
    this.setState({showBanner:true});
  }
}


  goToTop=()=>{
    document.getElementById("app").scrollTo(0,0);
  }

  dropdownSelector = (name,id,value) => {
              this.setState({[name]:value});
          }

  dismissBanner = () => {
                this.setState({showBanner:false});
         }

 snackHandler = (state) =>{
   this.setState({snackbarStatus:state});
 }

  selectRecon = (data) => {
                    this.setState({
                      appContentContainer:"app-content-container in",
                      reconQuestionsClass: "page-content select-container",
                      reconResultsClass: "page-content results-container in",
                      pageNavContainer:"page-nav-container",
                      reconSelectedRecord:data
                    });
                    this.props.getReconItems(data.file);
                    this.goToTop();
                }


    runsOveriew=(name,value)=>{
      if(name==="freqRunTimePeriod"){
        this.props.getMostFreqRuleset(value.value);
      } else if(name==="dvpTimePeriod"){
        this.props.getMostDvpRuleset(value.value);
      }
    }

    rulesetTrend=(ruleset)=>{
      this.props.getRulesetTrend(ruleset.id)
    }

  goBack = (level) => {
                    if(level===0){
                      this.setState({
                        appContentContainer:"app-content-container",
                        reconQuestionsClass: "page-content select-container in",
                        reconResultsClass: "page-content results-container",
                        pageNavContainer:"hide"
                      });
                    }
                }


   render() {




     return (
       <div className={this.state.appContentContainer}>

       <Snackbar
           open={this.state.snackbarStatus}
           message="Please wait..."
           className="snack-bar"
         />





       <div className={this.state.pageNavContainer}>
         <a onClick={this.goBack.bind(this,0)} className={this.state.level0}>&lt; Back</a>
       </div>

       <Banner
          type={this.state.bannerType}
          hasAlerts={this.state.showBanner}
          header="Oh snap! Something went wrong."
          body="Please refresh the page and try again."
          dismissBanner={this.dismissBanner.bind(this)}
          />

        <div id='' className={this.state.reconQuestionsClass}>
          <h2>Analytics</h2>
          <Row>
            <Col sm={12} xs={12}>
            <Overview
            totalRuleset={this.props.totalRuleset}
            totalRunsToday={this.props.totalRunsToday}
            totalRunsPastWeek={this.props.totalRunsPastWeek}
            isLoading={this.props.isLoading}
            />
            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={12}>
            <RunsOverview
            freqRunData={this.props.mostFreqList}
            dvpData={this.props.mostDvpList}
            runsOveriew={this.runsOveriew.bind(this)}
            isLoading={this.props.isLoading} />
            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={12}>
            <TrendSummary data={this.props.rulesetTrend} isLoading={this.props.isLoading} rulesetTrend={this.rulesetTrend.bind(this)} />
            </Col>
          </Row>
        </div>

       </div>
     );
   }
}

const mapStateToProps = (state) => {
  return{
    error: state.analyticsReducer.error,
    isLoading: state.analyticsReducer.isLoading,
    mostFreqList: state.analyticsReducer.mostFreqList,
    mostDvpList: state.analyticsReducer.mostDvpList,
    rulesetTrend: state.analyticsReducer.rulesetTrend,
    totalRuleset:state.analyticsReducer.totalRuleset,
    totalRunsToday:state.analyticsReducer.totalRunsToday,
    totalRunsPastWeek:state.analyticsReducer.totalRunsPastWeek
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    getMostFreqRuleset : (day) => {
      dispatch(loadMostFreqRuleset(day));
    },
    getMostDvpRuleset : (day) => {
      dispatch(loadMostDvpRuleset(day));
    },
    getRulesetTrend : (rulesetId) => {
      dispatch(loadRulesetTrend(rulesetId));
    },
    getTotalRuleset : () => {
      dispatch(loadTotalRuleset());
    },
    getTotalRunsToday : () => {
      dispatch(loadTotalRunsToday());
    },
    getTotalRunsPastTime : () => {
      dispatch(loadTotalRunsPastTime());
    }

  }
}


export default connect(mapStateToProps,mapDispatchToProps) (Analytics);
