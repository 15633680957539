/* util common functions */

/* Remove Item from Array */
export function removeItemFromArray(arr, val) {
  return arr.filter((item)=>{
    return item != val;
  });
}

/* Insert Item to Array */
export function insertItemInToArray(arr, val) {
  if (!arr.find((item) => item === val)) {
    arr.push(val);
  } else {
    arr;
  }
  return arr;
}
