import { loginActions } from './login.actions'

export const initialState = {
    isAuthenticated: false,
    authError:''
};

export function loginReducer (state = initialState , action){
    switch(action.type){

        //-------Login Request-------------
        case loginActions.LOGIN_REQUEST:
            return{
              ...state,
              isAuthenticated: false,
              authError:''
            }

        //-------Login Success-------------
        case loginActions.LOGIN_SUCCESS:
            return{
              ...state,
              isAuthenticated: true
            };

        //-------Login Failure-------------
        case loginActions.LOGIN_FAILURE:
            return{
              ...state,
              isAuthenticated: false,
              authError:'incorrect'
            };
        default:
            return state;
    }
}
