import React, { Component } from 'react';
import MenuItem from '@mui/material/MenuItem';
import {Col, Row} from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import EmptyState from '../../../components/empty-state-container';
import Preloader from '../../../components/preloader';
import Button from '../../../components/button';
import FilterAutocomplete from '../../../components/filter-autocomplete';
import OneOptAutocomplete from './oneopt-autocomplete';
// import TouchRipple from '@mui/material/internal/TouchRipple';
import ComponentErrorBoundary from '../../../components/component-error-boundary';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Drawer from '@mui/material/Drawer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';



let reconDisplayList,reconDisplayListLength;

class ReconFilters extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filterContainer:false,
      filterList:[],
      dateFilter:[],
      dateOptions:[],
      statusFilter:[],
      statusOptions:["Completed","Error"],
      rulesetnameFilter:[],
      rulesetnameOptions:[]
    };
  }


componentDidUpdate(prevProps){

  let rulesetnameOptions,dateOptions;

  if(this.props.rulesetList !== prevProps.rulesetList){
    rulesetnameOptions = [... new Set(this.props.rulesetList.map(data => data.name))]
    this.setState({rulesetnameOptions});
  }

  if(this.props.reconList !== prevProps.reconList){
    dateOptions = [... new Set(this.props.reconList.map(data => data.date))];
    this.setState({dateOptions});
  }

}


   handleClick = (file) => {
            this.props.onClick(file);
          }


 toggleFilterContainer=()=>{
   this.props.toggleFilterContainer();
 }

 selectRuleValue = (item,values) => {
   this.setState({[item]:values});

   if(values.length > 0 && !this.state.filterList.includes(item)){
     this.setState({filterList:[...this.state.filterList,item]});
   } else if (values.length === 0 && this.state.filterList.includes(item)){
     this.setState({filterList:this.state.filterList.filter(val=> val !== item)});
   }

   // if (this.state.filterList.find(o => o !== item)){
   //   this.setState({changeTracker:[...this.state.changeTracker,attr]});
   // }
 }

 applyFilter=()=>{
   let filteredRunList=[];

   let sourceList=this.props.reconList;

   this.state.rulesetnameFilter.forEach((item, i) => {
     filteredRunList=filteredRunList.concat(sourceList.filter(val=> val.ruleName === item));
     sourceList=filteredRunList;
     filteredRunList=[];
   });

   this.state.statusFilter.forEach((item, i) => {
     filteredRunList=filteredRunList.concat(sourceList.filter(val=> val.status === item));
     sourceList=filteredRunList;
     filteredRunList=[];
   });


   this.props.filterReconList(sourceList,this.state.filterList);

   this.toggleFilterContainer();



 }

 clearFilter=()=>{
   this.toggleFilterContainer();
   this.setState({
     filterList:[],
     dateFilter:[],
     statusFilter:[],
     rulesetnameFilter:[]
   },()=>{this.props.filterReconList(this.props.reconList,this.state.filterList);});
 }




   render() {

     return (
            <Drawer anchor="right" open={this.props.filterContainerState}>
             <div className="filter-side-sheet">
             <Row className="title">
              <Col sm={10} xs={6}><h3>Filters</h3></Col>
              <Col sm={2} xs={6}><a className="material-icons" onClick={this.toggleFilterContainer.bind(this)}>close</a></Col>
             </Row>
             <Row className="filter-content">
              <Col sm={12} xs={12}>
                <FilterAutocomplete
                label="Date"
                placeholder="Select an option"
                value={this.state.dateFilter}
                dropDownOptions={this.state.dateOptions}
                onSelectValue={this.selectRuleValue.bind(this,"dateFilter")}
                />
              </Col>
             </Row>
             <Row className="filter-content">
              <Col sm={12} xs={12}>
                <FilterAutocomplete
                label="Ruleset name"
                placeholder="Select an option"
                value={this.state.rulesetnameFilter}
                dropDownOptions={this.state.rulesetnameOptions}
                onSelectValue={this.selectRuleValue.bind(this,"rulesetnameFilter")}
                />
              </Col>
             </Row>
             <Row className="filter-content">
              <Col sm={12} xs={12}>
                <FilterAutocomplete
                label="Status"
                placeholder="Select an option"
                value={this.state.statusFilter}
                dropDownOptions={this.state.statusOptions}
                onSelectValue={this.selectRuleValue.bind(this,"statusFilter")}
                />
              </Col>
             </Row>
             <Row className="cta-content btn-container">
              <Col sm={12} xs={12}>
              <Button label="Apply filters" className='btn btn-action' onClick={this.applyFilter} />
              <Button label="Clear all" className='btn btn-default' onClick={this.clearFilter} />
              </Col>
             </Row>


             </div>
           </Drawer>
     );
   }
}


export default ReconFilters;
