import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';



class Header extends Component {

  render()
    {
      return(
          <header>
          <Row className='header-container'>
            <Col sm={12} xs={12} className="header-title">
              CANINSOFT
            </Col>
            </Row>
          </header>
      );
    }

}

export default Header;
