import React, { Component } from 'react';
import FieldSelectorAutoComplete from './field-selector-autocomplete';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import Dropdown from './oneopt-autocomplete';
import {Col, Row} from 'react-bootstrap';
import moment from 'moment';

const dataTypeValues = [
  { label: 'Text', value: 'string' },
  { label: 'Number', value: 'number' },
  { label: 'Date', value: 'date' }
];

const stringOperationTypeValues = [
  { label: 'Convert to upper case', value: 'upper', operationCategory:'case' },
  { label: 'Convert to lower case', value: 'lower', operationCategory:'case' }
];

const numberOperationTypeValues = [
  { label: 'Set the decimal value', value: 'near', operationCategory:'round' }
];

const dateOperationTypeValues = [
  { label: 'Set the format', value: 'cast', operationCategory:'convert' }
];

let updateConfigValues="";

class FieldConfig extends Component {



  constructor(props) {
    super(props);
    this.state = {
      id:this.props.fieldConfigValues.id,
      field:this.props.fieldConfigValues.field,
      dataType:this.props.fieldConfigValues.dataType,
      operationType:this.props.fieldConfigValues.operationType,
      fieldConfigValue:this.props.fieldConfigValues.fieldConfigValue,
      operationTypeValues:[],
      fieldConfigValueState:"hide",
      fieldConfigHelperText:""
    };
  }

  componentDidMount(){
    if(this.props.fieldConfigValues.operationType.value==="near" || this.props.fieldConfigValues.operationType.value==="cast"){
      this.setState({fieldConfigValueState:"show"});
    }
    this.setOperationTypeDropdown(this.props.fieldConfigValues.dataType.value);
  }


  componentDidUpdate(prevProps) {
  if (this.props.fieldConfigValues !== prevProps.fieldConfigValues) {
    this.setState({
      id:this.props.fieldConfigValues.id,
      field:this.props.fieldConfigValues.field,
      dataType:this.props.fieldConfigValues.dataType,
      operationType:this.props.fieldConfigValues.operationType,
      fieldConfigValue:this.props.fieldConfigValues.fieldConfigValue
    });
  }
}

filterByValue = (array, string) => {
    return array.filter(o =>
        Object.keys(o).some(k => o[k].toLowerCase().includes(string.toLowerCase())));
}



setOperationTypeDropdown=(value)=>{
  switch (value){
    case "string":
    this.setState({operationTypeValues:stringOperationTypeValues});
    break;

    case "number":
    this.setState({operationTypeValues:numberOperationTypeValues});
    break;

    case "date":
    this.setState({operationTypeValues:dateOperationTypeValues});
    break;

    default:
    this.setState({operationTypeValues:[]});

}

}

  dropdownSelector = (name,selectedOption) => {
    updateConfigValues="";

    //------ data type operation -----------

    if(name==="dataType"){
      this.setState({fieldConfigValueState:"hide"});
      this.setOperationTypeDropdown(selectedOption.value);
      this.props.updateDoneButtonState(true);

  }

  //------ Operation type operation -----------

  else if(name==="operationType"){
    if(selectedOption.value==="near" || selectedOption.value==="cast"){
      this.setState({fieldConfigValue:"",fieldConfigValueState:"show"});
      this.props.updateDoneButtonState(true);
      }

      else{
          this.props.updateDoneButtonState(false);
      }
    }

    else{
      //this.props.updateDoneButtonState(true);
    }

    this.updateConfig(name,selectedOption);

}






  handleTextfield = (event,item) => {

    if(this.props.fieldConfigValues.operationType.value==="cast" && event.target.value !== ""){
      this.setState({fieldConfigHelperText:"E.g. "+moment().format(event.target.value)});
    }

    else{
      this.setState({fieldConfigHelperText:""});
    }

    updateConfigValues="";

    this.setState({[item]:event.target.value});
    this.updateConfig(item,event.target.value);
    if(event.target.value!==""){this.props.updateDoneButtonState(false);} else {this.props.updateDoneButtonState(true);}
  }


  updateConfig=(name,value)=>{
    var operationType,fieldConfigValue;

    if(name==="dataType"){operationType="";fieldConfigValue="";}
    else if(name==="operationType"){fieldConfigValue=""}
    else {operationType=this.state.operationType; fieldConfigValue=this.state.fieldConfigValue}

    updateConfigValues={
      id:this.state.id,
      field:this.state.field,
      dataType:this.state.dataType,
      operationType:operationType,
      fieldConfigValue:fieldConfigValue
    }
    updateConfigValues[name]=value;
    this.props.updateConfigValues(this.state.id,updateConfigValues);
  }

  deleteConfig=()=>{
    this.props.deleteConfig(this.state.id)
  }


  render() {
  return (
    <Row>
      <Col sm={3} xs={12}>
      <FieldSelectorAutoComplete
        label='Select a field'
        fileColumns={this.props.filteredFieldList}
        onSelectValue={this.dropdownSelector.bind(this,'field')}
        value={this.state.field}
        checkRuleCompleteness={this.props.checkRuleCompleteness.bind(this)}
        />
      </Col>
      <Col sm={3} xs={12}>
      <Dropdown
           label='Select field data type'
           fileColumns={dataTypeValues}
           onSelectValue={this.dropdownSelector.bind(this,'dataType')}
           value={this.state.dataType} />
      </Col>
      <Col sm={3} xs={12}>
      <Dropdown
           label='Select operation type'
           fileColumns={this.state.operationTypeValues}
           onSelectValue={this.dropdownSelector.bind(this,'operationType')}
           value={this.state.operationType} />
      </Col>
      <Col sm={2} xs={12}>
        <TextField
          label="Enter value"
          value={this.state.fieldConfigValue}
          fullWidth
          className={this.state.fieldConfigValueState}
          onChange={(e)=>this.handleTextfield(e,"fieldConfigValue")}
          helperText={this.state.fieldConfigHelperText}
        />
        </Col>
        <Col sm={1} xs={12}>
        <Fab className='button' size='small' color='primary' onClick={this.deleteConfig.bind(this)}><span className='material-icons'>delete</span></Fab>
        </Col>
      </Row>
  );
}
}

export default FieldConfig;
