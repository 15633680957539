import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import EmptyState from '../../../components/empty-state-container';
import Preloader from '../../../components/preloader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {connect} from 'react-redux';


class ImpactResults extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }







   handleClick = (item) => {
            var clickedItem=this.props.reconList.length-item-1;
            this.props.onClick(this.props.reconList[clickedItem]);
          }










   render() {

     let impactResults;


       if(this.props.isLoading.includes('fieldimpact')){
         impactResults=  <Preloader loading={true} /> ;
       }

       else{

         if(this.props.fieldImpactResults.length===0){
           impactResults=<div className='rounded-card'><EmptyState message="No record found." /></div>
         }
           else {
             impactResults=<div className='rounded-card'>
             <h3>Impacted Rulesets</h3>
             <Table className='data-table'>
               <TableHead>
                 <TableRow>
                   <TableCell>Ruleset name</TableCell>
                   <TableCell>Source</TableCell>
                   <TableCell>Dependencies</TableCell>
                 </TableRow>
               </TableHead>
               <TableBody>
                 {
                   this.props.fieldImpactResults.map((impact,index) => <TableRow key={index}>
                  <TableCell> {impact.rulesetName} </TableCell>
                  <TableCell> {impact.source} </TableCell>
                  <TableCell> {impact.appName} </TableCell>
                  </TableRow>)
                }
               </TableBody>
              </Table>
              </div>
            }

     }


     return (

       <div> {impactResults} </div>
     );
   }
}



export default ImpactResults;
