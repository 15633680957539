import { analyticsActions } from './analytics.actions';
import {removeItemFromArray} from "../../util/util";

export const initialState = {
    isLoading: [],
    ruleSetList: [],
    mostFreqList: [],
    mostDvpList: [],
    rulesetTrend:[],
    error:[],
    totalRuleset:"",
    totalRunsToday:"",
    totalRunsPastWeek:""


};

export function analyticsReducer (state= initialState, action){

let status;



  switch(action.type){


    //-------Load Total Ruleset Request-------------
    case analyticsActions.GET_TOTAL_RULESET_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'totalruleset']
    };

    //-------Load Total Ruleset Success-------------
    case analyticsActions.GET_TOTAL_RULESET_SUCCESS:
    return{
      ...state,
      totalRuleset:action.payload.response,
      isLoading:removeItemFromArray(state.isLoading,'totalruleset')
    };

    //-------Load Total Ruleset Failure-------------
    case analyticsActions.GET_TOTAL_RULESET_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };



    //-------Load Total Runs Today Request-------------
    case analyticsActions.GET_TOTAL_RUNS_TODAY_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'totalrunsinday']
    };

    //-------Load Total Runs Today Success-------------
    case analyticsActions.GET_TOTAL_RUNS_TODAY_SUCCESS:
    return{
      ...state,
      totalRunsToday:action.payload.response,
      isLoading:removeItemFromArray(state.isLoading,'totalrunsinday')
    };

    //-------Load Total Runs Today Failure-------------
    case analyticsActions.GET_TOTAL_RUNS_TODAY_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };


    //-------Load Total Runs Past Time Request-------------
    case analyticsActions.GET_TOTAL_RUNS_PAST_TIME_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'totalrunsinweek']
    };

    //-------Load Total Runs Past Time Success-------------
    case analyticsActions.GET_TOTAL_RUNS_PAST_TIME_SUCCESS:
    return{
      ...state,
      totalRunsPastWeek:action.payload.response,
      isLoading:removeItemFromArray(state.isLoading,'totalrunsinweek')
    };

    //-------Load Total Runs Past Time Failure-------------
    case analyticsActions.GET_TOTAL_RUNS_PAST_TIME_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

    //-------Load Most Frquent Request-------------
    case analyticsActions.GET_MOST_FREQ_RULESET_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'freqlist']
    };

    //-------Load Most Frquent Success-------------
    case analyticsActions.GET_MOST_FREQ_RULESET_SUCCESS:
    return{
      ...state,
      mostFreqList:action.payload.response,
      isLoading:removeItemFromArray(state.isLoading,'freqlist')
    };

    //-------Load Most Frquent Failure-------------
    case analyticsActions.GET_MOST_FREQ_RULESET_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

    //-------Load Most DVP Request-------------
    case analyticsActions.GET_MOST_DVP_RULESET_REQUEST:
    return{
      ...state,
      isLoading:[...state.isLoading,'dvplist']
    };

    //-------Load Most DVP Success-------------
    case analyticsActions.GET_MOST_DVP_RULESET_SUCCESS:
    return{
      ...state,
      mostDvpList:action.payload.response,
      isLoading:removeItemFromArray(state.isLoading,'dvplist')
    };

    //-------Load Most DVP Failure-------------
    case analyticsActions.GET_MOST_DVP_RULESET_FAILURE:
    status="";
    action.payload.response===undefined?status="404":status=action.payload.response.status;
    return{
      ...state,
      error:[...state.error,status]
    };

      //------- Load Ruleset Trend Request-------------
      case analyticsActions.GET_RULESET_TREND_REQUEST:
      return{
        ...state,
        isLoading:[...state.isLoading,'rulesettrend']
      };

      //------- Load Ruleset Trend Success-------------
      case analyticsActions.GET_RULESET_TREND_SUCCESS:
      return{
        ...state,
        rulesetTrend:action.payload.response,
        isLoading:removeItemFromArray(state.isLoading,'rulesettrend')
      };

      //------- Load Ruleset Trend Failure-------------
      case analyticsActions.GET_RULESET_TREND_FAILURE:
      status="";
      action.payload.response===undefined?status="404":status=action.payload.response.status;
      return{
        ...state,
        error:[...state.error,status]
      };

      default:
        return state;
  }
}
