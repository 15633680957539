import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import EmptyState from '../../../components/empty-state-container';
import Preloader from '../../../components/preloader';

import {connect} from 'react-redux';


class Overview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      freqRunView:"chart",
      mostDvpiew:"chart"
    };
  }







   handleClick = (item) => {
            var clickedItem=this.props.reconList.length-item-1;
            this.props.onClick(this.props.reconList[clickedItem]);
          }






   render() {

     let totalRulesContent,totalRunsinDay,totalRunsinWeek;

     if(this.props.isLoading.includes('totalruleset')){ totalRulesContent=  <Preloader loading={true} /> ; }

     else{ if(this.props.totalRuleset!==""){totalRulesContent=  this.props.totalRuleset[0].totalRulesets.toLocaleString();} }

     if(this.props.isLoading.includes('totalrunsinday')){ totalRunsinDay=  <Preloader loading={true} /> ; }

     else{ if(this.props.totalRunsToday!=="") {totalRunsinDay=  this.props.totalRunsToday[0].totalRuns.toLocaleString();} }

     if(this.props.isLoading.includes('totalrunsinweek')){ totalRunsinWeek=  <Preloader loading={true} /> ; }

     else{ if(this.props.totalRunsPastWeek!=="") {totalRunsinWeek =  this.props.totalRunsPastWeek[0].totalRuns.toLocaleString(); }}



     return (

       <div>
       <Row className="extendable-height">
        <Col sm={4} xs={12}>
          <div className='rounded-card analytics-overview-card'>
            <div className="title">Total Rules</div>
            <div className="value">{totalRulesContent}</div>
          </div>
        </Col>

        <Col sm={4} xs={12}>
          <div className='rounded-card analytics-overview-card'>
            <div className="title">Total Runs Today</div>
            <div className="value">{totalRunsinDay}</div>
          </div>
        </Col>

        <Col sm={4} xs={12}>
          <div className='rounded-card analytics-overview-card'>
            <div className="title">Total Runs Past Week </div>
            <div className="value">{totalRunsinWeek}</div>
          </div>
        </Col>



      </Row>

       </div>
     );
   }
}



export default Overview;
