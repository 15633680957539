import { impactorActions } from './impactor.actions';

export const initialState = {
    isLoading: [],
    fieldImpactResults: [],
    error:[]
};

export function impactorReducer (state= initialState, action){

let status;



  switch(action.type){

    //-------Field Impact Analysis Request-------------
   case impactorActions.GET_FIELD_IMPACT_ANALYSIS_REQUEST:
     return{
       ...state,
       isLoading:[...state.isLoading,'fieldimpact']
     };

     //-------Field Impact Analysis Success-------------
     case impactorActions.GET_FIELD_IMPACT_ANALYSIS_SUCCESS:
     return{
       ...state,
       fieldImpactResults:action.payload.response,
       isLoading:state.isLoading.splice(...state.isLoading.indexOf('fieldimpact'), 1 )
     };

     //-------Field Impact Analysis Failure-------------
    case impactorActions.GET_FIELD_IMPACT_ANALYSIS_FAILURE:
     status="";
     action.payload.response===undefined?status="404":status=action.payload.response.status;
     return{
       ...state,
       error:[...state.error,status]
     };

      default:
        return state;
  }
}
