import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Modal from '../../../components/modal';
import Preloader from '../../../components/preloader';
import EmptyState from '../../../components/empty-state-container';
import IconMenu from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import {connect} from 'react-redux';

let queryList;

class ManageQueries extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userFilterCriteria:"",
      emptyState:"You do not have any saved queries.",
      deleteQueryVal:"",
      modalOpen:false,
      modalTitle:"",
      modalContent:"",
      modalPrimaryButton:"",
      modalSecondaryButton:""
    };
  }


componentDidMount=()=>{
  this.setState({
    userFilterCriteria:""
  });
}




editQuery = (qy) => {
    //this.props.getRuleSetContent("edit-account");
    this.props.onClick({qy});
}

modalClose=()=>{
  this.setState({
    modalOpen:false
  });
}

modalConfirm=()=>{

  this.setState({
    modalOpen:false
  });
  let deleteObj={
      dbConnectionId:this.state.deleteQueryVal.dbId,
      dbQryId:this.state.deleteQueryVal.queryId,
      dbQryName:this.state.deleteQueryVal.name
  }
  this.props.deleteQuery(deleteObj);
}

deleteItem = (qy) => {
    //this.props.getRuleSetContent(value);
    //this.props.onClick(value);
    this.setState({
      modalOpen:true,
      modalTitle:"Delete Query",
      modalContent:"Are you sure you want to delete \""+qy.name+"\"?",
      modalPrimaryButton:"Yes",
      modalSecondaryButton:"No",
      modalState:"delete",
      deleteQueryVal:qy
    });
}

  createNewQuery = () => {

    this.props.onClick('new');

}





   render() {






     const {isLoading} = this.props;
     let manageQueriesContent,queryListContent;

     if(isLoading.includes('querylist')){
       manageQueriesContent=<Preloader loading={true} /> ;
     }
     else if(this.props.queriesList !== undefined){

      const filterCriteria=this.state.userFilterCriteria;
      queryList = this.props.queriesList.filter(function (e) {  return e.name.toLowerCase().includes(filterCriteria.toLowerCase()); } );


      if(queryList.length>0){
        queryListContent=<table className='manage-ruleset-table'>
        <tbody>
             {queryList.map((query,index) => <tr key={index}>
               <td onClick={this.editQuery.bind(this,query)} className='user' > {query.name+" ("+query.dbConnName+")"}</td>
               <td>
               <IconMenu
                   iconButtonElement={<IconButton size="large"><i className='material-icons'>more_horiz</i></IconButton>}
                   anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                   targetOrigin={{horizontal: 'right', vertical: 'top'}}
                   className='actions-menu'
                 >
                   <MenuItem
                   primaryText="Delete"
                   className='wl-group-card-menu'
                   onClick={this.deleteItem.bind(this,query)}  />
               </IconMenu>
                </td>
               </tr>)}
       </tbody>
        </table>
      }else{
        queryListContent=<EmptyState className="margin-top-0" message={this.state.emptyState} />
      }
       manageQueriesContent=<Row>

       <Col sm={4} xs={6}>{queryListContent}</Col>
       <Col sm={4} xs={6} className="manage-rule-buttons">

          <Button href=""  onClick={this.createNewQuery.bind(this)} label="Add a new query" className='createruleset-button'></Button>
       </Col>

       </Row>
     }

     return (
       <div>
       <Modal
        open={this.state.modalOpen}
        title={this.state.modalTitle}
        content={this.state.modalContent}
        primaryButton={this.state.modalPrimaryButton}
        secondaryButton={this.state.modalSecondaryButton}
        close={this.modalClose}
        confirm={this.modalConfirm}
        />
       <div className="manage-ruleset margin-top-30"> {manageQueriesContent} </div>
      </div>

     );
   }
}


const mapStateToProps = (state) => {
  return{
    errors: state.databaseReducer.errorMessage
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {

  }
}


export default connect(mapStateToProps,mapDispatchToProps) (ManageQueries);
