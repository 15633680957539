import React, { Component } from 'react';
// import TouchRipple from '@mui/material/internal/TouchRipple';
import {Col, Row} from 'react-bootstrap';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {DropdownButton,Dropdown} from 'react-bootstrap';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Preloader from '../../../components/preloader';
import EmptyState from '../../../components/empty-state-container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Modal from '../../../components/modal';
import LinearProgress from '@mui/material/LinearProgress';
import {connect} from 'react-redux';
import {
      loadRecon,
      filterReconRun,
      downloadXlsFile
    } from '../recon.actions';

let dataSanityCheck=1,rulesTableContent="",key;
let matchedRecordResults,matchedTableHead1,matchedTableHead2,matchedTableRows,totalTableColumnsCount;
let unMatchedTableHeadFile2,unMatchedTableBodyFile2;
let missingRecords,missingRecordData,exceptionRecords,exceptionRecordData,loadMoreVariable;
let numberOfExceptionFile1,numberOfExceptionFile2;

const ruleAddtionalDetailsMapping = {
  string: 'Text',
  number: 'Number',
  date:'Date',
  upper:'Converted to upper case',
  lower:'Converted to lower case',
  near:'Number of decimals',
  cast:'Date formatting'
}

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`vertical-tabpanel-${index}`}
//       aria-labelledby={`vertical-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

class ReconciliationResults extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fixedHeader: true,
      fixedFooter: true,
      stripedRows: false,
      showRowHover: false,
      selectable: false,
      multiSelectable: false,
      enableSelectAll: false,
      deselectOnClickaway: true,
      showCheckboxes: false,
      step1:"",
      step2:"hide",
      ruleSummaryTable:"show",
      ruleSummaryExpand:"Hide details",
      isRulesExpanded:true,
      passRecords:0,
      failRecords:0,
      filterValue:"all",
      tab1_tableItemCount:100,
      tab2_tableItemCount:100,
      tab3_tableItemCount:100,
      missing_File1Count:100,
      missing_File2Count:100,
      exception_File1Count:100,
      exception_File2Count:100,
      selectedFileName:"",
      triggerDownload:false,
      hasError: false,
      slideIndex:'0',
      missingRecordsItem:"file1",
      exceptionRecordsItem:"file1",
      modalOpen:false,
      modalTitle:"",
      modalContent:"",
      modalPrimaryButton:"",
      modalSecondaryButton:"",

    };

  }

  static getDerivedStateFromProps(props,state,prevProps){
    if(props.reconSelectedRecord !== undefined){
        if(props.reconSelectedRecord !== state.selectedFileName){
          return {
            selectedFileName: props.reconSelectedRecord,
            filterValue: "all",
            tab1_tableItemCount:100,
            slideIndex: '0'
          }
    }
  }

  return null;
  }


  componentDidCatch(error, info) {
  this.setState({ hasError: true })
  }

  componentDidUpdate(prevProps) {
   if (prevProps.reconRunData !== this.props.reconRunData) {
     this.props.snackHandler(false);
   }

   if(prevProps.pageNavContainer !== this.props.pageNavContainer){
     this.setState({
       filterValue:"all",
       missingRecordsItem:"file1",
       exceptionRecordsItem:"file1"
     });
   }




   if (prevProps.isLoading !== this.props.isLoading && this.state.triggerDownload === true) {
     if(this.props.isLoading.includes('downloadxls')){
      this.props.snackHandler(true);
     }
     else{
       this.props.snackHandler(false);
       this.setState({triggerDownload:false});
       //this.downloadXlsFile();
     }
   }


}




  expandRuleSummary = () => {
    if(this.state.ruleSummaryTable==="hide"){
      this.setState({ruleSummaryTable:"",ruleSummaryExpand:"Hide details",isRulesExpanded:true});
    }

    else{
      this.setState({ruleSummaryTable:"hide",ruleSummaryExpand:"Show details"});
    }

  }


  loadRulesSections = () =>{

  }

  filterSelector = (value) => {
     this.setState({filterValue:value});
     this.props.snackHandler(true);
     this.props.filterReconRunItems(value);
   }

   loadMore = (loadMoreVariable) => {

     let value
     if(loadMoreVariable==="1"){
       value=this.state.tab1_tableItemCount+100;
       this.setState({tab1_tableItemCount:value});
     }
     else if(loadMoreVariable==="missing_File1Count"){
       value=this.state.missing_File1Count+100;
       this.setState({missing_File1Count:value});
     }
     else if(loadMoreVariable==="missing_File2Count"){
       value=this.state.missing_File2Count+100;
       this.setState({missing_File2Count:value});
     }
     else if(loadMoreVariable==="exception_File1Count"){
       value=this.state.exception_File1Count+100;
       this.setState({exception_File1Count:value});
     }
     else if(loadMoreVariable==="exception_File2Count"){
       value=this.state.exception_File2Count+100;
       this.setState({exception_File2Count:value});
     }



   }

   calculateRecordThreshold=(recordcount)=>{

     let threshold=(recordcount*this.props.reconData.totalRules)/500000;

     return threshold;

   }

   triggerHugeFileDownload=(recordFilter,startRec,endRec,part)=>{
     this.modalClose();
     this.setState({triggerDownload:true});
     this.props.downloadXls(this.state.selectedFileName,recordFilter,this.props.reconData.reconName,startRec,endRec,part)
   }

   downloadXls = (recordFilter,recordCount) =>{

     let downloadThreshold,recordCountForEachDownload;
     let downloadModalContent;
     let buttonsForDownload=[<div key='0'>The records you're trying to download is quite huge. However, you could download them in parts.</div>];
     downloadThreshold=Math.ceil(this.calculateRecordThreshold(recordCount));


     if(downloadThreshold>1){

       recordCountForEachDownload = Math.round(Math.round(recordCount/downloadThreshold)/100)*100;

       var partToDownload;

       for(var i=1;i<=downloadThreshold;i++){
         var startRec,endRec;
         startRec = ((i-1)*recordCountForEachDownload)+1;
         i === downloadThreshold?endRec=recordCount:endRec=i*recordCountForEachDownload;

         partToDownload="-Part"+i;

         buttonsForDownload.push(<div  key={i} className='download-btn' onClick={this.triggerHugeFileDownload.bind(this,recordFilter,startRec,endRec,partToDownload)}>Part {i} - Records {startRec} to {endRec}</div>)
       }

       downloadModalContent = <div>The records you're trying to download is quite huge. However, you could download them in parts.</div> + buttonsForDownload

       this.setState({
         modalOpen:true,
         modalTitle:"Download results",
         modalContent:buttonsForDownload,
         modalPrimaryButton:"Close",
         modalSecondaryButton:"null"
       });



     }else{
       this.props.downloadXls(this.state.selectedFileName,recordFilter,this.props.reconData.reconName,1,recordCount,'');
       this.setState({triggerDownload:true});
     }

     //this.props.downloadXls(this.state.selectedFileName,recordFilter,this.props.reconData.reconName);
    // this.setState({triggerDownload:true});
   }


   modalClose=()=>{
     this.setState({
       modalOpen:false
     });
   }



handleTabChange = (event, newValue) => {
   this.setState({
      slideIndex: newValue
    });
};

 handleRadioButton = (event,recordid) => {
   if(recordid==="missing"){
     this.setState({
        missingRecordsItem: event.target.value
      });
   } else{
     this.setState({
        exceptionRecordsItem: event.target.value
      });
   }

};





   render() {
     const {isLoading} = this.props;
     dataSanityCheck=1;
     let reconResultContent;
       if(isLoading.includes('recon')){
         reconResultContent=<Preloader loading={true} /> ;
       }
       else{
       if(this.props.reconData=== "" ){
           dataSanityCheck=1;
           reconResultContent= <EmptyState message="Oops! Something went wrong. Please verify your rules and files." />
       }

       else{
       dataSanityCheck=0;
       //------------ Iterate table body -----------
       if(this.state.isRulesExpanded===false) {
        rulesTableContent=<TableRow><TableCell colSpan="6">Please wait...</TableCell></TableRow>
       }
       else{
         key=0;


         const sortedData = this.props.reconData.rules.sort((a, b) => b.failCount - a.failCount);
         rulesTableContent = sortedData.map((row) => {
             var i;
             var colNumber=0
             var returnValue = [];
             for (i=0; i<6; i++ in row){
                 key = key+1;
                 switch(i) {
                       case 0:
                         returnValue.push( <TableCell key={key}> {row['name']} </TableCell> )
                         break;

                       case 1:
                         returnValue.push( <TableCell key={key}> {row['rule'].replace(/\[/g, "").replace(/\]/g, "").replace(/\{/g, "").replace(/\}/g, "").replace(/ \|\| /g, " ").replace(/!!/g, "")} </TableCell> )
                         break;

                       case 2:
                         returnValue.push( <TableCell key={key}> {row['passPercentage']=== undefined ? 'Not available' : <div className='text-center'>{row['passPercentage']}%  <br /> <LinearProgress variant="determinate" value={row['passPercentage']} /> </div>}</TableCell> )
                         break;

                       case 3:
                         returnValue.push( <TableCell key={key}> {row['passCount']} </TableCell> )
                         break;

                       case 4:
                         returnValue.push( <TableCell key={key}> {row['failCount']} </TableCell> )
                         break;

                       case 5:
                         let addtionalDetails="",thresholdDetails="",vMapDetails="",fieldConfigDetails="";
                         if(row['threshold']=== undefined) { addtionalDetails = 'Not available' }
                         else if (row['threshold']=== null && row['valueMap'].length === 0 && row['ruleFormat'].length === 0)
                         { addtionalDetails = 'None' }
                         else{
                           if(row['threshold'] !== null) {thresholdDetails=<div className='rule-addnl-details-data'><label>Margin of error:</label> {row['threshold']}</div>}


                           var k;
                           if(row['valueMap'].length !== 0){
                             let vmapValues=[];
                             for(k=0; k<row['valueMap'].length; k++){
                               vmapValues.push(row['valueMap'][k].key + '=' + row['valueMap'][k].value)
                             }

                             vMapDetails=<div className='rule-addnl-details-data'><label>Value map:</label> <br />{row['valueMap'][0].colName} (Source{row['valueMap'][0].source}): {vmapValues.join(' , ')}</div>
                           }

                           if(row['ruleFormat'].length !== 0){
                             let ruleFormatValues=[],format;
                             for(k=0; k<row['ruleFormat'].length; k++){
                               format=row['ruleFormat'][k].colFormat;

                               ruleFormatValues.push(<div key={'fieldconfig'+k}> <div>Field: {row['ruleFormat'][k].colName} (Source{row['ruleFormat'][k].source })</div><div> Format: {ruleAddtionalDetailsMapping[row['ruleFormat'][k].colFormat]} </div> <div> Operation Type : {ruleAddtionalDetailsMapping[row['ruleFormat'][k].formatOperation]} </div> <div className={row['ruleFormat'][k].formatValue===""?'hide':''}> Value : {row['ruleFormat'][k].formatValue} </div>   </div>)
                             }

                             fieldConfigDetails=<div className='rule-addnl-details-data'><label>Field configuration(s):</label> {ruleFormatValues}</div>
                           }



                           addtionalDetails=<div className='rule-addnl-details'> {thresholdDetails} {vMapDetails} {fieldConfigDetails} </div>

                         }


                         returnValue.push( <TableCell key={key}> {addtionalDetails}</TableCell> )
                         break;

                       default:
                         // code block
                    }


                 colNumber=colNumber+1;
             };
             key = key+1;
             return (<TableRow key={key}>
                 {returnValue}
                 </TableRow>
             );

         });
       }




        reconResultContent= <Card className='recon-result-title'>
             <div>
                 <Row>
                     <Col sm={5} className='recon-result-title'>
                         <h3>{this.props.reconData.reconName}</h3>
                         <div className='recon-executed' >Executed on {this.props.reconData.reconDate}</div>
                          </Col>

                     <Col sm={7}>
                     </Col>
                 </Row>


                 <Row className='extendable-height'>
                     <Col sm={3} xs={12}>
                      <div className='recon-results-card'>
                        <h4>Source1</h4>
                        <div><label>Name - </label> {this.props.reconData.sourceFileName.split(/-(.+)/)[1]}</div>
                        <div><label>Primary key - </label> {this.props.reconData.sourceAnchor}</div>
                        <div><label>Total records - </label> {this.props.reconData.totalRecordsInSource}</div>
                      </div>
                     </Col>

                     <Col sm={3} xs={12}>
                      <div className='recon-results-card'>
                        <h4>Source2</h4>
                        <div><label>Name - </label> {this.props.reconData.destFileName.split(/-(.+)/)[1]}</div>
                        <div><label>Primary key - </label> {this.props.reconData.destAnchor}</div>
                        <div><label>Total records - </label> {this.props.reconData.totalRecordsInDest}</div>
                      </div>
                     </Col>

                     <Col  sm={3} xs={12}>
                      <div className='recon-results-card'>
                        <h4>Matched Records</h4>
                        <div><label>Total - </label> {this.props.reconData.matchedRecords}</div>
                        <div><label>Passed - </label> {this.props.reconData.totalPassedRecords}</div>
                        <div><label>Failed - </label> {this.props.reconData.totalFailedRecords}</div>
                      </div>
                     </Col>

                     <Col  sm={3} xs={12}>
                      <div className='recon-results-card'>
                        <h4>Missing Records</h4>
                        <div><label>In {this.props.reconData.sourceFileName.split(/-(.+)/)[1]} - </label> {this.props.reconData.unmatchedRecordsInDestCount}</div>
                        <div><label>In {this.props.reconData.destFileName.split(/-(.+)/)[1]} - </label> {this.props.reconData.unmatchedRecordsInSourceCount}</div>
                      </div>
                     </Col>
                 </Row>

                 <Row className='recon-rule-summary'>
                     <Col sm={5} xs={12}>
                        <h3>Validation Rule Summary</h3>
                    </Col>
                     <Col sm={7} xs={12}>
                 <div className='recon-results-summary'>
                  <span><label>Total rules</label> {this.props.reconData.totalRules}</span>
                  <span><label>Passed</label> {this.props.reconData.totalPassedRules}</span>
                  <span><label>Failed</label> {this.props.reconData.totalFailedRules}</span>
                 </div>
                 </Col>
                 </Row>
                 <div className={this.state.ruleSummaryTable}>
                 <Table className='data-table rule-details-table'>
                   <TableHead>
                     <TableRow>
                       <TableCell>Rule name</TableCell>
                       <TableCell>Rule</TableCell>
                       <TableCell>Pass Percentage</TableCell>
                       <TableCell>Pass records</TableCell>
                       <TableCell>Failed records</TableCell>
                       <TableCell>Additional details</TableCell>
                     </TableRow>
                   </TableHead>
                   <TableBody>
                     {rulesTableContent}
                   </TableBody>
                  </Table>
                  </div>
                  <div onClick={this.expandRuleSummary.bind()} className='show-more'><a>{this.state.ruleSummaryExpand}</a></div>
             </div>
         </Card>

           //--- Tab1 Content -----
           if(this.state.slideIndex==='0'){
           if(this.props.reconData.results.length === 0){
             matchedRecordResults=<EmptyState message="No differences to show" />
           }
           else{
             dataSanityCheck=0;
             for(var i=0;i<this.props.reconData.results[0].rule.length;i++){
               if(this.props.reconData.results[0].rule[i].sourceCol===null || this.props.reconData.results[0].rule[i].destCol===null){
                 dataSanityCheck=1;
                 reconResultContent= <EmptyState message="Looks like there is something wrong with rule you executed. Please verify your rules and files." />
                 break;
               }
             }


          if(dataSanityCheck===0){
           key = 0;
           totalTableColumnsCount=1;
           //------------ Iterate table header -----------

              matchedTableHead1 = this.props.reconData.results[0].rule.map((column) => {
                  key = key+1;
                  var cols=[]
                  cols.push(<TableCell key={key} colSpan={column.sourceCol.length+column.destCol.length}>{column.name}<div className='rule'>{column.rule.replace(/\[/g, "").replace(/\]/g, "").replace(/\{/g, "").replace(/\}/g, "").replace(/ \|\| /g, " ").replace(/!!/g, "")}</div></TableCell>);
                  totalTableColumnsCount=totalTableColumnsCount+column.sourceCol.length+column.destCol.length;
                  return(cols)
              });

              matchedTableHead2 = this.props.reconData.results[0].rule.map((column) => {
                  key = key+1;
                  var cols=[]
                  var str = column.rule;
                  var firstRuleIndices = [];
                  var secondRuleIndices = [];
                  for(var i=0; i<str.length;i++) {   if (str[i] === "]") firstRuleIndices.push(i);  }
                  for(var j=0; j<str.length;j++) {   if (str[j] === "{") secondRuleIndices.push(j); }

                        cols.push(<TableCell key={key} className='firstfile-column' colSpan={column.sourceCol.length}>{column.rule.substring(0,firstRuleIndices[column.sourceCol.length-1]+1).replace(/\[/g, "").replace(/\]/g, "").replace(/\{/g, "").replace(/\}/g, "").replace(/ \|\| /g, " ").replace(/!!/g, "")}<i className="material-icons">looks_one</i></TableCell>);
                        key++;

                  key = key+1;
                    cols.push(<TableCell key={key} className='secondfile-column' colSpan={column.destCol.length}>{column.rule.substring(secondRuleIndices[0],str.length).replace(/\[/g, "").replace(/\]/g, "").replace(/\{/g, "").replace(/\}/g, "").replace(/ \|\| /g, " ").replace(/!!/g, "")}<i className="material-icons">looks_two</i></TableCell>)
                    key++;

                  return(cols)

              });



           if(this.props.reconRunData.length===0){
             matchedTableRows=<TableRow><TableCell key={key+1} colSpan={totalTableColumnsCount}><EmptyState message="No records to show" /></TableCell></TableRow>;
           }
           else{

          //------------ Iterate table body -----------
           matchedTableRows = this.props.reconRunData.slice(0,this.state.tab1_tableItemCount).map((results) => {
            key = key+1;
            var rows=[];
             for(var j=0;j<results.rule.length;j++){

                   rows.push(<TableCell key={key} colSpan={results.rule[j].sourceCol.length} className={results.rule[j].isPass?'pass':'fail'} > { String(results.rule[j].sourceVal)  } </TableCell>);
                   key++;

                   rows.push(<TableCell key={key} colSpan={results.rule[j].destCol.length} className={results.rule[j].isPass?'pass':'fail'} > { String(results.rule[j].destVal)  } </TableCell>);
                   key++;

             }

               return (<TableRow key={key}>
                   <TableCell className={results.hasFailedItem?'rec-error':''}>{results.anchorValue.withValueMap}</TableCell>
                   {rows}
                   </TableRow>
               )


           });
         }


          var matchedRecordsThreshold=Math.ceil(this.calculateRecordThreshold(this.props.reconData.matchedRecords));

           matchedRecordResults=<div>
            <div className={matchedRecordsThreshold>1?"info-container":"hide"}>
                 <span className="title">Note: </span>
                <span className="message">We noticed there are too many records to show. For your better experience we have restricted the records displayed here, however you can download and view them.</span>
            </div>
            <Row>
               <Col sm={12} xs={12}>
               <Row className='data-chips'>
               <Col sm={2} xs={6}>
                 <div className={this.state.filterValue === "all" ? 'dashboard-all selected' : 'dashboard-all'} onClick={this.filterSelector.bind(this,"all")}>
                   <span className='material-icons'>{this.state.filterValue === "all" ? 'check' : ''}</span>

                   <div>All records</div>
                 </div>
               </Col>
               <Col sm={2} xs={6}>
                 <div className={this.state.filterValue === "pass" ? 'dashboard-pass selected' : 'dashboard-pass'} onClick={this.filterSelector.bind(this,"pass")}>
                   <span className='material-icons'>{this.state.filterValue === "pass" ? 'check' : ''}</span>
                   <div>No difference ({this.props.reconData.totalPassedRecords})</div>
                 </div>
               </Col>
               <Col sm={2} xs={6}>
                 <div className={this.state.filterValue === "fail" ? 'dashboard-fail selected' : 'dashboard-fail'} onClick={this.filterSelector.bind(this,"fail")}>
                   <span className='material-icons'>{this.state.filterValue === "fail" ? 'check' : ''}</span>
                   <div>Difference ({this.props.reconData.totalFailedRecords})</div>
                 </div>
               </Col>
               <Col sm={4} xs={6}></Col>
               <Col sm={2} xs={6} className='none'>
                   <DropdownButton className='download-btn' title="Download as XLSX" id="downloadXlsBtn">
                         <Dropdown.Item eventKey="1" onClick={this.downloadXls.bind(this,'all',this.props.reconData.matchedRecords)}>All</Dropdown.Item>
                         <Dropdown.Item eventKey="2" onClick={this.downloadXls.bind(this,'pass',this.props.reconData.totalPassedRecords)}>No Difference</Dropdown.Item>
                         <Dropdown.Item eventKey="2" onClick={this.downloadXls.bind(this,'fail',this.props.reconData.totalFailedRecords)}>Difference</Dropdown.Item>
                  </DropdownButton>
               </Col>
               </Row>
               </Col>
             </Row>


             <Row>
              <Col xs={12} className='recon-details-legend'>
                  <span> <i className="material-icons">looks_one</i> <span>Columns from {this.props.reconData.sourceFileName.split(/-(.+)/)[1]}</span> </span>
                  <span> <i className="material-icons">looks_two</i> <span>Columns from {this.props.reconData.destFileName.split(/-(.+)/)[1]}</span> </span>
              </Col>
           </Row>
           <div className='recon-table-holder'>
           <Table
             className='data-table'
             id='reconResultsTable'
             stickyHeader
             >
             <TableHead>
               <TableRow>
                <TableCell rowSpan='2' className='id'>ID</TableCell>
                 {matchedTableHead1}
               </TableRow>
               <TableRow>
                 {matchedTableHead2}
               </TableRow>
             </TableHead>
             <TableBody>
               {matchedTableRows}
             </TableBody>
            </Table>
            <div className='left-sticky-container'>
              {/* <TouchRipple> */}
                <div className={this.props.reconRunData.length>this.state.tab1_tableItemCount? 'show-more' : 'hide'}  onClick={this.loadMore.bind(this,'1')}> Show more </div>
              {/* </TouchRipple> */}
            </div>
           </div>

           </div>
          }
         }
       }
       //--- Tab 2 Content -------
else if(this.state.slideIndex==='1'){

  let missingRecordCount;

      if(this.state.missingRecordsItem==='file1') {
        missingRecordData=this.props.reconData.unmatchedRecordsInDest;
        missingRecordCount=this.props.reconData.unmatchedRecordsInDestCount;
        loadMoreVariable='missing_File1Count';
      } else {
        missingRecordData=this.props.reconData.unmatchedRecordsInSource;
        missingRecordCount=this.props.reconData.unmatchedRecordsInSourceCount;
        loadMoreVariable='missing_File2Count';
      }

      if(missingRecordData.length===0){
      missingRecords=<EmptyState message="No records to show" />;
      }

      else{
                unMatchedTableHeadFile2=[];
                unMatchedTableBodyFile2=[];
                var key1 = 0;
                var key2 = 0;
                var key3 = 0;
                var keyName;

                for (keyName in missingRecordData[0]) {
                if (missingRecordData[0].hasOwnProperty(keyName)) {
                    key1 = key1+1;
                    unMatchedTableHeadFile2.push(<th key={key1}>{keyName}</th>);
                  }
                }
              unMatchedTableBodyFile2 = missingRecordData.slice(0,this.state[loadMoreVariable]).map((results) => {
                let data=[]
                key3=key3+1;
                for (keyName in results) {
                if (results.hasOwnProperty(keyName)) {
                    key2 = key2+1;
                    if(results[keyName] !== null){
                        if(typeof(results[keyName])==="object"){
                          results[keyName].hasOwnProperty("result") ? data.push(<td key={key2}>{results[keyName].result}</td>) : data.push(<td key={key2}>Data Error</td>);
                        }
                        else{
                          data.push(<td key={key2}>{results[keyName]}</td>);
                        }
                  }
                  else{
                      data.push(<td key={key2}>{results[keyName]}</td>);
                  }
                }
                }
                return(<tr key={key3}>{data}</tr>);
            });


            missingRecords=<div className='recon-table-holder'>


<div className={Number(missingRecordCount)>1500?"info-container":"hide"}>
     <span className="title">Note: </span>
    <span className="message">We noticed there are too many records to show. For your better experience we have restricted the records displayed here, however you can download and view them.</span>
</div>

            <table>
              <thead>
                <tr>
                  {unMatchedTableHeadFile2}
                </tr>
              </thead>
              <tbody>
                {unMatchedTableBodyFile2}
              </tbody>
             </table>
             {/* <TouchRipple> */}
              <div className={missingRecordData.length>this.state[loadMoreVariable] ? 'show-more' : 'hide'}  onClick={this.loadMore.bind(this,loadMoreVariable)}> Show more </div>
             {/* </TouchRipple> */}
            </div>;
            }
        }
//--- Tab3 Content----
else if(this.state.slideIndex==='2'){

  numberOfExceptionFile1=this.props.reconData.sourceDuplicates.length;
  numberOfExceptionFile2=this.props.reconData.destDuplicates.length;

if(this.state.exceptionRecordsItem==='file1') {
 exceptionRecordData=this.props.reconData.sourceDuplicates;
 loadMoreVariable='exception_File1Count';
} else {
 exceptionRecordData=this.props.reconData.destDuplicates;
 loadMoreVariable='exception_File2Count';
}

if(exceptionRecordData.length===0){
exceptionRecords=<EmptyState message="No records to show" />;
}

else{
         unMatchedTableHeadFile2=[];
         unMatchedTableBodyFile2=[];
         key1 = 0;
         key2 = 0;
         key3 = 0;

         for (keyName in exceptionRecordData[0]) {
         if (exceptionRecordData[0].hasOwnProperty(keyName)) {
             key1 = key1+1;
             unMatchedTableHeadFile2.push(<th key={key1}>{keyName}</th>);
           }
         }
       unMatchedTableBodyFile2 = exceptionRecordData.slice(0,this.state[loadMoreVariable]).map((results) => {
         let data=[]
         key3=key3+1;
         for (keyName in results) {
         if (results.hasOwnProperty(keyName)) {
             key2 = key2+1;
             if(results[keyName] !== null){
                 if(typeof(results[keyName])==="object"){
                   results[keyName].hasOwnProperty("result") ? data.push(<td key={key2}>{results[keyName].result}</td>) : data.push(<td key={key2}>Data Error</td>);
                 }
                 else{
                   data.push(<td key={key2}>{results[keyName]}</td>);
                 }
           }
           else{
               data.push(<td key={key2}>{results[keyName]}</td>);
           }
         }
         }
         return(<tr key={key3}>{data}</tr>);
     });


     exceptionRecords=<div className='recon-table-holder'>
     <table>
       <thead>
         <tr>
           {unMatchedTableHeadFile2}
         </tr>
       </thead>
       <tbody>
         {unMatchedTableBodyFile2}
       </tbody>
      </table>
      {/* <TouchRipple> */}
        <div className={exceptionRecordData.length>this.state[loadMoreVariable] ? 'show-more' : 'hide'}  onClick={this.loadMore.bind(this,loadMoreVariable)}> Show more </div>
      {/* </TouchRipple> */}
     </div>;
     }
 }



     }
  }



    return (
      <div>
      <Modal
       open={this.state.modalOpen}
       title={this.state.modalTitle}
       content={this.state.modalContent}
       primaryButton={this.state.modalPrimaryButton}
       secondaryButton={this.state.modalSecondaryButton}
       close={this.modalClose}
       confirm={this.modalClose}
       />
      {reconResultContent}
        <div className={dataSanityCheck=== 1 ? 'hide' : '' }>
        <Box className="recon-result-tabs">
          <TabContext value={this.state.slideIndex}>
            <Box sx={{ borderBottom: 1, borderColor: '#EEE' }}>
              <TabList onChange={this.handleTabChange} aria-label="lab API tabs example">
                <Tab label={'Matched records (' + this.props.reconData.matchedRecords + ')'} value='0' />
                <Tab label="Missing records" value='1' />
                <Tab label="Exceptions" value='2' />
              </TabList>
            </Box>
            <TabPanel value='0'>
                {matchedRecordResults}
            </TabPanel>
            <TabPanel value='1'>
            <Row>
             <Col sm={10} xs={6}>
             <RadioGroup aria-label="position" className="radio-group" name="position" value={this.state.missingRecordsItem} onChange={(e)=>this.handleRadioButton(e,'missing')} row>
                  <FormControlLabel
                    value="file1"
                    control={<Radio color="primary" />}
                    label={'Records found in Source2 but missing in Source1 (' + this.props.reconData.unmatchedRecordsInDestCount + ')'}
                    labelPlacement="end"
                  />

                  <FormControlLabel
                    value="file2"
                    control={<Radio color="primary" />}
                    label={'Records found in Source1 but missing in Source2 (' + this.props.reconData.unmatchedRecordsInSourceCount + ')'}
                    labelPlacement="end"
                  />
              </RadioGroup>
              </Col>
              <Col sm={2} xs={6} className='none'>
                  <DropdownButton className='download-btn' title="Download as XLSX" id="downloadXlsBtn">
                        <Dropdown.Item eventKey="1" onClick={this.downloadXls.bind(this,'missinginfile1',this.props.reconData.unmatchedRecordsInDestCount)}>Missing in Source1</Dropdown.Item>
                        <Dropdown.Item eventKey="2" onClick={this.downloadXls.bind(this,'missinginfile2',this.props.reconData.unmatchedRecordsInSourceCount)}>Missing in Source2</Dropdown.Item>
                 </DropdownButton>
              </Col>
              </Row>

            {missingRecords}
            </TabPanel>
            <TabPanel value='2'>
            <h5>Exceptions are the records that have duplicate primary ID (basis of data validation) during data processing. </h5>

            <Row>
             <Col sm={10} xs={6}>
             <RadioGroup aria-label="position" className="radio-group" name="position" value={this.state.exceptionRecordsItem} onChange={(e)=>this.handleRadioButton(e,'exception')} row>
                  <FormControlLabel
                    value="file1"
                    control={<Radio color="primary" />}
                    label={'Exceptions in Source1 (' + numberOfExceptionFile1 + ')'}
                    labelPlacement="end"
                  />

                  <FormControlLabel
                    value="file2"
                    control={<Radio color="primary" />}
                    label={'Exceptions in Source2 (' + numberOfExceptionFile2 + ')'}
                    labelPlacement="end"
                  />
              </RadioGroup>
              </Col>
              <Col sm={2} xs={6} className='none'>
                  <DropdownButton className='download-btn' title="Download as XLSX" id="downloadXlsBtn">
                        <Dropdown.Item eventKey="1" onClick={this.downloadXls.bind(this,'exceptionsinfile1',numberOfExceptionFile1)}>Exceptions in Source1</Dropdown.Item>
                        <Dropdown.Item eventKey="2" onClick={this.downloadXls.bind(this,'exceptionsinfile2',numberOfExceptionFile2)}>Exceptions in Source2</Dropdown.Item>
                 </DropdownButton>
              </Col>
              </Row>

            {exceptionRecords}

            </TabPanel>
          </TabContext>
         </Box>
        </div>
      </div>
    )

    }
   }


const mapStateToProps = (state) => {
  return{
    reconData: state.reconReducer.reconData,
    reconRunData: state.reconReducer.reconRunData,
    isLoading: state.reconReducer.isLoading,
    downloadXlsContent : state.reconReducer.downloadXlsContent
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    getReconItems : (filename) => {
      dispatch(loadRecon(filename));
    },
    filterReconRunItems : (filter) => {
      dispatch(filterReconRun(filter));
    },
    downloadXls : (fileName,status,reconName,startRec,endRec,part) => {
      dispatch(downloadXlsFile(fileName,status,reconName,startRec,endRec,part));
    }


  }
}



export default connect(mapStateToProps,mapDispatchToProps) (ReconciliationResults);
