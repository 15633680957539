import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import EmptyState from '../../../components/empty-state-container';
import Preloader from '../../../components/preloader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Plot from 'react-plotly.js';
import OneOptAutocomplete from '../../recon/components/oneopt-autocomplete';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import {connect} from 'react-redux';
import {
      loadRuleSet,
      loadReconList,
      startReconiliation
    } from '../analytics.actions';


class RunsOverview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      freqRunView:"chart",
      mostDvpiew:"chart",
      dvpTimePeriod:{name:"7 Days",value:7},
      freqRunTimePeriod:{name:"7 Days",value:7},
      timePeriod:[
        {name:"7 Days",value:7},
        {name:"14 Days",value:14},
        {name:"30 Days",value:30}
      ]
    };
  }







   handleClick = (item) => {
            var clickedItem=this.props.reconList.length-item-1;
            this.props.onClick(this.props.reconList[clickedItem]);
          }


   dropdownSelector = (name,value) => {
                      this.setState({[name]:value});

                      this.props.runsOveriew(name,value)

                  }



          toggleClick=(item,event,value)=>{
            this.setState({
              [item]:value
            });
          }




   render() {

     let freqRunRulesetsList,freqRunRulesetsChart,mostDvpRulesetsList,mostDvpRulesetsChart;

     let freqRunRulesetsChartX=[];
     let freqRunRulesetsChartY=[];

     let mostDvpRulesetsChartX=[];
     let mostDvpRulesetsChartY=[];


       if(this.props.isLoading.includes('freqlist')){
         freqRunRulesetsChart=  <Preloader loading={true} /> ;
       }

       else{
           if(this.props.freqRunData.length!==0){
             freqRunRulesetsList=<Table className='data-table'>
               <TableHead>
                 <TableRow>
                   <TableCell>S.no</TableCell>
                   <TableCell>Ruleset name</TableCell>
                   <TableCell>Total runs</TableCell>
                 </TableRow>
               </TableHead>
               <TableBody>
                 {
                   this.props.freqRunData.map(runs => <TableRow key={runs.sno}>
                  <TableCell> {runs.sno} </TableCell>
                  <TableCell> {runs.ruleSetName} </TableCell>
                  <TableCell> {runs.totalRuns.toLocaleString()} </TableCell>
                  </TableRow>)
                }
               </TableBody>
              </Table>



              this.props.freqRunData.forEach((ruleset) => {
                freqRunRulesetsChartX.unshift(ruleset.totalRuns);
                freqRunRulesetsChartY.unshift(ruleset.ruleSetName)
              });

              let freqRunChartData = [
                {
                  x:freqRunRulesetsChartX,
                  y:freqRunRulesetsChartY,
                  name: 'Total runs',
                  type: 'bar',
                  orientation: 'h'
                }
              ];

              freqRunRulesetsChart=<Plot
                data={freqRunChartData}
                layout={{xaxis:{title:"Total runs"}}}
                />

            }
     }




     if(this.props.isLoading.includes('dvplist')){
       mostDvpRulesetsChart=  <Preloader loading={true} /> ;
     }

     else{
         if(this.props.dvpData.length!==0){
           mostDvpRulesetsList=<Table className='data-table'>
             <TableHead>
               <TableRow>
                 <TableCell>S.no</TableCell>
                 <TableCell>Ruleset name</TableCell>
                 <TableCell>Total DVP</TableCell>
               </TableRow>
             </TableHead>
             <TableBody>
                 {
                   this.props.dvpData.map(runs => <TableRow key={runs.sno}>
                     <TableCell> {runs.sno} </TableCell>
                     <TableCell> {runs.ruleSetName} </TableCell>
                     <TableCell> {runs.totalDvp.toLocaleString()} </TableCell>
                     </TableRow>)
                 }
             </TableBody>
            </Table>


            this.props.dvpData.forEach((ruleset) => {
              mostDvpRulesetsChartX.unshift(ruleset.totalDvp);
              mostDvpRulesetsChartY.unshift(ruleset.ruleSetName)
            });

            let mostDvpChartData = [
              {
                x:mostDvpRulesetsChartX,
                y:mostDvpRulesetsChartY,
                type: 'bar',
                orientation: 'h'
              }
            ];

            mostDvpRulesetsChart=<Plot
              data={mostDvpChartData}
              layout={{xaxis:{title:"Total DVP"}}}
              />
          }
   }





     return (

       <div>
       <Row className="extendable-height">
        <Col sm={6} xs={12}>
          <div className='rounded-card recon-results'>

            <Row>
               <Col sm={8} xs={8}><h4>Frequently run rulesets</h4></Col>
               <Col sm={4} xs={4} className="text-right">
               <ToggleButtonGroup
                   color="primary"
                   value={this.state.freqRunView}
                   exclusive
                   onChange={this.toggleClick.bind(this,"freqRunView")}
                 >
                   <ToggleButton value="chart">Chart view</ToggleButton>
                   <ToggleButton value="list">List view</ToggleButton>
                 </ToggleButtonGroup>
             </Col>
            </Row>
            <Row className='margin-top-0'>
             <Col sm={4} xs={12}>
              <OneOptAutocomplete
                   label='Time preiod'
                   fileColumns={this.state.timePeriod}
                   onSelectValue={this.dropdownSelector.bind(this,'freqRunTimePeriod')}
                   value={this.state.freqRunTimePeriod} />
            </Col>
            </Row>
            {
              this.state.freqRunView==="chart" ? freqRunRulesetsChart : freqRunRulesetsList
            }

          </div>
        </Col>
        <Col sm={6} xs={12}>
        <div className='rounded-card recon-results'>
        <Row>
           <Col sm={8} xs={8}><h4>Rulesets with most DVP</h4>

           </Col>
           <Col sm={4} xs={4} className="text-right">
           <ToggleButtonGroup
               color="primary"
               value={this.state.mostDvpiew}
               exclusive
               onChange={this.toggleClick.bind(this,"mostDvpiew")}
             >
               <ToggleButton value="chart">Chart view</ToggleButton>
               <ToggleButton value="list">List view</ToggleButton>
             </ToggleButtonGroup>
         </Col>
        </Row>
        <Row className='margin-top-0'>
         <Col sm={4} xs={12}>
          <OneOptAutocomplete
               label='Time preiod'
               fileColumns={this.state.timePeriod}
               onSelectValue={this.dropdownSelector.bind(this,'dvpTimePeriod')}
               value={this.state.dvpTimePeriod} />
        </Col>
        </Row>
        {
          this.state.mostDvpiew==="chart" ? mostDvpRulesetsChart : mostDvpRulesetsList
        }
        </div>
        </Col>



      </Row>

       </div>
     );
   }
}



export default RunsOverview;
