import axios from 'axios';
import Auth from '../../auth/auth';

export const settingsActions ={
  GET_RULESET_REQUEST : 'GET_RULESET_REQUEST',
  GET_RULESET_SUCCESS : 'GET_RULESET_SUCCESS',
  GET_RULESET_FAILURE : 'GET_RULESET_FAILURE',
  GET_RULESETCONTENT_REQUEST : 'GET_RULESETCONTENT_REQUEST',
  GET_RULESETCONTENT_SUCCESS : 'GET_RULESETCONTENT_SUCCESS',
  GET_RULESETCONTENT_FAILURE : 'GET_RULESETCONTENT_FAILURE',
  GET_RECON_REQUEST : 'GET_RECON_REQUEST',
  GET_RECON_SUCCESS : 'GET_RECON_SUCCESS',
  GET_RECON_FAILURE : 'GET_RECON_FAILURE',
  RELOAD_RECONLIST_SUCCESS: 'RELOAD_RECONLIST_SUCCESS',
  RECON_ADDRULESET_REQUEST : 'RECON_ADDRULESET_REQUEST',
  RECON_ADDRULESET_SUCCESS : 'RECON_ADDRULESET_SUCCESS',
  RECON_ADDRULESET_FAILURE : 'RECON_ADDRULESET_FAILURE',
  RECON_UPDATERULESET_REQUEST : 'RECON_UPDATERULESET_REQUEST',
  RECON_UPDATERULESET_SUCCESS : 'RECON_UPDATERULESET_SUCCESS',
  RECON_UPDATERULESET_FAILURE : 'RECON_UPDATERULESET_FAILURE',
  RECON_DUPLICATERULESET_REQUEST : 'RECON_DUPLICATERULESET_REQUEST',
  RECON_DUPLICATERULESET_SUCCESS : 'RECON_DUPLICATERULESET_SUCCESS',
  RECON_DUPLICATERULESET_FAILURE : 'RECON_DUPLICATERULESET_FAILURE',
  RECON_EXPORTRULESET_REQUEST : 'RECON_EXPORTRULESET_REQUEST',
  RECON_EXPORTRULESET_SUCCESS : 'RECON_EXPORTRULESET_SUCCESS',
  RECON_EXPORTRULESET_FAILURE : 'RECON_EXPORTRULESET_FAILURE',
  RECON_DELETERULESET_REQUEST : 'RECON_DELETERULESET_REQUEST',
  RECON_DELETERULESET_SUCCESS : 'RECON_DELETERULESET_SUCCESS',
  RECON_DELETERULESET_FAILURE : 'RECON_DELETERULESET_FAILURE'
}

const auth = new Auth();
const { getAccessToken } = auth;



//--------- Add new Rule -----------

export function addNewRuleset(data){
  return function(dispatch){
    dispatch({ type: settingsActions.RECON_ADDRULESET_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/comparizone/newrule',data,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then ((response) => dispatch({ type: settingsActions.RECON_ADDRULESET_SUCCESS,payload: response }))
      .catch((error) => dispatch({ type: settingsActions.RECON_ADDRULESET_FAILURE, payload: error, error: true }));
   }
}

//--------- Update Rule -----------

export function updateRuleset(data){
  return function(dispatch){
    dispatch({ type: settingsActions.RECON_UPDATERULESET_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/comparizone/editrule',data,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then ((response) => dispatch({ type: settingsActions.RECON_UPDATERULESET_SUCCESS,payload: response }))
      .catch((error) => dispatch({ type: settingsActions.RECON_UPDATERULESET_FAILURE, payload: error, error: true }));
   }
}


//--------- Delete Rule -----------

export function deleteRuleset(data){
  return function(dispatch){
    dispatch({ type: settingsActions.RECON_DELETERULESET_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/comparizone/deleteruleset',data,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
    .then ((response) => dispatch({ type: settingsActions.RECON_DELETERULESET_SUCCESS,payload: response }))
    .catch((error) => dispatch({ type: settingsActions.RECON_DELETERULESET_FAILURE, payload: error, error: true }));
   }
}


//--------- Export Rule -----------

export function exportRuleset(data){
  return function(dispatch){
    dispatch({ type: settingsActions.RECON_EXPORTRULESET_REQUEST});
      axios.post(process.env.REACT_APP_API_URL+'/comparizone/exportruleset',data,{
        responseType: 'blob',
        headers: {
          'x-access-token':getAccessToken()
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', data.name+'.sko');
        document.body.appendChild(link);
        link.click();
        dispatch({ type: settingsActions.RECON_EXPORTRULESET_SUCCESS});
      })
      .catch((error) => dispatch({ type: settingsActions.RECON_EXPORTRULESET_FAILURE, payload: error, error: true }));
   }
}


//--------- Duplicate Rule -----------

export function duplicateRuleset(data){
  return function(dispatch){
    dispatch({ type: settingsActions.RECON_DUPLICATERULESET_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/comparizone/duplicateruleset',data,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
    .then ((response) => dispatch({ type: settingsActions.RECON_DUPLICATERULESET_SUCCESS,payload: response }))
    .catch((error) => dispatch({ type: settingsActions.RECON_DUPLICATERULESET_FAILURE, payload: error, error: true }));
   }
}


//--------- Load Recon-----------

export function loadRecon(filename){
  return function(dispatch){
    dispatch({ type: settingsActions.GET_RECON_REQUEST });
  axios.get(process.env.REACT_APP_API_URL+'/comparizone/getExecutedFile?filename='+filename,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    }).then (response => response.data)
        .then ((json) => dispatch({ type: settingsActions.GET_RECON_SUCCESS, payload: json } ))
        .catch((error) => dispatch({ type: settingsActions.GET_RECON_FAILURE, payload: error, error: true }));
   }
}



//--------- Reload Watchlist group-----------

export function reloadReconList(json){
  return function(dispatch){
    dispatch({ type: settingsActions.RELOAD_RECONLIST_SUCCESS, payload: json })
  }
}
