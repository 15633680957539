import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Modal from '../../../components/modal';
import Preloader from '../../../components/preloader';
import EmptyState from '../../../components/empty-state-container';
import IconMenu from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import {connect} from 'react-redux';

let dsList;

class ManageDs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dbFilterCriteria:"",
      emptyState:"You do not have any data sources.",
      deleteVal:"",
      modalOpen:false,
      modalTitle:"",
      modalContent:"",
      modalPrimaryButton:"",
      modalSecondaryButton:""
    };
  }


componentDidMount=()=>{
  this.setState({
    dbFilterCriteria:""
  });
}




editDs = (ds) => {
    this.props.onClick({ds});
}

modalClose=()=>{
  this.setState({
    modalOpen:false
  });
}

modalConfirm=()=>{

  this.setState({
    modalOpen:false
  });
  this.props.deleteEnv(this.state.deleteVal);
}

deleteItem = (item) => {
    //this.props.getRuleSetContent(value);
    //this.props.onClick(value);
    this.setState({
      modalOpen:true,
      modalTitle:"Delete Data Source",
      modalContent:"Are you sure you want to delete \""+item.dsName+"\"?",
      modalPrimaryButton:"Yes",
      modalSecondaryButton:"No",
      modalState:"delete",
      deleteVal:item
    });
}

  createNewDs = () => {
    this.props.onClick('new');
}





   render() {




let dsList;

     const {isLoading} = this.props;
     let manageContent,dsListContent;

     if(isLoading.includes('dslist')){
       manageContent=<Preloader loading={true} /> ;
     }
     else if(this.props.dsList !== undefined){

      const filterCriteria=this.state.dbFilterCriteria;
      dsList = this.props.dsList.filter(function (e) {  return e.datasourceName.toLowerCase().includes(filterCriteria.toLowerCase()); } );


      if(dsList.length>0){
        dsListContent=<table className='manage-ruleset-table'>
        <tbody>
             {dsList.map((ds,index) => <tr key={index}>
               <td onClick={this.editDs.bind(this,ds)} className='user' >{ds.datasourceName}</td>
               <td>
               <IconMenu
                   iconButtonElement={<IconButton size="large"><i className='material-icons'>more_horiz</i></IconButton>}
                   anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                   targetOrigin={{horizontal: 'right', vertical: 'top'}}
                   className='actions-menu'
                 >
                   <MenuItem
                   primaryText="Delete"
                   className='wl-group-card-menu'
                   onClick={this.deleteItem.bind(this,ds)}  />
               </IconMenu>
                </td>
               </tr>)}
       </tbody>
        </table>
      }else{
        dsListContent=<EmptyState className="margin-top-0" message={this.state.emptyState} />
      }
       manageContent=<Row>

       <Col sm={4} xs={6}>{dsListContent}</Col>
       <Col sm={4} xs={6} className="manage-rule-buttons">

          <Button href=""  onClick={this.createNewDs.bind(this)} label="Add a new data source" className='createruleset-button'></Button>
       </Col>

       </Row>
     }

     return (
       <div>
       <Modal
        open={this.state.modalOpen}
        title={this.state.modalTitle}
        content={this.state.modalContent}
        primaryButton={this.state.modalPrimaryButton}
        secondaryButton={this.state.modalSecondaryButton}
        close={this.modalClose}
        confirm={this.modalConfirm}
        />
       <div className="manage-ruleset margin-top-30">

       {manageContent}

       </div>

         </div>

     );
   }
}


const mapStateToProps = (state) => {
  return{
    isLoading: state.databaseReducer.isLoading.concat(state.commonReducer.isLoading),
    errors: state.databaseReducer.errorMessage
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {

  }
}


export default connect(mapStateToProps,mapDispatchToProps) (ManageDs);
