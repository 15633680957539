import React, {Component} from 'react';
import ErrorMessageReload from './error-reload-container';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
     this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    this.setState({ hasError: true });
    //logErrorToMyService(error, info);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return  <ErrorMessageReload message="Oops! Something went wrong." />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
