import React, {Component} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from './button';

class Modal extends Component {
  handleClose = () => {
   this.props.close();
  };

  handleConfirm = () => {
   this.props.confirm();
  };

  render() {
    return (
      <Dialog
      open={this.props.open}
      onClose={this.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
      <DialogContent>
          {this.props.content}
      </DialogContent>
      <DialogActions className='btn-container'>
        <Button label={this.props.secondaryButton} className={this.props.secondaryButton==="null"?'hide':'btn btn-default'} onClick={this.handleClose}  />
        <Button label={this.props.primaryButton} className='btn btn-action'  onClick={this.handleConfirm}  />

      </DialogActions>
    </Dialog>
    );
  }
}


export default Modal;
