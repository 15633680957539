import React, {Component} from 'react';
import Button from '@mui/material/Button';

class CustomButton extends Component {

  handleChange = (event, index, value) => {
    this.props.onChange(value);
  }

  render() {
    return (
        <Button variant="contained" 
          disabled={this.props.disabled}
          onClick={this.props.onClick}
          className={this.props.className}
          >{this.props.label}</Button>
    );
  }
}


export default CustomButton;
