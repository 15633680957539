import React, { Component } from 'react';
import StyledEngineProvider from '@mui/styled-engine';
import {connect} from 'react-redux';
import { withRouter } from "react-router-dom";
import { Header } from "./layout/amplify-header";
import Main from './main';
import { withAuthenticator } from '@aws-amplify/ui-react';
import Author from './auth/auth';
import { Auth } from 'aws-amplify';
import Preloader from './components/preloader';

// import io from 'socket.io-client';

const auth = new Author();
const { setSession,isAuthenticated } = auth;

class App extends Component {


  constructor(props){
    super(props)
    setSession()
  }




  // constructor(){
  //   super()

  //   this.state = {
  //     endpoint: "http://localhost:5000/watchlist" // this is where we are connecting to with sockets
  //   }
  //    // method for emitting a socket.io event
  //   const socket = io(this.state.endpoint);

  // }

  // render() {
  //   return (
  //     <StyledEngineProvider injectFirst>
  //       <ThemeProvider>
  //             <div className="app-page-wrap" id="app">
  //                 <Main />
  //             </div>
  //       </ThemeProvider>
  //     </StyledEngineProvider>
  //   );
  // }



  render() {

    //await setSession() === 1 ? console.log("Set in true") : console.log("Set in false");

    //const { isAuthenticated} = this.props.auth;

          if(isAuthenticated()){
            return(
              <div className="app-page-wrap" id="app">
                <Main />
              </div>
            )
          } else {
            return(
              <div>
              <Preloader loading={true} />
              {window.location.reload()}
              </div>
            )

          }


  }

}


function mapStateToProps(state){
  return state;
}

export default withAuthenticator(withRouter(connect(mapStateToProps,null) (App)),{
  components: {
    Header
  }
});
