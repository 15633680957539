import React, { Component } from 'react';
import { Redirect } from 'react-router';
import caninsoftLogo from '../../images/caninsoft-logo.svg';
import {connect} from 'react-redux';
import CustomButton from '../../components/button';
import TextField from '@mui/material/TextField';
import ComponentErrorBoundary from '../../components/component-error-boundary';
import {
      loginRequest
    } from './login.actions';

import Author from '../../auth/auth';

const auth = new Author();
const { setSession } = auth;



class Login extends Component {

  constructor(props) {

    super(props);


    this.state = {
      username: '',
      password: '',
      loginButtonStatus: true,
      loginButtonLabel:"Log in",
      userIdError:"",
      passwordError:"",
      userIdErrorStatus:false,
      passwordErrorStatus:false,
      isRedirect:false
    };

  };







  componentDidUpdate(prevProps) {




    if(this.state.username.length>1 && this.state.password.length >1 && this.state.loginButtonStatus){
      this.setState({
       loginButtonStatus:false
      })
    }


  if (this.props.authError !== prevProps.authError) {
    if (this.props.authError === ""){
      this.setState({
        userIdError:"",
        passwordError:"",
        userIdErrorStatus:false,
        passwordErrorStatus:false,
        isRedirect:false
      })
    }
    else{
      this.setState({
        loginButtonLabel:"Login",
        userIdError:"Email Id is incorrect",
        passwordError:"Password is incorrect",
        userIdErrorStatus:true,
        passwordErrorStatus:true,
        isRedirect:false
      })
    }
  }
}



resetForm = () => {
  this.setState({
    userIdError:"",
    passwordError:"",
    loginButtonStatus:true,
    userIdErrorStatus:false,
    passwordErrorStatus:false
  })
  }



keyPressed=(event)=>{

  if (event.key === 'Enter' && !this.state.loginButtonStatus) {
    this.login();
    event.preventDefault();
    event.stopPropagation();
  }

}


  setTxtFieldValue = (event,name) => {
                       this.setState({
                         [name]:event.target.value,
                         userIdError:"",
                         passwordError:"",
                         userIdErrorStatus:false,
                         passwordErrorStatus:false
                       });
                       if(this.state.username.length>1 && this.state.password.length >1){
                         this.setState({loginButtonStatus: false  });
                       }
                       else{
                         this.setState({loginButtonStatus: true  });
                       }
                  }

  login=()=> {
    let credentials={
      email: this.state.username,
      password: this.state.password,
     }



     this.setState({
       loginButtonLabel:"Please wait...",
       isRedirect:true
     },()=>{this.props.sendLoginRequest(credentials);});

  }

  async signOut() {
      try {
          await Auth.signOut();
      } catch (error) {
          console.log('error signing out: ', error);
      }
  }



// async signingIn=()=> {
//
//   let credentials={
//     email: this.state.username,
//     password: this.state.password,
//   }
//
//    let username=this.state.username;
//    let password=this.state.password;
//
//    try {
//           const user = await Auth.signIn(username, password);
//       } catch (error) {
//           console.log('error signing in', error);
//       }
//   }

  render() {
    const { isAuthenticated,getContext,getRoleId } = this.props.auth;
    window.addEventListener("keydown", this.keyPressed);

    //console.log("isAuthenticated()",isAuthenticated(),'getContext()',getContext());

    // if(isAuthenticated() && getContext() !== null && getRoleId() !== 1 && this.state.isRedirect ){
    //   return(
    //     <Redirect to="/comparizone" />
    //   )
    // } else if(isAuthenticated() && getContext() === null && getRoleId() !== 1 && this.state.isRedirect ){
    //   return(
    //     <Redirect to="/context" />
    //   )
    // } else if(isAuthenticated() && getContext() === null && getRoleId() === 1 && this.state.isRedirect ){
    //   return(
    //     <Redirect to="/admin" />
    //   )
    // }

    if(isAuthenticated()){
      return(
        <Redirect to="/comparizone" />
      )
    }
    else{
      return(
        //<Redirect to="/login" />
        <div>{auth.logout()}</div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return{
    authError: state.loginReducer.authError
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    sendLoginRequest : (credentials) => {
      dispatch(loginRequest(credentials));
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps) (Login);
