  import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import Button from '../../../components/button';
import Modal from '../../../components/modal';
import DBConnections from './oneopt-autocomplete';
import TextField from '@mui/material/TextField';
import ComponentErrorBoundary from '../../../components/component-error-boundary';
import ErrorBoundary from '../../../components/error-boundary';
import Preloader from '../../../components/preloader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import QueryParameters from './query-parameters';


import {connect} from 'react-redux';

let testQueryResponse;

class DBQuery extends Component {

  constructor(props) {
    super(props);
    this.state = {
      qyName:"",
      qyDbValue:"",
      qyQueryValue:"",
      queryTestStatus:"",
      nameErrorStatus:false,
      nameErrorText:"",
      dbTablesList:[],
      saveButtonStatus:true,
      dbTablesSection:"hide",
      parameters:[],
      paramDuplicates:false,
      testQueryButtonState:true,
      formErrorState:['queryTestState','qyName'],
      testQueryErrorState:['qyDbValue','qyQueryValue'],
      deletedParam:[],
      modalOpen:false,
      modalTitle:"",
      modalContent:"",
      modalPrimaryButton:"",
      modalSecondaryButton:""
    };
  }


  componentDidUpdate(prevProps,prevState) {
  if (this.props.dbQuery !== prevProps.dbQuery && this.props.dbQuery.length !== 0) {
    this.setState({
      qyName:this.props.dbQuery.name,
      qyDbValue:{name:this.props.dbQuery.name,connId:this.props.dbQuery.connId},
      qyQueryValue:this.props.dbQuery.query,
      parameters:this.props.dbQuery.parameter,
      queryTestStatus:"",
      saveButtonStatus:true,
      testQueryButtonState:false,
      testQueryErrorState:[],
      formErrorState:['noUpdate'],
      deletedParam:[]
    })

    if(this.props.dbQuery.parameter.length>0){
      for(var i=0; i<this.props.dbQuery.parameter.length; i++){
        this.setState({[this.props.dbQuery.parameter[i].ref+this.props.dbQuery.parameter[i].name]:this.props.dbQuery.parameter[i]});
      }
    }

    //this.props.getDBTablesList({name:this.props.dbQuery[0].name,connId:this.props.dbQuery[0].connId});

  } else if(this.props.dbQuery !== prevProps.dbQuery && this.props.dbQuery.length === 0){
    this.resetForm();
  }

  if(prevProps.dbQueryClass !== this.props.dbQueryClass){
    if(this.props.dbQueryClass === "page-content results-container"){
      this.resetForm();
    }

  }

  if(this.props.queryTestStatus !== prevProps.queryTestStatus){
    this.setState({
      queryTestStatus:this.props.queryTestStatus,
    })


    this.props.queryTestStatus.status === true ? this.formErrorRemoveValue('formErrorState','queryTestState') : this.formErrorInsertValue('formErrorState','queryTestState');
  }


  if(this.props.dbFields !== prevProps.dbFields){
    this.setState({
      dbTablesList:this.props.dbFields,
    })
  }

  if(prevState.testQueryErrorState !== this.state.testQueryErrorState){this.enableTestButton();}
  if(prevState.formErrorState !== this.state.formErrorState){   this.enableSaveButton(); }


 }



 cancelFlow=()=>{
    this.props.cancelFlow();
 }

 resetForm=()=>{
   this.setState({
     qyName:"",
     qyDbValue:"",
     qyQueryValue:"",
     queryTestStatus:"",
     dbTablesList:[],
     newPassword:"",
     newPasswordErrorStatus:false,
     newPasswordErrorText:"",
     confirmPassword:"",
     confirmPasswordErrorStatus:false,
     confirmPasswordErrorText:"",
     saveButtonStatus:false,
     dbTablesSection:"hide",
     formErrorState:[]
   })
 }

 saveFlow=()=>{

   let queryObj={
     id:this.props.queryId,
     name: this.state.qyName,
     query: this.state.qyQueryValue,
     dbName:this.state.qyDbValue.name,
     parameter:this.aggrParamValues(),
     connId:this.state.qyDbValue.connId,
     orgId: Number(this.props.orgId)
   }

   //console.log("save parameters",queryObj);

   this.props.queryId=== null ? this.props.saveFlow(queryObj,'new',this.state.qyName) : this.props.saveFlow(queryObj,'update',this.state.qyName);


 }

 validateBeforeSave = () => {
   if(this.state.deletedParam.length>0){
     let deletedParamValues=[<div className='delete-param title' key='0001'>Are you sure you want to remove the following {this.state.deletedParam.length>1?'parameter(s)':'parameter'}?</div>]
     // --- get all deleted params ---
     this.state.deletedParam.forEach((param,index) => {
       deletedParamValues.push(<div key={index} className='delete-param'>{param.name} (Param for {param.ref})</div>)
     })

       this.setState({
         modalOpen:true,
         modalTitle:"Removed Parameter",
         modalContent:deletedParamValues,
         modalPrimaryButton:"Yes",
         modalSecondaryButton:"No"
       });
   }
   else{
     this.saveFlow();
   }
 }

 modalClose=()=>{
   this.setState({
     modalOpen:false
   });
 }

 modalConfirm=()=>{
   this.setState({
     modalOpen:false
   });
   this.saveFlow();
 }



  handleTextfield = (event,item) => {
      this.setState({[item]:event.target.value});
      this.formErrorRemoveValue('formErrorState','noUpdate');

      if(item==="qyQueryValue"){
        this.checkAndGeneratedParameters(event.target.value);
        event.target.value.length>5 ? this.formErrorRemoveValue("testQueryErrorState",item) : this.formErrorInsertValue("testQueryErrorState",item);
      //  this.setState({[item]:event.target.value},()=>{this.enableTestButton()});
      }
  }




  formErrorRemoveValue=(variable,attr)=>{
    if (this.state[variable].find(o => o === attr)){
      this.setState({[variable]:this.state[variable].filter(val=> val !== attr)});
    }
  }

  formErrorInsertValue=(variable,attr)=>{
    if (!this.state[variable].find(o => o === attr)){
      this.setState({[variable]:[...this.state[variable],attr]});
    }
  }


  validateName=()=>{

    if(this.state.qyName.length>2)
    {
      this.checkNameDuplicates(this.state.qyName);
    }
    else{

      this.formErrorInsertValue('formErrorState','qyName');
      this.setState({qyNameErrorStatus:true,qyNameErrorText:"Please enter a valid name."});
      return false;
    }

  }

  checkNameDuplicates=(values)=>{
    let hasMatchingName = this.props.queriesList.filter(function (item) { return item.name.toLowerCase() === values.toLowerCase(); });

    if(hasMatchingName.length !=0){
      if(hasMatchingName[0].queryId!==this.props.queryId){
        this.setState({qyNameErrorText:"You used this name already. Please try another.",qyNameErrorStatus:true});
        this.formErrorInsertValue('formErrorState','qyName');
        return false;
      } else{
        this.setState({qyNameErrorText:"",qyNameErrorStatus:false});
        this.formErrorRemoveValue('formErrorState','qyName');
        return true;
      }

    }

    else{
      this.setState({qyNameErrorText:"",qyNameErrorStatus:false});
      this.formErrorRemoveValue('formErrorState','qyName');
      return true;
    }
  }

  testQuery=()=>{

    let queryObj={
    testQuery: this.state.qyQueryValue,
    parameter:this.state.parameters,
    dbConnectionId: this.state.qyDbValue.connId,
    dbConnectionName: this.state.qyDbValue.name
    }

    //console.log("queryObj",this.state.formErrorState);
    this.props.testQueryConnect(queryObj);

  }






  enableSaveButton=()=>{
    if(this.state.formErrorState.length===0 && this.state.testQueryErrorState.length===0)
      {
      this.setState({saveButtonStatus:false});
      } else{
      this.setState({saveButtonStatus:true});
      }
}


enableTestButton=()=>{
this.state.testQueryErrorState.length ===0 ? this.setState({testQueryButtonState:false}) :this.setState({testQueryButtonState:true});
}

  dropdownSelector = (name,value) => {
              this.formErrorRemoveValue("testQueryErrorState",name);
              this.formErrorRemoveValue('formErrorState','noUpdate');
              this.setState({[name]:value});
              if(name==="qyDbValue"){
                this.setState({dbTablesSection:"hide"});
                this.formErrorInsertValue('formErrorState','queryTestState')
              }
            }

  showDBTables=()=>{
    this.props.getDBTablesList(this.state.qyDbValue);
    this.setState({dbTablesSection:"rounded-card"});

  }


  checkAndGeneratedParameters=(query)=>{

    let paramsArray=[];
    let paramVal="";
    let hasDuplicates;

    //let arrayOfEquations=query.match(/([^\s]*) = ([^\s]*)|([^\s]*)= ([^\s]*)|([^\s]*) =([^\s]*)|([^\s]*)=([^\s]*)/g);
    //let filteredArray = arrayOfEquations !== null && arrayOfEquations.length>0 ? arrayOfEquations.filter(function (str) { return str.includes("@@"); }) : null;

    let filteredArray=query.match(/([^\s]*)([ ]*)=([ ]*)(@@[^\s]*)|([^\s]*)=(@@[^\s]*)/g);

    if(filteredArray !== null && filteredArray.length>0) {



    filteredArray.forEach((param,index) => {
        paramVal={
          id:'n_'+index,
          name:param.split("=")[1].trim().substr(2),
          ref:param.split("=")[0].trim(),
          dataType:null,
          dateFormat:"",
          listValue:[],
          defaultValue:null,
          state:'incomplete'
        }
        this.state[param.split("=")[0].trim()+param.split("=")[1].trim().substr(2)]===undefined?this.setState({[param.split("=")[0].trim()+param.split("=")[1].trim().substr(2)]:paramVal}):paramVal=this.state[param.split("=")[0].trim()+param.split("=")[1].trim().substr(2)]
        paramsArray.push(paramVal);

    })
  }

    //console.log("paramsArray",paramsArray);

    if(paramsArray.length>0){
      const name = paramsArray.map(obj => obj.name);
      const set = new Set(name);
      hasDuplicates = set.size < paramsArray.length;
      this.formErrorInsertValue("testQueryErrorState","params");
    } else{
      this.formErrorRemoveValue("testQueryErrorState","params");
    }

    if(hasDuplicates) {this.setState({paramDuplicates:true}); this.formErrorInsertValue("testQueryErrorState","qyhasDupParams");} else {this.setState({paramDuplicates:false}); this.formErrorRemoveValue("testQueryErrorState","qyhasDupParams");}

    this.setState({parameters:paramsArray});

    //let uniqueParamsVal=paramsArray.filter(item1 => !this.state.tempParamsValue.find(item2 => (item2.ref === item1.ref && item2.name === item1.name)))

    //console.log("uniqueParamsVal",uniqueParamsVal);
    //this.setState(prevState => ({ parameters: prevState.parameters.concat(uniqueParamsVal) }));
    //this.setState({parameters:this.state.parameters.concat(uniqueParamsVal)});
  }


  checkDeletedParams=()=>{
    this.formErrorInsertValue('formErrorState','queryTestState');
    this.setState({queryTestStatus:""});
    let deletedParamsVal
    if(this.props.dbQuery.parameter !== undefined){
    if(this.props.dbQuery.parameter.length>0){
      deletedParamsVal=this.props.dbQuery.parameter.filter(item1 => !this.state.parameters.find(item2 => (item2.ref === item1.ref && item2.name === item1.name)))
    }
    deletedParamsVal === undefined ? deletedParamsVal=[] : null
    this.setState({deletedParam:deletedParamsVal});
  }
  this.checkParamsCompleteness();
}

  aggrParamValues=()=>{

    let params=[],paramValue;
    // --- get all new & updated params ---
    this.state.parameters.forEach((param,index) => {
      paramValue=this.state[param.ref+param.name];
      if(paramValue.state==='complete'){
        if(String(paramValue.id).substr(0,2)==="n_"){
           params.push(paramValue)
        } else{
          paramValue.id="u_"+paramValue.id
          params.push(paramValue)
        }
      }

    })

    // --- get all deleted params ---
    this.state.deletedParam.forEach((param,index) => {
      paramValue=param;
      paramValue.id="d_"+param.id
      params.push(paramValue)
    })


    return params;
  }


  updateParams=(id,value)=>{


  //this.formErrorInsertValue('formErrorState','queryTestState');
  this.formErrorRemoveValue('formErrorState','noUpdate');

  let arr=this.state.parameters;

  const index = this.state.parameters.findIndex((el) => el.id === id)

  this.state.parameters[index]=value;
  //String(value.id).substr(0,2) !== "n_" && value.state !== "incomplete" ? value.state==="updated" : null

  this.setState({[value.ref+value.name]:value});

  //const findOdds=this.state.parameters.find(v => v.state === "incomplete")
  //findOdds===undefined ? this.formErrorRemoveValue("testQueryErrorState","params") : this.formErrorInsertValue("testQueryErrorState","params");
  this.checkParamsCompleteness();

  }

  checkParamsCompleteness=()=>{
    const findOdds=this.state.parameters.find(v => v.state === "incomplete")
    findOdds===undefined ? this.formErrorRemoveValue("testQueryErrorState","params") : this.formErrorInsertValue("testQueryErrorState","params");
  }






   render() {

     let dbFieldDetails;

     let queryParametersContent="";

     const {isLoading} = this.props;
     if(isLoading.includes('testquery')){
       testQueryResponse=  <Preloader loading={true} /> ;
     } else if(this.state.queryTestStatus !== undefined && this.state.queryTestStatus !== "" ){

       if(this.state.queryTestStatus.status===true && this.state.queryTestStatus.response !== undefined){

       if(this.state.queryTestStatus.response.length!==0){

         let key = 0;

         //------------ Iterate table header -----------

         let tableHead=[],key1=0;
         var keyName;

         for (keyName in this.state.queryTestStatus.response[0]) {
         if (this.state.queryTestStatus.response[0].hasOwnProperty(keyName)) {
             key1 = key1+1;
             tableHead.push(<TableCell key={key1}>{keyName}</TableCell>);
           }
         }

         //------------ Iterate table body -----------

         let tableRow = this.state.queryTestStatus.response.map((row) => {
             var i;
             var colNumber=0
             var returnValue = [];
             for (i in row){
                 key = key+1;
                 returnValue.push(
                     <TableCell key={key}>
                        { row[i]}
                     </TableCell>
                 )
                 colNumber=colNumber+1;
             };
             key = key+1;
             return (<TableRow key={key} >
                 {returnValue}
                 </TableRow>
             );

         });


         testQueryResponse=<Row>
         <Col sm={12} xs={12}><div className="rounded-card">
         <div className="query-response-title">Sample data for your query</div>
         <div className="query-response-data">
         <Table className='data-table'>
         <TableHead><TableRow>{tableHead}</TableRow></TableHead>
          <TableBody>{tableRow}</TableBody>
          </Table>
          </div>
          </div></Col></Row>

     } else{
       testQueryResponse=<div className="query-response-title">Query is fine, however there is no data available. </div>
     }
   } else if(this.props.queryTestStatus.status===false){
     if(this.props.queryTestStatus.error.originalError !== undefined){
     if(this.props.queryTestStatus.error.originalError.info !== undefined){
       testQueryResponse=<ErrorBoundary><div><div className="query-response-title">Error: {this.props.queryTestStatus.error.originalError.info.message} </div></div></ErrorBoundary>
     } else if(this.props.queryTestStatus.error.originalError.message !== undefined){
       testQueryResponse=<ErrorBoundary><div><div className="query-response-title">Error: {this.props.queryTestStatus.error.originalError.message} </div></div></ErrorBoundary>
     }
   }else{
       testQueryResponse=<ErrorBoundary><div><div className="query-response-title">Error: Something went wrong. Please try again. </div></div></ErrorBoundary>
     }
   }
 } else{
   testQueryResponse="";
 }


   if(isLoading.includes('dbtableslist')){
     dbFieldDetails= <Preloader loading={true} /> ;
   }

   else{

   if(this.state.dbTablesList.length !==0){

   dbFieldDetails=this.state.dbTablesList.map((dbField,index) => <Accordion key={index}>
       <AccordionSummary expandIcon={<i className="material-icons collapsed">arrow_right</i>}>
         {dbField.name}
       </AccordionSummary>
       <AccordionDetails>
         <div>
                   {
                     dbField.fields !== undefined ?
                     <Table className='data-table'>
                       <TableHead>
                         <TableRow>
                           <TableCell>Field Name</TableCell>
                           <TableCell>Data Type</TableCell>
                         </TableRow>
                       </TableHead>
                       <TableBody>
                       {
                       dbField.fields.map((field,index) => <TableRow key={index}>
                         <TableCell> {field.name} </TableCell>
                         <TableCell> {field.datatype} </TableCell>
                         </TableRow>)
                      }
                   </TableBody>
                  </Table> :
                  <div>Details not found</div>
                   }
         </div>
       </AccordionDetails>
   </Accordion>);
  }

  else if(this.state.dbTablesList.length === 0){
    dbFieldDetails="We couldn't find any tables in the database."
  }
 }


 queryParametersContent=<div className="rounded-card params">
 <h4>Query Parameters</h4>
 <div className="note">Set the parameter data type and default value in order to test the query.</div>
 {
   this.state.paramDuplicates?<div className="error"><span>Your have duplicates parameter names. Please use unique names.</span></div> : null
 }
 {
   this.state.parameters.map((param,index) => <QueryParameters key={index} paramValues={this.state[param.ref+param.name]} updateParams={this.updateParams.bind(this)} deleted={false} checkParamsCompleteness={this.checkParamsCompleteness.bind(this)} />)
 }

 {
   this.state.deletedParam.map((param,index) => <QueryParameters key={index} paramValues={this.state[param.ref+param.name]} updateParams={this.updateParams.bind(this)} deleted={true} checkParamsCompleteness={this.checkParamsCompleteness.bind(this)} />)
 }
 </div>



     return (
       <div>
       <Modal
        open={this.state.modalOpen}
        title={this.state.modalTitle}
        content={this.state.modalContent}
        primaryButton={this.state.modalPrimaryButton}
        secondaryButton={this.state.modalSecondaryButton}
        close={this.modalClose}
        confirm={this.modalConfirm}
        />

       {
       this.props.isLoading.includes('querydetails') ? <Preloader loading={true} />  :
       <div> <Row className="">
        <Col sm={8} xs={12}>
        <div className="rounded-card">
        <Row>
         <Col sm={3} xs={12}>
          <DBConnections
               label='Select database'
               fileColumns={this.props.dbList}
               onSelectValue={this.dropdownSelector.bind(this,'qyDbValue')}
               value={this.state.qyDbValue} />
          <div className={this.state.qyDbValue===''? 'hide': 'show'}><a onClick={this.showDBTables.bind(this)}>Show database tables</a></div>
        </Col>
        </Row>

        <Row>
          <Col sm={12} xs={12}>
         <ComponentErrorBoundary value="DB Query Value">
         <TextField
          id="query"
          label="Your query"
          fullWidth
          multiline
          rows={20}
          value={this.state.qyQueryValue}
          onBlur={this.checkDeletedParams.bind(this)}
          onChange={(e)=>this.handleTextfield(e,"qyQueryValue")}
          variant="outlined" />
          </ComponentErrorBoundary>
          </Col>
        </Row>
        </div>
        </Col>
        <Col sm={4} xs={6}>
        <div className={this.state.dbTablesSection}>

              <div className="db-table-details">
              <h3>DB reference</h3>
              <div className="db-table-details-content">
                {dbFieldDetails}
              </div>
              </div>
        </div>
      </Col>
      </Row>
      {
        this.state.parameters.length>0 || this.state.deletedParam.length>0 ?
        <Row>
        <Col sm={12} xs={12}>
        {queryParametersContent}
        </Col>
        </Row>  : null
      }
      <Row>
      <Col sm={12} xs={12} className=''>

        <Button label="Test query"
        className='btn btn-default'
        disabled={this.state.testQueryButtonState}
        onClick={this.testQuery.bind(this)} />

      </Col>

      </Row>

       {testQueryResponse}

       <div className="rounded-card margin-top-30">
       <div className="note"><span>Note:</span> Please test the query and make sure you it's working in order to save it.</div>
       <Row>
       <Col sm={4} xs={12}>
       <ComponentErrorBoundary value="DB query name">
        <FormControl fullWidth>
         <InputLabel htmlFor="DB Id">Query name</InputLabel>
         <Input
           type="text"
           value={this.state.qyName}
           onChange={(e)=>this.handleTextfield(e,"qyName")}
           onBlur={this.validateName.bind(this)}
           error={this.state.qyNameErrorStatus}
         />
         <FormHelperText className="Mui-error">{this.state.qyNameErrorText}</FormHelperText>
        </FormControl>
        </ComponentErrorBoundary>
       </Col>
       </Row>
       <Row>
       <Col sm={4} xs={12} className=''>

        <Button label="Save" className='btn btn-action' disabled={this.state.saveButtonStatus} onClick={this.validateBeforeSave.bind(this)}  />

         <Button label="Cancel" className='btn btn-default' onClick={this.cancelFlow.bind(this)} />

       </Col>

       </Row>
       </div>
       </div>
       }



         </div>

     );
   }
}


export default DBQuery;
