import { reconActions } from './recon.actions';

import {removeItemFromArray} from "../../util/util";


export const initialState = {
    reconList: [],
    reconData: "",
    reconRunData:"",
    reconSummary:"",
    reconMatchedResults:"",
    reconMatchedResultsMainSource:"",
    reconUnmatchedResults:"",
    isLoading: [],
    downloadXlsContent:"",
    error:[],
    downloadingList:[],
    isDownloadListOpen:false,
    queryParam:[]
};

export function reconReducer (state= initialState, action){


  let status,downLoadList,downLoadListOpen;

  switch(action.type){

      //-------Recon list Request-------------
      case reconActions.GET_RECONLIST_REQUEST:
      return{
        ...state,
        isLoading:[...state.isLoading,'reconlist']
      };

      //-------Recon list Success-------------
      case reconActions.GET_RECONLIST_SUCCESS:
      return{
        ...state,
        reconList:action.payload.response.value,
        isLoading:removeItemFromArray(state.isLoading,'reconlist')
      };

      //-------Recon list Failure-------------
      case reconActions.GET_RECONLIST_FAILURE:
      status="";
      action.payload.response===undefined?status="404":status=action.payload.response.status;
      return{
        ...state,
        error:[...state.error,status]
      };

      //-------Reload Recon list Success-------------
      case reconActions.RELOAD_RECONLIST_SUCCESS:

      if(Array.isArray(action.payload)){
        //------ Array handling --------
        if(action.payload.length !==0){
        for(var i=0; i<action.payload.length;i++){

          state.reconList.forEach((element, index) => {
              if(String(element.id) === String(action.payload[i].id)) {
                  state.reconList[index].status = action.payload[i].status;
              }
            });
          }
        }


      } else{
        //------ Object handling --------
        if(action.payload.status==="Initiated"){
           state.reconList.unshift(action.payload);
        }
        else{
          state.reconList.forEach((element, index) => {
              if(element.id === action.payload.id) {
                  state.reconList[index].file = action.payload.file;
                  state.reconList[index].status = action.payload.status;
                  state.reconList[index].runtime = action.payload.runtime;
                  state.reconList[index].failCount = action.payload.failCount;
                  state.reconList[index].totalCount = action.payload.totalCount;
              }
            });

        }

      }





      return{
        ...state
      };



      //------- Start Reconcile Request-------------
      case reconActions.RECON_PROCESS_REQUEST:
      return{
        ...state
      };

      //-------  Reconcile Success-------------
      case reconActions.RECON_PROCESS_SUCCESS:
      return{
        ...state
      };

      //-------  Reconcile Failure-------------
      case reconActions.RECON_PROCESS_FAILURE:
      status="";
      action.payload.response===undefined?status="404":status=action.payload.response.status;
      return{
        ...state,
        error:[...state.error,status]
      };



      //------- Download Request-------------
      case reconActions.DOWNLOAD_XLS_REQUEST:
      return{
        ...state,
        isLoading:[...state.isLoading,'downloadxls'],
        downloadingList:[...state.downloadingList,action.downloadFileName],
        //isDownloadListOpen:true, for the download chips
        isDownloadListOpen:false
      };

      //------- Download Success-------------
      case reconActions.DOWNLOAD_XLS_SUCCESS:

      downLoadList=removeItemFromArray(state.downloadingList,action.downloadFileName);

      if(downLoadList.length===0){downLoadListOpen=false} else {downLoadListOpen = state.isDownloadListOpen}

      return{
        ...state,
        //downloadXlsContent:action.payload,
        isLoading:removeItemFromArray(state.isLoading,'downloadxls'),
        downloadingList:downLoadList,
        //isDownloadListOpen:downLoadListOpen for the download chips
        isDownloadListOpen:false

      };

      //------- Download Failure-------------
      case reconActions.DOWNLOAD_XLS_FAILURE:
      status="";
      action.payload.response===undefined?status="404":status=action.payload.response.status;
      return{
        ...state,
        error:[...state.error,status]
      };


      //------- Hide Download List-------------
      case reconActions.HIDE_DOWNLOAD_LIST:
      return{
        ...state,
        isDownloadListOpen:false
      };



      //-------Recon Request-------------
      case reconActions.GET_RECON_REQUEST:
      return{
        ...state,
        isLoading:[...state.isLoading,'recon']
      };

      //-------Recon Success-------------
      case reconActions.GET_RECON_SUCCESS:
      return{
        ...state,
        reconData:action.payload,
        reconRunData:action.payload.results,
        isLoading:removeItemFromArray(state.isLoading,'recon')
      };

      //-------Recon Failure-------------
      case reconActions.GET_RECON_FAILURE:
      status="";
      action.payload.response===undefined?status="404":status=action.payload.response.status;
      return{
        ...state,
        error:[...state.error,status]
      };

      //-------Param Request-------------
      case reconActions.GET_PARAMS_REQUEST:
      return{
        ...state,
        isLoading:[...state.isLoading,'param']
      };

      //-------Param Success-------------
      case reconActions.GET_PARAMS_SUCCESS:
      return{
        ...state,
        queryParam:action.payload.response,
        isLoading:removeItemFromArray(state.isLoading,'param')
      };

      //-------Param Failure-------------
      case reconActions.GET_PARAMS_FAILURE:
      status="";
      action.payload.response===undefined?status="404":status=action.payload.response.status;
      return{
        ...state,
        error:[...state.error,status]
      };



      //-------Get Selected Recon Summary Request-------------
      case reconActions.GET_SELCETED_RECON_SUMMARY_REQUEST:
      return{
        ...state,
        isLoading:[...state.isLoading,'reconSummary']
      };

      //-------Get Selected Recon Summary Success-------------
      case reconActions.GET_SELCETED_RECON_SUMMARY_SUCCESS:
      return{
        ...state,
        reconSummary:action.payload,
        isLoading:removeItemFromArray(state.isLoading,'reconSummary')
      };

      //-------Get Selected Recon Summary Failure-------------
      case reconActions.GET_SELCETED_RECON_SUMMARY_FAILURE:
      status="";
      action.payload.response===undefined?status="404":status=action.payload.response.status;
      return{
        ...state,
        error:[...state.error,status]
      };



      //-------Get Selected Recon Results Request-------------
      case reconActions.GET_SELCETED_RECON_RESULTS_REQUEST:
      return{
        ...state,
        isLoading:[...state.isLoading,'reconResults']
      };

      //-------Get Selected Recon Results Success-------------
      case reconActions.GET_SELCETED_RECON_RESULTS_SUCCESS:
      return{
        ...state,
        reconMatchedResults:action.payload,
        reconMatchedResultsMainSource:action.payload,
        isLoading:removeItemFromArray(state.isLoading,'reconResults')
      };

      //-------Get Selected Recon Results Failure-------------
      case reconActions.GET_SELCETED_RECON_RESULTS_FAILURE:
      status="";
      action.payload.response===undefined?status="404":status=action.payload.response.status;
      return{
        ...state,
        error:[...state.error,status]
      };




      //-------Get Selected Recon Exceptions Request-------------
      case reconActions.GET_SELCETED_RECON_EXCEPTIONS_REQUEST:
      return{
        ...state,
        isLoading:[...state.isLoading,'reconExceptions']
      };

      //-------Get Selected Recon Exceptions Success-------------
      case reconActions.GET_SELCETED_RECON_EXCEPTIONS_SUCCESS:
      return{
        ...state,
        reconUnmatchedResults:action.payload,
        isLoading:removeItemFromArray(state.isLoading,'reconExceptions')
      };

      //-------Get Selected Recon Exceptions Failure-------------
      case reconActions.GET_SELCETED_RECON_EXCEPTIONS_FAILURE:
      status="";
      action.payload.response===undefined?status="404":status=action.payload.response.status;
      return{
        ...state,
        error:[...state.error,status]
      };


      //-------Update Run details-------------
      case reconActions.UPDATE_RECONRUN_DETAILS:

      let selectedStatus = action.filter;
      let updatedReconRun=[];

      switch(selectedStatus){
        case "pass":
              for (let i = 0; i < state.reconData.results.length; i++) {
                if(state.reconData.results[i].hasFailedItem===false)
                  {
                    updatedReconRun.push(state.reconData.results[i]);
                 }
             }
          break;
          case "fail":
                for (let i = 0; i < state.reconData.results.length; i++) {
                  if(state.reconData.results[i].hasFailedItem===true)
                    {
                      updatedReconRun.push(state.reconData.results[i]);
                   }
               }
            break;
          case "all":
                  updatedReconRun=state.reconData.results;
              break;
        default:
          break;
      }



      return{
        ...state,
        reconRunData:updatedReconRun
      };


      //-------Clear All Data -------------
      case reconActions.CLEAR_ALL_DATA:
      return{
        ...state,
        reconList:[],
        reconData: "",
        reconRunData:"",
        reconSummary:"",
        reconMatchedResults:"",
        reconMatchedResultsMainSource:"",
        reconUnmatchedResults:"",
        isLoading: [],
        downloadXlsContent:"",
        error:[],
        downloadingList:[],
        isDownloadListOpen:false
      };


      default:
        return state;
  }
}
