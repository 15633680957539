import { commonActions } from './common.actions';
import {removeItemFromArray} from "../util/util";

export const initialState = {
    menuItems: [],
    selectedItemName: null,
    categoryItems: [],
    stageItems: [],
    suggestItems:[],
    isLoading: [],
    notificationItems:[],
    notificationItemsAll: [],
    error:[],
    ruleSetList: [],
    queryList:[],
    ruleSetContent:"",
    envList:[],
    dsList:[],
    userList: [],
    colsForQuery:[],
    dsDetails:"",
    contextList:[],
    envTypeList:[]
};

export function commonReducer (state = initialState , action){
  switch(action.type){

      //---------- Loading Menu ------------
      case commonActions.RECEIVE_MENU:
        return {
          ...state,
          menuItems:action.payload
        };

        //-------Notification Request-------------
        case commonActions.GET_NOTIFICATION_REQUEST:
        return{
          ...state,
          // isLoading:[...state.isLoading,'notification']
        };

        //-------Notification Success-------------
        case commonActions.GET_NOTIFICATION_SUCCESS:
        return{
          ...state,
          notificationItems:action.payload
          // isLoading:state.isLoading.splice(...state.isLoading.indexOf('notification'), 1 )
        };

        //-------Notification Success-------------
        case commonActions.GET_NOTIFICATION_RELOAD_SUCCESS:
        //  let notificationItemsAll = state.notificationItemsAll.push();
        let newNotificationItems = state.notificationItems;
        for(let index=0; index<action.payload.length; index++){
          newNotificationItems.push(action.payload[index]);
        }
        return{
          ...state,
          notificationItems:newNotificationItems
          // isLoading:state.isLoading.splice(...state.isLoading.indexOf('notification'), 1 )
        };

        //-------Notification Failure-------------
        case commonActions.GET_NOTIFICATION_FAILURE:
        return{
          ...state,
          errorMessage: action.payload.message
        };

        //---------- Loading All Category ------------
          case commonActions.GET_CATEGORY:
            return {
              ...state,
              categoryItems:action.payload
            };
          //---------- Loading All Stages ------------
          case commonActions.GET_STAGES:
            let stageItems = []
            for (let i = 0; i < state.categoryItems.length; i++) {
              for(let k = 0; k < state.categoryItems[i].subCategory.length; k++)
                {
                  stageItems.push(state.categoryItems[i].subCategory[k]);
               }
           }
            return {
              ...state,
              stageItems
            };
          //---------- Selecting a Category ------------
          case commonActions.SELECT_CATEGORY:
          let suggestItems = [], selectedItemName=null
          for (let i = 0; i < state.categoryItems.length; i++) {
            if(state.categoryItems[i].value===action.payload)
              {
                selectedItemName = state.categoryItems[i].name;
                suggestItems = state.categoryItems[i].Suggestions;
                stageItems = state.categoryItems[i].subCategory;
              }
         }
          return {
            ...state,
            selectedItemName,
            suggestItems,
            stageItems
          };
          //---------- Selecting a Stage ------------
          case commonActions.SELECT_STAGE:
          for (let i = 0; i < state.stageItems.length; i++) {
            if(state.stageItems[i].value === action.payload)
              {
                selectedItemName = state.stageItems[i].name;
                suggestItems = state.stageItems[i].Suggestions;
              }
         }
          return {
            ...state,
            selectedItemName,
            suggestItems
          };


          //-------Recon list Request-------------
          case commonActions.GET_RULESET_REQUEST:
          return{
            ...state,
            isLoading:[...state.isLoading,'rulsetlist']
          };

          //-------Recon list Success-------------
          case commonActions.GET_RULESET_SUCCESS:
          return{
            ...state,
            ruleSetList:action.payload,
            isLoading:removeItemFromArray(state.isLoading,'rulsetlist')

          };

          //-------Recon list Failure-------------
          case commonActions.GET_RULESET_FAILURE:
          status="";
          action.payload.response===undefined?status="404":status=action.payload.response.status;
          return{
            ...state,
            error:[...state.error,status]
          };


          //-------RulesetContent Request-------------
          case commonActions.GET_RULESETCONTENT_REQUEST:
          return{
            ...state,
            isLoading:[...state.isLoading,'rulsetcontent']
          };

          //-------RulesetContent Success-------------
          case commonActions.GET_RULESETCONTENT_SUCCESS:
          return{
            ...state,
            ruleSetContent:action.payload,
            isLoading:removeItemFromArray(state.isLoading,'rulsetcontent')
          };

          //-------RulesetContent Failure-------------
          case commonActions.GET_RULESETCONTENT_FAILURE:
          console.log("action.payload",action.payload);
          status="";
          action.payload.response===undefined?status="404":status=action.payload.response.status;
          return{
            ...state,
            error:[...state.error,status]
          };


          //-------Query list Request-------------
          case commonActions.GET_QUERY_LIST_REQUEST:
          return{
            ...state,
            isLoading:[...state.isLoading,'querylist']
          };

          //-------Query list Success-------------
          case commonActions.GET_QUERY_LIST_SUCCESS:
          return{
            ...state,
            queryList:action.payload.response,
            isLoading:removeItemFromArray(state.isLoading,'querylist')
          };

          //-------Query list Failure-------------
          case commonActions.GET_QUERY_LIST_FAILURE:
          status="";
          action.payload.response===undefined?status="404":status=action.payload.response.status;
          return{
            ...state,
            error:[...state.error,status]
          };

          //-------Env list Request-------------
          case commonActions.GET_ENV_LIST_REQUEST:
          return{
            ...state,
            isLoading:[...state.isLoading,'envlist']
          };

          //-------Env list Success-------------
          case commonActions.GET_ENV_LIST_SUCCESS:
          return{
            ...state,
            envList:action.payload.envList,
            isLoading:removeItemFromArray(state.isLoading,'envlist')
          };


          //-------Env list Failure-------------
          case commonActions.GET_ENV_LIST_FAILURE:
          status="";
          action.payload.response===undefined?status="404":status=action.payload.response.status;
          return{
            ...state,
            error:[...state.error,status]
          };

          //-------Data Source list Request-------------
          case commonActions.GET_DATA_SOURCE_LIST_REQUEST:
          return{
            ...state,
            isLoading:[...state.isLoading,'dslist']
          };

          //-------Data Source list Success-------------
          case commonActions.GET_DATA_SOURCE_LIST_SUCCESS:
          return{
            ...state,
            dsList:action.payload,
            isLoading:removeItemFromArray(state.isLoading,'dslist')
          };


          //-------Data Source list Failure-------------
          case commonActions.GET_DATA_SOURCE_LIST_FAILURE:
          status="";
          action.payload.response===undefined?status="404":status=action.payload.response.status;
          return{
            ...state,
            error:[...state.error,status]
          };

          //-------User list Request-------------
          case commonActions.GET_USER_LIST_REQUEST:
          return{
            ...state,
            isLoading:[...state.isLoading,'userlist']
          };

          //-------User list Success-------------
          case commonActions.GET_USER_LIST_SUCCESS:
          return{
            ...state,
            userList:action.payload.response.value,
            isLoading:removeItemFromArray(state.isLoading,'userlist')
          };

          //-------User list Failure-------------
          case commonActions.GET_USER_LIST_FAILURE:
          status="";
          action.payload.response===undefined?status="404":status=action.payload.response.status;
          return{
            ...state,
            error:[...state.error,status]
          };

          //-------Cols for Query Request-------------
          case commonActions.GET_COLS_FOR_QUERY_REQUEST:
          return{
            ...state,
            isLoading:[...state.isLoading,'colsforquery']
          };

          //-------Cols for Query Success-------------
          case commonActions.GET_COLS_FOR_QUERY_SUCCESS:
          return{
            ...state,
            colsForQuery:action.payload.response,
            isLoading:removeItemFromArray(state.isLoading,'colsforquery')
          };

          //-------Cols for Query Failure-------------
          case commonActions.GET_COLS_FOR_QUERY_FAILURE:
          status="";
          action.payload.response===undefined?status="404":status=action.payload.response.status;
          return{
            ...state,
            error:[...state.error,status]
          };


          //-------Load Data Source Details Request-------------
          case commonActions.LOAD_DATA_SOURCE_DETAILS_REQUEST:
          return{
            ...state,
            isLoading:[...state.isLoading,'dsdetails']
          };

          //-------Load Data Source Details Success-------------
          case commonActions.LOAD_DATA_SOURCE_DETAILS_SUCCESS:
          return{
            ...state,
            dsDetails:action.payload,
            isLoading:removeItemFromArray(state.isLoading,'dsdetails')
          };

          //-------Load Data Source Details Failure-------------
          case commonActions.LOAD_DATA_SOURCE_DETAILS_FAILURE:
          status="";
          action.payload.response===undefined?status="404":status=action.payload.response.status;
          return{
            ...state,
            error:[...state.error,status]
          };


          //-------Context list Request-------------
          case commonActions.GET_CONTEXT_LIST_REQUEST:
          return{
            ...state,
            isLoading:[...state.isLoading,'contextlist']
          };

          //-------Context list Success-------------
          case commonActions.GET_CONTEXT_LIST_SUCCESS:
          return{
            ...state,
            contextList:action.payload.contextList,
            isLoading:removeItemFromArray(state.isLoading,'contextlist')
          };


          //-------Query list Failure-------------
          case commonActions.GET_CONTEXT_LIST_FAILURE:
          status="";
          action.payload.response===undefined?status="404":status=action.payload.response.status;
          return{
            ...state,
            error:[...state.error,status]
          };

          //-------Load Env Type List Request-------------
          case commonActions.GET_ENV_TYPE_LIST_REQUEST:
          return{
            ...state,
            isLoading:[...state.isLoading,'envtypelist']
          };

          //-------Load Env Type List Success-------------
          case commonActions.GET_ENV_TYPE_LIST_SUCCESS:
          return{
            ...state,
            envTypeList:action.payload.envTypeList,
            isLoading:removeItemFromArray(state.isLoading,'envtypelist')
          };

          //-------Load Env Type List Failure-------------
          case commonActions.GET_ENV_TYPE_LIST_FAILURE:
          status="";
          action.payload.response===undefined?status="404":status=action.payload.response.status;
          return{
            ...state,
            error:[...state.error,status]
          };

          //-------Clear All Data -------------
          case commonActions.CLEAR_ALL_DATA:
          return{
            ...state,
            error:[]
          };



      default:
        return state;
  }
}
