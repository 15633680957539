import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import Dropdown from './oneopt-autocomplete';
import {Col, Row} from 'react-bootstrap';
import List from './list-autocomplete';
import MomentUtils from '@date-io/moment';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDateTimePicker,
//   KeyboardDatePicker,
//   DatePicker
// } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
const dataTypeValues = ['Text','List','Date'];

class QueryParameters extends Component {



  constructor(props) {
    super(props);
    this.state = {
      id:this.props.paramValues.id,
      name:this.props.paramValues.name,
      ref:this.props.paramValues.ref,
      dataType:this.props.paramValues.dataType,
      dateFormat:this.props.paramValues.dateFormat,
      listValue:this.props.paramValues.listValue,
      defaultValue:this.props.paramValues.defaultValue,
      state:this.props.paramValues.state,
      deleted:this.props.deleted,
      defaultValueState:false,
      defaultValueHelperText:"",
      dateFormatHelperText:""
    };
  }

  componentDidMount(){
  }


  componentDidUpdate(prevProps) {
  if (this.props.paramValues !== prevProps.paramValues) {
    this.setState({
      id:this.props.paramValues.id,
      name:this.props.paramValues.name,
      ref:this.props.paramValues.ref,
      dataType:this.props.paramValues.dataType,
      dateFormat:this.props.paramValues.dateFormat,
      listValue:this.props.paramValues.listValue,
      defaultValue:this.props.paramValues.defaultValue,
      state:this.props.paramValues.state,
    });
  }
}






  dropdownSelector = (name,value) => {

    this.setState({[name]:value});

    if(name === "dataType"){
      this.setState({listValue:[],dateFormat:"",defaultValue:null});
      let updateParamValues={
        id:this.state.id,
        name:this.state.name,
        ref:this.state.ref,
        dataType:value,
        dateFormat:"",
        listValue:[],
        defaultValue:null,
        state:"incomplete"
      }
      this.props.updateParams(this.state.id,updateParamValues);

    } else if(name === "listValue"){
      let updateParamValues={
        id:this.state.id,
        name:this.state.name,
        ref:this.state.ref,
        dataType:this.state.dataType,
        dateFormat:"",
        listValue:value,
        defaultValue:null,
        state:"incomplete"
      }
      this.props.updateParams(this.state.id,updateParamValues);
    }
    else{
      this.setState({[name]:value},()=>this.updateConfig(name,value));
    }


}






  handleTextfield = (event,item) => {
    this.setState({[item]:event.target.value});

    if(item==="dateFormat"){
      this.setState({dateFormatHelperText:"E.g. "+moment().format(event.target.value),defaultValue:null});
    }
  }


  updateConfig=(name,value)=>{
    let updateParamValues={
      id:this.state.id,
      name:this.state.name,
      ref:this.state.ref,
      dataType:this.state.dataType,
      dateFormat:this.state.dateFormat,
      listValue:this.state.listValue,
      defaultValue:this.state.defaultValue,
      state:"incomplete"
    }



    if(name==="defaultValue" && value !==""){
      updateParamValues.state="complete";
    } else if(name==="defaultValue" && value ===""){
      updateParamValues.state="incomplete";
    }

    updateParamValues[name]=value;
    this.props.updateParams(this.state.id,updateParamValues);
  }

  generateListValues=()=>{

  }

  checkParamsCompleteness=()=>{
    this.props.checkParamsCompleteness();
  }

  handleDateChange=(date)=>{
    var momentDate= moment(date).format(this.state.dateFormat);
    this.setState({defaultValue:momentDate});

    moment(momentDate,this.state.dateFormat, true).isValid() ? this.updateConfig("defaultValue",momentDate) : this.updateConfig("state","incomplete")
     }


  render() {
  return (
    <div className="param-container">
    <Row>
      <Col sm={3} xs={12} className="param-value">
      {this.state.name}
      <div>(Param for {this.state.ref})</div>
      {
        this.state.deleted?<div className='tag-delete'>Removed</div>:null
      }
      </Col>
      <Col sm={2} xs={12}>
      <Dropdown
           label='Data type'
           disabled={this.state.deleted}
           fileColumns={dataTypeValues}
           onSelectValue={this.dropdownSelector.bind(this,'dataType')}
           value={this.state.dataType} />
      </Col>
      {
        this.state.dataType !== null && this.state.dataType === "List" ?
        <Col sm={4} xs={12}>
        <List
        onSelectValue={this.dropdownSelector.bind(this,'listValue')}
        generateListValues={this.generateListValues.bind(this)}
        value={this.state.listValue}
        disabled={this.state.deleted}
        />
        </Col> : null
      }

      {
        this.state.dataType !== null && this.state.dataType === "Date" ?
        <Col sm={3} xs={12}>
        <TextField
          label="Date format"
          value={this.state.dateFormat}
          fullWidth
          onChange={(e)=>this.handleTextfield(e,"dateFormat")}
          onBlur={this.updateConfig.bind(this,"dateFormat",this.state.dateFormat)}
          helperText={this.state.dateFormatHelperText}
          disabled={this.state.deleted}
        />
        </Col> : null
      }



      {
        this.state.dataType !== null && this.state.dataType === "List" ?
        <Col sm={3} xs={12}>
        <Dropdown
             label='Default value'
             fileColumns={this.state.listValue}
             onSelectValue={this.dropdownSelector.bind(this,'defaultValue')}
             value={this.state.defaultValue}
               disabled={this.state.deleted} />
        </Col> : null
      }


      {
        this.state.dataType !== null && this.state.dataType === "Text" ?
        <Col sm={3} xs={12}>
        <TextField
          label="Default value"
          value={this.state.defaultValue===null?"":this.state.defaultValue}
          fullWidth
          onChange={(e)=>this.handleTextfield(e,"defaultValue")}
          onBlur={this.updateConfig.bind(this,"defaultValue",this.state.defaultValue)}
          helperText={this.state.defaultValueHelperText}
          disabled={this.state.deleted}
        />
        </Col> : null
      }


      {
        this.state.dataType !== null && this.state.dataType === "Date" ?
        <Col sm={3} xs={12}>
        {/* <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          disableToolbar
          autoOk={true}
          variant="inline"
          format={this.state.dateFormat}
          id="date-picker-inline"
          label="Default date"
          value={this.state.defaultValue}
          disabled={this.state.deleted}
          onChange={this.handleDateChange.bind(this)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
    </MuiPickersUtilsProvider> */}
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
         disableToolbar
         autoOk={true}
         variant="inline"
         format={this.state.dateFormat}
         id="date-picker-inline"
         label="Default date"
         value={this.state.defaultValue}
         disabled={this.state.deleted}
         onChange={this.handleDateChange.bind(this)}
         KeyboardButtonProps={{
           'aria-label': 'change date',
         }}
      />
    </LocalizationProvider>
        </Col> : null
      }

      </Row>
      </div>
  );
}
}

export default QueryParameters;
