import axios from 'axios';
import Auth from '../../auth/auth';

export const contextActions ={
  ADD_CONTEXT_REQUEST : 'ADD_CONTEXT_REQUEST',
  ADD_CONTEXT_SUCCESS : 'ADD_CONTEXT_SUCCESS',
  ADD_CONTEXT_FAILURE : 'ADD_CONTEXT_FAILURE',
  UPDATE_CONTEXT_REQUEST : 'UPDATE_CONTEXT_REQUEST',
  UPDATE_CONTEXT_SUCCESS : 'UPDATE_CONTEXT_SUCCESS',
  UPDATE_CONTEXT_FAILURE : 'UPDATE_CONTEXT_FAILURE',
  DELETE_CONTEXT_REQUEST : 'DELETE_CONTEXT_REQUEST',
  DELETE_CONTEXT_SUCCESS : 'DELETE_CONTEXT_SUCCESS',
  DELETE_CONTEXT_FAILURE : 'DELETE_CONTEXT_FAILURE',
  LOAD_CONTEXT_DETAILS_REQUEST : 'LOAD_CONTEXT_DETAILS_REQUEST',
  LOAD_CONTEXT_DETAILS_SUCCESS : 'LOAD_CONTEXT_DETAILS_SUCCESS',
  LOAD_CONTEXT_DETAILS_FAILURE : 'LOAD_CONTEXT_DETAILS_FAILURE',
  GET_ASSIGNED_CONTEXT_LIST_REQUEST:'GET_ASSIGNED_CONTEXT_LIST_REQUEST',
  GET_ASSIGNED_CONTEXT_LIST_SUCCESS:'GET_ASSIGNED_CONTEXT_LIST_SUCCESS',
  GET_ASSIGNED_CONTEXT_LIST_FAILURE:'GET_ASSIGNED_CONTEXT_LIST_FAILURE',
  SET_CONTEXT_REQUEST:'SET_CONTEXT_REQUEST',
  SET_CONTEXT_SUCCESS:'SET_CONTEXT_SUCCESS',
  SET_CONTEXT_FAILURE:'SET_CONTEXT_FAILURE'
}

const auth = new Auth();
const { getAccessToken } = auth;



//--------- Add Context Connection-----------

export function addContext(data){
  return function(dispatch){
    dispatch({ type: contextActions.ADD_CONTEXT_REQUEST });
     axios.post(process.env.REACT_APP_API_URL+'/createContext',data,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: contextActions.ADD_CONTEXT_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: contextActions.ADD_CONTEXT_FAILURE, payload: error, error: true }));
   }
}


//--------- Update Context Connection-----------

export function updateContext(data){
  return function(dispatch){
    dispatch({ type: contextActions.UPDATE_CONTEXT_REQUEST });
     axios.post(process.env.REACT_APP_API_URL+'/updateContext',data,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: contextActions.UPDATE_CONTEXT_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: contextActions.UPDATE_CONTEXT_FAILURE, payload: error, error: true }));
   }
}


//--------- Delete Context Connection -----------

export function deleteContext(data){
  return function(dispatch){
    dispatch({ type: contextActions.DELETE_CONTEXT_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/manageDb/deleteDBQuery',data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then ((response) => dispatch({ type: contextActions.DELETE_CONTEXT_SUCCESS,payload: response }))
      .catch((error) => dispatch({ type: contextActions.DELETE_CONTEXT_FAILURE, payload: error, error: true }));
   }
}



//--------- Load Context Details -----------

export function loadContextDetails(id){
  return function(dispatch){
    dispatch({ type: contextActions.LOAD_CONTEXT_DETAILS_REQUEST });
     axios.get(process.env.REACT_APP_API_URL+'/getContextDetails?contextId='+id,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: contextActions.LOAD_CONTEXT_DETAILS_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: contextActions.LOAD_CONTEXT_DETAILS_FAILURE, payload: error, error: true }));
   }
}


//--------- Load Assigned Context List-----------

export function loadAssgnContextList(){
  return function(dispatch){
    dispatch({ type: contextActions.GET_ASSIGNED_CONTEXT_LIST_REQUEST });
    axios.get(process.env.REACT_APP_API_URL+'/getContextSwitchList',{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then (response => response.data)
        .then ((json) => dispatch({ type: contextActions.GET_ASSIGNED_CONTEXT_LIST_SUCCESS, payload: json }))
        .catch((error) => dispatch({ type: contextActions.GET_ASSIGNED_CONTEXT_LIST_FAILURE, payload: error, error: true }));
   }
}


//--------- Set Context For User -----------

export function setContext(data){
  return function(dispatch){
    dispatch({ type: contextActions.SET_CONTEXT_REQUEST });
    axios.post(process.env.REACT_APP_API_URL+'/setContextForUser',data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':getAccessToken()
      }
    })
      .then ((response) => dispatch({ type: contextActions.SET_CONTEXT_SUCCESS,payload: response }))
      .catch((error) => dispatch({ type: contextActions.SET_CONTEXT_FAILURE, payload: error, error: true }));
   }
}
