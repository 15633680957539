import React, { Component } from 'react';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import {NavLink,withRouter } from "react-router-dom";
// import TouchRipple from '@mui/material/internal/TouchRipple';
import Tooltip from '@mui/material/Tooltip';
import {connect} from 'react-redux';
import {loadMenu} from '../common-redux/common.actions';
import caninsoftTreeLogo from '../images/caninsoft-tree-logo.svg';
import { Auth } from 'aws-amplify';
import Author from '../auth/auth';

const auth = new Author();
const { getRoleId } = auth;


class MainMenu extends Component {

constructor(props) {
      super(props);
      this.state = {
        open: true,
        docked:true,
        menuInput:'',
        contextOptionsMenu:false,
        contextMenuPosition:null
      };
    this.props.getMenuItems();
    }

componentDidMount() {
  this.setState({
    open: true,
    docked:true
  })
}

componentDidUpdate(prevProps) {
  if (this.props.isMobile !== prevProps.isMobile) {

        if(!prevProps.isMobile){
              this.setState({ open: false, docked:false })
          }

        else{
              this.setState({ open: true, docked:true })
          }
    }
}

handleContextMenu=(e)=>{
  this.setState({
    contextOptionsMenu: !this.state.contextOptionsMenu,
    contextMenuPosition:e.currentTarget
  });
}

closeContextMenu=()=>{
  this.setState({contextOptionsMenu: false});
}



  handleToggle = () => { this.setState({open: !this.state.open});  }

  shouldRefresh=(pagename) =>{
    let index = window.location.href.lastIndexOf("/")
    let currentPage = window.location.href.substring(index,window.location.href.length);
    if(pagename===currentPage){
      window.location.reload();
    }
  }

  handleClose = () => { //alert(this.className);
    if(this.props.isMobile===true) { this.setState({open: false});} }

  logout() {  auth.logout(); }



  render()
    {
      //const displayName = String(this.state.profile.nickname).replace(/\./g,' ');
      const displayName = localStorage.getItem('saganUsername');
      const currentContext = JSON.parse(localStorage.getItem('context'));


      return(
        <div className='menu-container'>
        <div className={(this.state.open ? 'mobile-menu-icon hidden-lg hidden-md hidden-sm clicked' : 'mobile-menu-icon hidden-lg hidden-md hidden-sm')} onClick={this.handleToggle}>
          {/* <TouchRipple> */}
              <span className='top'><hr /></span>
              <span className='middle'><hr /></span>
              <span className='bottom'><hr /></span>
          {/* </TouchRipple> */}
        </div>
        <Drawer
        //docked={this.state.docked}
        width={60}
        open={this.state.open}
        // overlayClassName={'menu-overlay'}
        variant={'permanent'}
        hideBackdrop={true}
        className='menu-content-container'
        //onRequestChange={(open) => this.setState({open})}
        >
        <div className="cs-in-app-logo"><img src={caninsoftTreeLogo} width="48" align="center" alt="Caninsoft" /></div>
        <div className='product-title'><span>CANINSOFT</span></div>
        {
          currentContext !== null ?
          <div className="context-menu">
            <NavLink exact to="/context" className='menu-item context-menu-select'><Tooltip title={"Your are in: "+currentContext.contextName} placement="right"><MenuItem className='menu-item' onClick={this.handleClose}>{currentContext.contextName.substr(0,1)}</MenuItem></Tooltip></NavLink>
          </div> : null
        }

        <div className='welcome'>
          <div className='welcome-text'>Welcome</div>
          <div className='user'>{displayName}</div>
        </div>
        {
          getRoleId() !== 1 ?
          this.props.menuItems.map((menuItems,index) => {
            if(menuItems.visible===true){
            return(
            <NavLink exact to={menuItems.url} activeClassName='active' className='menu-item' key={index}><Tooltip title={menuItems.label} placement="right"><MenuItem className='menu-item' onClick={this.shouldRefresh.bind(this,menuItems.url)}><span className='material-icons'>{menuItems.name}</span></MenuItem></Tooltip></NavLink>
             )
            }
            return null
          }) : null
        }


        {
          currentContext === null ? null :
          getRoleId() !== 1 && currentContext.userType=== 'SuperUser' ?
          <NavLink exact to="/superuser" className='menu-item'><Tooltip title={"Super User Panel"} placement="right"><MenuItem className='menu-item' onClick={this.handleClose}><span className='material-icons'>manage_accounts</span></MenuItem></Tooltip></NavLink> : null
        }

        {
          getRoleId()===1 ?
          <NavLink exact to="/admin" className='menu-item'><Tooltip title={"Admin Panel"} placement="right"><MenuItem className='menu-item' onClick={this.handleClose}><span className='material-icons'>admin_panel_settings</span></MenuItem></Tooltip></NavLink> : null
        }




      <div className='profile'>
      {
        getRoleId()===1 ?
        <NavLink exact to="/adminprofile" className='menu-item'><Tooltip title={"Hello "+displayName} placement="right"><MenuItem className='menu-item' onClick={this.handleClose}><span className='material-icons'>account_circle</span></MenuItem></Tooltip></NavLink> :
        <NavLink exact to="/profile" className='menu-item'><Tooltip title={"Hello "+displayName} placement="right"><MenuItem className='menu-item' onClick={this.handleClose}><span className='material-icons'>account_circle</span></MenuItem></Tooltip></NavLink>
      }

      </div>


      <div className='sign-out'>

       <div className='icon-button' onClick={this.logout.bind(this)}>
            <i className="material-icons">arrow_back</i>
            <div>Log out</div>
       </div>

      </div>

      </Drawer>
      </div>
      );
    }
}



const mapStateToProps = (state) => {
  return{
    menuItems: state.commonReducer.menuItems,
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    getMenuItems : () => {
      dispatch(loadMenu());
    }
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps) (MainMenu));
