import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
// import Button from '@mui/material/Button';
import Button from '../../../components/button';
import TextField from '@mui/material/TextField';
import Modal from '../../../components/modal';
import Preloader from '../../../components/preloader';
import EmptyState from '../../../components/empty-state-container';
import ComponentErrorBoundary from '../../../components/component-error-boundary';
import ManageRsMenu from './manage-rs-menu';

import {connect} from 'react-redux';
import {
      loadRuleSetContent
    } from '../../../common-redux/common.actions';

let rulesetList;



class ReconciliationManageRulesets extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalOpen:false,
      modalTitle:"",
      modalContent:"",
      modalPrimaryButton:"",
      modalSecondaryButton:"",
      deleteRulesetval:"",
      ruleFilterCriteria:"",
      importFileStatus:"hide",
      emptyState:"No rulesets found."
    };
  }



modalClose=()=>{
  this.setState({
    modalOpen:false
  });
}

modalConfirm=()=>{

  this.setState({
    modalOpen:false
  });
  let deleteObj={
    rulesetName: this.state.deleteRulesetval.name,
    id:this.state.deleteRulesetval.id
  }
  this.props.deleteRule(deleteObj);
}

editRuleset = (ruleset) => {
    this.resetError();
    this.props.onClick({id:ruleset.id,name:ruleset.name});
}

exportRuleset = (ruleset) => {
    this.props.exportRule({id:ruleset.id,name:ruleset.name});
}

deleteRuleset = (ruleset,menuId) => {
    //this.props.getRuleSetContent(value);
    //this.props.onClick(value);
    this.resetError();
    this.setState({
      modalOpen:true,
      modalTitle:"Delete ruleset",
      modalContent:"Are you sure you want to delete \""+ruleset.name+"\"?",
      modalPrimaryButton:"Yes",
      modalSecondaryButton:"No",
      deleteRulesetval:ruleset
    });
}

resetError=()=>{
  this.setState({
    importFileStatus:"hide"
  });
}

displayFileError=()=>{
  this.setState({
    importFileStatus:"show file-error"
  });
}

importRuleset = () => {

  var importedFile = document.getElementById('import-ruleset').files[0]

   if(importedFile !== undefined){

    if(importedFile.name.split('.').pop()==="sko"){
      this.setState({
        importFileStatus:"hide"
      });

    this.importFileReader(importedFile,this.props.importRule,this.displayFileError)

    }
    else{
      this.displayFileError();
    }
  }
  else {
    //  do nothing
  }
}

getCurrentDateTime=()=>{
  var d = new Date();
  var day = d.getDate();
  var month = d.getMonth() + 1;
  var year = d.getFullYear();
  var hour =  d.getHours();
  var minute =  d.getMinutes();
  var second =  d.getSeconds();


  if (month.toString().length < 2) month = '0' + month;
  if (hour.toString().length < 2) hour = '0' + hour;
  if (minute.toString().length < 2) minute = '0' + minute;
  if (second.toString().length < 2) second = '0' + second;

  return month + '/' + day + '/' + year + " @ " + hour + ":" + minute + ":" + second;

}


importFileReader=(importedFile,callback,displayFileError)=>{



      var reader = new FileReader();
      reader.onload = function (evt) {

        try{
          let importedJsonObject = JSON.parse(atob(evt.target.result));
          var currentdate = new Date();
          var datetime = " - Imported on " + ("0"+(currentdate.getMonth()+1)).slice(-2) + "/"
                  + ("0"+currentdate.getDate()).slice(-2) + "/"
                  + currentdate.getFullYear() + " @ "
                  + ("0"+currentdate.getHours()).slice(-2) + ":"
                  + ("0"+currentdate.getMinutes()).slice(-2) + ":"
                  + ("0"+currentdate.getSeconds()).slice(-2);


          let ruleObj={
            rulesetName: importedJsonObject.rulesetName + datetime,
            file1primary:importedJsonObject.file1primary,
            file2primary:importedJsonObject.file2primary,
            file1Columns:importedJsonObject.file1column,
            file2Columns:importedJsonObject.file2column,
            fileColumnsMerged:importedJsonObject.fileColumnsMerged,
            rules:importedJsonObject.rulesContent
          }
          callback(ruleObj)
        }
        catch(err){
          displayFileError();
          console.log("Error occured in file parsing - could be a invalid file.");
        }
      }

      reader.onerror = function (evt) {
        console.log("Error occured in file reading.");
      }


      reader.readAsText(importedFile, "UTF-8");
}


duplicateRuleset = (ruleset) => {

  let duplicateObj={
    id:ruleset.id,
    name:ruleset.name
  }
  this.props.duplicateRule(duplicateObj);

}


  createNewRule = () => {
  this.resetError();
  this.props.onClick('new');
}




   render() {

     const {isLoading} = this.props;
     let manageRulesContent,existingRulesContent;

     if(isLoading.includes('rulsetlist')){
       manageRulesContent=<Preloader loading={true} /> ;
     }
     else{

      const filterCriteria=this.state.ruleFilterCriteria;
      rulesetList = this.props.ruleSetList.filter(function (e) {  return e.name.toLowerCase().includes(filterCriteria.toLowerCase()); } );

      if(rulesetList.length>0){
        existingRulesContent=<table className='manage-ruleset-table'>
        <tbody>
             {rulesetList.map(ruleset => <tr key={ruleset.id}>
               <td onClick={this.editRuleset.bind(this,ruleset)} >{ruleset.name}</td>
               <td><ManageRsMenu ruleset={ruleset} deleteRuleset={this.deleteRuleset.bind(this)}  /></td>
               </tr>)}
       </tbody>
        </table>
      }else{
        existingRulesContent=<EmptyState className="margin-top-0" message={this.state.emptyState} />
      }
       manageRulesContent=<Row>

       <Col sm={4} xs={6}>{existingRulesContent}</Col>
       <Col sm={4} xs={6} className="manage-rule-buttons btn-container">
       <div>
        <Button href=""  onClick={this.createNewRule.bind(this)} label="Create a new Ruleset" className='createruleset-button'></Button>
       </div>

       <div className="import-button hide">
          <label htmlFor="import-ruleset">Import Ruleset</label>
          <input type="file" name="photo" id="import-ruleset" onChange={this.importRuleset.bind(this)}  />
          <div className={this.state.importFileStatus}>The file you imported appears invalid. Please try again.</div>
       </div>

       </Col>

       </Row>
     }

     return (
       <div className="manage-ruleset">

       <Modal
        open={this.state.modalOpen}
        title={this.state.modalTitle}
        content={this.state.modalContent}
        primaryButton={this.state.modalPrimaryButton}
        secondaryButton={this.state.modalSecondaryButton}
        close={this.modalClose}
        confirm={this.modalConfirm}
        />

        <Row className="search-section" id="searchBar"> <Col sm={4} xs={6}>
        <ComponentErrorBoundary value="ruleset list search field">
        <TextField
          label="Search an existing ruleset"
          fullWidth
          value={ this.state.ruleFilterCriteria }
          onChange={ e => this.setState({ ruleFilterCriteria:e.target.value }) }
          variant="standard"
        />
        </ComponentErrorBoundary>

        </Col>




        </Row>

       {manageRulesContent}



         </div>

     );
   }
}


const mapStateToProps = (state) => {
  return{
    isLoading: state.commonReducer.isLoading,
    ruleSetContent: state.settingsReducer.ruleSetContent,
    errors: state.settingsReducer.errorMessage
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getRuleSetContent : (value) => {
      dispatch(loadRuleSetContent(value));
    }
  }
}


export default connect(mapStateToProps,mapDispatchToProps) (ReconciliationManageRulesets);
