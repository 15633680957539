import React, {Component} from 'react';

class ComponentErrorBoundary extends Component {
  constructor(props) {
    super(props);
     this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    this.setState({ hasError: true });
    //logErrorToMyService(error, info);
  }
  render() {
  if (this.state.hasError) {
    console.log("Error occured in ",this.props.value);
    }

    return this.props.children;
  }
}

export default ComponentErrorBoundary;
