import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import ComponentErrorBoundary from '../../../components/component-error-boundary';

export default function Tags(props) {



  const handleKeyDown = event => {

    switch (event.key) {
      case "Enter": {
        event.preventDefault();
        event.stopPropagation();
        if (event.target.value.length > 0) {
          var mapVal=[...props.value,event.target.value];
          props.onSelectValue(mapVal);
        }
        break;
      }
      default:
    }
  };




  return (
    <div>
    <ComponentErrorBoundary value="value map autocomplete">
      <Autocomplete
        multiple
        freeSolo
        options={[]} 
        getOptionLabel={option => option}
        value={props.value}
        onChange={(event, newValue) => {props.onSelectValue(newValue);}}
        onBlur={()=>{props.checkRuleCompleteness();}}
        renderInput={params => {
          params.inputProps.onKeyDown = handleKeyDown;
          return (
            <TextField
              {...params}
              variant="standard"
              label="Your value map"
              placeholder="Type your value maps and press Enter For e.g. b=buy and press Enter"
              margin="normal"
              fullWidth
              className='valuemap-content'
            />
          );
        }}
      />
      </ComponentErrorBoundary>
    </div>
  );
}
