import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import QueryParamsComponent from './query-params-component';


let reconDisplayList,reconDisplayListLength;

class QueryParams extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }



updateParams=(id,attr,value)=>{
  let paramsObj=this.props.params;
  paramsObj.find(x => x.id === id)[attr]=value

  this.props.updateParamValues(this.props.source+'Params',paramsObj)
  //console.log("paramsObj",paramsObj);
}






   render() {

     return (
            <div className="cz-query-params">
              <div className="title">Set the query parameters</div>
            {
              this.props.params.map((param,index) => {
                return(<Row key={index}><Col sm={12} xs={12}><QueryParamsComponent param={param} updateParams={this.updateParams.bind(this)} /></Col></Row>);
              })
            }

             </div>
     );
   }
}


export default QueryParams;
